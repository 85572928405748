<color-ranges-bar [min]="min" [max]="max"></color-ranges-bar>

<section class="interval-action-container">
  <span
    class="interval-action"
    (click)="setFormVisibility()"
    afaqy-tooltip
    [tooltipText]="'tooltips.add' | translate"
  >
    <i class="fa fa-plus-circle clickOutsideExcept" aria-hidden="true"></i>
  </span>
  <span
    class="interval-action"
    (click)="resetColorRange()"
    afaqy-tooltip
    [tooltipText]="'tooltips.reset' | translate"
  >
    <i class="fa fa-undo" aria-hidden="true"></i>
  </span>
</section>

<color-ranges-form
  *ngIf="colorRangesFormService.isOpen"
  [min]="min"
  [max]="max"
  clickOutside
  (clickOutside)="colorRangesFormService.closeSensorForm()"
>
</color-ranges-form>
