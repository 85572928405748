import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  HostBinding,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { EventsService } from 'app/modules/events/services';
import { EventData } from 'app/modules/events/models';
import { NotificationDetailsService } from '../../services/notification-details.service';
import { AuthService } from 'app/core';
import { AppConfig, AfaqyHelper } from '../../../common/classes';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  showAnimation,
  HideAnimation,
} from 'app/shared/animations/show-hide-menu';
import { map, takeUntil, takeWhile } from 'rxjs/operators';
import { UnitService } from 'app/modules/units/services';
import { Subject } from 'rxjs';
import { PusherSocketService } from 'app/core/services/pusher-socket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  exportAs: 'AfaqyNotificationList',
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 1,
            hidepercent: 'calc(var(--direction-multiplier) * 100%)',
            showpercent: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 1,
            hidepercent: 'calc(var(--direction-multiplier) * 100%)',
            showpercent: '0',
          },
        }),
      ]),
    ]),
    trigger('fadeIn', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 0,
            hidepercent: '0',
            showpercent: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 0,
            hidepercent: '0',
            showpercent: '0',
          },
        }),
      ]),
    ]),
  ],
})
export class NotificationListComponent implements OnInit, OnDestroy {
  alive = true;
  eventsList: any[] = [];
  loading = true;
  loadingData = true;
  configs = AppConfig;
  @Output() eventDetails = new EventEmitter<EventData>(); //event that will be sent to parent component
  smallWidth = false;
  soundStatus: boolean;
  sliderStatus: boolean;
  @Input() NotificationsOpen: boolean;
  @Output() NotificationsState: EventEmitter<boolean> = new EventEmitter();
  @HostBinding('@showHide')
  @HostBinding('@fadeIn')
  animation = true;
  hasUnitsList: Subject<any> = new Subject();
  operationsList = [];
  unreadOperationsCount: number;
  unreadEventsCount: number;
  @ViewChild('importToFill', { read: ViewContainerRef })
  importToFill: ViewContainerRef;
  @Output() notificationImportToFill = new EventEmitter();

  constructor(
    public service: EventsService,
    private unitService: UnitService,
    private authService: AuthService,
    private notificationDetailsService: NotificationDetailsService,
    private pusherNotificationService: PusherSocketService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.authService.loadedSession.subscribe({
      next: () => {
        this.loadOperationsList();
        this.loadLastEventsData();
      },
    });

    // unread means get unread count from service
    this.soundStatus = this.authService.preferences('events', 'sounds', true);
    this.sliderStatus = this.authService.preferences('events', 'slider', false);
    // this.updateEventsList();
    this.service.events.subscribe({
      next: (flag) => {
        if (flag) this.updateEventsList();
      },
    });
    AfaqyHelper.smallWidth.pipe(takeWhile(() => this.alive)).subscribe({
      next: (response) => {
        this.smallWidth = <boolean>response;
      },
    });

    this.subscribeToPusherNotifications();

    this.translate.onLangChange
      .pipe(takeWhile(() => this.alive))
      .subscribe((lang: any) => {
        this.operationsList.map((operation: any) => {
          operation.notificationMessage = this.getOperationMessage(operation);
        });
      });
  }

  subscribeToPusherNotifications() {
    this.pusherNotificationService.notificationCenter$
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        if (res) {
          this.operationTypesIconUrl(res.data.operation);
          res.data.operation = 'operations.' + res.data.operation;
          res.data.notificationMessage = this.getOperationMessage(res.data);
          this.operationsList.unshift(res.data);
          this.unreadOperationsCount = this.operationsList.filter(
            (operation) => !operation.read
          ).length;
          this.service.unreadOperationCount$.next(this.unreadOperationsCount);
        }
      });
  }
  refactorOperationList(operationList: any) {
    operationList.map((operation: any) => {
      operation.operation = 'operations.' + operation.operation;
      operation.notificationMessage = this.getOperationMessage(operation);
      this.operationTypesIconUrl(operation);

      return operation;
    });

    return operationList;
  }

  getOperationMessage(operation) {
    let notification = '';

    switch (operation.operation) {
      case 'operations.import_completed':
        notification = this.translate.instant('operations.importCompleted', {
          total: operation.notification?.total,
          success: operation.notification?.success,
        });
        break;

      case 'operations.import_failed':
        notification = this.translate.instant('operations.importFailed', {
          total: operation.notification?.total,
          fails: operation.notification?.fails,
        });
        break;

      case 'operations.import_completed_with_failures':
        notification = this.translate.instant(
          'operations.importCompletedWithFailure',
          {
            total: operation.notification?.total,
            fails: operation.notification?.fails,
          }
        );
        break;

      case 'operations.copy_completed':
        notification = this.translate.instant('operations.copyCompleted', {
          total: operation.notification?.total,
          success: operation.notification?.success,
        });
        break;

      case 'operations.copy_failed':
        notification = this.translate.instant('operations.copyFailed', {
          total: operation.notification?.total,
          fails: operation.notification?.fails,
        });
        break;

      case 'operations.copy_completed_with_failures':
        notification = this.translate.instant(
          'operations.copyCompletedWithFailure',
          {
            total: operation.notification?.total,
            fails: operation.notification?.fails,
          }
        );
        break;

      case 'operations.package_exceeded':
        notification = this.translate.instant('operations.packageExceeded', {
          moduleName: this.translate.instant(
            `appModulesNames.${operation.notification.module}`
          ),
        });
        break;
    }

    return notification;
  }

  getModuleName(module: string) {
    return module;
  }

  operationTypesIconUrl(operation) {
    switch (operation.operation) {
      case 'operations.import_completed_with_failures':
      case 'operations.import_failed':
      case 'operations.copy_completed_with_failures':
      case 'operations.copy_failed': {
        operation.errorIcon = 'assets/icons/import-fail.svg';
        break;
      }
      case 'operations.import_completed':
      case 'operations.copy_completed': {
        operation.errorIcon = 'assets/icons/import-success.svg';
        break;
      }
      case 'operations.package_exceeded': {
        operation.errorIcon = 'assets/icons/package-exceeded.svg';
        break;
      }
    }
  }

  loadOperationsList() {
    this.service
      .loadOperationsData()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.operationsList = this.refactorOperationList(res.data);

            this.unreadOperationsCount = this.operationsList.filter(
              (operation) => !operation.read
            ).length;
            this.service.unreadOperationCount$.next(this.unreadOperationsCount);
          }
        },
        () => {
          this.service.pushNotification('Something went wrong', '', 'error');
        }
      );
  }

  loadLastEventsData() {
    this.service
      .loadLastList()
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (data) => {
          const elist = [...data];
          this.service.eventsList = [...elist];
          this.service.events.next(true);
          this.loadingData = false;
          this.loading = false;
        },
        error: () => {
          this.loadingData = false;
          this.loading = false;
        },
      });
  }

  loadFailedDataTable(operation: any) {
    operation.operation.includes('fail') &&
      this.notificationImportToFill.emit(operation);
  }

  toggleReadAll(read: string) {
    this.loading = true;
    this.operationsList.map((operation) => {
      operation.read = read === 'read';
      return operation;
    });

    const reqBody = {
      status: read,
      ids: [],
    };

    this.service
      .toggleReadStatus(reqBody)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        () => {
          this.unreadOperationsCount =
            read === 'unread' ? this.operationsList.length : 0;
          this.loading = false;
          this.service.unreadOperationCount$.next(this.unreadOperationsCount);
        },
        () => {
          this.service.pushNotification('Something went wrong', '', 'error');
          this.loading = false;
        }
      );
  }

  toggleNotificationReadStatus(id: string, read: boolean) {
    this.loading = true;
    this.unreadOperationsCount = read
      ? this.unreadOperationsCount + 1
      : this.unreadOperationsCount - 1;

    this.service.unreadOperationCount$.next(this.unreadOperationsCount);
    const req = {};
    let selectedOperation;
    let operation = this.operationsList.find((x) => x.id == id);
    if (operation) {
      selectedOperation = operation;
      operation.read = !operation.read;
    }

    req['status'] = read ? 'unread' : 'read';
    req['ids'] = [selectedOperation['id']];

    this.service
      .toggleReadStatus(req)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res) => {
          // this.loadOperationsList();
          this.loading = false;
        },
        () => {
          this.service.pushNotification('Something went wrong', '', 'error');
          this.loading = false;
        }
      );
  }

  toggleNotificationSider() {
    this.sliderStatus = !this.sliderStatus;
    this.authService.updatePreferences('events', 'slider', this.sliderStatus);
    this.service.sliderStatus.next(this.sliderStatus);
  }

  toggleNotificationSound() {
    this.soundStatus = !this.soundStatus;
    this.authService.updatePreferences('events', 'sounds', this.soundStatus);
  }

  updateEventsList() {
    if (this.service.eventsList.length > 0) {
      this.eventsList = [...this.service.eventsList];
      this.unreadEventsCount = this.eventsList.filter(
        (event) => !event.isread
      ).length;
      this.service.unreadEventCount$.next(this.unreadEventsCount);
    }
  }

  refresh() {
    const cmp = this;
    setTimeout(() => {
      cmp.updateEventsList();
    }, 250);
  }

  toogleRead(id: any) {
    const event = this.eventsList.find((item) => {
      return item['id'] == id;
    });
    this.unreadEventsCount = event.isread
      ? this.unreadEventsCount + 1
      : this.unreadEventsCount - 1;
    this.service.unreadEventCount$.next(this.unreadEventsCount);
    this.loading = true;
    this.service
      .updateStatus(event.isread ? 'unread' : 'read', [id])
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.loading = false;
        },
      });
  }

  /**
   * when event clicked , show it on map and set as read if unread
   * @param event Event object.
   */
  expandEvent(event: any) {
    this.loading = true;
    const eventData = this.eventsList.find((item) => {
      return item['id'] === event.id;
    });

    // set event as read if not read.
    if (!eventData.isread) {
      this.service
        .updateStatus('read', [event.id])
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: () => {
            this.emitEventDetails(event); // Emit the event value to home.
          },
        });
    } else {
      this.emitEventDetails(event); // Emit the event value to home.
    }
    this.closeModal();
  }

  /**
   * Emit value to eventDetails if units list has data.
   * @param event Event object.
   */
  private emitEventDetails(event: EventData): void {
    if (!event) return;

    if (
      this.unitService.resourcesList &&
      this.unitService.resourcesList.length
    ) {
      this.onEmitEventDetails(event);
      return;
    }

    this.unitService.resources
      .pipe(takeUntil(this.hasUnitsList))
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          if (
            this.unitService.resourcesList &&
            this.unitService.resourcesList.length
          ) {
            this.onEmitEventDetails(event);
            this.hasUnitsList.next();
          }
        },
      });
  }

  /**
   * Emit value to eventDetails and set false to loading property.
   * @param event Event object.
   */
  private onEmitEventDetails(event: any): void {
    if (!event) return;
    this.eventDetails.emit(event);
    this.loading = false;
  }

  getIdsList(status: any) {
    let ids = [];
    for (let item of this.eventsList) {
      if (item.isread == status) {
        ids.push(item.id);
      }
    }
    return ids;
  }

  readAll() {
    let ids = this.getIdsList(false);
    if (ids.length) {
      this.loading = true;
      this.service
        .updateStatus('read', ids)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: () => {
            //this.service.unreadEventCount = ids.length;
            this.loading = false;
          },
        });
    }
    this.unreadEventsCount = 0;
    this.service.unreadEventCount$.next(this.unreadEventsCount);
  }

  readAllNotes() {
    let ids = this.getIdsList(false);
    if (ids.length) {
      this.loading = true;
      this.service.updateStatus('read', []).subscribe({
        next: () => {
          this.service.unreadEventCount$.next(0);
          this.loading = false;
        },
      });
    }
  }

  unreadAll() {
    let ids = this.getIdsList(true);
    if (ids.length) {
      this.loading = true;
      this.service.updateStatus('unread', ids).subscribe({
        next: () => {
          //this.service.unreadEventCount = parseInt(response.extra["count"]);
          this.loading = false;
        },
      });
    }
    this.unreadEventsCount = this.eventsList.length;
    this.service.unreadEventCount$.next(this.unreadEventsCount);
  }

  // scrollPositionChanged(event: any) {
  //   // check of currently we are requesting service or not
  //   if (!this.loadingData && !this.service.notificationFullyLoaded) {
  //     // const lastDisplayedRow = this.grid.viewRange.row2;
  //     this.loadingData = true;
  //     this.loading = true;
  //     // request service :
  //     const param = { simplify: false };
  //     this.service.loadLastList(param).subscribe({
  //       next: (data) => {
  //         const elist = data;
  //         this.service.eventsList = elist;
  //         this.service.events.next(true);
  //         this.loadingData = false;
  //         this.loading = false;
  //       },
  //       error: () => {
  //         this.loadingData = false;
  //       },
  //     }); // unread means get unread count from service
  //   }
  // }

  closeEventPopup() {
    this.notificationDetailsService.hideNotification();
  }

  closeModal() {
    var l = document.getElementById('notifications-sidebar');
    for (var i = 0; i < 5; i++) {
      l.click();
    }
  }

  /* helper function for setting notifications state open or closed from the notifications itself */
  toggleNotificationshelper(status = false) {
    this.NotificationsOpen = !this.NotificationsOpen;
    this.NotificationsState.emit(status);
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.eventsList = [];
  }
}
