import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'assets/js/particles.js';
import * as particlesOptions from 'assets/json/particles.json';
import { AnimationOptions } from 'ngx-lottie';
import { takeWhile } from 'rxjs/operators';
import { AppConfig, Message } from '../../../common/classes';
import {
  HideAnimation,
  showAnimation,
} from '../../../shared/animations/scale-show-hide';
import { AfaqyAPIResponse } from '../../classes';
import { AuthService } from '../../services';

declare var particlesJS: any;

@Component({
  selector: 'forgot',
  styleUrls: ['./forgot.component.scss'],
  templateUrl: './forgot.component.html',
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: { timings: '300ms 200ms ease-in-out' },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: { timings: '200ms ease-in-out' },
        }),
      ]),
    ]),
  ],
})
export class ForgotComponent implements OnInit {
  alive: boolean = true;
  model: any = {};
  loading = false;
  configs = AppConfig;
  //syslang: string;
  login_anmation_src: string = 'assets/login.json';
  domain: string = window.location.href;

  @HostBinding('@showHide')
  animation = true;

  public lottieConfig: AnimationOptions;
  private anim: any;
  private animationSpeed: number = 1;

  constructor(
    private router: Router,
    private authService: AuthService,
    public message: Message
  ) {
    if (this.domain.includes('isuzu')) {
      this.login_anmation_src = 'assets/login_isuzu.json';
    }

    this.lottieConfig = {
      path: this.login_anmation_src,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      rendererSettings: {
        className: 'BgAnimation',
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }

  ngOnInit() {
    this.message.clear();

    particlesJS('particles-js', particlesOptions, null);
    this.authService.logout();
  }

  forgot() {
    this.loading = true;
    this.message.clear();
    this.authService
      .forgot(this.model.username)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (response: AfaqyAPIResponse) => {
          if (response.status_code == 200) {
            this.loading = false;
            //this.message.clear();
            this.message.message = response.extra.code;
            this.router.navigate(['/auth/reset']);
          } else {
            this.message.type = 'danger';
            this.message.message = response.errors.username;
            this.loading = false;
          }
        },
        error: (error) => {
          this.message.type = 'danger';
          this.message.message = error.message;
          this.loading = false;
        },
      });
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }
}
