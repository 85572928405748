import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';

import { AuthService } from '../../../../core/services';
import { AppModel, Message } from './../../../../common/classes';

import { Unit, UnitSensor } from 'app/modules/units/models';
import { UnitSensorsService, UnitService } from 'app/modules/units/services';
import { UnitFormBaseSubComponent } from 'app/shared/components/unitFormSub/unit-form-base-sub.component';

@Component({
  selector: 'unit-form-sensors',
  templateUrl: './unit-form-sensors.component.html',
  styleUrls: ['./unit-form-sensors.component.scss'],
})
export class UnitFormSensorComponent
  extends UnitFormBaseSubComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() group: FormGroup;

  cid = 'units-sensors-';
  filtersCols = ['name'];
  controller = 'unit_sensors';
  selectedItem: UnitSensor;
  moduleKey = 'sensors';

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public service: UnitSensorsService,
    public unitService: UnitService
  ) {
    super(route, authService, service, unitService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addForm() {
    super.addForm();
    this.selectedItem = new UnitSensor();
    this.openForm();
  }

  updateDefaultSpeed(currentItem) {
    if (currentItem.is_vss_default) {
      this.itemsList.sourceCollection = this.itemsList.sourceCollection.map(
        (item) => {
          item.is_vss_default = item.id === currentItem?.id;
          return item;
        }
      );
    }
  }

  update(event) {
    this.updateDefaultSpeed(event.object);
    if (!event.isAdd) {
      super.update(event);
    } else {
      let itemIndex = this.itemsList.sourceCollection.findIndex(
        (x) => x.id === event.object.id
      );
      if (itemIndex > -1) {
        this.itemsList.sourceCollection[itemIndex] = event.object;
        this.itemsList.refresh();
        this.showForm = false;
      } else {
        super.update(event);
      }
    }
    this.selectedItem = new UnitSensor();
  }

  updateSensorsList(item) {
    let sensor = item;
    this.unitObject.sensors = this.unitObject.sensors.filter(
      (sens) => sens.id != sensor.id
    );
    this.unitService.setResourceObject(this.unitObject);
  }

  afterDeleteSuccess(item) {
    super.afterDeleteSuccess(item);
    this.updateSensorsList(item);
  }
}
