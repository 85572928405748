import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { Chat } from '../models';
import {
  AfaqyAPIResponse,
  AfaqyResponse,
} from '../../../core/classes/afaqy-response';
import {
  throwError as observableThrowError,
  Observable,
  Subject,
  pipe,
  of,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UnitService } from '../../units/services/unit.service';
import { BookLogService } from '../../../core/services/book-log.service';

@Injectable({ providedIn: 'root' })
export class ChatService extends RootService {
  public updates: Subject<any> = new Subject<any>();

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    public unitService: UnitService,
    public bookLog: BookLogService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  routerPrefix(val: string = '') {
    return val ? val : 'chat';
  }

  get modelInstance() {
    return new Chat();
  }

  get cmdTypes() {
    return Chat.cmdTypes;
  }

  get gateways() {
    return Chat.gateways;
  }

  send(params) {
    params['user_id'] = this.getUserId();
    return this.apiRequest
      .authPost(this.getFunctionURL('send_message'), params)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqRes = new AfaqyResponse();
          reqRes.status(result.status_code);
          if (reqRes.success) {
            if (params['resource_type'] == 'unit') {
              this.logChat(result.data);
            }
            return reqRes;
          } else {
            throw new Error(JSON.stringify(reqRes));
          }
        }),
        catchError((err) => this.serverError(err))
      );
  }

  logChat(data) {
    let unit;
    if (data['sms_chat']) {
      unit = this.unitService.getItemFromResources(data['uId']);
      this.bookLog.log({
        resource: unit.name,
        type: 'command',
        message: data['sms_chat']['content'],
        dir: 1,
        loc: { lat: unit.last_update.lat, lng: unit.last_update.lng },
        date: data['sms_chat']['date'],
      });
    }
    if (data.gprs_chat) {
      if (!unit) {
        unit = this.unitService.getItemFromResources(data['uId']);
      }
      this.bookLog.log({
        resource: unit.name,
        type: 'command',
        message: data['gprs_chat']['content'],
        dir: 1,
        loc: { lat: unit.last_update.lat, lng: unit.last_update.lng },
        date: data['gprs_chat']['date'],
      });
    }
    this.updates.next();
  }

  log(updates) {
    let logs = [];
    for (let data of updates) {
      const unit = this.unitService.getItemFromResources(data['uId']);
      logs.push({
        resource: unit.name,
        type: 'command',
        message: data['cmd'],
        dir: data['type'],
        date: data['date'] / 1000,
        loc: { lat: unit.last_update.lat, lng: unit.last_update.lng },
        details: {
          eId: data['eId'],
          did: data['dId'],
        },
      });
    }
    this.bookLog.logBulk(logs);
  }
}
