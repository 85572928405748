import { AfaqyObject } from '../../../common/classes';

export class AppsSmartTaxi extends AfaqyObject {
  private _enable: boolean = false;
  private _taxiUserRole: string = 'client';

  protected copyFields = ['enable', 'taxiUserRole'];

  get enable(): boolean {
    return this._enable;
  }

  set enable(value: boolean) {
    this._enable = value;
  }

  get taxiUserRole(): string {
    return this._taxiUserRole;
  }

  set taxiUserRole(value: string) {
    this._taxiUserRole = value;
  }
}
