import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { AfaqyHelper, Message } from 'app/common';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { takeWhile } from 'rxjs/operators';
import { ColorRangeObject } from '../color-range';
import { ColorRangesBarService } from '../color-ranges-bar/color-ranges-bar.service';
import { ColorRangesFormService } from './color-ranges-form.service';

@Component({
  selector: 'color-ranges-form',
  templateUrl: './color-ranges-form.component.html',
  styleUrls: ['./color-ranges-form.component.scss'],
})
export class ColorRangesFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  alive: boolean = true;
  form: UntypedFormGroup;
  @Input() min: number; // Minimum value
  @Input() max: number; // Maximum value
  errorMessage: Message = new Message();

  constructor(
    public colorRangesBarService: ColorRangesBarService,
    private colorRangesFormService: ColorRangesFormService,
    private fb: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.updateForm();
  }

  get formFields(): any {
    return {
      from: [null, AfaqyValidation.numberValidatorIfexist],
      to: [null, AfaqyValidation.numberValidatorIfexist],
      color: ['#000000', AfaqyValidation.colorValidator],
    };
  }

  private createForm(): void {
    this.form = this.fb.group(this.formFields);
  }

  /** Subscribe on formData and set its value to color form. */
  private updateForm(): void {
    this.colorRangesFormService.formData
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (data: any) => {
          const formData: any = this.prepareFormData(data);
          this.form.reset(formData);
          this.form.updateValueAndValidity();
        },
      });
  }

  private prepareFormData(data: any): any {
    if (!data) return { from: null, to: null, color: '#000000' };

    const formData: any = { from: null, to: null };

    for (const key in formData) {
      if (!isNaN(data[key])) {
        formData[key] = data[key];
      }
    }

    data['color']
      ? (formData['color'] = data['color'])
      : (formData['color'] = '#000000');

    return formData;
  }

  private isValidColorRange(colorRange: ColorRangeObject): boolean {
    if (!colorRange) return;
    // Swap the received interval values, if it needs that.
    this.swapInterval(colorRange);

    // If valid color range, return true.
    if (this.isValidFrom(colorRange.from) && this.isValidTo(colorRange.to))
      return true;

    // Else, set error message and return false.
    this.errorMessage.message = 'invalid_color_range';
    this.errorMessage.type = 'danger';
    return false;
  }

  private isValidFrom(from: number): boolean {
    const isValidFromNumber: boolean = AfaqyHelper.isNumber(from);
    const isValidMinNumber: boolean = AfaqyHelper.isNumber(this.min);

    if (
      !isValidMinNumber ||
      (isValidMinNumber && isValidFromNumber && from >= this.min)
    )
      return true;
    return false;
  }

  private isValidTo(to: number): boolean {
    const isValidToNumber: boolean = AfaqyHelper.isNumber(to);
    const isValidMaxNumber: boolean = AfaqyHelper.isNumber(this.max);

    if (
      !isValidMaxNumber ||
      (isValidMaxNumber && isValidToNumber && to <= this.max)
    )
      return true;
    return false;
  }

  /** If from value greater than to value, swap them. */
  private swapInterval(colorRange: ColorRangeObject): void {
    if (!colorRange) return;
    const receivedFrom: number = parseFloat(colorRange.from);
    const receivedTo: number = parseFloat(colorRange.to);

    if (
      !isNaN(receivedFrom) &&
      !isNaN(receivedTo) &&
      receivedFrom > receivedTo
    ) {
      [colorRange['from'], colorRange['to']] = [
        colorRange['to'],
        colorRange['from'],
      ];
    }
  }

  onSubmit(): void {
    if (this.form.invalid) return;
    const colorRange: ColorRangeObject = { ...this.form.value };
    if (!this.isValidColorRange(colorRange)) return;
    this.colorRangesBarService.addIntervalColor(colorRange);
    this.colorRangesFormService.closeSensorForm();
    this.colorRangesFormService.isFormSubmitted.next(true);
  }

  onCancel(): void {
    this.colorRangesFormService.closeSensorForm();
  }

  onChangeColor(value: string): void {
    if (!value) return;
    this.colorCtl.setValue(value);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  get fromCtl(): FormControl {
    return this.form.controls['from'] as FormControl;
  }
  get toCtl(): FormControl {
    return this.form.controls['to'] as FormControl;
  }
  get colorCtl(): FormControl {
    return this.form.controls['color'] as FormControl;
  }
}
