import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { UnitDriverBehavior } from '../models';

@Injectable()
export class UnitDriverBehaviorService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  get limitForPageList() {
    return 0;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'unit_driver_behavior';
  }

  get modelInstance() {
    return new UnitDriverBehavior();
  }
}
