import { Injectable } from '@angular/core';
import { AfaqyHelper } from 'app/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { ColorRangeObject } from '../color-range';

@Injectable()
export class ColorRangesBarService {
  colorRangesObjects: ColorRangeObject[] = [];
  resultType: string;
  colorRanges: BehaviorSubject<ColorRangeObject[]> = new BehaviorSubject<
    ColorRangeObject[]
  >([]);
  doReset: Subject<boolean> = new Subject<boolean>();
  min: number; // Minimum value
  max: number; // Maximum value

  constructor() {}

  /** Sends `value` to colorRanges BehaviorSubject. */
  updateColorRanges(value: ColorRangeObject[]): void {
    // Deep clone `value` to `colorRanges`.
    let colorRanges: ColorRangeObject[] = [];
    value.map((object: ColorRangeObject) => colorRanges.push({ ...object }));
    //  Sends `colorRanges` to colorRanges BehaviorSubject.
    this.colorRanges.next([...colorRanges]);
    //  Sets `colorRanges` to `colorRangesObjects` variable.
    this.colorRangesObjects = [...colorRanges];
  }

  /** Return default color ranges. */
  getDefaultColorRanges(from: any = null, to: any = null): ColorRangeObject[] {
    return [{ from: from, to: to, color: '#000000' }];
  }

  reset(): void {
    this.doReset.next(true);
  }

  /** Regenerate array of color ranges and assign it to updateColorRanges fn. */
  addIntervalColor(value: ColorRangeObject): void {
    if (!value) return;
    const colorRange: ColorRangeObject = { ...value };
    colorRange.from = parseFloat(colorRange.from);
    colorRange.to = parseFloat(colorRange.to);

    // Override intervals handler.
    if (this.overrideIntervalsHandler(colorRange)) {
      return;
    }

    // Intersect interval handler.
    if (this.intersectIntervalHandler(colorRange)) {
      return;
    }
  }

  /** Override current intervals with the received interval and return true, if one of these conditions is valid:
   *  If received `from` and `to` values are empty.
   *  If the received 'from' and the `min` has the same value and received `to` has no value.
   *  If the received 'to' and the `max` has the same value and received `from` has no value.
   *  If the received 'from' and the `min` has the same value and the received 'to' and the `max` has the same value.
   */
  private overrideIntervalsHandler(colorRange: ColorRangeObject): boolean {
    if (!colorRange) return;
    const receivedFrom: number = parseFloat(colorRange.from);
    const receivedTo: number = parseFloat(colorRange.to);
    if (
      (!this.isNumber(receivedFrom) && !this.isNumber(receivedTo)) ||
      (this.isNumber(receivedFrom) &&
        this.isNumber(this.min) &&
        receivedFrom === this.min &&
        !this.isNumber(receivedTo)) ||
      (this.isNumber(receivedTo) &&
        this.isNumber(this.max) &&
        receivedTo === this.max &&
        !this.isNumber(receivedFrom)) ||
      (this.isNumber(receivedFrom) &&
        this.isNumber(this.min) &&
        receivedFrom === this.min &&
        this.isNumber(receivedTo) &&
        this.isNumber(this.max) &&
        receivedTo === this.max)
    ) {
      this.updateColorRanges([colorRange]);
      return true;
    }
    return false;
  }

  /** If range intersect the existing range, edit the intervals depends on its current value and return true. */
  private intersectIntervalHandler(colorRange: ColorRangeObject): boolean {
    if (
      !colorRange ||
      !this.colorRangesObjects ||
      !this.colorRangesObjects.length
    )
      return;
    let colorRanges: ColorRangeObject[] = [...this.colorRangesObjects];
    const hasMin: boolean = this.isNumber(this.min);
    const hasMax: boolean = this.isNumber(this.max);
    const receivedFrom: number = parseFloat(colorRange.from);
    const receivedTo: number = parseFloat(colorRange.to);
    const lastIndex: number = colorRanges.length - 1;
    const firstItemFrom: number = parseFloat(colorRanges[0].from);
    const firstItemTo: number = parseFloat(colorRanges[0].to);
    let secondtItemFrom: number;
    let secondItemTo: number;

    if (this.colorRangesObjects.length > 1) {
      secondtItemFrom = parseFloat(colorRanges[1].from);
      secondItemTo = parseFloat(colorRanges[1].to);
    }

    let startIndex: number; // Index of the item that contains the received 'from' value.
    let endIndex: number; // Index of the item that contains the received 'to' value.

    /* Set zero to startIndex if one of these conditions is valid:
     * If the received 'from' and the first item 'from' has no value.
     * If the received 'from' and the `min` has the same value.
     * If the first item has no 'from' value and the received 'from' smaller than or equal the first item 'to' value.
     */
    if (
      (!this.isNumber(receivedFrom) && !this.isNumber(firstItemFrom)) ||
      (this.isNumber(receivedFrom) && hasMin && receivedFrom === this.min) ||
      (!this.isNumber(firstItemFrom) && receivedFrom < firstItemTo) ||
      (!this.isNumber(firstItemFrom) &&
        receivedFrom === firstItemTo &&
        receivedFrom !== receivedTo &&
        secondtItemFrom !== secondItemTo)
    ) {
      startIndex = 0;
    }

    /** Set the last index of intervals to endIndex if one of these conditions is valid:
     *  If the received 'to' has no value.
     *  If the received 'to' and the `max` has the same value.
     */
    if (
      !this.isNumber(receivedTo) ||
      (this.isNumber(receivedTo) && hasMax && receivedTo === this.max)
    ) {
      endIndex = lastIndex;
    }

    /** Set zero to endIndex if the first item has no 'from' value and
        the received 'to' smaller than or equal the first item 'to' value. */
    if (
      !this.isNumber(firstItemFrom) &&
      receivedTo <= firstItemTo &&
      receivedFrom !== receivedTo
    ) {
      endIndex = 0;
    }

    /** Set zero to startIndex and endIndex if one of these conditions is valid:
     *  If the first item has no value 'infinity interval'.
     *  If the received 'from' and the `min` has the same value and the received 'to' and the `max` has the same value.
     */
    if (
      (!this.isNumber(firstItemFrom) && !this.isNumber(firstItemTo)) ||
      (this.isNumber(firstItemFrom) &&
        hasMin &&
        firstItemFrom === this.min &&
        this.isNumber(firstItemTo) &&
        hasMax &&
        firstItemTo === this.max)
    ) {
      startIndex = endIndex = 0;
    }

    /** Comparing received 'from' and 'to' with other intervals to get startIndex and endIndex. */
    for (let i = 0; i < colorRanges.length; i++) {
      if (this.isNumber(startIndex) && this.isNumber(endIndex)) break;
      const itemFrom: number = parseFloat(colorRanges[i].from);
      const itemTo: number = parseFloat(colorRanges[i].to);

      /** If the item values are the same received interval values, set item index to startIndex and endIndex. */
      if (itemFrom === receivedFrom && itemTo === receivedTo) {
        startIndex = endIndex = i;
      }

      /* Set i to startIndex if one of these conditions is valid:
       * If the received 'from' greater than the item 'from' and the item has no 'to' value.
       * If the received 'from' greater than or equal the item 'from' and smaller than the item 'to'.
       */
      if (
        (!this.isNumber(startIndex) &&
          receivedFrom >= itemFrom &&
          !this.isNumber(itemTo)) ||
        (receivedFrom >= itemFrom && receivedFrom < itemTo)
      ) {
        startIndex = i;
      }

      /* Set i to endIndex if one of these conditions is valid:
       * If the received 'to' greater than or equal the item 'from' and the item has no 'to' value.
       * If the received 'to' greater than or equal the item 'from' and smaller than or equal the item 'to'.
       */
      if (
        (!this.isNumber(endIndex) &&
          receivedTo >= itemFrom &&
          !this.isNumber(itemTo)) ||
        (!this.isNumber(itemFrom) && receivedTo <= itemTo) ||
        (receivedTo >= itemFrom && receivedTo <= itemTo)
      ) {
        endIndex = i;
      }
    }

    const currentColorRanges: ColorRangeObject[] = [...colorRanges];
    let colorsBeforeIntersection: any[] = [
      ...currentColorRanges.slice(0, startIndex),
    ];
    let colorsAfterIntersection: any[] = [];
    let intervalBeforeTheReceived: any;
    let intervalAfterTheReceived: any;

    /** One index intersected */
    /** If start index equal end index. */
    if (
      this.isNumber(startIndex) &&
      this.isNumber(endIndex) &&
      startIndex === endIndex
    ) {
      let intersectedInterval: ColorRangeObject =
        currentColorRanges[startIndex];
      const intersectedFrom: number = parseFloat(intersectedInterval.from);
      const intersectedTo: number = parseFloat(intersectedInterval.to);
      colorsAfterIntersection = [
        ...currentColorRanges.slice(startIndex + 1, currentColorRanges.length),
      ];

      // If the intersected interval is the same received interval, change only the color.
      if (
        (intersectedFrom === receivedFrom && intersectedTo === receivedTo) ||
        (intersectedFrom === receivedFrom &&
          !this.isNumber(intersectedTo) &&
          !this.isNumber(receivedTo)) ||
        (!this.isNumber(intersectedFrom) &&
          !this.isNumber(receivedFrom) &&
          intersectedTo === receivedTo)
      ) {
        intersectedInterval.color = colorRange.color;
        colorRanges = [...currentColorRanges];
        this.updateColorRanges(colorRanges);
        return true;
      }

      // If the intersected interval 'from' equal the received 'from'.
      if (
        intersectedFrom === receivedFrom ||
        (!this.isNumber(intersectedFrom) && !this.isNumber(receivedFrom))
      ) {
        intervalAfterTheReceived = {
          from: receivedTo,
          to: intersectedTo,
          color: intersectedInterval.color,
        };
        colorRanges = [
          ...colorsBeforeIntersection,
          colorRange,
          intervalAfterTheReceived,
          ...colorsAfterIntersection,
        ];
        this.updateColorRanges(colorRanges);
        return true;
      }

      // If intersected interval 'to' equal the received 'to'.
      if (
        intersectedTo === receivedTo ||
        (!this.isNumber(intersectedTo) && !this.isNumber(receivedTo))
      ) {
        intervalBeforeTheReceived = {
          from: intersectedFrom,
          to: receivedFrom,
          color: intersectedInterval.color,
        };
        colorRanges = [
          ...colorsBeforeIntersection,
          intervalBeforeTheReceived,
          colorRange,
          ...colorsAfterIntersection,
        ];
        this.updateColorRanges(colorRanges);
        return true;
      }

      // If the received interval between 'from' and 'to' of the intersected interval.
      intervalBeforeTheReceived = {
        from: intersectedFrom,
        to: receivedFrom,
        color: intersectedInterval.color,
      };
      intervalAfterTheReceived = {
        from: receivedTo,
        to: intersectedTo,
        color: intersectedInterval.color,
      };
      colorRanges = [
        ...colorsBeforeIntersection,
        intervalBeforeTheReceived,
        colorRange,
        intervalAfterTheReceived,
        ...colorsAfterIntersection,
      ];
      this.updateColorRanges(colorRanges);
      return true;
    }

    /** Two indexes intersected */
    colorsAfterIntersection = [
      ...currentColorRanges.slice(endIndex + 1, currentColorRanges.length),
    ];
    const secondIntersectedInterval: any = currentColorRanges[endIndex];
    const secondIntersectedFrom: number = parseFloat(
      secondIntersectedInterval.from
    );
    const secondIntersectedTo: number = parseFloat(
      secondIntersectedInterval.to
    );
    let intervalsBeforeTheReceived: any[] = [];
    if (!this.isNumber(startIndex)) {
      colorsBeforeIntersection = [];
    }

    if (startIndex >= 0) {
      const firstIntersectedInterval: any = currentColorRanges[startIndex];
      const firstIntersectedFrom: number = parseFloat(
        firstIntersectedInterval.from
      );
      const firstIntersectedTo: number = parseFloat(
        firstIntersectedInterval.to
      );
      if (
        firstIntersectedFrom < receivedFrom ||
        (!this.isNumber(firstIntersectedFrom) &&
          receivedFrom <= firstIntersectedTo)
      ) {
        intervalsBeforeTheReceived = [
          {
            from: firstIntersectedFrom,
            to: receivedFrom,
            color: firstIntersectedInterval.color,
          },
        ];
      }
    }

    // If second intersected interval 'to' equal the received 'to'.
    if (
      secondIntersectedTo === receivedTo ||
      (!this.isNumber(secondIntersectedTo) && !this.isNumber(receivedTo))
    ) {
      colorRanges = [
        ...colorsBeforeIntersection,
        ...intervalsBeforeTheReceived,
        colorRange,
        ...colorsAfterIntersection,
      ];
      this.updateColorRanges(colorRanges);
      return true;
    }

    // If the received 'to' between second intersected interval 'from' and 'to' values.
    if (
      receivedTo > secondIntersectedFrom &&
      (receivedTo < secondIntersectedTo || !this.isNumber(secondIntersectedTo))
    ) {
      intervalAfterTheReceived = {
        from: receivedTo,
        to: secondIntersectedTo,
        color: secondIntersectedInterval.color,
      };
      colorRanges = [
        ...colorsBeforeIntersection,
        ...intervalsBeforeTheReceived,
        colorRange,
        intervalAfterTheReceived,
        ...colorsAfterIntersection,
      ];
      this.updateColorRanges(colorRanges);
      return true;
    }

    return false;
  }

  private isNumber(value: any): boolean {
    return AfaqyHelper.isNumber(value);
  }
}
