import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreFormComponent } from 'app/common/core-form-compenent';
import { AuthService } from 'app/core';
import { UserService, VerifyOTPService } from 'app/modules/users/services';
import * as moment from 'moment';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription, interval } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import {
  OtpMethodParameters,
  OTPVerification,
} from 'app/shared/interfaces/interfaces';

@Component({
  selector: 'verify-account-otp',
  templateUrl: './verify-account-otp.component.html',
  styleUrls: ['./verify-account-otp.component.scss'],
})
export class VerifyAccountOTP
  extends CoreFormComponent
  implements AfterViewInit
{
  @ViewChild('otpInput', { static: false }) otpInput: any;
  @Output() otpInputValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() otpModel: OTPVerification;
  @Input() resendOtpUrl: string;
  @Input() resendOtpToken: string;
  hideModal = false;
  backgroundEvent: Subscription;
  remainingResendTime: { value: number; text: string };
  remainingVerifyTime: { value: number; text: string };
  formattedExpiresIn: any;
  otpStatus = 'default'; // Initial state
  private resendTimerSubscription: Subscription;
  private verifyTimerSubscription: Subscription;
  otpInputConfig: NgxOtpInputConfig = {
    autofocus: false,
    otpLength: 6,
  };

  constructor(
    translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    fb: UntypedFormBuilder,
    protected authService: AuthService,
    service: UserService,
    private verifyOTPService: VerifyOTPService
  ) {
    super(translate, route, router, fb, service);
    this.cid = 'otp-verify-account';
  }
  modalClose() {
    this.hideModal = true;
  }

  ngOnInit() {
    this.verifyOTPService.triggerResendTimer
      .pipe(filter((x) => x))
      .subscribe(() => {
        this.handlingResendExpiryInTimer(
          this.otpModel.method[this.otpModel.type]
        );
      });
    this.verifyOTPService.triggerVerifyTimer
      .pipe(filter((x) => x))
      .subscribe(() => {
        this.handlingVerifyExpiryInTimer(
          this.otpModel.method[this.otpModel.type]
        );
      });
  }

  handlingResendExpiryInTimer(data: OtpMethodParameters) {
    if (this.resendTimerSubscription) {
      this.resendTimerSubscription?.unsubscribe();
    }
    this.resendTimerSubscription = interval(1000) // Update every second
      .pipe(
        takeWhile(() => this.alive),
        takeWhile(() => data?.resend_expires_In > 0), // Stop timer when expires
        map(() => {
          data.resend_expires_In--;
          const remainingTime = this.formatTime(data.resend_expires_In);
          data.disabled = data.resend_expires_In > 0;
          return remainingTime;
        })
      )
      .subscribe(
        (formattedTime) => {
          this.remainingResendTime = {
            value: data.resend_expires_In,
            text: formattedTime,
          };
        },
        null,
        () => {
          // This block is executed when the observable completes
          data.resend_expires_In = 0;
          data.blocked = false;
          this.otpModel.method[this.otpModel.type].disclaimerMessage = null;
          this.resendTimerSubscription.unsubscribe();
        }
      );
  }

  handlingVerifyExpiryInTimer(data: OtpMethodParameters) {
    if (this.verifyTimerSubscription) {
      this.verifyTimerSubscription?.unsubscribe();
    }
    this.verifyTimerSubscription = interval(1000) // Update every second
      .pipe(
        takeWhile(() => this.alive),
        takeWhile(() => data?.verify_expires_In > 0), // Stop timer when expires
        map(() => {
          data.verify_expires_In--;
          const remainingTime = this.formatTime(data.verify_expires_In);
          data.disabled = data.verify_expires_In > 0;
          return remainingTime;
        })
      )
      .subscribe(
        (formattedTime) => {
          this.remainingVerifyTime = {
            value: data.verify_expires_In,
            text: formattedTime,
          };
        },
        null,
        () => {
          // This block is executed when the observable completes
          data.verify_expires_In = 0;
          data.blocked = false;
          this.otpModel.method[this.otpModel.type].disclaimerMessage = null;
          this.verifyOTPService.triggerBlockedStatus.next({
            type: this.otpModel.type,
            blocked: false,
          });
          this.verifyTimerSubscription.unsubscribe();
        }
      );
  }

  formatTime(seconds: number): string {
    const duration = moment.duration(seconds, 'seconds');
    const formatExpiresIn = moment
      .utc(duration.asMilliseconds())
      .format('mm:ss');
    return formatExpiresIn;
  }

  otpChangeValue(event) {
    this.otpInputValue.emit({ currentValue: event });
  }

  ngAfterViewInit(): void {
    if (this.otpInput) {
      const otpInputs = this.otpInput.otpInputElements;
      otpInputs.forEach((input) => {
        ((input as ElementRef).nativeElement as HTMLInputElement).placeholder =
          '-';
      });
    }
  }

  resendCode() {
    this.verifyOTPService
      .getOtp(this.resendOtpUrl, this.otpModel.type, this.resendOtpToken)
      .subscribe({
        next: (res: any) => {
          if (res.status_code == 200) {
            if (res.data) {
              this.otpModel.method[this.otpModel.type].resend_expires_In =
                res.data.expires_in;
              this.handlingResendExpiryInTimer(
                this.otpModel.method[this.otpModel.type]
              );
            }
          }
          if (res.status_code == 405) {
            this.otpStatus = 'error';
          }
        },
        error: (err) => {
          if (err.error?.message)
            switch (err.error.message) {
              case 'otp_expired': {
                this.otpModel.method[this.otpModel.type].disclaimerMessage =
                  this.translate.instant('validations.otp_expired');
                const expiredOtpDuration = err.error?.expires_in;
                this.otpModel.method[this.otpModel.type].resend_expires_In =
                  expiredOtpDuration;
                this.handlingResendExpiryInTimer(
                  this.otpModel.method[this.otpModel.type]
                );
                this.otpModel.method[this.otpModel.type].disabled = true;
                this.otpStatus = 'error';
                this.otpModel = { ...this.otpModel };
                break;
              }
              case 'otp_blocked': {
                this.otpModel.method[this.otpModel.type].disclaimerMessage =
                  this.translate.instant('validations.otp_blocked');
                const blockDuration = err.error?.expires_in;
                this.otpModel.method[this.otpModel.type].verify_expires_In =
                  blockDuration;
                this.handlingVerifyExpiryInTimer(
                  this.otpModel.method[this.otpModel.type]
                );
                this.resendTimerSubscription?.unsubscribe();
                this.otpModel.method[this.otpModel.type].blocked = true;
                this.otpStatus = 'error';
                this.otpModel = { ...this.otpModel };

                break;
              }
              case 'validation_error': {
                //TODO validation for invalid otp entered
                this.otpModel.method[this.otpModel.type].disclaimerMessage =
                  this.translate.instant('validations.otp_incorrect');
                break;
              }
            }
        },
      });
  }

  ngOnDestroy() {
    if (this.resendTimerSubscription) {
      this.resendTimerSubscription?.unsubscribe();
    }
    if (this.verifyTimerSubscription) {
      this.verifyTimerSubscription?.unsubscribe();
    }
    this.verifyOTPService.triggerResendTimer.next(false);
    this.verifyOTPService.triggerVerifyTimer.next(false);
  }

  otpValue(event) {
    if (event) {
      this.otpInputValue.next({ filledValue: event });
    }
  }
}
