import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { Protocols } from '../models';
import {
  AfaqyAPIResponse,
  AfaqyResponse,
} from '../../../core/classes/afaqy-response';
import { catchError, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
@Injectable()
export class ProtocolsService extends RootService {
  private _systemProtocolsList;

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.disableListItemAction = {
      copy: true,
      delete: true,
    };
  }
  refactorListResponse(res) {
    res?.list?.map((item) => {
      if (item?.enabled) {
        item.enabled = 'Enabled';
      } else {
        item.enabled = 'Disable';
      }
      return item;
    });
    return res;
  }

  refactorViewResponse(res) {
    if (res?.data?.enabled) {
      res.data.enabled = 'Enabled';
    } else {
      res.data.enabled = 'Disable';
    }
    return res;
  }

  getById(id: string, prefix: string = '') {
    this.applyBeforeView(id);
    return this.doGetById(this.getFunctionURL('view', prefix), id, 0);
  }
  systemProtocolsList() {
    if (this._systemProtocolsList) {
      return of(this._systemProtocolsList);
    }
    return this.apiRequest
      .authPost(this.getFunctionURL('protocols'), { simplify: 0 })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          if (result.status_code == 200) {
            let list = [];
            for (let obj of result.data) {
              let object = new Protocols();
              object.copyInto(obj);
              list.push(object);
            }
            this._systemProtocolsList = list;
            return list;
          }
          return [];
        })
      );
  }

  routerPrefix(val: string = '') {
    return val ? val : 'protocols';
  }

  get modelInstance() {
    return new Protocols();
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'name',
        minWidth: 75,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: 'code',
        minWidth: 50,
        width: '*',
        colValue: 'code',
        active: false,
        immutable: false,
        default: true,
      },
      {
        header: 'port',
        minWidth: 50,
        width: '*',
        colValue: 'port',
        active: false,
        immutable: false,
        default: true,
      },
      {
        header: 'enabled',
        minWidth: 50,
        width: '*',
        colValue: 'enabled',
        active: false,
        immutable: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-user-preferences';
  }
}
