import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UnitService } from '../../../../modules/units/services';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ApiRequestService } from '../../../../core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'unit-sensor-clone-form',
  templateUrl: './unit-form-sensor-clone-form.component.html',
  styleUrls: ['./unit-form-sensor-clone-form.component.scss'],
})
export class UnitFormSensorCloneFormComponent {
  public myGroup: UntypedFormGroup;
  posting: boolean;
  public unitService: UnitService;
  @Input() sensorId: string;
  @Input() unitID?: any;

  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  cid = 'units';
  constructor(
    public translate: TranslateService,
    unitService: UnitService,
    private ApiRequestService: ApiRequestService
  ) {
    this.unitService = unitService;
    this.myGroup = new UntypedFormGroup({
      unitsList: new UntypedFormControl(),
    });
  }

  get fields(): UntypedFormArray {
    return this.myGroup.get('unitsList') as UntypedFormArray;
  }

  get form_fields(): any {
    return {
      unitsList: [Validators.required],
    };
  }

  modalClose($event: any) {
    this.closeForm.next($event);
  }

  onSubmit() {
    const payload = { units: this.fields.value, sensor_id: this.sensorId };

    this.ApiRequestService.authPost(
      'unit_sensors/clone_sensor',
      payload
    ).subscribe({
      next: (res) => {
        if (res['status_code'] === 200) {
          this.modalClose({
            success: true,
            type: 'success',
            msg: 'sensors.sensor_is_cloned',
          });
        } else {
          this.unitService.alert('sensors.sensor_is_duplicated');
        }
      },
    });
  }
}
