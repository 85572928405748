import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
interface Color {
  hexString: string;
}

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  color: Color = { hexString: '#000000' };
  @Input() width: string = '100px';
  @Input() height: string = '100px';
  @Input() initialColor: string;
  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
  isBlackColor: boolean;
  colors: string[] = [
    '#3e6158',
    '#7c90c1',
    '#9d8594',
    '#3f7a89',
    '#197b30',
    '#ff0000',
    '#ff6600',
    '#ffcc00',
  ];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialColor) {
      this.changeColor(this.initialColor);
    }
  }

  onChangeColor(color: string): void {
    this.setIsBlackColor(color);
    this.colorChange.emit(color);
  }

  changeColor(color: string): void {
    this.color.hexString = color;
    this.onChangeColor(color);
  }

  private setIsBlackColor(color: string): void {
    if (color === '#000000' || color === '#000') this.isBlackColor = true;
    else this.isBlackColor = false;
  }
}
