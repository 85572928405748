import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfig } from '../../../common/classes/index';
import { CoreFormComponent } from 'app/common/core-form-compenent';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../modules/users/services';
import { AuthService } from '../../../core/services/auth.service';
import { AfaqyResponse } from '../../../core/classes';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'lib-package-usage',
  templateUrl: './package-usage.component.html',
  styleUrls: ['./package-usage.component.scss'],
})
export class PackageUsageComponent extends CoreFormComponent implements OnInit {
  appsExist = false;
  packageUsageData: any;
  percentage: any;
  constructor(
    translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    fb: UntypedFormBuilder,
    service: UserService,
    protected authService: AuthService
  ) {
    super(translate, route, router, fb, service);
    service
      .getPackageUsage()
      .pipe(
        takeWhile(() => this.alive),
        map((item: any) => {
          item.modules.map((data) => {
            if (data.st == 2) {
              data.percentage = 100;
            } else if (data.st == 1) {
              data.percentage =
                data.count > 0 && data.limit > 0
                  ? (data.count / data.limit) * 100
                  : 0;
            }
          });
          return item;
        })
      )
      .subscribe({
        next: (res) => {
          this.packageUsageData = res;
        },
        error: () => {
          this.loading = false;
        },
        complete: () => {},
      });
  }

  ngOnInit() {
    if (this.authService?.user.account_apps?.length > 0) {
      this.appsExist = true;
    }
  }

  get title(): string {
    return 'package_usage_info';
  }

  navigateToList(refresh: boolean = false) {
    this.router.navigate([{ outlets: { popup: null } }], {
      skipLocationChange: AppConfig.skipLocationChange,
    });
    //AfaqyHelper.profileModal.next();
  }
}
