import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AfaqyHelper } from '../../../common/classes';
import { AuthService } from '../../../core/services';
import { UserService } from '../../../modules/users/services';

import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';

import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'form-assignations',
  templateUrl: './form-assignations.component.html',
  styleUrls: ['./form-assignations.component.css'],
})
export class FormAssignationsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  alive: boolean = true;
  searchText = '';
  selected_ids = {};
  @Input() isView: boolean;
  @Input()
  set refresh(v) {
    setTimeout(() => {
      if (this.cvData && this.grid) {
        this.cvData.refresh();
        this.grid.refresh(true);
      }
    }, 200);
  }
  /**
   * If this.selected is array of objects
   * then you {MUST} provide nestedIdObjectKey to get the id value from each object
   * this.selected cannot be refactored through HOC because (selected) event depends on it as is
   */
  @Input() nestedIdObjectKey?: string;
  @Input()
  set selected(ids) {
    this.selected_ids = {};
    ids.forEach((id) => {
      if (typeof id === 'string') {
        this.selected_ids[id] = true;
      } else if (typeof id === 'object' && !!this.nestedIdObjectKey) {
        // referance at line 36 to line 40
        this.selected_ids[id[this.nestedIdObjectKey]] = true;
      }
    });
  }

  @Output() updateSelection: EventEmitter<any> = new EventEmitter<any>();

  loading = true;
  cvData: wjcCore.CollectionView;
  @ViewChild('grid') grid: wjcGrid.FlexGrid;
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {
    this.cvData = new wjcCore.CollectionView([]);
    this.cvData.filter = this.applyFilters.bind(this);
    var sd = this.cvData.sortDescriptions;
    var sdNew = new wjcCore.SortDescription('is_old_selected', false);
    sd.push(sdNew);
    this.updateGridResources(true);
  }

  UpdateSelecting() {
    this.updateSelection.next({ ids: Object.keys(this.selected_ids) });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.grid) {
      this.grid.rowHeaders.columns.defaultSize = 25;
      AfaqyHelper.resizer.pipe(takeWhile(() => this.alive)).subscribe({
        next: (res) => {
          this.grid.refresh(true);
        },
      });
    }
    AfaqyHelper.windowResize();
  }
  applyFilters(item: any) {
    // Check if the object's username matches the search text
    if (
      item['username']?.toLowerCase().includes(this.searchText?.toLowerCase())
    ) {
      return true;
    }
    // Check if any item's username within the object matches the search text
    return item?.subusers.some((item) =>
      item['username']?.toLowerCase().includes(this.searchText?.toLowerCase())
    );
  }

  setSelectedSearchText(txt) {
    this.searchText = txt.toLowerCase();
    this.cvData.refresh();
  }

  updateGridResources(update = false) {
    this.userService
      .usersListAsTree()
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (response) => {
          let items = <Array<any>>response;
          items.map((item) => {
            if (update) {
              item.is_old_selected = this.selected_ids[item.id] ? 1 : 0;
            }
            if (this.selected_ids[item.id]) {
              item.is_selected = 1;
            } else {
              item.is_selected = 0;
            }
            return item;
          });
          this.loading = false;
          this.cvData.sourceCollection = items;
        },
      });
  }

  updateSelected(items) {
    items.map((item) => {
      if (this.selected_ids[item.id]) {
        item.is_selected = 1;
      } else {
        item.is_selected = 0;
      }
      return this.updateSelected(item['subusers']);
    });
    return items;
  }

  checkSelected(item, id, status) {
    if (item.id == id) {
      item['is_selected'] = status ? 1 : 0;
      if (status) {
        this.selected_ids[id] = true;
      } else {
        delete this.selected_ids[id];
      }
      if (status) {
        if (item['parent_id']) {
          this.selected_ids[item['parent_id']] = 1;
          this.toggleSelection(item['parent_id'], true);
        }
      } else {
        item['subusers'] = item['subusers'].map((sub_item) => {
          return this.checkSelected(sub_item, sub_item['id'], 0);
        });
      }
    } else {
      item['subusers'].map((sub_item) => {
        return this.checkSelected(sub_item, id, status);
      });
    }
    return item;
  }

  forceAction(item, status) {
    item['subusers'] = item['subusers'].map((sub_item) => {
      return this.checkSelected(sub_item, sub_item['id'], status);
    });
    item['subusers'].map((ssitem) => {
      ssitem = this.checkSelected(ssitem, ssitem.id, status);
      this.forceAction(ssitem, status);
      return ssitem;
    });
    return item;
  }

  toggleSelection(id, status, event = {}) {
    if (event['ctrlKey']) {
      this.cvData.sourceCollection.map((item) => {
        item = this.checkSelected(item, item.id, status);
        this.forceAction(item, status);
        return item;
      });
    } else {
      this.cvData.sourceCollection.map((item) => {
        return this.checkSelected(item, id, status);
      });
    }
    this.cvData.refresh();
    this.UpdateSelecting();
  }

  isChecked(id) {
    return this.selected_ids[id] === true;
  }
}
