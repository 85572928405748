<afaqy-modal
  [title]="'units.sensor'"
  (modalClose)="modalClose($event)"
  #modal="afaqyModal"
  [draggable]="false"
  sizeClass="modal-lg"
  [staticHeight]="false"
>
  <div class="modalbody">
    <div class="p-relative" [formGroup]="form">
      <div class="afaqy-mask" *ngIf="isView"></div>
      <div class="row">
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['name'].dirty || form.controls['name'].touched) &&
              form.controls['name'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'name' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="name"
                [attr.testId]="cid + 'name'"
              />
              <control-errors-hint
                [ctl]="form.controls['name']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['type'].dirty || form.controls['type'].touched) &&
              form.controls['type'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'type' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="type"
                (change)="checkType(true, true)"
                [attr.testId]="cid + 'type'"
              >
                <option
                  *ngFor="let type of $any(sensorOptions)?.types"
                  [value]="type.id"
                >
                  {{
                    translate.currentLang == 'en' ? type.nameEn : type.nameAr
                  }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="form.controls['type']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['param'].dirty ||
                form.controls['param'].touched) &&
              form.controls['param'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'param' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="param"
                [attr.testId]="cid + 'param'"
              >
                <option
                  *ngFor="let param of $any(sensorOptions)?.params"
                  [value]="param"
                >
                  {{ param }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="form.controls['param']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['tooltip_show'].dirty ||
                form.controls['tooltip_show'].touched) &&
              form.controls['tooltip_show'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label">{{
                'units.sensor_form.tooltip_show' | translate
              }}</label>
            </div>
            <div class="col-12">
              <label
                class="switch"
                for="tooltip_show"
                [attr.testId]="cid + 'tooltip_show'"
              >
                <input
                  id="tooltip_show"
                  type="checkbox"
                  [checked]="form.controls['tooltip_show'].value"
                  (click)="setCheckboxValue($event, 'tooltip_show')"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col-12">
              <control-errors
                [ctl]="form.controls['tooltip_show']"
              ></control-errors>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['last_update'].dirty ||
                form.controls['last_update'].touched) &&
              form.controls['last_update'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label">{{
                'units.sensor_form.last_update' | translate
              }}</label>
            </div>
            <div class="col-12">
              <label
                class="switch"
                for="last_update"
                [attr.testId]="cid + 'last_update'"
              >
                <input
                  id="last_update"
                  type="checkbox"
                  [disabled]="!isToolTipShow"
                  [checked]="form.controls['last_update'].value"
                  (click)="setCheckboxValue($event, 'last_update')"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col-12">
              <control-errors
                [ctl]="form.controls['last_update']"
              ></control-errors>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['fixed'].dirty ||
                form.controls['fixed'].touched) &&
              form.controls['fixed'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label">{{
                'units.sensor_form.fixed' | translate
              }}</label>
            </div>
            <div class="col-12">
              <label class="switch" for="fixed" [attr.testId]="cid + 'fixed'">
                <input
                  id="fixed"
                  type="checkbox"
                  [checked]="form.controls['fixed'].value"
                  (click)="setCheckboxValue($event, 'fixed')"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col-12">
              <control-errors [ctl]="form.controls['fixed']"></control-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showVehicleSpeedSensorInput">
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['is_vss_default'].dirty ||
                form.controls['is_vss_default'].touched) &&
              form.controls['is_vss_default'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label">{{
                'units.sensor_form.is_vss_default' | translate
              }}</label>
            </div>
            <div class="col-12">
              <label
                class="switch"
                for="is_vss_default"
                [attr.testId]="cid + 'is_vss_default'"
              >
                <input
                  id="is_vss_default"
                  type="checkbox"
                  [checked]="form.controls['is_vss_default'].value"
                  (click)="setCheckboxValue($event, 'is_vss_default')"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col-12">
              <control-errors
                [ctl]="form.controls['is_vss_default']"
              ></control-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="row heading-card">
            <div class="col-12">
              <h5 class="title my-4">
                {{ 'units.sensor_form.result' | translate }}
              </h5>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.result_type' | translate }} :</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div
                    class="row form-group"
                    [class.has-danger]="
                      (form.controls['result_type'].dirty ||
                        form.controls['result_type'].touched) &&
                      form.controls['result_type'].errors
                    "
                  >
                    <div class="col-12 input-group form-group-hint">
                      <select
                        class="form-control form-control-sm"
                        formControlName="result_type"
                        [attr.testId]="cid + 'result_type'"
                        (change)="checkResultType()"
                      >
                        <option
                          *ngFor="let rtype of $any(sensorOptions).result_types"
                          [value]="rtype"
                        >
                          {{
                            'units.sensor_form.result_types.' + rtype
                              | translate
                          }}
                        </option>
                      </select>
                      <control-errors-hint [ctl]="form.controls['result_type']">
                      </control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              @showhide
              *ngIf="!form.controls['text_0'].disabled"
            >
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.text_0' | translate }}:</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div
                    class="row form-group"
                    [class.has-danger]="
                      (form.controls['text_0'].dirty ||
                        form.controls['text_0'].touched) &&
                      form.controls['text_0'].errors
                    "
                  >
                    <div class="col-12 input-group form-group-hint">
                      <input
                        class="form-control form-control-sm"
                        formControlName="text_0"
                        [attr.testId]="cid + 'text_0'"
                      />
                      <control-errors-hint
                        [ctl]="form.controls['text_0']"
                      ></control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              @showhide
              *ngIf="!form.controls['text_1'].disabled"
            >
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.text_1' | translate }}:</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div
                    class="row form-group"
                    [class.has-danger]="
                      (form.controls['text_1'].dirty ||
                        form.controls['text_1'].touched) &&
                      form.controls['text_1'].errors
                    "
                  >
                    <div class="col-12 input-group form-group-hint">
                      <input
                        class="form-control form-control-sm"
                        formControlName="text_1"
                        [attr.testId]="cid + 'text_1'"
                      />
                      <control-errors-hint
                        [ctl]="form.controls['text_1']"
                      ></control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              @showhide
              *ngIf="!form.controls['units'].disabled"
            >
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.units' | translate }}:</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div
                    class="row form-group"
                    [class.has-danger]="
                      (form.controls['units'].dirty ||
                        form.controls['units'].touched) &&
                      form.controls['units'].errors
                    "
                  >
                    <div *ngIf="showMeasurementUnitsDDLOption" class="col-12">
                      <afaqy-custom-select
                        [dataList]="measurement_units"
                        [predefinedList]="true"
                        [ctl]="form.controls['units']"
                        [isDisabled]="form.controls['units'].disabled"
                        [titleField]="
                          translate.currentLang == 'en' ? 'nameEn' : 'nameAr'
                        "
                        formControlName="units"
                      >
                      </afaqy-custom-select>
                    </div>
                    <div class="col-12 input-group form-group-hint">
                      <div
                        *ngIf="!showMeasurementUnitsDDLOption"
                        class="col-12 p-0"
                      >
                        <input
                          class="form-control form-control-sm"
                          formControlName="units"
                          [attr.testId]="cid + 'units'"
                        />
                      </div>
                      <control-errors-hint
                        [ctl]="form.controls['units']"
                      ></control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              @showhide
              *ngIf="!form.controls['formula_value'].disabled"
            >
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.formula' | translate }}:</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div class="row form-group">
                    <div class="col-md-6 pr-0">
                      <select
                        class="form-control form-control-sm"
                        formControlName="formula"
                        [attr.testId]="cid + 'formula'"
                      >
                        <option
                          *ngFor="let f of sensorOptions.formulas"
                          [value]="f"
                        >
                          {{ f }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="col-md-6 input-group form-group-hint pl-0"
                      [class.has-danger]="
                        (form.controls['formula_value'].dirty ||
                          form.controls['formula_value'].touched) &&
                        form.controls['formula_value'].errors
                      "
                    >
                      <input
                        class="form-control form-control-sm"
                        formControlName="formula_value"
                        [attr.testId]="cid + 'formula_value'"
                      />
                      <control-errors-hint
                        [ctl]="form.controls['formula_value']"
                      >
                      </control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              @showhide
              *ngIf="!form.controls['low_val'].disabled"
            >
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.low_val' | translate }}:</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div
                    class="row form-group"
                    [class.has-danger]="
                      (form.controls['low_val'].dirty ||
                        form.controls['low_val'].touched) &&
                      form.controls['low_val'].errors
                    "
                  >
                    <div class="col-12 input-group form-group-hint">
                      <input
                        class="form-control form-control-sm"
                        formControlName="low_val"
                        [attr.testId]="cid + 'low_val'"
                      />
                      <control-errors-hint
                        [ctl]="form.controls['low_val']"
                      ></control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              @showhide
              *ngIf="!form.controls['high_val'].disabled"
            >
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label class="form-control-label"
                    >{{ 'units.sensor_form.high_val' | translate }}:</label
                  >
                </div>
                <div class="col-12 col-lg-8">
                  <div
                    class="row form-group"
                    [class.has-danger]="
                      (form.controls['high_val'].dirty ||
                        form.controls['high_val'].touched) &&
                      form.controls['high_val'].errors
                    "
                  >
                    <div class="col-12 input-group form-group-hint">
                      <input
                        class="form-control form-control-sm"
                        formControlName="high_val"
                        [attr.testId]="cid + 'high_val'"
                        (keyup)="checkHighValue($event)"
                      />
                      <control-errors-hint [ctl]="form.controls['high_val']">
                      </control-errors-hint>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="hasColorField()" class="row sensor-colors-section">
            <div class="col-12">
              <h5 class="title my-4">
                {{ 'units.sensor_form.intervals_colors' | translate }}
              </h5>
            </div>
            <div class="col-12">
              <sensor-colors
                [unitSensorForm]="form"
                [unitSensorObject]="object"
              ></sensor-colors>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h5 class="title my-4">
                {{ 'units.sensor_form.icon_list' | translate }}
              </h5>
            </div>
            <div class="col-12">
              <div class="icon_selector">
                <a
                  [class.selected]="form.controls['icon'].value == icon"
                  *ngFor="let icon of iconsList"
                  (click)="setIcon(icon)"
                  [attr.testId]="icon"
                >
                  <i class="{{ icon }}"></i>
                  <!--<img [class.selected]="form.controls['icon'].value == icon.key " height="30" src={{icon.url}}>-->
                </a>
                <control-errors-hint
                  [ctl]="form.controls['icon']"
                ></control-errors-hint>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" [ngClass]="{ inactive: !hasRightPanel() }">
          <div class="heading-card" *ngIf="rightPanelType == 'calibration'">
            <div class="row">
              <div class="col">
                <h5 class="title my-4">
                  {{ 'units.sensor_form.calibration' | translate }}
                </h5>
              </div>
              <div
                class="col-auto d-flex justify-content-center align-items-center ml-auto"
              >
                <button
                  [class.hidden]="
                    form.controls['calibration'].disabled || !hasRightPanel()
                  "
                  type="button"
                  class="btn btn-sm btn-success calibration-btn"
                  (click)="addNewCalibration()"
                  [attr.testId]="cid + 'addNewCalibration'"
                >
                  <i class="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
            <table
              class="table table-sm table-bordered table-stripped table-hover"
              formArrayName="calibration"
            >
              <thead>
                <tr>
                  <th width="40%" class="align-middle">X</th>
                  <th width="40%" class="align-middle">Y</th>
                  <th width="20%" class="align-middle"></th>
                </tr>
              </thead>
              <tbody [dragula]="'calibrations'">
                <tr
                  *ngFor="let calb of calibrations.controls; let i = index"
                  [formGroupName]="i"
                >
                  <td width="40%" class="align-middle">
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (calibrations['controls'][i]['controls']['x'].dirty ||
                          calibrations['controls'][i]['controls']['x']
                            .touched) &&
                        calibrations['controls'][i]['controls']['x'].errors
                      "
                    >
                      <div class="col-12 input-group form-group-hint">
                        <input
                          class="form-control form-control-sm xvalue"
                          formControlName="x"
                          (keyup)="checkCalibrationX($event, i)"
                        />
                        <control-errors-hint
                          [ctl]="calibrations['controls'][i]['controls']['x']"
                        >
                        </control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td width="40%" class="align-middle">
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (calibrations['controls'][i]['controls']['y'].dirty ||
                          calibrations['controls'][i]['controls']['y']
                            .touched) &&
                        calibrations['controls'][i]['controls']['y'].errors
                      "
                    >
                      <div class="col-12 input-group form-group-hint">
                        <input
                          class="form-control form-control-sm"
                          formControlName="y"
                        />
                        <control-errors-hint
                          [ctl]="calibrations['controls'][i]['controls']['y']"
                        >
                        </control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td width="20%" class="align-middle px-0 text-center">
                    <div class="row form-group">
                      <div class="col-12">
                        <a
                          class="d-block float-left w-50 text-danger"
                          (click)="deleteCalibration(i)"
                          [attr.testId]="cid + 'delete_' + i"
                        >
                          <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                          class="d-block float-left w-50 text-theme-color handle"
                        >
                          <i class="fa fa-sort" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="heading-card" *ngIf="rightPanelType == 'codeValue'">
            <div class="row">
              <div class="col">
                <h5 class="title my-4">
                  {{ 'units.sensor_form.codeValue' | translate }}
                </h5>
              </div>
              <div
                class="col-auto d-flex justify-content-center align-items-center ml-auto"
              >
                <button
                  [class.hidden]="
                    form.controls['codeValue'].disabled || !hasRightPanel()
                  "
                  type="button"
                  class="btn btn-sm btn-success calibration-btn"
                  (click)="addNewCodeValue()"
                  [attr.testId]="cid + 'addNewCodeValue'"
                >
                  <i class="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
            <table
              class="table table-sm table-bordered table-stripped table-hover"
              formArrayName="codeValue"
            >
              <thead>
                <tr>
                  <th width="40%" class="align-middle">X</th>
                  <th width="40%" class="align-middle">Y</th>
                  <th width="20%" class="align-middle"></th>
                </tr>
              </thead>
              <tbody [dragula]="'codeValue'">
                <tr
                  *ngFor="let cv of codeValues.controls; let i = index"
                  [formGroupName]="i"
                >
                  <td width="30%" class="align-middle">
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (codeValues['controls'][i]['controls']['x'].dirty ||
                          codeValues['controls'][i]['controls']['x'].touched) &&
                        codeValues['controls'][i]['controls']['x'].errors
                      "
                    >
                      <div class="col-12 input-group form-group-hint">
                        <input
                          class="form-control form-control-sm cv_xvalue"
                          formControlName="x"
                          (change)="updateIntervalColors()"
                          (keyup)="checkCodeExist($event, i)"
                        />
                        <control-errors-hint
                          [ctl]="codeValues['controls'][i]['controls']['x']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td width="30%" class="align-middle">
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (codeValues['controls'][i]['controls']['y'].dirty ||
                          codeValues['controls'][i]['controls']['y'].touched) &&
                        codeValues['controls'][i]['controls']['y'].errors
                      "
                    >
                      <div class="col-12 input-group form-group-hint">
                        <input
                          class="form-control form-control-sm"
                          formControlName="y"
                        />
                        <control-errors-hint
                          [ctl]="codeValues['controls'][i]['controls']['y']"
                        >
                        </control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td width="20%" class="align-middle px-0 text-center">
                    <div class="row form-group">
                      <div class="col-12">
                        <a
                          class="d-block float-left w-50 text-danger"
                          (click)="deleteCodeValue(i)"
                          [attr.testId]="cid + 'delete_' + i"
                        >
                          <i class="fa fa-trash-o"></i>
                        </a>
                        <a
                          class="d-block float-left w-50 text-theme-color handle"
                        >
                          <i class="fa fa-sort" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modalfooter">
    <form-actions
      [form]="form"
      [isView]="isView"
      [posting]="posting"
      (submit)="onSubmit()"
      (cancel)="modalClose($event)"
      (reset)="reset()"
    ></form-actions>
  </div>
</afaqy-modal>
