<div class="wrapper contain-error">
  <div class="main-contain-error">
    <div class="main-contain-inner-error">
      <div class="error-des text-xs-center">
        <p class="mdi-custom-size">4<i class="mdi mdi-emoticon-sad"></i>4</p>
        <h1>{{ 'page_404' | translate }}</h1>
        <p>
          {{ 'page_404_desc' | translate }}
          <a [routerLink]="['/']">
            <strong>{{ 'home' | translate }}</strong>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
