<div [class.cmsmodal]="configs.isCMS">
  <div [class.modalinsidetab]="insidetab" [class.classicmoal]="!insidetab">
    <div
      class="modal afaqy-modal"
      [ngClass]="{
        'modal-background': hasbackground,
        'modal-nobackground': !hasbackground
      }"
      #myBounds
    >
      <div
        [ngDraggable]="draggable"
        [bounds]="myBounds"
        [inBounds]="inBounds"
        [preventDefaultEvent]="true"
        [handle]="demoHandle"
        (edge)="checkEdge($event)"
        class="modal-dialog"
        [class.modal-dialog-centered]="modalCentered"
        [ngClass]="sizeClass"
      >
        <div
          class="modal-content animated"
          [attr.testid]="'modal-content'"
          [class.static-modal-height]="staticHeight"
          [ngClass]="{
            topbottombounce: !edge?.top || !edge?.bottom,
            leftrightbounce: !edge?.left || !edge?.right,
            draggablefalse: !draggable
          }"
        >
          <div
            class="modal-header"
            [ngClass]="{ 'modal-draggable-header': draggable }"
          >
            <ng-content select="div.modalheader"></ng-content>
            <h5 #demoHandle class="text-light modalDrag">{{ modalTitle }}</h5>
            <a
              class="close modal-close"
              [attr.testId]="'closeModal'"
              (click)="closeModal($event)"
            >
              <i class="mdi mdi-close"></i>
            </a>
          </div>
          <div class="modal-body">
            <div class="afaqy-modal-content">
              <ng-content select="div.modalbody"></ng-content>
            </div>
          </div>
          <div class="modal-footer">
            <ng-content select="div.modalfooter"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
