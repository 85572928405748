import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { UnitAdditionalInfo } from '../../../modules/users/models';
import { Unit } from '../../../modules/units/models/unit';
import { UnitService } from '../../../modules/units/services/unit.service';
import { AuthService } from '../../../core/services/auth.service';
import { AfaqyHelper } from '../../../common/classes';

@Component({
  selector: 'unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.scss'],
})
export class UnitDetailsComponent implements OnDestroy, OnInit, AfterViewInit {
  alive: boolean = true;
  _unitId: any;
  unit: Unit;
  unitAdditionalInfo: UnitAdditionalInfo;
  index: number = 0;
  isEventPopUpStyle: boolean = false;
  EventData: any = [];
  emptyprms: boolean = true;
  hasDeviceAdmin: boolean = false;

  @Input() updatable: boolean = true;
  @Input()
  set isTooltip(tooltip: any) {
    this.index = tooltip ? 0 : 1;
  }
  @Input()
  set isEventPopUP(EventPopUP: any) {
    this.setEventPopUP(EventPopUP);
  }
  @Input() set infoIndex(idx: any) {
    this.index = idx;
  }
  @Input() set unitId(uid: any) {
    this.unit = this.unitService.getUnitDetails(uid);
    this._unitId = uid;
    if (this.unit) {
      this.setUnitDetails();
    }
  }
  @Input() set unitObj(unit: any) {
    this.unit = unit;
    this._unitId = unit.id;
    this.setUnitDetails();
  }

  constructor(
    public unitService: UnitService,
    public authService: AuthService
  ) {
    this.unit = new Unit();
    this.unitAdditionalInfo =
      this.authService.user.user_settings.unit_additional_info;
    this.hasDeviceAdmin =
      this.authService.checkPermissions('units-deviceAdmin');
  }

  setUnitDetails() {
    // Nearest Zone
    this.unitService.updateUnitNearest(this._unitId, 'nearest_zone');
    // Nearest Marker
    this.unitService.updateUnitNearest(this._unitId, 'nearest_marker');
    // Address
    this.updateAddress();
    this.updateServicesStatus();

    const chPrms = this.unit?.last_update?.chPrams;
    this.emptyprms =
      Object.keys(chPrms)?.length === 0 && chPrms?.constructor === Object
        ? true
        : false;
  }

  setEventPopUP(EventPopUP: any) {
    this.isEventPopUpStyle = EventPopUP;
    this.EventData = EventPopUP;
  }

  ngOnInit() {
    // if (this.updatable) {
    this.unitService.tracking.pipe(takeWhile(() => this.alive)).subscribe({
      next: (data) => {
        if (data['ids'] && data['ids'].includes(this.unit.id)) {
          if (data['event'] == 'monitoring_location_update') {
            this.updateAddress();
          }
          this.updateServicesStatus();
        }
      },
    });
    // }
  }

  ngAfterViewInit(): void {
    this.updateUnitDetails();
  }

  /** Update unit data depends on `updatedListIds subject` that emit the `updated units ids` from the `socket`. */
  private updateUnitDetails(): void {
    this.unitService.updatedListIds
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (ids: Array<string>) => {
          if (!ids.includes(this.unitId)) return;
          this.unit = this.unitService.getItemFromResources(this.unitId);
          this.setUnitDetails();
        },
      });
  }

  navigateToGoogleMaps() {
    const coordinates = {
      lat: this.unit.last_update.lat,
      lng: this.unit.last_update.lng,
    };
    window.open(
      `https://maps.google.com/?q=${coordinates.lat},${coordinates.lng}&z=8`,
      '_blank'
    );
  }

  navigateToGoogleMapsUsingEventData(lat: any, lng: any) {
    window.open(`https://maps.google.com/?q=${lat},${lng}&z=8`, '_blank');
  }

  private updateServicesStatus() {
    this.unit?.services?.map((service) => {
      const serviceStatus = this.unitService.calcServiceStatus(
        service,
        this.unit.counters.odometer,
        this.unit.counters.engine_hours
      );
      service.status = serviceStatus['text'];
      return service;
    });
  }

  updateAddress() {
    let coordinates: { lat?: number; lng?: number } = {
      lat: this.unit?.last_update?.lat,
      lng: this.unit?.last_update?.lng,
    };
    this.unitService?.getAddress(this.unit?.id, coordinates);
  }

  get unitId() {
    return this._unitId;
  }

  ngOnDestroy() {
    this.alive = false;
  }

  processSensor(sensor: any) {
    const result = AfaqyHelper.getSensorValue(this.unit.last_update, sensor);
    return result['value_full'];
  }

  showSensorLastUpdateTime(sensor: any) {
    return (
      this.unitAdditionalInfo.sensors_time[this.index] &&
      sensor.last_update &&
      this.unit.last_update.chPrams[sensor['param']]
    );
  }

  getSensorLastUpdateTime(sensor: any) {
    return this.unit.last_update.chPrams[sensor['param']].cdt;
  }

  showAllSensors() {
    if (!this.unitAdditionalInfo.sensors[this.index]) return false;
    if (this.unit.sensors.length <= 0) return false;

    for (let sensor of this.unit.sensors) {
      if (sensor.tooltip_show) return true;
    }

    return false;
  }

  getUnitNameWithPlateNumber(): string {
    const unitName: string =
      this.unit.name.length > 40
        ? this.unit.name.substring(0, 40) + '...'
        : this.unit.name;
    const plateNumber: string = this.unit.profile.plate_number;
    if (!plateNumber || unitName === plateNumber) return unitName;
    else {
      const hasArabicCharacters: boolean =
        AfaqyHelper.hasArabicCharacters(unitName);
      if (hasArabicCharacters) return `[${plateNumber}] ${unitName}`;
      else return `${unitName} [${plateNumber}]`;
    }
  }
}
