import { AfaqyObject } from '../../../common/classes';

export class AppsCCTV extends AfaqyObject {
  private _enable: boolean = false;
  private _system: string;
  private _username: string;
  private _password: string;

  protected copyFields = ['enable', 'system', 'username', 'password'];

  get enable(): boolean {
    return this._enable;
  }

  set enable(value: boolean) {
    this._enable = value;
  }

  get system(): string {
    return this._system;
  }

  set system(value: string) {
    this._system = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }
}
