<div
  id="notifications-sidebar"
  class="modal right fade show"
  [class.NotificationsOpen]="NotificationsOpen"
  [@showHide]
  *ngIf="NotificationsOpen"
  tabindex="-1"
  role="dialog"
>
  <div
    class="modal-dialog"
    role="document"
    clickOutside
    (clickOutside)="toggleNotificationshelper()"
  >
    <div class="modal-content">
      <div class="tabs-container" style="background-color: #fff">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
          <mat-tab>
            <ng-template mat-tab-label>
              {{ 'common.events' | translate }}
              <span class="notifications-count" *ngIf="unreadEventsCount > 0">
                {{ unreadEventsCount }}
              </span>
            </ng-template>
            <div class="notification-wrapper">
              <div class="action-icons">
                <afaqy-spinner [loading]="loading"></afaqy-spinner>
                <span
                  class="icon-round pad-4"
                  (click)="toggleNotificationSider()"
                >
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="'notifications.show_hide_notification_slider' | translate"
                    [svgIcon]="'toggle-notification'"
                  ></mat-icon>
                </span>

                <span
                  class="icon-round pad-4"
                  (click)="toggleNotificationSound()"
                >
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="soundStatus ? ('notifications.mute_notifications' | translate) : ('notifications.unmute_notifications' | translate)"
                    [svgIcon]="soundStatus ? 'sound-on' : 'sound-mute'"
                  ></mat-icon>
                </span>
                <span class="icon-round pad-4" (click)="readAll()">
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="'notifications.readall' | translate"
                    [svgIcon]="'eye-visible'"
                  ></mat-icon>
                </span>
                <span class="icon-round pad-4" (click)="unreadAll()">
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="'notifications.unreadall' | translate"
                    [svgIcon]="'eye-hidden'"
                  ></mat-icon>
                </span>
              </div>
              <div class="">
                <div
                  class="notification"
                  [ngClass]="{ 'unread-background': !event.isread }"
                  *ngFor="let event of eventsList"
                >
                  <div
                    class="icon-title-wrapper cursor-pointer"
                    (click)="expandEvent(event)"
                  >
                    <span class="icon-title-round">
                      <mat-icon [svgIcon]="'title-bell'"></mat-icon>
                    </span>
                    <p class="notification-title">{{ event?.unit_name }}</p>
                  </div>
                  <p>{{ event.desc }}</p>
                  <div class="comment-read-icons">
                    <p class="notification-date">
                      {{ event.pos.dtt | date : 'd/M/yyyy  h:mm a' }}
                    </p>
                    <div
                      class="d-flex justify-content-between"
                      style="gap: 4px"
                    >
                      <span
                        class="icon-round pad-4"
                        preventRightClick
                        [routerLink]="[
                          '/',
                          { outlets: { popup: ['eventComments'] } }
                        ]"
                        [skipLocationChange]="configs['skipLocationChange']"
                        [queryParams]="{
                          sid: sessionID,
                          eventDataId: event.id
                        }"
                        (click)="closeModal()"
                      >
                        <mat-icon [svgIcon]="'chat-bubble'"></mat-icon>
                      </span>
                      <span
                        class="icon-round pad-4"
                        (click)="toogleRead(event.id)"
                      >
                        <mat-icon [svgIcon]="'eye-visible'"></mat-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              {{ 'common.operations' | translate }}
              <span
                class="notifications-count"
                *ngIf="unreadOperationsCount > 0"
              >
                {{ unreadOperationsCount }}
              </span>
            </ng-template>
            <div class="notification-wrapper">
              <div class="action-icons">
                <afaqy-spinner [loading]="loading"></afaqy-spinner>

                <span
                  class="icon-round pad-4"
                  (click)="toggleNotificationSider()"
                >
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="'notifications.show_hide_notification_slider' | translate"
                    [svgIcon]="'toggle-notification'"
                  ></mat-icon>
                </span>
                <span
                  class="icon-round pad-4"
                  (click)="toggleNotificationSound()"
                >
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="soundStatus ? ('notifications.mute_notifications' | translate) : ('notifications.unmute_notifications' | translate)"
                    [svgIcon]="soundStatus ? 'sound-on' : 'sound-mute'"
                  ></mat-icon>
                </span>
                <span class="icon-round pad-4" (click)="toggleReadAll('read')">
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="'notifications.readall' | translate"
                    [svgIcon]="'eye-visible'"
                  ></mat-icon>
                </span>
                <span
                  class="icon-round pad-4"
                  (click)="toggleReadAll('unread')"
                >
                  <mat-icon
                    afaqy-tooltip
                    [tooltipText]="'notifications.unreadall' | translate"
                    [svgIcon]="'eye-hidden'"
                  ></mat-icon>
                </span>
              </div>
              <div class="">
                <div
                  class="notification"
                  [ngClass]="{ 'unread-background': !operation.read }"
                  *ngFor="let operation of operationsList"
                  (click)="loadFailedDataTable(operation)"
                  [ngStyle]="{
                    cursor: operation.operation.includes('fail')
                      ? 'pointer'
                      : 'unset'
                  }"
                >
                  <div class="icon-title-wrapper">
                    <img
                      [src]="operation.icon"
                      alt=""
                      hideMissing
                      [errorIconURL]="operation?.errorIcon"
                    />
                    <p class="notification-title">
                      {{ operation.operation | translate }}
                    </p>
                  </div>
                  <p [style.direction]="translate.currentLang == 'ar' ? 'rtl' : 'ltr'">{{ operation.notificationMessage }}</p>
                  <div class="comment-read-icons">
                    <p class="notification-date">
                      {{ operation.createdAt | afaqyDate }}
                    </p>
                    <div class="float-right">
                      <!--                      <img-->
                      <!--                        preventRightClick-->
                      <!--                        [routerLink]="['/', { outlets: { popup: ['eventComments'] } }]"-->
                      <!--                        [skipLocationChange]="configs['skipLocationChange']"-->
                      <!--                        [queryParams]="{ sid: sessionID, eventDataId: operation.id }"-->
                      <!--                        (click)="closeModal()"-->
                      <!--                        src="../../../../assets/icons/eye-message.png"-->
                      <!--                        alt=""-->
                      <!--                        class="cursor-pointer"-->
                      <!--                      >-->
                      <span
                        class="icon-round pad-4"
                        (click)="
                          toggleNotificationReadStatus(
                            operation?.id,
                            operation?.read
                          )
                        "
                      >
                        <mat-icon [svgIcon]="'eye-visible'"></mat-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
<div class="copyInfoStatus">
  <ng-template #importToFill></ng-template>
</div>
<div class="custom-backdrop" [@fadeIn] *ngIf="NotificationsOpen"></div>
