export class Period {
  name: string;
  classes: string;
  timeFramePeriod: number;
  timeFrameOverall: number;
  timeFrameHeaders: string[];
}

export class Item {
  id: number; // distinct
  name: string; // excess
  start: any; // planned start time as moment
  end: any; // planned end time as moment
  waitStart?: any; // planned time of wait to begin
  actualStart?: any; // actual time the trip started
  actualEnd?: any; // actual time the trip ends
  actualWaitStart?: any; // actual time wait starts
  isFirst?: boolean; // Start point of trip
  isLast?: boolean; // End point of trip
  classes: string;
  status: string; // planned, ongoing, done
  sectionID: number; // ref
}

export class Section {
  id: number | string;
  name?: string;
  data?: SectionData[];
  routeName?: string;
  busName?: string;
  driver?: string;
  status?: number | string;
  [name: string]: any;
}
export class SectionData {
  key: string;
  title: string | number;
  additional?: string;
  type?: string;
  width?: number;
  show?: boolean;
  toggleable?: boolean;
}
export class Text {
  NextButton: string;
  PrevButton: string;
  TodayButton: string;
  GotoButton: string;
  SectionTitle: string;

  constructor() {
    this.NextButton = 'Next';
    this.PrevButton = 'Prev';
    this.TodayButton = 'Today';
    this.GotoButton = 'Go to';
    this.SectionTitle = 'Section';
  }
}

export class Events {
  // ItemResized: (item: Item, start: any, end: any) => void;
  // ItemMovement: (item: Item, start: any, end: any) => void;
  // ItemMovementStart: (item: Item, start: any, end: any) => void;
  // ItemMovementEnd: (item: Item, start: any, end: any) => void;
  ItemDropped: (item: Item) => void;
  ItemClicked: (item: Item) => void;
  checkpointClicked: (item: Item) => void;
  SectionClickEvent: (section: Section) => void;
}

export class SectionItem {
  section: Section;
  minRowHeight: number;
  itemMetas: ItemMeta[];
  itemActualMetas: ItemMeta[];

  constructor() {
    this.itemMetas = new Array<ItemMeta>();
  }
}

export class ItemMeta {
  item: Item;
  hasStarted: boolean;
  hasEnded: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  cssTop: number;
  cssLeft: number;
  cssWidth: number;
  cssWaitWidth: number;
  cssStartWaitWidth: number;
  class: string = '';

  constructor() {
    this.cssTop = 0;
    this.cssLeft = 0;
    this.cssWidth = 0;
  }
}

export class Header {
  headerDetails: HeaderDetails[];

  constructor() {
    this.headerDetails = new Array<HeaderDetails>();
  }
}

export class HeaderDetails {
  name: string;
  colspan: number;
}
