import { AppModel } from '../../../common/classes';

export class ZoneGroup extends AppModel {
  private _name: string;
  private _user_id: string;
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'user_id',
    'created_at',
    'deleted_at',
    'users_list',
  ];
  protected createFields = ['name', 'users_list'];
  protected updateFields = ['name', 'users_list'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }
}
