import * as moment from 'moment';

export class CellFormatter {
  public static showUnit = true;
  public static durationFormat = '';
  public static currency;
  static fDate(val) {
    if (val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    }
    return '';
  }

  static fHours(val) {
    return val + ' h';
  }

  static fMapAddress(arr) {
    if (!(arr instanceof Array)) {
      return '';
    }
    var txt = '';
    if (arr.length === 3) {
      if (arr[2]) {
        txt = arr[2];
      } else {
        if (arr[0] && arr[1]) {
          txt = arr[0] + '°, ' + arr[1] + '°';
        }
      }
    }
    return txt;
  }

  static fMap(arr) {
    if (!(arr instanceof Array)) {
      return '';
    }
    var txt = arr.length === 3 ? arr[2] : arr[0] + '°, ' + arr[1] + ' °';
    if (arr.length == 3) {
      var txtArr = txt.split('،');
      txt = txtArr[0];
      if (txtArr[1]) txt = txt + ',' + txtArr[1];
      if (txtArr[2]) txt = txt + ',' + txtArr[2];
    }
    //hover focus //http://maps.google.com/maps?q=' + arr[0] + ',' + arr[1] + '&t=m
    var htmlDiv =
      '<div  data-class="popoverClass" data-trigger="focus"  data-container = "body" data-placement = "auto"    data-content="<iframe width=570  height=240 frameborder=0 style=border:0 src=//maps.google.com/maps?q=' +
      arr[0] +
      ',' +
      arr[1] +
      '&output=embed&key=AIzaSyDt_LXqNEEYzUPFhn1eUG-QMtaujZKLOIY&t=k ></iframe>" >';
    var html =
      htmlDiv + '<a href="javascript:void(0)" target="_blank">' + txt + '</a>';
    html += '</div>';
    return html;
  }

  static fDistance(val) {
    if (val == '' || val == null) val = 0;
    return CellFormatter.float2decimals(val) + (this.showUnit ? ' KM' : '');
  }

  static fInt(val) {
    if (val == '') val = 0;
    return CellFormatter.float2decimals(val);
  }

  static fFuel(val) {
    return CellFormatter.float2decimals(val) + (this.showUnit ? ' Liter' : '');
  }

  static fFuel_price(val, data, index) {
    if (data.length > 0) {
      let newVal = data[0][index];
      let isRepeated = false;
      for (let i = 1; i < data.length; i++) {
        if (newVal != data[i][index]) {
          return '';
        }
      }

      if (!isRepeated) {
        val = newVal;
      }
    }
    return CellFormatter.float2decimals(val) + ' ' + this.currency;
  }

  static fFuel_cost(val) {
    return CellFormatter.float2decimals(val) + ' ' + this.currency;
  }

  static fFuel_efficiency(value, field, data) {
    if (data.length > 0) {
      let routeLengthColData = window['_grid_fields'].find(
        (elm) => elm['code'] == 'route_length'
      );

      let fuelColData = false;
      if (field == 'fuel_efficiency_math')
        fuelColData = window['_grid_fields'].find(
          (elm) => elm['code'] == 'fuel_consumption'
        );
      else if (field == 'fuel_level_sensor_efficiency')
        fuelColData = window['_grid_fields'].find(
          (elm) => elm['code'] == 'fuel_level_sensor'
        );

      if (routeLengthColData && fuelColData) {
        let rlIndex = routeLengthColData['index'];
        let fcIndex = fuelColData['index'];

        let rlSum = 0;
        let fcSum = 0;
        for (let i = 0; i < data.length; i++) {
          rlSum += data[i][rlIndex];
          fcSum += data[i][fcIndex];
        }
        value = fcSum == 0 ? 0 : rlSum / fcSum;
      }
    }

    return (
      CellFormatter.float2decimals(value) + (this.showUnit ? ' KM / Liter' : '')
    );
  }

  static fFuel_sensor(val, fuel_Unit) {
    return (
      CellFormatter.float2decimals(val) + ' ' + (this.showUnit ? fuel_Unit : '')
    );
  }

  static fDuration(val) {
    //return val;
    if (typeof val === 'number' && val < 0) {
      return 'N/A';
    }

    if (typeof val === 'string') {
      if (Number(val) < 0 || parseInt(val) < 0 || parseFloat(val) < 0) {
        return 'N/A';
      } else {
        return val;
      }
    } else {
      var arr = moment.duration(val, 'seconds');
      const days = arr['_data']['months'] * 30 + arr['_data']['days'] * 1;
      //            if (arr['_data']['months'] > 0) {
      //                days = (arr['_data']['months'] * 30 ) + (arr['_data']['days'] * 1);
      //            } else {
      //                days = arr['_data']['days'];
      //            }
      if (this.durationFormat == 'days') {
        return (
          days +
          (days > 1 ? ' days ' : ' day ') +
          CellFormatter.time2digits(arr['_data']['hours']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['minutes']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['seconds'])
        );
      } else if (this.durationFormat == 'hours') {
        return (
          days * 24 +
          +CellFormatter.time2digits(arr['_data']['hours']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['minutes']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['seconds'])
        );
      } else if (days > 0) {
        return (
          days +
          (days > 1 ? ' days ' : ' day ') +
          CellFormatter.time2digits(arr['_data']['hours']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['minutes']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['seconds'])
        );
      } else {
        return (
          days * 24 +
          +CellFormatter.time2digits(arr['_data']['hours']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['minutes']) +
          ':' +
          CellFormatter.time2digits(arr['_data']['seconds'])
        );
      }
    }
  }

  static time2digits(n): any {
    return n > 9 ? '' + n : '0' + n;
  }

  static fSpeed(val) {
    return CellFormatter.float2decimals(val) + (this.showUnit ? ' K/H' : '');
  }

  private static fAvgSpeed(val: any, data: any) {
    let value: number = 0;
    if (val instanceof Array && val.length > 0) value = val[0];
    else if (!isNaN(val)) value = val;
    else if (data.length > 0) {
      let avgSpeedColData = window['_grid_fields'].find(
        (elm) => elm['code'] == 'avg_spd'
      );
      if (avgSpeedColData) {
        let avgSpdIndex = avgSpeedColData['index'];

        let spdSum = 0;
        let spdCount = 0;
        for (let i = 0; i < data.length; i++) {
          spdCount += data[i][avgSpdIndex][1];
          spdSum += data[i][avgSpdIndex][2];
        }
        value = spdCount == 0 ? 0 : spdSum / spdCount;
      }
    }

    return CellFormatter.float2decimals(value) + (this.showUnit ? ' K/H' : '');
  }

  static fLabel(val, data, index) {
    if (index == 0) return val;

    if (data.length > 0) {
      val = data[0][index];
      for (let i = 1; i < data.length; i++) {
        if (val != data[i][index]) return '';
      }
    }

    return val;
  }

  static fPercentage(val) {
    return CellFormatter.float2decimals(val) + '%';
  }

  static float2decimals(num) {
    return parseFloat(num).toFixed(2);
  }

  static formatCell(
    val: any,
    field: any,
    format: any,
    grouped_data: any,
    colIndex: number = 0,
    fuel_unit: string = 'L'
  ) {
    if (val == null) {
      if (format == '' || format == 'label' || format == 'customfield') {
        return this.fLabel(val, grouped_data, colIndex);
      } else if (format == 'fuel_price') {
        return this.fFuel_price(val, grouped_data, colIndex);
      }
      val = 0;
    }
    switch (format) {
      case 'map':
        return CellFormatter.fMapAddress(val);
      case 'hours':
        return CellFormatter.fHours(val);
      case 'date':
      case 'datetime':
        return CellFormatter.fDate(val);
      case 'duration':
        return CellFormatter.fDuration(val);
      case 'distance':
        return CellFormatter.fDistance(val);
      case 'int':
        return CellFormatter.fInt(val);
      case 'fuel':
        return CellFormatter.fFuel(val);
      case 'fuel_price':
        return CellFormatter.fFuel_price(val, grouped_data, colIndex);
      case 'fuel_cost':
        return CellFormatter.fFuel_cost(val);
      case 'fuel_efficiency':
        return CellFormatter.fFuel_efficiency(val, field, grouped_data);
      case 'fuel_sensor':
        return CellFormatter.fFuel_sensor(val, fuel_unit);
      case 'speed':
      case 'avgSpeed':
        return CellFormatter.fAvgSpeed(val, grouped_data);
      case 'maxSpeed':
      case 'minSpeed':
        return CellFormatter.fSpeed(val);
      case 'label':
        return CellFormatter.fLabel(val, grouped_data, colIndex);
      case 'percentage':
        return CellFormatter.fPercentage(val);
      case 'download':
        return '';
      default:
        return CellFormatter.fLabel(val, grouped_data, colIndex);
    }
  }
}
export class HeaderFormatter {
  public static showUnit = true;
  static HEADER_UNITS = {
    distance: 'KM',
    fuel: 'Liter',
    fuel_price: 'SAR',
    fuel_cost: 'SAR',
    fuel_efficiency: 'KM / Lite',
    fuel_sensor: 'L',
    speed: 'K/H',
    avgSpeed: 'K/H',
    maxSpeed: 'K/H',
    minSpeed: 'K/H',
    percentage: '%',
  };
  static formatHeader(val: string, format: string) {
    return (
      val +
      '' +
      (this.HEADER_UNITS[format] ? ' | ' + this.HEADER_UNITS[format] : '')
    );
  }
}
