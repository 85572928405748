import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
@Component({
  selector: 'control-errors-hint',
  templateUrl: './control-errors-hint.component.html',
  styleUrls: ['./control-errors-hint.component.scss'],
  providers: [],
})
export class ControlErrorsHintComponent {
  _ctl: UntypedFormControl;

  @Input() set ctl(value: UntypedFormControl) {
    this._ctl = value;
    this._ctl.statusChanges.subscribe({
      next: (status) => {
        if (status == 'VALID' && document.getElementById('afaqy-tooltip')) {
          document.getElementById('afaqy-tooltip').remove();
        }
      },
    });
  }
}
