import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'app/core/services';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'login-agreement',
  templateUrl: './login-agreement.component.html',
  styleUrls: ['./login-agreement.component.scss'],
})
export class LoginAgreementComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  currentLang: string;
  isAgreed: boolean;

  constructor(
    private loginService: LoginService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.setCurrentLang();
  }

  private setCurrentLang(): void {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (obj: any) => {
          this.currentLang = obj.lang;
        },
      });
  }

  changeAgreementState(value: boolean): void {
    this.isAgreed = value;
  }

  closeModal(): void {
    this.loginService.isIsuzuAgreementNeeded = false;
    sessionStorage.setItem('isuzuConnected', 'true');
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
