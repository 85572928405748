import { Component, OnInit } from '@angular/core';
import * as wjcInput from '@grapecity/wijmo.input';

import { AuthService } from '../../../core/services/auth.service';
import { AppConfig } from '../../../common/classes/app-config';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'system-langs',
  templateUrl: './system-langs.component.html',
  styleUrls: ['./system-langs.component.scss'],
})
export class SystemLangsComponent implements OnInit {
  selectedLang = '';
  selectedLangImage = '';
  configs = AppConfig;
  alive = true;

  constructor(
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.selectedLang = this.translate.currentLang;
    this.setLangs(this.selectedLang);
  }

  setLangs(lang?: string) {
    this.selectedLang = lang;
    this.selectedLangImage =
      '<img width="16" height="16" class="lang-icon" src="assets/images/langs/' +
      this.selectedLang +
      '.png/>';
  }

  changeLang(event: any) {
    const lang = event.target.value;
    if (lang != this.selectedLang) {
      this.translate.use(this.trimSuffix(lang));
      this.selectedLang = lang;
    }
  }

  trimSuffix(lng: string) {
    return lng.replace('-ltr', '').replace('-rtl', '');
  }
}
