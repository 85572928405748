import { AfaqyObject, AppConfig, AfaqyHelper } from '../../../common/classes';

export class UserMap extends AfaqyObject {
  private _rmp: boolean = false;
  private _st: boolean = false;
  private _tc: string = '#ffffff';
  private _rc: string;
  private _rhc: string;
  private _icon: string = 'arrow';
  private _rmz: boolean = false;
  private _zoom_slider: boolean;
  private _show_select_tile: boolean;
  private _default_tile: string;
  private _show_tiles: string[];
  private _show_layers_control: boolean;
  private _show_layers: string[];
  private _show_utilities_control: boolean;
  private _show_utilities: string[];
  private _default_map_center: MapLocation = new MapLocation();
  private _default_unit_location: MapLocation = new MapLocation();

  protected copyFields = [
    'rmp',
    'st',
    'tc',
    'rc',
    'rhc',
    'icon',
    'rmz',
    'zoom_slider',
    'show_select_tile',
    'default_tile',
    'show_tiles',
    'show_layers_control',
    'show_layers',
    'show_utilities_control',
    'show_utilities',
    'default_map_center',
    'default_unit_location',
  ];

  get rmp(): boolean {
    return this._rmp === undefined ? true : this._rmp;
  }

  set rmp(value: boolean) {
    if (typeof value == typeof true) {
      this._rmp = value;
    }
  }

  get st(): boolean {
    return this._st ? true : false;
  }

  set st(value: boolean) {
    this._st = value;
  }

  get tc(): string {
    return this._tc ? this._tc : '#ff0000';
  }

  set tc(value: string) {
    this._tc = value;
  }

  get rc(): string {
    return this._rc ? this._rc : '#ff0000';
  }

  set rc(value: string) {
    this._rc = value;
  }

  get rhc(): string {
    return this._rhc ? this._rc : '#ff0000';
  }

  set rhc(value: string) {
    this._rhc = value;
  }

  get icon(): string {
    return this._icon ? this._icon : 'icon';
  }

  set icon(value: string) {
    this._icon = value;
  }

  get rmz(): boolean {
    return this._rmz === undefined ? true : this._rmz;
  }

  set rmz(value: boolean) {
    if (typeof value == typeof true) {
      this._rmz = value;
    }
  }

  get zoom_slider(): boolean {
    return this._zoom_slider === undefined ? true : this._zoom_slider;
  }

  set zoom_slider(value: boolean) {
    if (typeof value == typeof true) {
      this._zoom_slider = value;
    }
  }

  get show_select_tile(): boolean {
    return this._show_select_tile === undefined ? true : this._show_select_tile;
  }

  set show_select_tile(value: boolean) {
    if (typeof value == typeof true) {
      this._show_select_tile = value;
    }
  }

  get default_tile(): string {
    return this._default_tile === undefined || this._default_tile === ''
      ? 'osm'
      : this._default_tile;
  }

  set default_tile(value: string) {
    this._default_tile = value;
  }

  get show_layers_control(): boolean {
    return this._show_layers_control === undefined
      ? true
      : this._show_layers_control;
  }

  set show_layers_control(value: boolean) {
    if (typeof value == typeof true) {
      this._show_layers_control = value;
    }
  }

  get show_utilities_control(): boolean {
    return this._show_utilities_control === undefined
      ? true
      : this._show_utilities_control;
  }

  set show_utilities_control(value: boolean) {
    if (typeof value === 'boolean') {
      this._show_utilities_control = value;
    }
  }

  get show_utilities(): string[] {
    return this._show_utilities ? this._show_utilities : AppConfig.utilities;
  }

  set show_utilities(value: string[]) {
    this._show_utilities = value;
  }

  get show_layers(): string[] {
    return this._show_layers ? this._show_layers : AppConfig.layers;
  }

  set show_layers(value: string[]) {
    this._show_layers = value;
  }

  get show_tiles(): string[] {
    return this._show_tiles ? this._show_tiles : AppConfig.maps;
  }

  set show_tiles(value: string[]) {
    this._show_tiles = value;
  }

  get default_map_center(): MapLocation {
    return this._default_map_center;
  }

  set default_map_center(value: MapLocation) {
    this._default_map_center = value;
  }

  get default_unit_location(): MapLocation {
    return this._default_unit_location;
  }

  set default_unit_location(value: MapLocation) {
    this._default_unit_location = value;
  }
}

export class MapLocation extends AfaqyObject {
  private _lat: number = 0;
  private _lng: number = 0;

  protected copyFields = ['lat', 'lng'];

  get lat(): number {
    return this._lat;
  }

  set lat(value: number) {
    this._lat = value;
  }

  get lng(): number {
    return this._lng;
  }

  set lng(value: number) {
    this._lng = value;
  }
}
