import { AppConfig } from './app-config';

export class Pagination {
  private _allCount: number;
  private _filtersCount: number;
  private _resultCount: number;
  private _offset: number;
  private _limit: number;
  private _pages: number;
  private _current: number;

  constructor() {
    this.allCount = 0;
    this.filtersCount = 0;
    this.resultCount = 0;
    this.offset = 0;
    this.limit = 0;
    this.pages = 0;
    this.current = 0;
  }

  static get defaultLimit() {
    return AppConfig.defaultPaginationLimit;
  }

  static getOffset(page: number, limit: number = 0) {
    if (!limit && limit != 0) {
      limit = this.defaultLimit;
    }
    return (page - 1) * limit;
  }

  public copyInto(obj: any, defaultLimit?: number) {
    const limit: number = defaultLimit ? defaultLimit : obj.limit;
    this.allCount = obj.allCount;
    this.filtersCount = obj.filtersCount;
    this.resultCount = obj.resultCount;
    this.offset = obj.offset;
    this.limit = obj.limit;
    this.pages = Math.ceil(obj.filtersCount / limit);
    this.current = Math.floor(obj.offset / obj.limit) + 1;
  }

  public hasNext() {
    return this.current < this.pages;
  }

  public hasPrev() {
    return this.current > 1;
  }

  public next() {
    return this.hasNext() ? this.current + 1 : this.current;
  }

  public prev() {
    return this.hasPrev() ? this.current - 1 : this.current;
  }

  public getAllCount() {
    return this.allCount;
  }

  get pages(): number {
    return this._pages;
  }

  get current(): number {
    return this._current;
  }

  set pages(value: number) {
    this._pages = value;
  }

  set current(value: number) {
    this._current = value;
  }

  set allCount(value: number) {
    this._allCount = value;
  }

  set filtersCount(value: number) {
    this._filtersCount = value;
  }

  set resultCount(value: number) {
    this._resultCount = value;
  }

  set offset(value: number) {
    this._offset = value;
  }

  set limit(value: number) {
    this._limit = value;
  }

  get allCount(): number {
    return this._allCount;
  }

  get filtersCount(): number {
    return this._filtersCount;
  }

  get resultCount(): number {
    return this._resultCount;
  }

  get offset(): number {
    return this._offset;
  }

  get limit(): number {
    return this._limit;
  }
}
