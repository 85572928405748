import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core';
import { Pagination } from '../../../common/classes/pagination';
@Component({
  selector: 'afaqy-paginate',
  exportAs: 'AfaqyPagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() getPageResources: EventEmitter<any> = new EventEmitter<any>();

  @Input() pagination: Pagination;

  @Input() first = 'first';
  @Input() last = 'last';
  @Input() next = 'next';
  @Input() previous = 'prev';
  @Input() small = false;
  pages = [];
  prevDots = false;
  afterDots = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    let x = [];
    this.prevDots = !this.small && this.pagination.current > 5 ? true : false;
    this.afterDots =
      !this.small && this.pagination.pages - this.pagination.current > 5
        ? true
        : false;
    const start = this.small
      ? this.pagination.current
      : this.pagination.current <= 5
      ? 1
      : this.pagination.current - 5;
    const end = this.small
      ? this.pagination.current
      : this.pagination.pages - this.pagination.current <= 5
      ? this.pagination.pages
      : this.pagination.current + 5;
    for (var i = start; i <= end; i++) {
      x.push(i);
    }
    this.pages = x;
  }

  getPage(page) {
    this.getPageResources.next(page);
  }
}
