import { Injectable } from '@angular/core';
import {
  PERIODS,
  SECTIONS,
  DETAILS_HEADER,
  SOURCE_ITEMS,
} from '../components/timeline/data';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';
import {
  Section,
  SectionData,
  Item,
} from 'app/shared/modules/ngx-timeline/ngx-time-scheduler.model';

@Injectable()
export class TimelineService {
  constructor() {}

  getPeriod() {
    return of(PERIODS);
  }
  getData(): Observable<Section[]> {
    return of(SECTIONS);
  }
  getHeader(): Observable<SectionData[]> {
    return of(DETAILS_HEADER);
  }
  getItems(): Observable<any[]> {
    return of(SOURCE_ITEMS);
  }
}
