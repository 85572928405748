<div class="loginContainer">
  <div class="container">
    <div class="inner-container">
      <div class="tile">
        <div class="tile-inner-container">
          <div class="row">
            <div class="col-12">
              <div
                class="brandContainer {{ configs.loginClass }}"
                [style.background]="configs.login_logo_bgColor"
              >
                <img
                  class="img-fluid brand-image"
                  src="{{ configs.loginlogo }}"
                  alt="{{ configs.title }}"
                />
              </div>
            </div>
          </div>

          <div class="tile-body d-flex flex-column align-items-center">
            <mat-spinner [diameter]="50"></mat-spinner>
            <h2 class="tile-header">{{'redirecting' | translate}}</h2>
            <p class="paragraph">{{'loginAs-details' | translate }}</p>
          </div>

        </div>
      </div>
      <div class="side-img">
        <div class="slide-container">
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
          >
            <div
              ngxSlickItem
              *ngFor="let slide of slides"
              class="slide-inner-conatiner"
            >
              <div class="slide">
                <div class="text">
                  {{ slide?.text }}
                  <div class="heading">
                    {{ slide?.heading }}
                  </div>
                </div>
                <div class="image">
                  <img
                    *ngIf="slide?.image"
                    src="{{ slide?.image }}"
                    alt="Illustration"
                  />
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
