import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectBoolean',
})
export class ObjectkeybooleanPipe implements PipeTransform {
  transform(value: any, col: any): any {
    col.split('.').forEach(function (key) {
      value = value[key];
    });
    return value ? 'true' : 'false';
  }
}
