export enum ButtonClass {
  primary = 'btn-primary',
  secondary = 'btn-secondary',
  success = 'btn-success',
  outlineDanger = 'btn-outline-danger',
  danger = 'btn-danger',
  warning = 'btn-warning',
  info = 'btn-info',
  light = 'btn-light',
  dark = 'btn-dark',
}
