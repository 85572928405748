import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { takeWhile } from 'rxjs/operators';
@Component({
  selector: 'control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss'],
  providers: [],
})
export class ControlErrorsComponent implements OnInit, OnDestroy {
  private alive: boolean = true;
  @Input()
  public ctl: FormControl;
  @Input()
  public translationKey: string = '';

  errorMessages: any[] = [];

  constructor() {}

  ngOnInit() {
    this.setErrorMessages();
    this.ctl.statusChanges.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        this.setErrorMessages();
      },
    });
    this.ctl.valueChanges.subscribe({
      next: () => {
        this.setErrorMessages();
      },
    });
  }

  setErrorMessages() {
    let messages = [];
    if (this.ctl) {
      for (let propertyName in this.ctl.errors) {
        if (this.ctl.errors.hasOwnProperty(propertyName)) {
          //Logger.error(this.ctl ,propertyName, this.ctl.errors[propertyName]);
          messages.push(
            AfaqyValidation.getValidatorErrorMessage(
              propertyName,
              this.ctl.errors[propertyName]
            )
          );
        }
      }
    }
    this.errorMessages = messages;
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
