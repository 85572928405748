<div class="unit-form-custom d-flex flex-column">
  <div *ngIf="!isView" class="d-flex justify-content-end filter no-padding">
    <div class="d-flex input-group form-group-hint col-12 col-lg-7 pr-0 pb-4">
      <afaqy-custom-select
        [dataList]="customFieldslist"
        [predefinedList]="true"
        [titleField]="'key'"
        [clear]="true"
        [multiple]="true"
        [placeholderText]="'add_new_custom' | translate"
        [formControl]="custom"
        (valueChanged)="selectionChanges($event)"
      >
      </afaqy-custom-select>
    </div>
  </div>
  <div class="d-flex w-100 unit-form-custom-2 custom-scrollable-area">
    <div class="p-relative w-100" [formGroup]="form">
      <div class="afaqy-mask" *ngIf="isView"></div>
      <div formArrayName="custom_fields" class="well well-lg">
        <table
          class="table table-responsive table-stripped table-hover table-sm"
        >
          <thead>
            <tr class="d-flex w-100">
              <th width="25%">
                {{ 'units.custom_fields.key' | translate }}
              </th>
              <th width="25%">
                {{ 'units.custom_fields.type' | translate }}
              </th>
              <th width="25%">
                {{ 'units.custom_fields.value' | translate }}
              </th>
              <th width="25%">
                {{ 'units.custom_fields.measurement_type' | translate }}
              </th>
            </tr>
          </thead>
          <tbody class="custom-fields-list">
            <tr
              class="d-flex w-100"
              *ngFor="let obj of custom_fields?.controls; let i = index"
              [formGroupName]="i"
            >
              <div class="d-flex w-100">
                <td width="25%" class="pl-0">
                  <div class="row form-group">
                    <div class="col-12 input-group form-group-hint">
                      <input
                        class="form-control form-control-sm"
                        formControlName="key"
                        readonly
                      />
                    </div>
                  </div>
                </td>
                <td width="25%">
                  <div class="row form-group">
                    <div class="col-12 input-group form-group-hint">
                      <ng-container
                        *ngIf="translateService.currentLang === 'en'"
                      >
                        <input
                          class="form-control form-control-sm"
                          formControlName="typeEn"
                          readonly
                        />
                      </ng-container>
                      <ng-container
                        *ngIf="translateService.currentLang === 'ar'"
                      >
                        <input
                          class="form-control form-control-sm"
                          formControlName="typeAr"
                          readonly
                        />
                      </ng-container>
                    </div>
                  </div>
                </td>
                <td width="25%">
                  <div class="row form-group">
                    <div class="col-12 input-group form-group-hint">
                      <afaqy-default-value
                        [type]="
                          form?.get('custom_fields').controls[i]?.value.type
                        "
                        [currentControl]="
                          form?.get('custom_fields').controls[i].get('value')
                        "
                        [showControlError]="true"
                      ></afaqy-default-value>
                    </div>
                  </div>
                </td>
                <td width="25%">
                  <div class="row form-group">
                    <div
                      class="col-12 input-group form-group-hint"
                      [class.has-danger]="
                        (form
                          ?.get('custom_fields')
                          .controls[i].get('measurement_unit')?.dirty ||
                          form
                            ?.get('custom_fields')
                            .controls[i].get('measurement_unit')?.touched) &&
                        form
                          ?.get('custom_fields')
                          .controls[i].get('measurement_unit')?.errors
                      "
                    >
                      <input
                        class="form-control form-control-sm"
                        formControlName="measurement_unit"
                      />
                      <control-errors-hint
                        [ctl]="
                          form
                            ?.get('custom_fields')
                            .controls[i].get('measurement_unit')
                        "
                      ></control-errors-hint>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
