import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import * as wjcCore from '@grapecity/wijmo';

import { Unit, UnitDriverBehavior } from 'app/modules/units/models';
import {
  UnitDriverBehaviorService,
  UnitService,
} from 'app/modules/units/services';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { AfaqyResponse } from '../../../../core/classes';
import { AfaqyAPIResponse } from '../../../../core/classes/afaqy-response';
import { ApiRequestService } from '../../../../core/services/api-request-service';
import { AuthService } from '../../../../core/services/auth.service';
import { Logger, Message } from './../../../../common/classes';
import { UnitFormBaseSubComponent } from 'app/shared/components/unitFormSub/unit-form-base-sub.component';

@Component({
  selector: 'unit-form-driver-behavior',
  templateUrl: './unit-form-driver-behavior.component.html',
  styleUrls: ['./unit-form-driver-behavior.component.scss'],
})
export class UnitFormDriverBehaviorComponent
  extends UnitFormBaseSubComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() group: UntypedFormGroup;
  cid = 'units-unit_driver_behavior-';
  controller = 'unit_driver_behavior';
  moduleKey = 'driver_behavior';
  selectedItem: UnitDriverBehavior;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public service: UnitDriverBehaviorService,
    public unitService: UnitService,
    protected apiRequest: ApiRequestService
  ) {
    super(route, authService, service, unitService);
  }

  loadItems() {
    let items = this.loadSensorForAllBehavious(this.unitObject.driver_behavior);
    this.itemsList.sourceCollection = items;
  }

  loadSensorForAllBehavious(items) {
    for (let i in items) {
      let sensor = this.unitObject.sensors.find((obj) => {
        return obj.id == items[i]['sensor_id'];
      });

      if (sensor) {
        items[i]['sensor_name'] = sensor['name'];
      }
    }
    return items;
  }

  update(event) {
    super.update(event);
    this.loadItems();
    this.selectedItem = new UnitDriverBehavior();
  }

  addForm() {
    super.addForm();
    this.selectedItem = new UnitDriverBehavior();
    this.openForm();
  }

  updateDriverBehaviorList(item) {
    let driverBehavior = item;
    this.unitObject.driver_behavior = this.unitObject.driver_behavior.filter(
      (sens) => sens.id != driverBehavior.id
    );
    this.unitService.setResourceObject(this.unitObject);
  }

  afterDeleteSuccess(item: any, response?: any) {
    super.afterDeleteSuccess(item, response);
    this.updateDriverBehaviorList(item);
  }
}
