import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import tippy, { hideAll } from 'tippy.js';

@Directive({
  selector: '[afaqy-tooltip]',
})
export class AfaqyTooltipDirective implements OnInit {
  component;
  content;

  tippyOptions: any = {
    // placement: 'bottom-start',
    placement: 'right-auto',
    interactive: false,
    // followCursor: true,
    // flipOnUpdate: true,
    arrow: false,
    offset: [5, 5],
    delay: [100, null],
    onHide: (instance) => {
      if (this.component) {
        this.component.destroy();
      }
    },
    onShow: (instance) => {
      hideAll({ exclude: instance });
      instance.setContent(this.content);
      if (!this.tooltipText && !this.afaqyTooltipHtml) {
        this.setTooltipHTML.emit({
          devContainerID: 'tippy-' + instance.id,
          instance: instance,
          directive: this,
        });
      }
    },

    appendTo: () => document.body,
    theme: 'light',
    // plugins: [followCursor],
    // popperOptions: {
    //     strategy: 'fixed',
    //     modifiers: [
    //         {
    //             name: 'flip',
    //             options: {
    //                 fallbackPlacements: ['bottom', 'right'],
    //             },
    //         },
    //         {
    //             name: 'preventOverflow',
    //             options: {
    //                 altAxis: true,
    //                 tether: false,
    //             },
    //         },
    //     ],
    // },
  };
  // tslint:disable-next-line:no-input-rename
  @Input('containerClass') containerClass = '';

  @Output() setTooltipHTML: EventEmitter<any> = new EventEmitter();

  @Input() set tooltipText(value) {
    this.content = value;
    // this.tippyOptions['content'] = value;
  }

  @Input() set afaqyTooltipHtml(value) {
    this.content = value;
    // this.tippyOptions['onShow'] = (instance) => {
    //     instance.setContent(value);
    // };
  }

  @Input() set tooltipOptions(options) {
    Object.keys(options).forEach((key) => {
      this.tippyOptions[key] = options[key];
    });
  }

  constructor(private elementRef: ElementRef) {
    // this.tippyOptions["boundary"] = "window";
    this.tippyOptions['placement'] = 'bottom-start';
  }

  public ngOnInit() {
    tippy(this.elementRef.nativeElement, this.tippyOptions);
  }
}
