import { Injectable } from '@angular/core';
import { MapLayerService } from '../map/map_layer.service';
import { MapService } from '../map/map.service';
import { TranslateService } from '@ngx-translate/core';
import { MapModel } from '../map/mapModel';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

@Injectable()
export class TrackingMaplayerService extends MapLayerService {
  constructor(mapService: MapService, translate: TranslateService) {
    super(mapService, null, translate);
  }

  layerId: string = 'tracking_points';
  features = {};
  filterFeatures(): void {}
  createFeature(marker) {
    let feature: any = {};
    this.mapService.action((mapModel: MapModel) => {
      feature = mapModel.createPoint(
        [marker.location.coordinates[0], marker.location.coordinates[1]],
        {}
      );
      feature.setId(marker.id);
      feature.set('label', marker.name);
      feature.set('maxResolutionForLabel', 1400);
      feature.setStyle(this.createStyle(marker));
    });
    return feature;
  }

  addFeatures(features, map_key = 'map_main') {
    this.mapService.action((mapModel: MapModel) => {
      mapModel.addFeatures(features, this.layerId);
    }, map_key);
  }
  private createStyle(marker) {
    let textStyle = new Text({
      placement: 'point',
      font: 'bold 16px Arial',
      fill: new Fill({ color: '#000' }),
      offsetY: 22,
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    });
    let iconStyle = new Icon({
      src: marker.iconUrl,
      rotation: 0,
    });
    return (feature, resolution) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      let styles = [];
      styles.push(
        new Style({
          image: iconStyle,
          text: textStyle,
        })
      );
      return styles;
    };
  }

  refresh() {
    console.log('refresh tracking');
  }
}
