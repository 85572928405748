import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomFieldsService } from 'app/modules/custom-fields/custom-fields.service';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { pairwise, startWith, takeWhile } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'unit-form-custom',
  templateUrl: './unit-form-custom.component.html',
  styleUrls: ['./unit-form-custom.component.scss'],
})
export class UnitFormCustomComponent implements OnInit {
  custom: FormControl = new FormControl<any>([]);
  @Input('form') form: UntypedFormGroup;
  @Input() isView;
  @Input() isAdd;
  @Input() selectedIds;
  @Input() isViewLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  @Input() customFieldsView: any;
  customFieldslist;
  alive: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    public customFieldsService: CustomFieldsService,
    public translateService: TranslateService
  ) {}

  get custom_fields(): UntypedFormArray {
    return this.form.get('custom_fields') as UntypedFormArray;
  }

  selectionChanges(event) {
    const removeIndices = [];
    this.custom_fields.controls.forEach((item, i) => {
      event.forEach((id) => {
        if (id == item.value.id) {
          removeIndices.push(i);
        }
      });
    });
    // removeIndices.forEach((index) => {
    //   this.custom_fields.removeAt(index);
    // });
  }

  customFieldsValueChange() {
    this.custom.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        startWith([]), // Include initial value
        pairwise()
      )
      .subscribe(([prevValues, currValues]) => {
        const { added, removed } = this.getAddedItemsAndRemovedItems(
          prevValues,
          currValues
        );
        const addedItems = this.customFieldslist?.filter((x) =>
          added?.includes(x.id)
        );
        const removedItems = this.customFieldslist?.filter((x) =>
          removed?.includes(x.id)
        );
        if (addedItems?.length) {
          addedItems?.forEach((item) => {
            this.custom_fields.push(this.createCustomField(item));
            this.custom_fields.controls.forEach((fb) => {
              if (fb['controls'].type) {
                switch (fb['controls'].type.value) {
                  case 'general':
                    fb['controls'].value.setValidators([
                      Validators.required,
                      Validators.minLength(1),
                      Validators.maxLength(30),
                    ]);
                    break;

                  case 'phone':
                    fb['controls'].value.setValidators([
                      Validators.required,
                      this.phoneNumberValidator.bind(this),
                    ]);
                    break;

                  case 'email':
                    fb['controls'].value.setValidators([
                      Validators.required,
                      this.emailValidator.bind(this),
                      Validators.minLength(5),
                      Validators.maxLength(30),
                    ]);
                    break;
                  default:
                    // // Handle default case, including empty or undefined
                    if (
                      !fb['controls'].value.type ||
                      fb['controls'].value.type === '' ||
                      fb['controls'].value.type === undefined ||
                      fb['controls'].value.type === null
                    ) {
                      fb['controls'].value.clearValidators();
                    }
                    break;
                }
              }
            });
          });
          this.form.markAsDirty();
        }
        if (removedItems?.length) {
          removedItems?.forEach((item) => {
            const index = this.custom_fields?.controls?.findIndex(
              (x) => x.value?.id === item.id
            );
            this.custom_fields.removeAt(index);
          });
        }
      });
  }

  phoneNumberValidator(control) {
    const phoneNumberPattern = /^\+\d{11,12}$/; // must start with a '+' sign and accept 12 (oman) or 13 (ksa) digits

    if (control.value && !phoneNumberPattern.test(control.value)) {
      return { phoneNumber: 'Invalid phone number format' };
    }

    return null;
  }

  emailValidator(control) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/i;

    if (control.value && !emailPattern.test(control.value)) {
      return { email: 'Invalid mail' };
    }

    return null;
  }

  ngOnInit(): void {
    this.getCustomFieldslist();
  }

  refactorValueBasedOnType(item): any {
    return this.isAdd ? item.default_value : item.value;
  }

  createCustomField(item): UntypedFormGroup {
    let value = this.refactorValueBasedOnType(item);
    return this.fb.group({
      id: [item.id],
      key: [item.key, [Validators.required]],
      value: [!this.isAdd ? item.default_value : value, [Validators.required]],
      type: [item.type],
      typeEn: [item.typeEn],
      typeAr: [item.typeAr],
      measurement_unit: [
        {
          value: item.type == 'general' ? item.measurement_unit : null,
          disabled: item.type != 'general' ? true : false,
        },
        [(Validators.minLength(1), Validators.maxLength(10))],
      ],
    });
  }

  getCustomFieldslist() {
    if (!this.isAdd) {
      combineLatest([
        this.customFieldsService.finishedLoading,
        this.isViewLoaded,
      ]).subscribe({
        next: ([customFieldsLoaded, isViewLoaded]) => {
          if (customFieldsLoaded && isViewLoaded) {
            this.customFieldslist = [...this.customFieldsService.resourcesList];
            this.customFieldsValueChange();
            this.custom.patchValue(this.selectedIds);
            this.customFieldsView.forEach((item) => {
              const value = this.refactorValueBasedOnType(item);
              this.custom_fields.controls.forEach((control) => {
                if (control.value.id == item.id) {
                  control.get('id').patchValue(item.id);
                  control.get('type').patchValue(item.type);
                  if (control.get('type').value != 'date') {
                    control.get('value').patchValue(value);
                  } else {
                    control
                      .get('value')
                      .patchValue(moment(value).utc(true).toDate());
                  }
                  control.get('key').patchValue(item.key);
                  control
                    .get('measurement_unit')
                    .patchValue(item.measurement_unit);
                }
              });
            });
          }
        },
      });
    } else {
      this.customFieldslist = [...this.customFieldsService.resourcesList];
      this.customFieldsValueChange();
      this.custom.patchValue(this.selectedIds);
    }
  }

  private getAddedItemsAndRemovedItems(
    prevValues: any[],
    currValues: any[]
  ): { added: any[]; removed: any[] } {
    if (currValues.length === 0) {
      return { added: [], removed: prevValues }; // All items removed
    }

    if (prevValues.length === 0) {
      return { added: currValues, removed: [] }; // All items added
    }

    const added = currValues.filter((item) => !prevValues.includes(item));
    const removed = prevValues.filter((item) => !currValues.includes(item));

    return { added, removed };
  }
}
