import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AfaqyHelper } from '../../common/classes/afaqy-helper';
import { BookLog } from '../classes/book-log';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class BookLogService {
  public changes: Subject<any> = new Subject<any>();
  public logs: BookLog[] = [];
  public status = false;
  public blocked = false;

  constructor(protected authService: AuthService) {
    this.status = false;
  }

  log(item) {
    if (!this.status) {
      return;
    }
    item.content = item.type + ': ' + item.message;
    this.logs.push(item);
    this.changes.next({ item: item });
  }

  logBulk(items) {
    if (!this.status) {
      return;
    }
    items.map((item) => {
      item.content = item.type + ': ' + item.message;
      return item;
    });
    this.logs.concat(items);
    this.changes.next({ items: items });
  }

  getLogs() {
    return this.logs;
  }

  toggle(toggle) {
    if (toggle) {
      this.status = !this.status;
    }
    this.authService.updatePreferences('site', 'booklog', this.status);
    this.logs = [];
    this.changes.next();
  }

  setStatus(status) {
    this.status = status;
    this.toggle(false);
  }

  forceBlocked() {
    this.status = false;
    this.blocked = true;
    if (AfaqyHelper?.MapSectionSplitter) {
      AfaqyHelper?.MapSectionSplitter?.next({ visible: false });
    }
  }

  removeBlocked() {
    this.blocked = false;
  }
}
