import { AppModel } from 'app/common';

export class PlanDetails extends AppModel {
  private _stop_name: string;
  private _stop_id: string;
  private _stop_address: string;
  private _stop_latitude: string;
  private _stop_longitude: string;

  protected copyFields = [
    'id',
    'stop-id',
    'stop-name',
    'stop-address',
    'stop-latitude',
    'stop-longitude',
  ];
  protected createFields = [
    'stop-name',
    'stop-address',
    'stop-latitude',
    'stop-longitude',
  ];
  protected updateFields = [
    'stop-name',
    'stop-address',
    'stop-latitude',
    'stop-longitude',
  ];

  get id(): string {
    return this.stop_id;
  }

  set id(value: string) {
    this._id = value;
  }

  get stop_id(): string {
    return this._stop_id;
  }

  set stop_id(value: string) {
    this._stop_id = value;
  }

  get stop_name(): string {
    return this._stop_name;
  }

  set stop_name(value: string) {
    this._stop_name = value;
  }

  get stop_address(): string {
    return this._stop_address;
  }

  set stop_address(value: string) {
    this._stop_address = value;
  }

  get stop_latitude(): string {
    return this._stop_latitude;
  }

  set stop_latitude(value: string) {
    this._stop_latitude = value;
  }

  get stop_longitude(): string {
    return this._stop_longitude;
  }

  set stop_longitude(value: string) {
    this._stop_longitude = value;
  }
}
