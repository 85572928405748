import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from 'app/core/services';
import { RoutesService } from 'app/modules/monitoring-timeline/services';
import { UnitService } from 'app/modules/units/services';
import { WarehousesService } from 'app/modules/warehouses/services';
import { Subject } from 'rxjs';
import { ManualRoute } from '../models/manual-route';

@Injectable()
export class ManualRouteService extends RootService {
  isAfterAddOrEdit: boolean;
  manualRoute: Subject<ManualRoute> = new Subject<ManualRoute>();
  allResourcesList: Subject<any[]> = new Subject<any[]>();

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    public unitService: UnitService,
    public warehouseService: WarehousesService,
    private routesService: RoutesService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.unitService.resources.subscribe({
      next: (updates) => {
        this.updateServicesRelations(
          updates,
          'unit',
          'unit_id',
          this.unitService
        );
      },
    });
    this.warehouseService.resources.subscribe({
      next: (updates) => {
        this.updateServicesRelations(
          updates,
          'start_warehouse',
          'start_warehouse_id',
          this.warehouseService
        );
      },
    });
    this.warehouseService.resources.subscribe({
      next: (updates) => {
        this.updateServicesRelations(
          updates,
          'end_warehouse',
          'end_warehouse_id',
          this.warehouseService
        );
      },
    });
  }

  setResourceObject(obj: any) {
    let vids = this.authService.preferences('routes_manual', 'visible');
    if (vids?.includes(obj?.id)) {
      obj['visible'] = 1;
    } else {
      obj['visible'] = 0;
    }
    super.setResourceObject(obj);
  }

  setResourcesList(list: any) {
    super.setResourcesList(list);
    this.routesService.manualRoutes.next(list);
    this.setManualRoutesColors();
    this.routesService.manualRoutes.next(this.resourcesList);
  }

  updateObjectsRelations(updates: any) {
    if (updates['action'] == 'list' || updates['action'] == 'addMulti') {
      for (const manualRoute of updates['data']) {
        manualRoute.unit = this.unitService.getItemFromResources(
          manualRoute.unit_id
        );
        manualRoute.start_warehouse =
          this.warehouseService.getItemFromResources(
            manualRoute.start_warehouse_id
          );
        manualRoute.end_warehouse = this.warehouseService.getItemFromResources(
          manualRoute.end_warehouse_id
        );
        this.setResourceObject(manualRoute);
      }
    } else if (updates['action'] == 'update' || updates['action'] == 'add') {
      updates['data'].unit = this.unitService.getItemFromResources(
        updates['data'].unit_id
      );
      updates['data'].start_warehouse =
        this.warehouseService.getItemFromResources(
          updates['data'].start_warehouse_id
        );
      updates['data'].end_warehouse =
        this.warehouseService.getItemFromResources(
          updates['data'].end_warehouse_id
        );
      this.setResourcesList(updates['data']);
    }
  }

  updateServicesRelations(
    updates: any,
    objName: string,
    objId: string,
    service: RootService
  ) {
    if (updates['action'] == 'list') {
      for (const obj of updates['data']) {
        for (const key in this._resourcesList) {
          if (obj.id == this._resourcesList[key][objId]) {
            this._resourcesList[key][objName] = obj;
          }
        }
      }
    } else if (updates['action'] == 'remove') {
      for (const key in this._resourcesList) {
        if (this._resourcesList[key][objId] == updates['data']) {
          this._resourcesList[key][objName] = service.modelInstance;
        }
      }
    } else if (updates['action'] == 'update' && updates['data']) {
      for (const key in this._resourcesList) {
        if (this._resourcesList[key][objId] == updates['data']['id']) {
          this._resourcesList[key][objName] = updates['data'];
        }
      }
    }
    this.pushChanges();
  }

  routerPrefix(val: string = '') {
    return val ? val : 'routes_manual';
  }

  removeFromResources(id: string) {
    this.allResourcesList.next(this.resourcesList);
    super.removeFromResources(id);
  }

  get modelInstance() {
    return new ManualRoute();
  }

  get listingIcon() {
    return 'fas fa-route';
  }

  private setManualRoutesColors(): void {
    for (let manualRoute of this.resourcesList) {
      const colorIndex: number =
        this.resourcesList.indexOf(manualRoute) % ManualRoute.colors.length;
      manualRoute.color = ManualRoute.colors[colorIndex];
    }
  }
}
