<div [formGroup]="group">
  <div class="row">
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['move'].dirty || group.controls['move'].touched) &&
          group.controls['move'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.move' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <select
            class="form-control form-control-sm"
            formControlName="move"
            [attr.testId]="cid + 'move'"
          >
            <option [value]="'acc+gps'">
              {{ 'units.td.gpsacc' | translate }}
            </option>
            <!--<option [value]="'acc'">{{'units.td.acc' | translate}}</option>-->
            <option [value]="'gps'">{{ 'units.td.gps' | translate }}</option>
          </select>
          <control-errors-hint
            [ctl]="group.controls['move']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['min_speed'].dirty ||
            group.controls['min_speed'].touched) &&
          group.controls['min_speed'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.min_speed' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="min_speed"
            [attr.testId]="cid + 'min_speed'"
          />
          <control-errors-hint
            [ctl]="group.controls['min_speed']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['min_stop'].dirty ||
            group.controls['min_stop'].touched) &&
          group.controls['min_stop'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.min_stop' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="min_stop"
            [attr.testId]="cid + 'min_stop'"
          />
          <control-errors-hint
            [ctl]="group.controls['min_stop']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['min_park'].dirty ||
            group.controls['min_park'].touched) &&
          group.controls['min_park'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.min_park' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="min_park"
            [attr.testId]="cid + 'min_park'"
          />
          <control-errors-hint
            [ctl]="group.controls['min_park']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['min_sat'].dirty ||
            group.controls['min_sat'].touched) &&
          group.controls['min_sat'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.min_sat' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="min_sat"
            [attr.testId]="cid + 'min_sat'"
          />
          <control-errors-hint
            [ctl]="group.controls['min_sat']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['max_distance'].dirty ||
            group.controls['max_distance'].touched) &&
          group.controls['max_distance'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.max_distance' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="max_distance"
            [attr.testId]="cid + 'max_distance'"
          />
          <control-errors-hint
            [ctl]="group.controls['max_distance']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['min_trip_time'].dirty ||
            group.controls['min_trip_time'].touched) &&
          group.controls['min_trip_time'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.min_trip_time' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="min_trip_time"
            [attr.testId]="cid + 'min_trip_time'"
          />
          <control-errors-hint
            [ctl]="group.controls['min_trip_time']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['min_trip_distance'].dirty ||
            group.controls['min_trip_distance'].touched) &&
          group.controls['min_trip_distance'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.td.min_trip_distance' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="min_trip_distance"
            [attr.testId]="cid + 'min_trip_distance'"
          />
          <control-errors-hint
            [ctl]="group.controls['min_trip_distance']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
</div>
