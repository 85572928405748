import { AfaqyHelper } from 'app/common';
import { Unit } from '../models';
export const NO_COLOR = 'no_color';
export const NO_SENSOR = 'no_sensor';

/**
 * Class having the sensors of a unit
 * Pointing to the selected sensor
 * getting value and color easier depending on currently selected sensor
 */
export class SensorColor {
  private _unit: Unit;
  private _sensors;
  sensorValue;
  type;
  name;
  selected;
  lastUpdate;
  color;
  unitName;
  tootip: string;
  sensorFullValue;
  constructor(unit: Unit) {
    // this._unit = unit;
    this.lastUpdate = unit.last_update;
    this.sensors = unit.sensors;
    this.unitName = unit['name'];
    this.selected = NO_SENSOR;
  }

  public get sensors() {
    return this._sensors;
  }
  public set sensors(value) {
    this._sensors = value;
  }

  /**
   * get sensor from sensor by name
   * if same name, return the current selected
   * else set name and find sensor
   */
  getSensor(name: string): {} {
    if (!this.type || this.type !== name) {
      this.type = name;
      this.selected =
        this.sensors.find((sensor) => sensor.type === name) || null;
    }
    return this.selected;
  }

  getSensorValue(type): string {
    let sensor = this.getSensor(type);
    if (sensor === NO_SENSOR) return NO_SENSOR;
    this.getSensorFullValue(type);
    this.type = type;
    this.sensorValue = NO_SENSOR;
    if (sensor) {
      this.sensorValue = AfaqyHelper.getSensorValue(this.lastUpdate, sensor)[
        'value'
      ];
    }
    return this.sensorValue;
  }
  getSensorFullValue(type): string {
    let sensor = this.getSensor(type);
    if (sensor === NO_SENSOR) return NO_SENSOR;
    this.type = type;
    this.sensorFullValue = NO_SENSOR;
    if (sensor) {
      this.sensorFullValue = AfaqyHelper.getSensorValue(
        this.lastUpdate,
        sensor
      )['value_full'];
    }

    return this.sensorFullValue;
  }

  getSensorColor(name): string {
    //* Make sure that the selected sensor is up to date
    let sensor = this.getSensor(name);
    if (sensor === NO_SENSOR) return NO_SENSOR;
    // if no sensor value, return;
    if (
      (!this.sensorValue && this.sensorValue !== 0) ||
      this.selected === NO_SENSOR
    )
      return NO_SENSOR;
    // if no colors for the selected sensor, return default behavior

    if (!this.selected.colors || !this.selected.colors.length) return NO_COLOR;

    // else find colors
    let colorRange = this.selected.colors.find((color) =>
      this.findRange(color)
    );

    return colorRange && colorRange.color ? colorRange.color : NO_COLOR;
  }

  private findRange(color: any) {
    if (this.selected.result_type === 'logic') {
      return this.sensorValue === color.type;
    }
    if (this.selected.result_type === 'codeValue') {
      return this.sensorValue === color.type;
    }
    if (
      (color.to === '' || (!color.to && color.to !== 0)) &&
      (color.from === '' || (!color.from && color.from !== 0))
    ) {
      return true;
    }
    if (this.sensorValue <= parseInt(color.to) && color.from === '') {
      return true;
    } else if (
      this.sensorValue <= parseInt(color.to) &&
      this.sensorValue >= parseInt(color.from)
    ) {
      return true;
    } else if (this.sensorValue >= parseInt(color.from) && color.to === '') {
      return true;
    }
    return false;
  }
}
