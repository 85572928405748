import {
  OnInit,
  Component,
  Input,
  ViewChild,
  OnChanges,
  OnDestroy,
  forwardRef,
  AfterViewInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { takeWhile } from 'rxjs/operators';

import { UnitService } from '../../../modules/units/services';

import { AfaqyHelper } from '../../../common/classes/afaqy-helper';
import { AfaqyControl } from '../../../common/afaqy-control';

@Component({
  selector: 'afaqy-unit-select',
  templateUrl: './afaqy-unit-select.component.html',
  styleUrls: ['./afaqy-unit-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyUnitSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyUnitSelectComponent),
      multi: true,
    },
  ],
})
export class AfaqyUnitSelectComponent
  extends AfaqyControl
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('grid') grid: wjcGrid.FlexGrid;

  @Input() set random(val) {
    this.optionsList.refresh();
  }

  alive: boolean = true;
  selectedGroups = {};
  optionsList;
  selectedValue = {};
  searchText = '';

  constructor(private objectsService: UnitService) {
    super();
    this.optionsList = new wjcCore.CollectionView([], { currentItem: null });
  }

  ngOnInit() {
    this.updateSourceCollection();
    this.objectsService.resources.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        this.updateSourceCollection();
      },
    });
  }

  collapsAll() {
    this.grid.collapseGroupsToLevel(0);
  }

  expandAll() {
    this.grid.collapseGroupsToLevel(1);
  }

  refreshFilters() {
    this.optionsList.refresh();
  }

  updateSourceCollection() {
    this.objectsService.groupedUnits
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (res) => {
          if (!res || !res.length) return;
          this.optionsList = new wjcCore.CollectionView(res, {
            currentItem: null,
          });
          this.optionsList.filter = this.applyFilters.bind(this);
          var groupDesc = new wjcCore.PropertyGroupDescription('group_id');
          this.optionsList.groupDescriptions.clear();
          this.optionsList.groupDescriptions.push(groupDesc);
        },
      });
  }

  applyFilters(item) {
    let searchText = this.searchText;
    if (searchText != '' && typeof searchText !== 'undefined') {
      let result = false;
      searchText = searchText.toLowerCase();
      return (
        this.objectsService.validateSearchTextExistInItem(
          item['name'],
          searchText
        ) ||
        this.objectsService.validateSearchTextExistInItem(
          item['group_name'],
          searchText
        )
      );
    }
    return true;
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  setSelectedItem(id, status) {
    if (status) {
      this.selectedValue[id] = true;
    } else {
      delete this.selectedValue[id];
    }
  }

  checkGroupSelection(push = true) {
    let selectedGroups = { all: true };
    this.optionsList.sourceCollection.forEach((item) => {
      let group_id = item.group_id;
      if (!selectedGroups.hasOwnProperty(group_id)) {
        selectedGroups[group_id] = true;
      }
      if (!this.getItemSelectionStatus(item.id)) {
        selectedGroups[group_id] = false;
        selectedGroups['all'] = false;
      }
    });
    this.selectedGroups = AfaqyHelper.cloneObject(selectedGroups);
    if (push) {
      this.pushValue();
    }
  }

  UnitGroupSelection(status, groupID) {
    this.optionsList.sourceCollection.forEach((item) => {
      if (this.applyFilters(item) && item.group_id == groupID) {
        this.setSelectedItem(item.id, status);
      }
    });
    this.checkGroupSelection();
  }

  AllSelection(status) {
    this.optionsList.sourceCollection.forEach((item) => {
      if (this.applyFilters(item)) {
        this.setSelectedItem(item.id, status);
      }
    });
    this.checkGroupSelection();
  }

  getItemSelectionStatus(id) {
    return this.selectedValue[id] ? true : false;
  }

  toggleItem(id, event, groupid = '') {
    const ctrKey = event.metaKey || event.ctrlKey;
    if (!this.selectedValue[id]) {
      this.selectedValue[id] = true;
      if (ctrKey) {
        this.optionsList.sourceCollection.forEach((item) => {
          if (this.applyFilters(item)) {
            if (groupid == '' || item.group_id == groupid) {
              this.setSelectedItem(item.id, true);
            }
          }
        });
      }
    } else {
      delete this.selectedValue[id];
      if (ctrKey) {
        this.optionsList.sourceCollection.forEach((item) => {
          if (this.applyFilters(item)) {
            if (groupid == '' || item.group_id == groupid) {
              this.setSelectedItem(item.id, false);
            }
          }
        });
      }
    }
    this.checkGroupSelection();
  }

  pushValue() {
    if (this.writing) {
      return;
    }
    let ctrValue = Object.keys(this.selectedValue);
    this.propagateChange(ctrValue);
  }

  writeValue(value: any) {
    if (value) {
      this.writing = true;
      this.selectedValue = {};
      value.forEach((item) => {
        this.selectedValue[item] = true;
      });
      this.checkGroupSelection(false);
      this.writing = false;
    }
  }
}
