import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { ShareLocationService } from './share-location.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiRequestService } from '../../../core/services';
import { takeWhile } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'afaqy-share-location-modal',
  templateUrl: './share-location-modal.component.html',
  styleUrls: ['./share-location-modal.component.scss'],
})
export class ShareLocationModalComponent implements OnInit, OnDestroy {
  today = new Date();
  public errorMessages = {
    must_be_numbers: this.translateService.instant(
      'share-location.enter-valid-mobile'
    ),
    must_start_with_plus: this.translateService.instant(
      'validations.must_start_with_plus'
    ),
    must_be_email: this.translateService.instant(
      'share-location.enter-valid-email'
    ),
  };
  shareLocationForm = this.fb.group({
    expire_in: [null, [Validators.required]],
    start_at: [this.today, [Validators.required]],
    emails: [],
    mobiles: [],
    reason: [''],
    link: [{ value: null, disabled: true }],
  });
  expirationStep = [
    {
      id: 1,
      name: '1 ' + this.translateService.instant('share-location.hour'),
    },
    {
      id: 2,
      name: '2 ' + this.translateService.instant('share-location.hour'),
    },
    {
      id: 4,
      name: '4 ' + this.translateService.instant('share-location.hours'),
    },
    {
      id: 8,
      name: '8 ' + this.translateService.instant('share-location.hours'),
    },
    {
      id: 12,
      name: '12 ' + this.translateService.instant('share-location.hour'),
    },
    {
      id: 24,
      name: '24 ' + this.translateService.instant('share-location.hour'),
    },
    {
      id: 48,
      name: '48 ' + this.translateService.instant('share-location.hour'),
    },
    {
      id: 72,
      name: '72 ' + this.translateService.instant('share-location.hour'),
    },
  ];
  alive = true;
  hideModal = false;
  selectedUnits = [];
  public emailValidators = [this.must_be_email];
  public mobileValidators = [this.valid_mobile_number];
  // disableSendButton = true;
  disableGenerateLinkButton = true;
  sessionId: any;
  generatedLink: string;
  disableSendButton = true;
  copyLinkDisabled = true;
  loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private shareLocationService: ShareLocationService,
    private translateService: TranslateService,
    private apiRequestService: ApiRequestService,
    private clipboard: Clipboard,
    private notificationsService: NotificationsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUnitIdsToShareLocation();
    this.formValueChanges();
    // todo: refactor the dropdown default value using a different approach
    this.shareLocationForm.controls['expire_in'].setValue(1);
  }

  modalClose() {
    this.hideModal = true;
  }

  getUnitIdsToShareLocation() {
    this.shareLocationService.shareLocationIds
      .pipe(takeWhile(() => this.alive))
      .subscribe((ids: any) => {
        this.selectedUnits = ids;
      });
  }

  private must_be_email(control: FormGroup) {
    const EMAIL_REGEXP =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      control.value !== '' &&
      (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
    ) {
      return { must_be_email: true };
    }
    return null;
  }

  private valid_mobile_number(control: FormGroup) {
    const MOBILE_REGEX = /^\+[0-9]{12}$/;
    if (
      control.value !== '' &&
      (control.value.length <= 5 || !MOBILE_REGEX.test(control.value))
    ) {
      return { must_be_numbers: true };
    }
    return null;
  }

  formValueChanges() {
    this.shareLocationForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (value: any) => {
          this.disableGenerateLinkButton = !(
            value['expire_in'] && value['start_at']
          );
          // console.log('rawValue: ', this.shareLocationForm.getRawValue());
          const emailValid = !!(value['emails'] && value['emails'].length);
          const mobileValid = !!(value['mobiles'] && value['mobiles'].length);
          const linkValid = !!this.shareLocationForm.getRawValue()['link'];
          this.disableSendButton = !((emailValid || mobileValid) && linkValid);
          // todo: research detectchange cycle
          this.cd.detectChanges();
          // console.log('emailValid?: ', emailValid);
          // console.log('mobileValid?: ', mobileValid);
          // console.log('linkValid?: ', linkValid);
          // console.log('disableSendButton?: ', this.disableSendButton);
        },
        () => {}
      );
  }

  formSubmit() {
    this.loading = true;
    this.shareLocationForm.value['ids'] = this.selectedUnits;
    if (this.shareLocationForm.value['emails']) {
      this.shareLocationForm.value['emails'] = this.shareLocationForm.value[
        'emails'
      ]?.map((email) => email?.display);
    }
    if (this.shareLocationForm.value['mobiles']) {
      this.shareLocationForm.value['mobiles'] = this.shareLocationForm.value[
        'mobiles'
      ]?.map((mobile) => mobile?.display);
    }

    // add the expire_in duration to the selected day, and compare it to the time at the moment
    const allowedDuration = this.addHours(
      this.shareLocationForm.value['start_at'],
      this.shareLocationForm.value['expire_in']
    );

    if (this.today > allowedDuration) {
      this.notificationsService.error(
        '',
        this.translateService.instant('share-location.invalid-link-date')
      );
      this.cd.detectChanges();
      this.loading = false;
    } else {
      this.apiRequestService
        .postWithToken('share-locations', this.shareLocationForm.value)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (res: any) => {
            if (!res?.['errors']) {
              this.copyLinkDisabled = false;
              this.loading = false;
              this.cd.detectChanges();
              this.sessionId = res.data?.id;
              this.notificationsService.success(
                '',
                this.translateService.instant(
                  'share-location.link-generated-successfully'
                )
              );
              this.generatedLink =
                window.location.origin + '/share-locations/' + this.sessionId;
              this.shareLocationForm.controls['link'].setValue(
                this.generatedLink
              );
              this.shareLocationForm.controls['link'].updateValueAndValidity();
            } else {
              this.loading = false;
              this.cd.detectChanges();
              Object.keys(res['errors']).map((error: string) => {
                this.notificationsService.error(
                  '',
                  res['errors'][error][0] ||
                    this.translateService.instant('share-location.invalid-data')
                );
              });
            }
          },
          () => {
            this.loading = false;
            this.cd.detectChanges();
          }
        );
    }
  }

  addHours(date, hours) {
    const cloneDate = new Date(date.valueOf());
    cloneDate.setTime(cloneDate.getTime() + hours * 60 * 60 * 1000);

    return cloneDate;
  }

  copyLinkToClipboard() {
    this.clipboard.copy(this.generatedLink);
    this.notificationsService.success(
      '',
      this.translateService.instant('share-location.link-copied-successfully')
    );
  }

  sendNotification() {
    this.loading = true;
    const reqPayload = {
      share_id: this.sessionId,
      link: this.generatedLink,
    };

    this.apiRequestService
      .postWithToken('share-locations/send', reqPayload)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any) => {
          if (!res?.['errors']) {
            this.notificationsService.success(
              '',
              this.translateService.instant(
                'share-location.link-sent-successfully'
              )
            );
            this.loading = false;
            this.modalClose();
            this.cd.detectChanges();
          } else {
            this.loading = false;
            this.cd.detectChanges();
            this.notificationsService.error(
              '',
              this.translateService.instant('share-location.invalid-data')
            );
          }
        },
        () => {
          this.loading = false;
          this.cd.detectChanges();
        }
      );
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
