import { BehaviorSubject, Subject } from 'rxjs';
import { AppConfig } from './app-config';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { UnitLastUpdate } from '../../modules/units/models';
import { ViewContainerRef } from '@angular/core';

export class AfaqyHelper {
  static userCurrentTime: any;
  static resizer = new Subject<any>();
  static smallWidth = new Subject<any>();
  static listingIcon = new Subject<string>();
  static MapSectionContainer: ViewContainerRef;
  static MapSectionContainerRefresh: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  static MapSectionSplitter = new Subject<any>();
  static pageTitle: Subject<any> = new Subject<any>();
  static cmsTabs: Subject<any> = new Subject<any>();
  static userTimeInterval: any;
  static connectionStatus: string = 'offline';
  static setTitle(obj: any) {
    this.pageTitle.next(obj);
  }

  setErrors(fc, errors) {
    fc.setErrors(errors);
    fc.markAsTouched();
  }

  public static touchAll(
    formGroup: UntypedFormGroup | UntypedFormArray,
    func = 'markAsDirty',
    opts = { onlySelf: false }
  ): void {
    for (let key in formGroup.controls) {
      let ctl = formGroup.controls[key];
      if (ctl instanceof UntypedFormGroup || ctl instanceof UntypedFormArray) {
        AfaqyHelper.touchAll(ctl, func, opts);
      } else {
        ctl[func](opts);
      }
    }
  }

  static setFGErrors(fg, errors, prefix = '') {
    let formCtrls = fg.controls;
    for (let ctl in formCtrls) {
      if (fg.controls[ctl] instanceof UntypedFormControl) {
        let ctrErrors = AfaqyHelper.getFieldErrors(errors, ctl, prefix);
        if (Object.keys(ctrErrors).length) {
          fg.controls[ctl].setErrors(ctrErrors);
          fg.controls[ctl].markAsTouched();
        }
      } else if (fg.controls[ctl] instanceof UntypedFormArray) {
        let aprefix = prefix;
        if (prefix) {
          aprefix = prefix + '.';
        }
        fg.controls[ctl]['controls'].forEach((control, idx) => {
          AfaqyHelper.setFGErrors(control, errors, aprefix + ctl + '.' + idx);
        });
      } else if (fg.controls[ctl] instanceof UntypedFormGroup) {
        let gprefix = prefix;
        if (prefix) {
          gprefix = prefix + '.';
        }
        AfaqyHelper.setFGErrors(fg.controls[ctl], errors, gprefix + ctl);
      }
    }
  }

  static getFieldErrors(errors, field, prefix = ''): string[] {
    const fieldErrors = [];
    let fullKey = '';
    if (prefix) {
      fullKey = prefix + '.';
    }
    fullKey += field;
    const errorsList = errors[fullKey];
    if (errorsList && errorsList.length) {
      for (const error of errorsList) {
        fieldErrors[error] = true;
      }
    }
    return fieldErrors;
  }

  static getDefaultImagePath(type = '') {
    if (type == 't') {
      return 'assets/images/tphoto.png';
    }
    return 'assets/images/default.png';
  }

  static setListingIcon(icon) {
    this.listingIcon.next(icon);
  }

  static afaqyRandom(min = 0, max = 100) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static getElm(selector) {
    return document.querySelector(selector);
  }

  static isSmallWindow() {
    return window.innerWidth <= 768;
  }

  static pushResizer() {
    AfaqyHelper.resizer.next({});
  }

  static windowResize(s = '') {
    AfaqyHelper.calcModalHeight();
    if (!AfaqyHelper.getElm('#header')) {
      return;
    }
    let baseheight =
      window.innerHeight -
      AfaqyHelper.getElm('#header').offsetHeight -
      AfaqyHelper.getElm('#footer').offsetHeight;
    // if (AfaqyHelper.isSmallWindow()) {
    //   height -= 50;
    // }
    //Logger.log(s, height);
    document.body.style.setProperty('--base-height', '' + baseheight);
    let height = baseheight;
    if (!AppConfig.isCMS) {
      if (AfaqyHelper.getElm('#left-content')) {
        AfaqyHelper.getElm('#left-content').style.height = height + 'px';
      }
      if (AfaqyHelper.getElm('.yaga-map')) {
        AfaqyHelper.getElm('.yaga-map').style.height = height + 'px';
      }
      if (AfaqyHelper.getElm('#page-container')) {
        AfaqyHelper.getElm('#page-container').style.height = height + 'px';
      }
      if (AfaqyHelper.getElm('#leafletMap')) {
        AfaqyHelper.getElm('#leafletMap').style.height = height + 'px';
      }
      if (AfaqyHelper.getElm('#page-listing-body')) {
        if (AfaqyHelper.getElm('#page-headers')) {
          height -= AfaqyHelper.getElm('#page-headers').offsetHeight;
        }
        if (AfaqyHelper.getElm('#extraContent')) {
          height -= AfaqyHelper.getElm('#extraContent').offsetHeight;
        }
        AfaqyHelper.updateClassHeight('#page-listing .wj-flexgrid', height - 6);
      }
      AfaqyHelper.calculateMapConenetSectionHeight();
    }
    AfaqyHelper.smallWidth.next(AfaqyHelper.isSmallWindow());
    AfaqyHelper.pushResizer();
  }

  static calculateMapConenetSectionHeight() {
    if (!AfaqyHelper.getElm('#header')) {
      return;
    }
    let mapheight =
      window.innerHeight -
      AfaqyHelper.getElm('#header').offsetHeight -
      AfaqyHelper.getElm('#footer').offsetHeight;
    if (AfaqyHelper.getElm('#map-listing-body')) {
      // if (AfaqyHelper.getElm('#map-content')) {
      //     console.log(AfaqyHelper.getElm('#map-content'));

      //     let mapContentHeight = (AfaqyHelper.getElm('#map-content').offsetHeight);
      //     if (mapContentHeight >= mapheight) {
      //         mapheight = (mapheight * 40 / 100);
      //     } else {
      //         mapheight -= mapContentHeight;
      //     }
      // }
      if (AfaqyHelper.getElm('#belowMapArea')) {
        let belowMapArea = AfaqyHelper.getElm('#belowMapArea');
        let belowMapAreaHeight = belowMapArea.offsetHeight;
        let gridData = belowMapAreaHeight - 20;
        /* seperator */

        if (AfaqyHelper.getElm('#map-listing-headers')) {
          gridData -= AfaqyHelper.getElm('#map-listing-headers').offsetHeight;
        }
        if (AfaqyHelper.getElm('#messages_tabs .nav-tabs')) {
          gridData -= AfaqyHelper.getElm(
            '#messages_tabs .nav-tabs'
          ).offsetHeight;
        }

        AfaqyHelper.updateClassHeight('#map-listing-body', gridData);
        AfaqyHelper.updateClassHeight('.mapwj-flexgrid', gridData);

        if (AfaqyHelper.getElm('#map-listing-pagination')) {
          let gridData_pagination = gridData;
          gridData_pagination -= AfaqyHelper.getElm(
            '#map-listing-pagination'
          ).offsetHeight;
          AfaqyHelper.updateClassHeight('.mapwj-flexgrid', gridData_pagination);
        }
        document.body.style.setProperty('--map-grid-data', gridData + 'px');
      }
    }
  }

  static updateClassHeight($class, $height) {
    //const elms = document.getElementsByClassName($class);
    const elm = AfaqyHelper.getElm($class);
    if (elm) {
      //const elm = elms.item(0);
      // let style = elm.getAttribute('style') || '';
      elm.style.height = $height + 'px';
    }
  }

  static hideDropdowns(className = '', newClass = '') {
    let dropmdowns = document.getElementsByClassName(className);
    if (dropmdowns.length) {
      for (let index = 0; index < dropmdowns.length; index++) {
        dropmdowns.item(index).classList.remove('show');
        if (newClass) {
          dropmdowns.item(index).classList.add(newClass);
        }
      }
    }
  }

  static hideDropdownMenus() {
    AfaqyHelper.hideDropdowns('dropdown-menu');
  }

  static hideSettings() {
    document.getElementsByTagName('body')[0].classList.remove('site-settings');
  }

  static loadJSFile(Id, url) {
    const v = '?v=' + AppConfig.version;
    if (document.getElementById(Id)) {
      //document.getElementById(Id).setAttribute('src', url + v);
      document.getElementById(Id).remove();
    }
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('script');
    link.id = Id;
    link.type = 'text/javascript';
    link.src = url + v;
    head.appendChild(link);
  }

  static loadCssFile(cssId, url) {
    const fullUrl = url + '?v=' + AppConfig.version;

    if (!document.getElementById(cssId)) {
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = fullUrl;
      head.appendChild(link);
      link.onload = () => {
        AfaqyHelper.windowResize('loadCssFile');
      };
    } else if (document.getElementById(cssId).getAttribute('href') != fullUrl) {
      document.getElementById(cssId).setAttribute('href', fullUrl);
    }
  }

  static updateThemeBodyClass(color) {
    // const colorName = color['name'];
    // const cssRoot = 'assets/layouts/layout-top-menu/css/color/';
    // document.getElementsByTagName('body')[0].setAttribute('theme', colorName);
    // document.getElementsByTagName('body')[0].classList.add("theme-" + colorName);
    // AfaqyHelper.loadCssFile('site-color', cssRoot + "light/color-" + colorName + ".min.css");
    document.body.style.setProperty('--them-color', color['code']);
    document.body.style.setProperty(
      '--them-color-abstract-highlight',
      color['abs_highlight']
    );
  }

  static calcModalHeight(flashMessage = false) {
    if (!AfaqyHelper.getElm('#header')) {
      return;
    }

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // setTimeout(() => {
    var header = document.getElementById('header').offsetHeight;
    var footer = document.getElementById('footer').offsetHeight;

    if (this.getElm('.height-calc .nav-tabs')) {
      var tabsHeight = this.getElm('.height-calc .nav-tabs').clientHeight;
      document.body.style.setProperty('--tabs-height', tabsHeight + 'px');
    }
    if (this.getElm('.modal-lg')) {
      var modalHeight = this.getElm('.modal-lg .modal-header').clientHeight;
      document.body.style.setProperty('--modal-header', modalHeight + 'px');
    }

    document.body.style.setProperty(
      '--page-content-height',
      windowHeight - header - footer + 'px'
    );
    document.body.style.setProperty(
      '--cms-main-content-width',
      windowWidth - 261 + 'px'
    );

    let height = windowHeight / 1.5;
    if (AppConfig.isCMS) {
      height -= 60;
    }
    document.body.style.setProperty('--modal-height', height + 'px');
    if (flashMessage) {
      height = height - 62;
    }
    document.body.style.setProperty('--modal-content-height', height + 'px');

    height = windowHeight / 2.5;
    if (flashMessage) {
      height = height - 62;
    }
    document.body.style.setProperty(
      '--left-content-form-height',
      height + 'px'
    );
    // }, 1000);
  }

  static updateSettingsStyle() {
    setTimeout(function () {
      let elm = document.getElementById('syssettings');
      if (elm) {
        elm.classList.add('disblock');
        let items = document.getElementsByClassName('dropdownlist');
        for (let x = 0; x < items.length; x++) {
          items.item(x).classList.add('disblock');
        }
      }
    }, 1000);
  }

  static arrayBufferToString(buffer) {
    var byteArray = new Uint8Array(buffer);
    var str = '',
      cc = 0,
      numBytes = 0;
    for (var i = 0, len = byteArray.length; i < len; ++i) {
      var v = byteArray[i];
      if (numBytes > 0) {
        //2 bit determining that this is a tailing byte + 6 bit of payload
        if ((cc & 192) === 192) {
          //processing tailing-bytes
          cc = (cc << 6) | (v & 63);
        } else {
          throw new Error('this is no tailing-byte');
        }
      } else if (v < 128) {
        //single-byte
        numBytes = 1;
        cc = v;
      } else if (v < 192) {
        //these are tailing-bytes
        throw new Error('invalid byte, this is a tailing-byte');
      } else if (v < 224) {
        //3 bits of header + 5bits of payload
        numBytes = 2;
        cc = v & 31;
      } else if (v < 240) {
        //4 bits of header + 4bit of payload
        numBytes = 3;
        cc = v & 15;
      } else {
        //UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
        //but JS can only handle 16bit.
        throw new Error('invalid encoding, value out of range');
      }

      if (--numBytes === 0) {
        str += String.fromCharCode(cc);
      }
    }
    if (numBytes) {
      throw new Error("the bytes don't sum up");
    }
    return str;
  }

  static today(timeZone: string = 'Asia/Riyadh'): string {
    return AfaqyHelper.timeStampToTimeZoneDateString(
      Date.now(),
      timeZone,
      'YYYY-MM-DD HH:mm'
    );
  }

  static formatDate(date) {
    // let d = new Date(date);
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  static getMappedKey(key) {
    return AppConfig.KEYSMAP[key] ? AppConfig.KEYSMAP[key] : key;
  }

  static removeFromArray(array, element) {
    const i = array.indexOf(element);
    array.splice(i, 1);
  }

  static cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static updateLeftContentStyle(properties = {}) {
    let elm = AfaqyHelper.getElm('#left-content');
    for (let k in properties) {
      elm.style[k] = properties[k];
    }
    elm = AfaqyHelper.getElm('#page-listing');
    for (let k in properties) {
      elm.style[k] = properties[k];
    }
  }

  static getCurrentLangField(titles, currentLang) {
    if (titles && titles[currentLang]) {
      return titles[currentLang];
    }
    if (titles && titles[AppConfig.defaultLang]) {
      return titles[AppConfig.defaultLang];
    }
    return '';
  }

  static getUserCurrentTime() {
    return AfaqyHelper.userCurrentTime;
  }

  static getMoment(ds) {
    return moment(ds);
  }

  static timestampToFormat(time = 0, format = 'YYYY-MM-DD HH:mm:ss') {
    if (time == 0) {
      time = parseInt(moment().format('x'));
    }
    if (typeof time == 'string') {
      time = parseInt('' + time);
    }
    return moment(time).format(format);
  }

  static UpdateUserCurrentTime(time) {
    if (typeof time == 'string') {
      time = parseInt(time);
    }
    AfaqyHelper.userCurrentTime = moment(time);
    AfaqyHelper.userTimeInterval = setInterval(() => {
      AfaqyHelper.userCurrentTime.add(1, 's');
    }, 1000);
  }

  static clearUserTimeInterval() {
    if (AfaqyHelper.userTimeInterval) {
      clearInterval(AfaqyHelper.userTimeInterval);
    }
  }

  static convertNumSecondsToPeriodString(seconds) {
    let days = Math.floor(seconds / 86400);
    let hours = Math.floor((seconds % 86400) / 3600);
    let minutes = Math.floor(((seconds % 86400) % 3600) / 60);
    let sec = ((seconds % 86400) % 3600) % 60;
    return { days: days, hours: hours, minutes: minutes, seconds: sec };
    //return ((days) ? days + " days " : '') + ((hours) ? hours + " hours " : '') + ((minutes) ? minutes + " minutes " : '') + ((sec) ? sec + " seconds" : '');
  }

  static timeStampToTimeZoneDateString(
    timestamp,
    timezone,
    format = null,
    addMelliSeconds = false
  ) {
    if (typeof timestamp === 'string' && timestamp.indexOf('-')) {
      timestamp = momenttz.tz(timestamp, 'GMT').unix() * 1000;
    }
    if (addMelliSeconds) {
      timestamp = timestamp * 1000;
    }
    if (!format) {
      format = 'DD-MM-YYYY HH:mm:ss';
    }
    return momenttz(timestamp).tz(timezone).format(format);
  }
  static timeStampToTimeZoneDate(timestamp, timezone, addMelliSeconds = false) {
    if (typeof timestamp === 'string' && timestamp.indexOf('-')) {
      timestamp = momenttz.tz(timestamp, 'GMT').unix() * 1000;
    }
    if (addMelliSeconds) {
      timestamp = timestamp * 1000;
    }
    return momenttz(timestamp).tz(timezone);
  }

  static getLengthBetweenCoordinates(lat1, lng1, lat2, lng2) {
    const R = 6371; // Radius of the earth in km
    let dLat = AfaqyHelper.toRad(lat2 - lat1); // deg2rad below
    let dLon = AfaqyHelper.toRad(lng2 - lng1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(AfaqyHelper.toRad(lat1)) *
        Math.cos(AfaqyHelper.toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  static toRad(val) {
    return (val * Math.PI) / 180;
  }

  static convDistanceUnits(val, from, to) {
    if (from == 'km') {
      if (to == 'mi') {
        val *= 0.621371;
      } else if (to == 'nm') {
        val *= 0.539957;
      }
    } else if (from == 'mi') {
      if (to == 'km') {
        val *= 1.60934;
      } else if (to == 'nm') {
        val *= 0.868976;
      }
    } else if (from == 'nm') {
      if (to == 'km') {
        val *= 1.852;
      } else if (to == 'nm') {
        val *= 1.15078;
      }
    }
    return val;
  }

  static isPointInPolygon(poly, pt) {
    let c = false;
    for (let i = -1, l = poly.length, j = l - 1; ++i < l; j = i) {
      ((poly[i].y <= pt.y && pt.y < poly[j].y) ||
        (poly[j].y <= pt.y && pt.y < poly[i].y)) &&
        pt.x <
          ((poly[j].x - poly[i].x) * (pt.y - poly[i].y)) /
            (poly[j].y - poly[i].y) +
            poly[i].x &&
        (c = !c);
    }
    return c;
  }

  static distance(lat1, lon1, lat2, lon2, unit = 'K') {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == 'K') {
        dist = dist * 1.609344;
      }
      if (unit == 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  static ObjectValues(data) {
    return Object.keys(data).map((key) => data[key]);
  }

  static getTimezoneOffset(timezome, format = 'offset'): any {
    let gmt = AfaqyHelper.timeStampToTimeZoneDateString(
      moment(),
      'UTC',
      'YYYY-MM-DD HH:mm:ss'
    );
    let tx = AfaqyHelper.timeStampToTimeZoneDateString(
      moment(),
      timezome,
      'YYYY-MM-DD HH:mm:ss'
    );

    switch (format) {
      case 'hours':
        return moment(tx).diff(moment(gmt), 'hours');
      case 'seconds':
        return moment(tx).diff(moment(gmt), 'seconds');
      case 'minutes':
        return moment(tx).diff(moment(gmt), 'minutes');
      case 'offset':
        let theDiff = moment(tx).diff(moment(gmt), 'minutes');
        let offset = '';
        if (theDiff < 0) {
          offset = '-';
          theDiff = theDiff * -1;
        }
        let minutes = (theDiff % 60).toString();
        let hours = Math.floor(theDiff / 60).toString();

        offset += hours < '9' ? '0' + hours : hours;
        offset += ':';
        offset += minutes < '9' ? '0' + minutes : minutes;
        return offset;
    }
  }

  static reviewMap() {
    AppConfig.mapSplitter.map = 70;
    AppConfig.mapSplitter.data = 30;
    AfaqyHelper.MapSectionSplitter.next({ visible: false });
    setTimeout(function () {
      AfaqyHelper.pushResizer();
    }, 100);
  }

  static invertColor(hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return (
      AfaqyHelper.padZero(r) + AfaqyHelper.padZero(g) + AfaqyHelper.padZero(b)
    );
  }

  static padZero(str, len?) {
    len = len || 2;
    let zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  static pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
    let color =
      bgColor && bgColor.charAt(0) === '#' ? bgColor?.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
  }

  static getSensorValue(lu: UnitLastUpdate, sensor) {
    let result = [];
    result['value'] = 0;
    result['value_full'] = '';

    // get param value
    if (!sensor['param']) return result;
    let param_value;
    if (lu.prms[sensor['param']]) param_value = lu.prms[sensor['param']];
    // sensor[2] = pin that contain sensor => di1 , di3 , di2 , param72 etc
    else if (lu.chPrams[sensor['param']])
      param_value = lu.chPrams[sensor['param']]['v'];
    else return result;

    //check if param one of params and return its value
    if (param_value == undefined || param_value == '') {
      param_value = 0;
    }

    let main_param_value = param_value;

    // sensor[2] = pin that contain sensor => di1 , di3 , di2 , param72 etc
    // sensor[3] = type of sensor
    // sensor[4] = "ON"
    // sensor[5] = "OFF"
    // sensor[7] = Lowest value
    // sensor[8] = Highest value
    // sensor[9] = Formula => ex. mul|1.2
    // sensor[11]= icon
    // sensor[12]= serial
    // sensor[13]= model

    /* ==============================
         check type of sensor
         ============================== */
    // Logic sensor
    // sensor['result_type'] == sensor[3]
    if (sensor['result_type'] == 'logic') {
      if (param_value == 1) {
        result['value'] = param_value;
        result['value_full'] = sensor.text_1; // sensor[4] == "ON"
      } else {
        result['value'] = param_value;
        result['value_full'] = sensor.text_0; // sensor[5] == "OFF"
      }
    }
    // value or value_low_high sensors
    else if (
      sensor['result_type'] == 'value' ||
      sensor['result_type'] == 'value_low_high'
    ) {
      param_value = parseFloat(param_value);
      //formula calculations
      if (sensor['formula'] != '') {
        let formula = sensor['formula']; // formula like + - ...etc
        let formula_value = parseFloat(sensor['formula_value']); // value of that formula

        if (formula === '+') {
          param_value = param_value + formula_value;
        }

        if (formula === '-') {
          param_value = param_value - formula_value;
        }

        if (formula === '*') {
          param_value = param_value * formula_value;
        }

        if (formula === '/') {
          param_value = param_value / formula_value;
        }
      }

      // calibration
      let out_of_cal = true;
      let calibration = sensor['calibration'];

      // function to get calibration Y value
      let calGetY = function (x) {
        let result = 0;
        for (let j = 0; j < calibration.length; j++) {
          if (calibration[j]['x'] == x) {
            result = parseFloat(calibration[j]['y']);
          }
        }
        return result;
      };

      if (calibration && calibration.length >= 2) {
        // put all X values to separate array
        let x_arr = new Array();
        for (let i = 0; i < calibration.length; i++) {
          x_arr.push(parseFloat(calibration[i]['x']));
        }

        //function sortNumber(a, b) {return a - b; }
        //instead in sortNumber(a, b) function
        x_arr.sort((a, b) => a - b);

        // loop and check if in cal
        for (let i = 0; i < x_arr.length; i++) {
          let x_low = x_arr[i];
          let x_high = x_arr[i + 1];

          if (param_value >= x_low && param_value <= x_high) {
            // get Y low and high
            let y_low = calGetY(x_low);
            let y_high = calGetY(x_high);

            // get coeficient
            let a = param_value - x_low;
            let b = x_high - x_low;

            let coef = a / b;

            let c = y_high - y_low;
            coef = c * coef;

            param_value = y_low + coef;

            out_of_cal = false;

            break;
          }
        }

        if (out_of_cal) {
          // check if lower than cal
          let x_low = x_arr[0];

          if (param_value < x_low) {
            param_value = calGetY(x_low);
          }

          // check if higher than cal
          let x_high = x_arr.slice(-1)[0];

          if (param_value > x_high) {
            param_value = calGetY(x_high);
            //weight sensor
            let calibrationCount = calibration.length;
            let a =
              parseFloat(calibration[calibrationCount - 1]['x']) -
              parseFloat(calibration[calibrationCount - 2]['x']);
            let b =
              parseFloat(calibration[calibrationCount - 1]['y']) -
              parseFloat(calibration[calibrationCount - 2]['y']);
            let dif = b / a;
            param_value =
              eval(param_value) +
              eval(
                String(
                  dif *
                    (main_param_value - calibration[calibrationCount - 1]['x'])
                )
              );
            //weight sensor
          }
        }
      }

      param_value = Math.round(param_value * 100) / 100;

      result['value'] = param_value;
      result['value_full'] = param_value + ' ' + sensor['units']; //sensor[6] = Units of measurement
    }
    // string value
    else if (sensor['result_type'] == 'string') {
      result['value'] = param_value;
      result['value_full'] = param_value;
    }
    // hexString value
    else if (sensor['result_type'] == 'hexString') {
      let newValue;
      result['value'] = param_value;
      const isDecimal = isNaN(param_value);
      if (!isDecimal) {
        newValue = parseInt(param_value);
        result['value_full'] = newValue.toString(16).toUpperCase();
      } else {
        result['value_full'] = param_value.toString(16).toUpperCase();
      }
    }
    // percentage value
    else if (sensor['result_type'] == 'percentage') {
      param_value = parseFloat(param_value);
      sensor['low_val'] = parseFloat(sensor['low_val']); //Lowest value
      sensor['high_val'] = parseFloat(sensor['high_val']); //Highest value

      if (param_value > sensor['low_val'] && param_value < sensor['high_val']) {
        let a = param_value - sensor['low_val'];
        let b = sensor['high_val'] - sensor['low_val'];

        result['value'] = Math.round((a / b) * 100);
      } else if (param_value <= sensor['low_val']) {
        result['value'] = 0;
      } else if (param_value >= sensor['high_val']) {
        result['value'] = 100;
      }

      result['value_full'] = result['value'] + ' ' + sensor['units'];
    }
    // code value
    else if (sensor['result_type'] == 'codeValue') {
      let val = '';
      for (let j = 0; j < sensor['codeValue'].length; j++) {
        if (sensor['codeValue'][j]['x'] == param_value) {
          val = sensor['codeValue'][j]['y'];
          break;
        }
      }

      result['value'] = val;
      result['value_full'] = val;
    }
    return result;
  }

  static playEventSound(filename) {
    if (document.getElementById('AfaqySoundElm')) {
      document.getElementById('AfaqySoundElm').remove();
    }
    let soundFile = AppConfig.baseURL + '/sounds/' + filename;
    let mp3Source = '<source src="' + soundFile + '" type="audio/mp3">';
    let embedSource =
      '<embed hidden="true" autostart="true" loop="false" src="' +
      soundFile +
      '">';
    let sound = document.createElement('audio');
    sound.setAttribute('id', 'AfaqySoundElm');
    sound.setAttribute('autoplay', 'autoplay');
    sound.innerHTML = mp3Source + embedSource;
    document.body.appendChild(sound);
  }

  static array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  static pointInZone(zone, point) {
    let inzone = false;
    if (zone.type == 'polygon') {
      let polygon = [];
      for (let j = 0; j < zone.vertices.length; j++) {
        let zoneLat = parseFloat(zone.vertices[j][1]);
        let zoneLng = parseFloat(zone.vertices[j][0]);
        polygon.push({ x: zoneLat, y: zoneLng });
      }
      if (
        AfaqyHelper.isPointInPolygon(polygon, {
          x: point.lat,
          y: point.lng,
        }) == true
      ) {
        inzone = true;
      }
    } else {
      for (let j = 0; j < zone.vertices.length; j++) {
        let zoneLat = parseFloat(zone.vertices[j][1]);
        let zoneLng = parseFloat(zone.vertices[j][0]);
        let distance =
          AfaqyHelper.distance(zoneLat, zoneLng, point.lat, point.lng) * 1000;
        if (distance <= zone.radius) {
          inzone = true;
        }
      }
    }
    return inzone;
  }

  // static updateURLParameter(url, param?, paramVal?) {
  // var newAdditionalURL = "";
  // var tempArray = url.split("?");
  // var baseURL = tempArray[0];
  // var additionalURL = tempArray[1];
  // var temp = "";
  // if (additionalURL) {
  //     tempArray = additionalURL.split("&");
  //     for (var i = 0; i < tempArray.length; i++) {
  //         if (tempArray[i].split('=')[0] != param) {
  //             newAdditionalURL += temp + tempArray[i];
  //             temp = "&";
  //         }
  //     }
  // }

  // var rows_txt = temp + "" + param + "=" + paramVal;
  // return baseURL + "?" + newAdditionalURL + rows_txt;
  // return url
  // }

  static getCommandIcon(channel) {
    let icon = '';
    switch (channel) {
      case 'sms':
        icon = 'mdi-message-processing';
        break;
      case 'gprs':
        icon = 'mdi-radio-tower';
        break;
      case 'auto':
        icon = 'mdi-spellcheck';
        break;
      case 'iridium':
        icon = 'icon-commands-iridium';
        break;
      case 'both':
        icon = 'mdi-source-branch';
        break;
    }
    return icon;
  }

  static openMapSection(map = 0, data = 100) {
    const full = data == 100;
    AfaqyHelper.MapSectionSplitter.next({ visible: true, full: full });
    AppConfig.mapSplitter.map = map;
    AppConfig.mapSplitter.data = data;
    AfaqyHelper.pushResizer();
  }

  static clearMapSection(returnMap = false) {
    AfaqyHelper.MapSectionContainer.clear();
    if (returnMap) {
      AfaqyHelper.reviewMap();
    }
  }

  /** Check if two arrays is equal, returning true or false as appropriate.*/
  static arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (!arr1 || !arr2) return;
    arr1.sort();
    arr2.sort();
    if (JSON.stringify(arr1) === JSON.stringify(arr2)) return true;
    else return false;
  }

  /**
   * Returns ids of data list
   * @param data Data list.
   * @param key id key with default value 'id'.
   */
  static getListIds(data: any[], key: string = 'id'): string[] {
    if (!data) return;
    const listIds: string[] = [];
    data.map((item: any) => listIds.push(item[key]));
    return listIds;
  }

  /**
   * Returns unique ids of data list
   * @param data Data list.
   * @param key id key with default value 'id'.
   */
  static getUniqueListIds(data: any[], key: string = 'id'): string[] {
    if (!data) return;
    const listIds: string[] = [];
    data.map((item: any) => {
      if (!listIds.includes(item[key])) listIds.push(item[key]);
    });
    return listIds;
  }

  /** Check if the text has any arabic character, returning true or false as appropriate. */
  static hasArabicCharacters(text: string): boolean {
    const arRegExp = /[\u0600-\u06FF]/;
    return arRegExp.test(text);
  }

  /** Check if the parameter value in number, returning true or false as appropriate. */
  static isNumber(value: any): boolean {
    if (!value && value !== 0) return false;
    return !isNaN(value);
  }

  static clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }

  static clearSessionStorage() {
    sessionStorage.clear();
  }

  static clearBrowserHistory() {
    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }

  static compareArrays(arr1, arr2) {
    // Check for array equality using a more robust method
    if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
      return {
        removedItems: [],
        addedItems: [],
      };
    }

    // Find removed items
    const removedItems = arr1?.filter((item) => !arr2?.includes(item));
    // Find added items
    const addedItems = arr2?.filter((item) => !arr1?.includes(item));

    return {
      removedItems,
      addedItems,
    };
  }
}
