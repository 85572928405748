<div id="map-listing">
  <div class="grid-container">
    <div id="map-listing-body">
      <wj-flex-grid
        #grid
        [itemsSource]="data"
        headersVisibility="1"
        [selectionMode]="'None'"
        [isReadOnly]="true"
        [allowSorting]="false"
        class="mapwj-flexgrid"
      >
        <ng-template wjFlexGridCellTemplate cellType="TopLeft">
          <span>#</span>
        </ng-template>
        <wj-flex-grid-filter
          [filterColumns]="filtersCols"
        ></wj-flex-grid-filter>
        <ng-template
          wjFlexGridCellTemplate
          cellType="RowHeader"
          let-cell="cell"
          let-item="item"
        >
          <span class="row-header">
            <span>{{ cell.row.index + 1 }}</span>
          </span>
        </ng-template>
        <wj-flex-grid-column
          [header]="'booklog.date' | translate"
          binding="date"
          [width]="160"
          [align]="'left'"
        >
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span> {{ cell.item['date'] | afaqyDate : true : '' : true }}</span>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'booklog.resource' | translate"
          binding="resource"
          [width]="'*'"
          [align]="'left'"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'booklog.message' | translate"
          binding="content"
          [width]="'*'"
          [align]="'left'"
        >
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span class="pointer" (click)="fitTo(cell.item)">{{
              cell.item['content']
            }}</span>
            <span
              class="pointer"
              (click)="fitTo(cell.item)"
              class="mdi"
              [ngClass]="{
                'mdi-arrow-right-bold': cell.item['dir'] == 2,
                'mdi-arrow-left-bold': cell.item['dir'] == 1
              }"
            ></span>
          </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
    </div>
  </div>
</div>
<div id="map-overlay-flash" [ngClass]="{ 'sr-only': hideLocation }">
  <div id="referanceDiv">
    <div class="gps_ring gps_ring_normal"></div>
    <div class="gps_ring2 gps_ring_normal"></div>
  </div>
</div>
