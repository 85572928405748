import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  Output,
  OnInit,
  HostListener,
  EventEmitter,
} from '@angular/core';

class Position {
  constructor(public x: number, public y: number) {}
}

@Directive({
  selector: '[ngDraggableOld]',
})
export class DraggableDirective implements OnInit {
  private allowDrag: boolean = true;
  private moving: boolean = false;
  private orignal: Position = null;
  private oldTrans: Position = new Position(0, 0);
  private tempTrans: Position = new Position(0, 0);
  private oldZIndex: string = '';
  private oldPosition: string = '';

  @Output() started = new EventEmitter<any>();
  @Output() stopped = new EventEmitter<any>();
  @Output() edge = new EventEmitter<any>();

  @Input() handle: HTMLElement;
  @Input() bounds: HTMLElement;

  @Input()
  set ngDraggableOld(setting: any) {
    if (setting !== undefined && setting !== null && setting !== '') {
      this.allowDrag = !!setting;

      let element = this.handle ? this.handle : this.el.nativeElement;

      if (this.allowDrag) {
        this.renderer.addClass(element, 'ng-draggableOld');
      } else {
        this.renderer.removeClass(element, 'ng-draggableOld');
      }
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  originalT: number;
  originalL: number;

  originalB: number;
  originalR: number;

  ngOnInit() {
    if (this.allowDrag) {
      let element = this.handle ? this.handle : this.el.nativeElement;
      this.renderer.addClass(element, 'ng-draggableOld');
    }

    this.originalT = this.helperfn().elem.top;
    this.originalL = this.helperfn().elem.left;
    this.originalB =
      this.helperfn().documentElem.height -
      this.helperfn().elem.height -
      this.helperfn().elem.top * 2;
    this.originalR =
      this.helperfn().documentElem.width -
      this.helperfn().elem.width -
      this.helperfn().elem.left;
  }

  private helperfn() {
    let boundary = this.bounds
      ? this.bounds.getBoundingClientRect()
      : { top: 0, right: 0, bottom: 0, left: 0 };
    let elem = this.el.nativeElement.getBoundingClientRect();
    let documentElem = document
      .getElementsByTagName('body')
      .item(0)
      .getBoundingClientRect();

    return {
      elem: elem,
      documentElem: documentElem,
    };
  }

  private getPosition(x: number, y: number) {
    return new Position(x, y);
  }

  private moveTo(x: number, y: number) {
    if (this.orignal) {
      this.tempTrans.x = x - this.orignal.x;
      this.tempTrans.y = y - this.orignal.y;

      let finalX: number;
      let finalY: number;

      finalX = this.tempTrans.x + this.oldTrans.x;
      finalY = this.tempTrans.y + this.oldTrans.y;

      // if (finalX <= -this.originalL && finalY <= -this.originalT) {
      //     // console.log('top left corner');
      //     finalX = -this.originalL;
      //     finalY = -this.originalT;
      // }
      // else if (finalX >= this.originalR && finalY >= this.originalB) {
      //     // console.log('bottom right corner');
      //     finalX = this.originalR;
      //     finalY = this.originalB;
      // }
      // else if (finalX <= -this.originalL && finalY >= this.originalB) {
      //     // console.log('bottom left corner');
      //     finalX = -this.originalL;
      //     finalY = this.originalB;
      // }
      // else if (finalX >= this.originalR && finalY <= -this.originalT) {
      //     // console.log('top right corner');
      //     finalX = this.originalR;
      //     finalY = -this.originalT;
      // }
      // else if (finalX >= this.originalR) {
      //     // console.log('right side');
      //     finalX = this.originalR;
      // }
      // else if (finalY >= this.originalB) {
      //     // console.log('bottom side');
      //     finalY = this.originalB;
      // }
      // else if (finalY <= -this.originalT) {
      //     // console.log('top side');
      //     finalY = -this.originalT;
      // }
      // else if (finalX <= -this.originalL) {
      //     // console.log('left side');
      //     finalX = -this.originalL;
      // }

      let value = `translate(${finalX}px, ${finalY}px)`;
      this.renderer.setStyle(this.el.nativeElement, 'transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-webkit-transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-ms-transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-moz-transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-o-transform', value);
      this.edge.emit(this.boundsCheck());
    }
  }

  private pickUp() {
    // get old z-index and position:
    this.oldZIndex = this.el.nativeElement.style.zIndex
      ? this.el.nativeElement.style.zIndex
      : '';
    this.oldPosition = this.el.nativeElement.style.position
      ? this.el.nativeElement.style.position
      : '';

    if (window) {
      this.oldZIndex = window
        .getComputedStyle(this.el.nativeElement, null)
        .getPropertyValue('z-index');
      this.oldPosition = window
        .getComputedStyle(this.el.nativeElement, null)
        .getPropertyValue('position');
    }

    // setup default position:
    let position = 'relative';

    // check if old position is draggable:
    if (
      this.oldPosition &&
      (this.oldPosition === 'absolute' ||
        this.oldPosition === 'fixed' ||
        this.oldPosition === 'relative')
    ) {
      position = this.oldPosition;
    }

    this.renderer.setStyle(this.el.nativeElement, 'position', position);
    this.renderer.setStyle(this.el.nativeElement, 'z-index', '99999');

    if (!this.moving) {
      this.started.emit(this.el.nativeElement);
      this.moving = true;
    }
  }

  private boundsCheck() {
    //let boundary = {top:0,right:0,bottom:0,left:0};
    let boundary = this.bounds
      ? this.bounds.getBoundingClientRect()
      : { top: 0, right: 0, bottom: 0, left: 0 };
    let elem = this.el.nativeElement.getBoundingClientRect();
    return {
      top: boundary.top < elem.top,
      right: boundary.right > elem.right,
      bottom: boundary.bottom > elem.bottom,
      left: boundary.left < elem.left,
    };
  }

  private putBack() {
    if (this.oldZIndex) {
      this.renderer.setStyle(this.el.nativeElement, 'z-index', this.oldZIndex);
    } else {
      this.el.nativeElement.style.removeProperty('z-index');
    }

    if (this.moving) {
      this.stopped.emit(this.el.nativeElement);
      this.edge.emit(this.boundsCheck());
      this.moving = false;
      this.oldTrans.x += this.tempTrans.x;
      this.oldTrans.y += this.tempTrans.y;
      this.tempTrans.x = this.tempTrans.y = 0;
    }
  }

  // Support Mouse Events:
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: any) {
    // 1. skip right click;
    // 2. if handle is set, the element can only be moved by handle
    if (
      event.button == 2 ||
      (this.handle !== undefined && event.target !== this.handle)
    ) {
      return;
    }

    this.orignal = this.getPosition(event.clientX, event.clientY);
    this.pickUp();
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.putBack();
  }

  @HostListener('document:mouseleave')
  onMouseLeave() {
    this.putBack();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    //console.log(event);
    if (this.moving && this.allowDrag) {
      this.moveTo(event.clientX, event.clientY);
    }
  }

  // Support Touch Events:
  @HostListener('document:touchend')
  onTouchEnd() {
    this.putBack();
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: any) {
    event.stopPropagation();
    event.preventDefault();

    if (this.handle !== undefined && event.target !== this.handle) {
      return;
    }

    this.orignal = this.getPosition(
      event.changedTouches[0].clientX,
      event.changedTouches[0].clientY
    );
    this.pickUp();
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: any) {
    event.stopPropagation();
    event.preventDefault();
    if (this.moving && this.allowDrag) {
      this.moveTo(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      );
    }
  }
}
