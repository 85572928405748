import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularDraggableModule } from 'angular2-draggable';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Message } from '../common';
import {
  AfaqyAlertService,
  ApiRequestService,
  AssignableResourcesService,
  AssignationService,
  AuthGuard,
  CanDeactivateGuard,
  LoginService,
  WASLGuard,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    // LoginRoutingModule,
    // AuthModule,
    AngularDraggableModule,
    ProgressSpinnerModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    Message,
    ApiRequestService,
    // AuthService,
    LoginService,
    AuthGuard,
    WASLGuard,
    CanDeactivateGuard,
    AfaqyAlertService,
    ApiRequestService,
    AssignableResourcesService,
    AssignationService,
    // MapService
  ],
})
export class CoreModule {}
