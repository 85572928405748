<div [formGroup]="group">
  <div class="row">
    <div
      class="wasl-connection-status"
      [ngClass]="{ 'connected-class': is_wasl_connected === 'Connected' }"
      secured="units-DisplayWaslInfo"
      *ngIf="!isAdd && wasl"
    >
      {{ 'units.wasl_status' | translate }} :
      {{
        is_wasl_connected === 'Connected'
          ? ('units.connected' | translate)
          : ('units.not_connected' | translate)
      }}
    </div>
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (DeviceType.dirty || DeviceType.touched) && DeviceType.errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.device_type' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <select
            class="form-control form-control-sm"
            formControlName="device_type"
            [attr.testid]="cid + 'device_type'"
          >
            <option *ngFor="let type of device_types" [value]="type.value">
              {{ type.title }}
            </option>
          </select>
          <control-errors-hint [ctl]="DeviceType"></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="showOnFixed">
      <div class="row">
        <div class="col-12">
          <label class="form-control-label">
            {{ 'units.gateway' | translate }}
          </label>
        </div>
        <div class="col-12">
          <label class="switch" for="unit-gateway">
            <input
              value="None"
              id="unit-gateway"
              type="checkbox"
              formControlName="gateway"
              [attr.testid]="cid + 'gateway'"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['name'].dirty || group.controls['name'].touched) &&
          group.controls['name'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label">{{ 'name' | translate }}:</label>
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            formControlName="name"
            [attr.testId]="cid + 'name'"
          />
          <control-errors-hint
            [ctl]="group.controls['name']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['operation_code'].dirty ||
            group.controls['operation_code'].touched) &&
          group.controls['operation_code'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.operation_code' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            formControlName="operation_code"
            [attr.testId]="cid + 'operation_code'"
          />
          <control-errors-hint
            [ctl]="group.controls['operation_code']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [revertsecured]="isAdd" secured="units-deviceAdmin">
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['device'].dirty ||
            group.controls['device'].touched) &&
          group.controls['device'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.device' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <select
            class="form-control form-control-sm"
            formControlName="device"
            [attr.testId]="cid + 'device'"
          >
            <option
              *ngFor="let p of formOptions.protocolsList"
              [value]="p.code"
            >
              {{ p.name }}
            </option>
          </select>
          <control-errors-hint
            [ctl]="group.controls['device']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div [formGroup]="group" class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['device_password'].dirty ||
            group.controls['device_password'].touched) &&
          group.controls['device_password'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.device_password' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            formControlName="device_password"
            [attr.testId]="cid + 'device_password'"
          />
          <control-errors-hint
            [ctl]="group.controls['device_password']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['imei'].dirty || group.controls['imei'].touched) &&
          group.controls['imei'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.imei' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            minlength="6"
            maxlength="25"
            class="form-control form-control-sm"
            formControlName="imei"
            [attr.testId]="cid + 'imei'"
          />
          <control-errors-hint
            [ctl]="group.controls['imei']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['device_serial'].dirty ||
            group.controls['device_serial'].touched) &&
          group.controls['device_serial'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.device_serial' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            minlength="6"
            maxlength="15"
            class="form-control form-control-sm"
            formControlName="device_serial"
            [attr.testId]="cid + 'device_serial'"
          />
          <control-errors-hint
            [ctl]="group.controls['device_serial']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6" *ngIf="showOnFixed && !showOnGateway">
      <div
        class="row form-group"
        [class.has-danger]="
          (DeviceGateway.dirty || DeviceGateway.touched) && DeviceGateway.errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.device_gateway' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <afaqy-custom-select
            class="input-group-item"
            [predefinedList]="true"
            [dataList]="gatewayUnits"
            [titleField]="'name'"
            [attr.testId]="cid + 'device_gateway'"
            formControlName="device_gateway"
            [ctl]="group.controls['device_gateway']"
          ></afaqy-custom-select>

          <control-errors-hint [ctl]="DeviceGateway"></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [revertsecured]="isAdd" secured="units-deviceAdmin">
    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['sim_number'].dirty ||
            group.controls['sim_number'].touched) &&
          group.controls['sim_number'].errors &&
          !SkipSIM
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.sim_number' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            maxlength="16"
            class="form-control form-control-sm"
            formControlName="sim_number"
            [attr.testId]="cid + 'sim_number'"
          />
          <control-errors-hint
            *ngIf="!SkipSIM"
            [ctl]="group.controls['sim_number']"
          ></control-errors-hint>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['sim_serial'].dirty ||
            group.controls['sim_serial'].touched) &&
          group.controls['sim_serial'].errors &&
          !SkipSIM
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.sim_serial' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            minlength="10"
            maxlength="25"
            class="form-control form-control-sm"
            formControlName="sim_serial"
            [attr.testId]="cid + 'sim_serial'"
          />
          <control-errors-hint
            *ngIf="!SkipSIM"
            [ctl]="group.controls['sim_serial']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 checkbox-SIMValidation">
      <div>
        <div class="checkboxFour">
          <input
            type="checkbox"
            id="checkboxSIMValidation"
            [attr.testid]="'checkboxSIMValidation'"
            formControlName="skip_sim_information"
          />
          <label class="title">{{ 'units.skip_sim' | translate }}</label>
        </div>
      </div>
    </div>
  </div>

  <div formGroupName="counters" class="row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.odometer' | translate }}:</label
          >
        </div>
      </div>
      <div class="row">
        <div class="col pr-0">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['counters']['controls']['odometer_type'].dirty ||
                group.controls['counters']['controls']['odometer_type']
                  .touched) &&
              group.controls['counters']['controls']['odometer_type'].errors
            "
          >
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="odometer_type"
                [attr.testId]="cid + 'odometer_type'"
              >
                <option [value]="'gps'">
                  {{ 'units.odometer_types.gps' | translate }}
                </option>
                <option [value]="'canbus'">
                  {{ 'units.odometer_types.canbus' | translate }}
                </option>
                <!--<option [value]="'odo'">{{"units.odometer_types.odo" | translate}}</option>-->
                <!--<option [value]="'odor'">{{"units.odometer_types.odor" | translate}}</option>-->
              </select>
              <control-errors-hint
                [ctl]="group.controls['counters']['controls']['odometer_type']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col pl-0">
          <div
            class="row form-group button-form-group"
            [class.has-danger]="
              (group.controls['counters']['controls']['odometer'].dirty ||
                group.controls['counters']['controls']['odometer'].touched) &&
              group.controls['counters']['controls']['odometer'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <div class="input-group input-group-sm">
                <input
                  class="form-control form-control-sm"
                  formControlName="odometer"
                  [attr.testId]="cid + 'odometer'"
                />
                <div class="input-group-append">
                  <label class="input-group-text">{{
                    'units.unit_services.km' | translate
                  }}</label>
                </div>
                <control-errors-hint
                  [ctl]="group.controls['counters']['controls']['odometer']"
                >
                </control-errors-hint>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.engine_hours' | translate }}:</label
          >
        </div>
      </div>
      <div class="row">
        <div class="col pr-0">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['counters']['controls']['engine_hours_type']
                .dirty ||
                group.controls['counters']['controls']['engine_hours_type']
                  .touched) &&
              group.controls['counters']['controls']['engine_hours_type'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="engine_hours_type"
                [attr.testId]="cid + 'engine_hours_type'"
              >
                <option [value]="'acc'">
                  {{ 'units.engine_hours_types.acc' | translate }}
                </option>
                <option [value]="'canbus'">
                  {{ 'units.odometer_types.canbus' | translate }}
                </option>
                <!--<option [value]="'engh'">{{"units.engine_hours_types.engh" | translate}}</option>-->
                <!--<option [value]="'enghr'">{{"units.engine_hours_types.enghr" | translate}}</option>-->
              </select>
              <control-errors-hint
                [ctl]="
                  group.controls['counters']['controls']['engine_hours_type']
                "
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col pl-0">
          <div
            class="row form-group button-form-group"
            [class.has-danger]="
              (group.controls['counters']['controls']['engine_hours'].dirty ||
                group.controls['counters']['controls']['engine_hours']
                  .touched) &&
              group.controls['counters']['controls']['engine_hours'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <div class="input-group input-group-sm">
                <input
                  class="form-control form-control-sm"
                  formControlName="engine_hours"
                  [attr.testId]="cid + 'engine_hours'"
                />
                <div class="input-group-append">
                  <label class="input-group-text">{{
                    'units.unit_services.hour' | translate
                  }}</label>
                </div>
                <control-errors-hint
                  [ctl]="group.controls['counters']['controls']['engine_hours']"
                >
                </control-errors-hint>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdd" class="row">
    <div class="col-12 col-md-6">
      <div class="row form-group">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.creator' | translate }}:
          </label>
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            formControlName="creator"
            [attr.testId]="cid + 'creator'"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row form-group">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.owner' | translate }}:
          </label>
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            formControlName="owner"
            [attr.testId]="cid + 'owner'"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label class="form-control-label">{{ 'units.crat' | translate }}</label>
        <wj-input-date-time
          [format]="'yyyy-MM-dd HH:mm:ss'"
          class="form-control form-control-sm"
          formControlName="crat"
          [timeStep]="10"
          [isReadOnly]="true"
        >
        </wj-input-date-time>
      </div>
    </div>
  </div>
</div>
