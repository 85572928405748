import { AppModel } from '../../../common/classes';
import { CustomerTypes } from './customer_types';
import { AfaqyObject } from '../../../common/classes/afaqy-object';
import { TranslateService } from '@ngx-translate/core';

class Customer extends AfaqyObject {
  private _name: string = '';
  private _email: string = '';
  private _phone: string = '';
  private _password: string = '';
  private _type_id: string = '';
  private _customer_type: CustomerTypes;
  private _price: number;
  private _capacity: number;
  private _national_id: number;
  private _notification: number;
  private _address: string = '';
  private _priority: number;
  private _consuming: number;
  private _contract_no: number;
  private _contract_end_date: Date;
  private _contract_customer_no: number;
  private _comment: string = '';
  private _last_visit_sdt: number;
  private _last_visit_edt: number;

  protected copyFields = [
    'id',
    'name',
    'email',
    'phone',
    'password',
    'type_id',
    'price',
    'capacity',
    'national_id',
    'notification',
    'address',
    'created_at',
    'priority',
    'consuming',
    'contract_no',
    'contract_end_date',
    'contract_customer_no',
    'comment',
    'last_visit_sdt',
    'last_visit_edt',
  ];
  protected createFields = [
    'name',
    'email',
    'phone',
    'password',
    'type_id',
    'price',
    'capacity',
    'national_id',
    'notification',
    'address',
    'priority',
    'consuming',
    'contract_no',
    'contract_end_date',
    'contract_customer_no',
    'comment',
    'last_visit_sdt',
    'last_visit_edt',
  ];
  protected updateFields = [
    'name',
    'email',
    'phone',
    'password',
    'type_id',
    'price',
    'capacity',
    'national_id',
    'notification',
    'address',
    'priority',
    'consuming',
    'contract_no',
    'contract_end_date',
    'contract_customer_no',
    'comment',
    'last_visit_sdt',
    'last_visit_edt',
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  get type_id(): string {
    return this._type_id;
  }

  set type_id(value: string) {
    this._type_id = value;
  }

  get customer_type(): CustomerTypes {
    return this._customer_type;
  }

  set customer_type(value: CustomerTypes) {
    this._customer_type = value;
  }

  get price(): number {
    return this._price;
  }

  set price(value: number) {
    this._price = value;
  }

  get capacity(): number {
    return this._capacity;
  }

  set capacity(value: number) {
    this._capacity = value;
  }

  get national_id(): number {
    return this._national_id;
  }

  set national_id(value: number) {
    this._national_id = value;
  }

  get notification(): number {
    return this._notification;
  }

  set notification(value: number) {
    this._notification = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get priority(): number {
    return this._priority;
  }

  set priority(value: number) {
    this._priority = value;
  }

  get consuming(): number {
    return this._consuming;
  }

  set consuming(value: number) {
    this._consuming = value;
  }

  get contract_no(): number {
    return this._contract_no;
  }

  set contract_no(value: number) {
    this._contract_no = value;
  }

  get contract_end_date(): Date {
    return this._contract_end_date;
  }

  set contract_end_date(value: Date) {
    this._contract_end_date = value;
  }

  get contract_customer_no(): number {
    return this._contract_customer_no;
  }

  set contract_customer_no(value: number) {
    this._contract_customer_no = value;
  }

  get comment(): string {
    return this._comment;
  }

  set comment(value: string) {
    this._comment = value;
  }

  get last_visit_sdt(): number {
    return this._last_visit_sdt;
  }

  set last_visit_sdt(value: number) {
    this._last_visit_sdt = value;
  }

  get last_visit_edt(): number {
    return this._last_visit_edt;
  }

  set last_visit_edt(value: number) {
    this._last_visit_edt = value;
  }
}

export class Customers extends AppModel {
  constructor(protected translate: TranslateService) {
    super();
  }

  private _name: string;
  private _user_id: string;
  private _color: string;
  private _type: string = 'polygon';
  private _status: number;
  private _personal: number;
  private _radius: number;
  private _area: number;
  private _vertices: any[];
  private _is_customer: boolean;
  private _customer: Customer = new Customer();
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'is_customer',
    'created_at',
    'customer',
    'deleted_at',
    'users_list',
  ];
  protected createFields = [
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'is_customer',
    'customer',
    'users_list',
  ];
  protected updateFields = [
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'is_customer',
    'customer',
    'users_list',
  ];

  static statusVal = ['not_active', 'active'];
  static isPersonal = ['no', 'yes'];
  static zoneTypes = ['line', 'circle', 'polygon'];
  static notifications = [
    { value: '10', display: '10%' },
    { value: '20', display: '20%' },
    { value: '30', display: '30%' },
    { value: '40', display: '40%' },
    { value: '50', display: '50%' },
    { value: '60', display: '60%' },
    { value: '70', display: '70%' },
    { value: '80', display: '80%' },
  ];
  static priority = [
    { value: '1', display: '1 High' },
    { value: '2', display: '2' },
    { value: '3', display: '3' },
    { value: '4', display: '4' },
    { value: '5', display: '5' },
    { value: '6', display: '6' },
    { value: '7', display: '7' },
    { value: '8', display: '8' },
    { value: '9', display: '9' },
    { value: '10', display: '10 Low' },
  ];
  static consuming = [
    { value: '0', display: 'Normal' },
    { value: '1', display: 'Fast' },
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get personal(): number {
    return this._personal;
  }

  set personal(value: number) {
    this._personal = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }

  get area(): number {
    return this._area;
  }

  set area(value: number) {
    this._area = value;
  }

  get vertices(): any[] {
    return this._vertices;
  }

  set vertices(value: any[]) {
    this._vertices = value;
  }

  get is_customer(): boolean {
    return this._is_customer;
  }

  set is_customer(value: boolean) {
    this._is_customer = value;
  }

  get customer(): Customer {
    return this._customer;
  }

  set customer(value: Customer) {
    this._customer = value;
  }

  get users_list(): string[] {
    return this._users_list;
  }

  set users_list(value: string[]) {
    this._users_list = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get statusDisplay() {
    return Customers.statusVal[this.status ? 1 : 0];
  }

  get customerTypeName() {
    if (this.customer.customer_type) return this.customer.customer_type.name;
    return '';
  }

  get zoneTypeIcon() {
    let cls = '';
    switch (this.type) {
      case 'polygon':
        cls = 'icon-zone-polygone';
        break;
      case 'line':
        cls = 'icon-zone-line';
        break;
      default:
        cls = 'icon-zone-circle';
        break;
    }
    return cls;
  }

  get zoneType() {
    return this.translate.instant(`customers.${this.type}`);
  }

  get statusType() {
    return this.translate.instant(this.statusDisplay);
  }
}
