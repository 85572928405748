import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AfaqyControl } from '../../../common/afaqy-control';
import { AuthService } from '../../../core/services/auth.service';

import { afaqyLocal } from './afaqy-local';

@Component({
  exportAs: 'afaqyDateCalendar',
  selector: 'afaqy-date-calendar',
  templateUrl: './afaqy-date-calendar.component.html',
  styleUrls: ['./afaqy-date-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyDateCalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyDateCalendarComponent),
      multi: true,
    },
  ],
})
export class AfaqyDateCalendarComponent
  extends AfaqyControl
  implements OnInit, OnDestroy
{
  cid = 'date_intervals';
  form: UntypedFormGroup;
  showInterval = false;
  disableTo = false;
  live = true;
  routerPrefix: string;
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() predefinedDateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() editDate = null;
  @Input() hideButtons;
  period: string = '';
  @Input() intervals = [
    { val: 'interval', txt: 'date_intervals.interval' },
    { val: 'from_until_today', txt: 'date_intervals.from_until_today' },
    { val: 'for_previous', txt: 'date_intervals.for_previous' },
  ];
  @Input() customIntervalUnits;

  intervalUnits = [
    { val: 'minutes', txt: 'date_intervals.minutes' },
    { val: 'hours', txt: 'date_intervals.hours' },
    { val: 'days', txt: 'date_intervals.days' },
    { val: 'weeks', txt: 'date_intervals.weeks' },
    { val: 'months', txt: 'date_intervals.months' },
    { val: 'years', txt: 'date_intervals.years' },
  ];

  userTime;
  initialDateTime;
  maxDate = moment().format('YYYY-MM-DD HH:mm:ss');
  maxToTime;
  maxFromDate;
  maxFromTime;
  isToDropDownOpen = false;
  oldToDateValue: Date;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private ElementRef: ElementRef,
    private router: Router
  ) {
    super();
    const days = {
      saturday: 6,
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
    };
    const number = days[this.authService.user.user_settings.start_weekday];
    afaqyLocal.week = {
      dow: number,
      doy: number, //7 + dow - janX, where janX is the first day of January that must belong to the first week of the year.
    };
    moment.updateLocale('en', afaqyLocal);
    this.initializeUserTime();
    this.setUserTimeAndMaxDate();
    this.initForm();
  }

  ngOnDestroy() {
    this.live = false;
  }

  ngOnInit() {
    this.intervalUnits = this.customIntervalUnits
      ? this.customIntervalUnits
      : this.intervalUnits;

    this.toggleInterval();
    this.created.next({ formGroup: this.form });
    // needed if the value arrived before init
    setTimeout(() => {
      if (this.editDate) {
        this.form.get('from').patchValue(this.editDate.from);
        this.form.get('to').patchValue(this.editDate.to);
      }
    }, 50);
    for (let field of [
      'interval',
      'interval_count',
      'interval_unit',
      'including_current',
    ]) {
      this.form.controls[field].valueChanges.subscribe({
        next: (change) => {
          this.setFromTO();
          this.period = '';
        },
      });
    }
    this.form.valueChanges.subscribe({
      next: () => {
        this.pushValue();
      },
    });
    this.pushValue();
    this.routerPrefix = this.router.url.split('/')[1].split('?')[0];
  }

  ngOnChanges(change) {
    if (change.editDate && change.editDate.currentValue) {
      // needed if we pass the formInit creation
      // if form not created it would cause error
      // setTimeout(() => {
      if (this.form) {
        this.form.get('from').patchValue(change.editDate.currentValue.from);
        this.form.get('to').patchValue(change.editDate.currentValue.to);
      }
      // }, 0);
    }
  }

  initForm() {
    const toDate = this.userTime?.format('YYYY-MM-DD HH:mm:ss');
    this.form = this.fb.group({
      interval: ['interval', []],
      from: [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), []],
      to: [toDate],
      interval_count: [1, []],
      interval_unit: ['minutes', []],
      including_current: [false, []],
    });
  }

  setFromTO() {
    if (this.form.controls['interval'].value == 'interval') {
      return false;
    }
    let fromDate;
    let toDate: any;
    const interval_count = this.form.controls['interval_count'].value;
    const interval_unit = this.form.controls['interval_unit'].value;
    if (this.form.controls['interval'].value == 'from_until_today') {
      fromDate = moment(this.form.controls['from'].value).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      toDate = moment(this.form.controls['to'].value).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    } else {
      switch (interval_unit) {
        case 'minutes':
          let minutes = moment()
            .subtract(interval_count, 'minutes')
            .startOf('minute');
          fromDate = minutes.format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'hours':
          let hours = moment()
            .subtract(interval_count, 'hours')
            .startOf('hour');
          fromDate = hours.format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'days':
          let days = moment().subtract(interval_count, 'days').startOf('day');
          fromDate = days.format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'weeks':
          let weeks = moment()
            .subtract(interval_count, 'weeks')
            .startOf('week');
          fromDate = weeks.format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'months':
          let months = moment()
            .subtract(interval_count, 'months')
            .startOf('month');
          fromDate = months.format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'years':
          let years = moment()
            .subtract(interval_count, 'years')
            .startOf('year');
          fromDate = years.format('YYYY-MM-DD HH:mm:ss');
          break;
      }
      const includingCurrent = this.form.controls['including_current'].value;

      if (!includingCurrent) {
        toDate = moment(fromDate)
          .add(interval_count, interval_unit)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        // Set "to" field to current moment
        toDate = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    }

    if (
      this.editDate &&
      this.editDate.interval == this.form.controls['interval'].value
    ) {
      this.form.get('from').patchValue(this.editDate.from);
      this.form.get('to').patchValue(this.editDate.to);
    } else {
      this.form.controls['from'].setValue(
        moment(fromDate).format('YYYY-MM-DD HH:mm:ss')
      );
      this.form.controls['to'].setValue(toDate);
    }
  }

  toggleInterval(update = false) {
    const intervalValue = this.form.controls['interval'].value;
    this.showInterval =
      intervalValue == 'interval' || intervalValue == 'from_until_today';
    this.disableTo = intervalValue == 'from_until_today';
    if (this.showInterval && update) {
      const currentMoment = this.userTime?.format('YYYY-MM-DD HH:mm:ss');
      this.form.controls['from'].setValue(this.maxDate);
      this.form.controls['to'].setValue(currentMoment); // To will be calculated to current moment
    }
  }

  updateDate(period_unit) {
    if (period_unit == 'today') {
      this.form.controls['interval'].setValue('interval');
      let today = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
      // Today at 00:00:00
      this.form.controls['from'].setValue(today);
      const currentMoment = this.userTime?.format('YYYY-MM-DD HH:mm:ss');
      this.form.controls['to'].setValue(currentMoment); // To will be calculated to current moment
    } else if (period_unit == 'yesterday') {
      this.form.controls['interval'].setValue('for_previous');
      this.form.controls['interval_unit'].setValue('days');
      this.form.controls['interval_count'].setValue(1);
    } else if (period_unit != 'today') {
      this.form.controls['interval'].setValue('for_previous');
      this.form.controls['interval_unit'].setValue(period_unit);
      this.form.controls['interval_count'].setValue(1);
    }
    this.toggleInterval();
    this.predefinedDateChanged.next();
    this.pushValue();
    this.period = period_unit;
  }
  public wijmoInputClick(e, event) {
    if (event.target.classList.contains('wj-form-control')) {
      e.isDroppedDown = true;
    }
  }

  pushValue() {
    if (!this.writing) {
      this.propagateChange(this.form.value);
    }
  }

  writeValue(value: any) {
    if (value) {
      this.writing = true;
      this.form.reset(value);
      if (moment(value.to) > moment(this.maxDate)) {
        this.form.get('to').patchValue(this.maxDate);
      }
      this.toggleInterval();
      this.setFromTO();
      this.writing = false;
    }
    this.reWriteValue();
  }

  reWriteValue() {
    this.pushValue();
  }

  onToDateValueChanged(e: any) {
    this.setUserTimeAndMaxDate();
    if (moment(e) > moment(this.maxDate)) {
      this.form.get('to').patchValue(this.maxDate);
    }
    // is the user only changed the time of the toDate and the date is the same as the oldvalue of the toDate
    // then do nothing
    if (moment(e).isSame(this.oldToDateValue, 'day')) return;
    let toDate = moment(e).toDate();
    // if the user enters that is not today make the time to be 23:59:59
    if (!moment(e).isSame(this.userTime, 'day')) {
      toDate = moment(e).endOf('day').toDate();
      this.form.get('to').patchValue(toDate);
    }
    this.oldToDateValue = toDate;
  }

  onFromDateValueChanged(e: any) {
    this.setUserTimeAndMaxDate();
    // // check if fromDate is greater than toDate
    if (moment(e) > moment(this.form.get('to').value)) {
      //  change toDate to be the greater than fromDate by 10 minutes
      const fromDate = moment(this.form.get('from').value);
      const toDate = moment(fromDate)
        .add(10, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss');
      this.form.get('to').patchValue(toDate);
    }
  }
  /*
    set user time to be the current time in the user timezone
    set maxDate, maxToTime, maxFromDate, maxFromTime
  */

  setUserTimeAndMaxDate() {
    this.initializeUserTime();
    this.setMaxToTime();
    this.setMaxFromDate();
    this.setMaxFromTime();
  }

  /**
   * Initialize userTime and maxDate based on the user's timezone.
   */
  initializeUserTime() {
    this.userTime = moment.tz(moment.now(), this.authService.user.timezone);
    this.maxDate = this.userTime?.format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Set maxToTime based on the difference in days between toDate and userTime.
   */
  setMaxToTime() {
    const toDate = this.getFormattedDate('to');
    const isSameDay = moment(toDate).isSame(this.userTime, 'day');
    const maxToTime = isSameDay
      ? this.maxDate
      : moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    // not changing the value if it's the same because
    // it will cause the calendar to close and open again eventually
    if (maxToTime !== this.maxToTime) this.maxToTime = maxToTime;
  }

  /**
   * Set maxFromDate to be one minute before toDate or maxDate.
   */
  setMaxFromDate() {
    const toDate = this.getFormattedDate('to');

    this.maxFromDate = moment(toDate).isBefore(this.userTime)
      ? moment(toDate).subtract(1, 'minute').format('YYYY-MM-DD HH:mm:ss')
      : this.maxDate;
  }

  /**
   * Set maxFromTime based on whether fromDate and toDate are on the same day.
   */
  setMaxFromTime() {
    const fromDate = this.getFormattedDate('from');
    const toDate = this.getFormattedDate('to');
    const isSameDay = moment(fromDate).isSame(toDate, 'day');

    this.maxFromTime = isSameDay
      ? moment(toDate).format('YYYY-MM-DD HH:mm:ss')
      : moment(fromDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
  /**
   * Helper function to get and format date from form control.
   */
  getFormattedDate(controlName: string): string {
    return moment(this.form?.get(controlName)?.value).format(
      'YYYY-MM-DD HH:mm:ss'
    );
  }
}
