import { AfaqyObject, AppConfig, AfaqyHelper } from '../../../common/classes';
import { FuelPrices } from './fuel-prices';
import { UnitAdditionalInfo } from './unit-additional-info';

export class UserSettings extends AfaqyObject {
  private _address_format: string = 'zone_name';
  private _start_weekday: string = 'saturday';
  private _use_shift: boolean = false;
  private _capacity_unit: string = 'litre';
  private _weight_unit: string = 'kg';
  private _length_unit: string = 'meter';
  private _count_unit: string = 'item';
  private _temp_unit: string = 'celsius';
  private _distance_unit: string = 'km';
  private _currency: string = 'sar';
  private _map_source: string = '';
  private _lock_job_order_number: boolean = true;
  private _fuel_prices: FuelPrices = new FuelPrices();
  private _unit_additional_info = new UnitAdditionalInfo();
  protected copyFields = [
    'address_format',
    ,
    'start_weekday',
    'use_shift',
    'capacity_unit',
    'weight_unit',
    'length_unit',
    'count_unit',
    'temp_unit',
    'distance_unit',
    'currency',
    'map_source',
    'lock_job_order_number',
    'fuel_prices',
    'unit_additional_info',
  ];

  get address_format(): string {
    return this._address_format;
  }

  set address_format(value: string) {
    this._address_format = value;
  }

  get capacity_unit(): string {
    return this._capacity_unit;
  }

  set capacity_unit(value: string) {
    this._capacity_unit = value;
  }

  get weight_unit(): string {
    return this._weight_unit;
  }

  set weight_unit(value: string) {
    this._weight_unit = value;
  }

  get length_unit(): string {
    return this._length_unit;
  }

  set length_unit(value: string) {
    this._length_unit = value;
  }

  get count_unit(): string {
    return this._count_unit;
  }

  set count_unit(value: string) {
    this._count_unit = value;
  }

  get temp_unit(): string {
    return this._temp_unit;
  }

  set temp_unit(value: string) {
    this._temp_unit = value;
  }

  get distance_unit(): string {
    return this._distance_unit;
  }

  set distance_unit(value: string) {
    this._distance_unit = value;
  }
  get currency(): string {
    return this._currency;
  }
  set currency(value: string) {
    this._currency = value;
  }

  get map_source(): string {
    return this._map_source;
  }

  set map_source(value: string) {
    this._map_source = value;
  }

  get lock_job_order_number(): boolean {
    return this._lock_job_order_number;
  }

  set lock_job_order_number(value: boolean) {
    this._lock_job_order_number = value;
  }

  get fuel_prices(): FuelPrices {
    return this._fuel_prices;
  }

  set fuel_prices(value: FuelPrices) {
    this._fuel_prices = value;
  }

  get unit_additional_info(): UnitAdditionalInfo {
    return this._unit_additional_info;
  }

  set unit_additional_info(value: UnitAdditionalInfo) {
    this._unit_additional_info = value;
  }

  get start_weekday(): string {
    return this._start_weekday ? this._start_weekday : AppConfig.weekDays[0];
  }

  set start_weekday(value: string) {
    this._start_weekday = value;
  }

  get use_shift(): boolean {
    return this._use_shift;
  }

  set use_shift(value: boolean) {
    this._use_shift = value;
  }
}
