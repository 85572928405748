import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from 'app/common';
import * as particlesOptions from 'assets/json/particles.json';
declare var particlesJS: any;
import 'assets/js/particles.js';

@Component({
  selector: 'app-logged-out-session',
  styleUrls: ['./logged-out-session.component.scss'],
  templateUrl: './logged-out-session.component.html',
})
export class LoggedOutSessionComponent implements OnInit {
  configs = AppConfig;

  ngOnInit(): void {
    particlesJS('particles-js', particlesOptions, null);
  }
}
