import {
  OnInit,
  Component,
  Input,
  ViewChild,
  OnDestroy,
  forwardRef,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { takeWhile } from 'rxjs/operators';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';
import { AfaqyControl } from '../../../common/afaqy-control';
import { RootService } from 'app/core/services';
import { UnitService } from 'app/modules/units/services';

@Component({
  selector: 'afaqy-custom-multiple-select',
  templateUrl: './afaqy-custom-multiple-select.component.html',
  styleUrls: ['./afaqy-custom-multiple-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyCustomMultipleSelect),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyCustomMultipleSelect),
      multi: true,
    },
  ],
})
export class AfaqyCustomMultipleSelect
  extends AfaqyControl
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('grid') grid: wjcGrid.FlexGrid;
  @Input() set random(value: any) {
    this.optionsList.refresh();
  }
  @Input() ctl: UntypedFormControl = new UntypedFormControl();
  @Input() service: RootService;
  @Input() serviceGroup: RootService;
  @Input() groupsList: string = 'groupsList';
  @Input() reducedMaxHeight: number = 352;
  @Input() refresh: boolean;
  @Input() disabled: boolean = false;
  alive: boolean = true;
  selectedGroups: any = {};
  optionsList: any;
  selectedValue: any = {};
  searchText: string = '';

  constructor(public rootService: RootService) {
    super();
    this.optionsList = new wjcCore.CollectionView([], { currentItem: null });
  }

  ngOnInit() {
    this.updateSourceCollection();
    this.service.resources.pipe(takeWhile(() => this.alive)).subscribe({
      next: (event: any) => {
        this.updateSourceCollection();
      },
    });
    this.serviceGroup.resources.pipe(takeWhile(() => this.alive)).subscribe({
      next: (event: any) => {
        this.updateSourceCollection();
      },
    });
  }

  ngAfterViewInit() {
    this.setGridHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refresh) this.updateSourceCollection();
  }

  setGridHeight() {
    const gridEl: HTMLElement = this.grid.hostElement;
    gridEl.style.maxHeight = `calc(var(--page-content-height) - ${this.reducedMaxHeight}px)`;
  }

  collapsAll() {
    this.grid.collapseGroupsToLevel(0);
  }

  expandAll() {
    this.grid.collapseGroupsToLevel(1);
  }

  refreshFilters() {
    this.optionsList.refresh();
  }

  updateSourceCollection() {
    const groupedList = this.AllGroupedList();
    this.optionsList = new wjcCore.CollectionView(groupedList, {
      currentItem: null,
    });
    this.optionsList.filter = this.applyFilters.bind(this);
    var groupDesc = new wjcCore.PropertyGroupDescription('group_id');
    this.optionsList.groupDescriptions.clear();
    this.optionsList.groupDescriptions.push(groupDesc);
  }

  private AllGroupedList() {
    let uList = [];
    this.service.resourcesList.forEach((item: any) => {
      if (typeof item[this.groupsList] == 'string') {
        const groupID: string = item[this.groupsList];
        const groupName: string =
          this.serviceGroup.getItemFromResources(groupID).name;
        let newitem = item.clone();
        newitem['group_id'] = groupID;
        groupName
          ? (newitem['group_name'] = groupName)
          : (newitem['group_name'] = 'Un-grouped');
        uList.push(newitem);
      } else if (item[this.groupsList] && item[this.groupsList].length) {
        item[this.groupsList].forEach((groupid: any) => {
          let newitem = item.clone();
          newitem['group_id'] = groupid;
          newitem['group_name'] =
            this.serviceGroup.getItemFromResources(groupid).name;
          uList.push(newitem);
        });
      } else {
        let newitem = item.clone();
        newitem['group_id'] = 0;
        newitem['group_name'] = 'Un-grouped';
        uList.push(newitem);
      }
    });
    return uList;
  }

  applyFilters(item: any) {
    let searchText = this.searchText;
    if (searchText != '' && typeof searchText !== 'undefined') {
      searchText = searchText.toLowerCase();
      return (
        this.rootService.validateSearchTextExistInItem(
          JSON.stringify(item['name']),
          searchText
        ) ||
        this.rootService.validateSearchTextExistInItem(
          JSON.stringify(item['group_name']),
          searchText
        )
      );
    }
    return true;
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  setSelectedItem(id: any, status: any) {
    if (status) {
      this.selectedValue[id] = true;
    } else {
      delete this.selectedValue[id];
    }
  }

  checkGroupSelection(push: boolean = true) {
    let selectedGroups = { all: true };
    this.optionsList.sourceCollection.forEach((item: any) => {
      let group_id = item.group_id;
      if (!selectedGroups.hasOwnProperty(group_id)) {
        selectedGroups[group_id] = true;
      }
      if (!this.getItemSelectionStatus(item.id)) {
        selectedGroups[group_id] = false;
        selectedGroups['all'] = false;
      }
    });
    this.selectedGroups = AfaqyHelper.cloneObject(selectedGroups);
    if (push) {
      this.pushValue();
    }
  }

  UnitGroupSelection(status: any, groupID: any) {
    if (this.disabled) return;
    this.optionsList.sourceCollection.forEach((item) => {
      if (this.applyFilters(item) && item.group_id == groupID) {
        this.setSelectedItem(item.id, status);
      }
    });
    this.checkGroupSelection();
  }

  AllSelection(status: any) {
    if (this.disabled) return;
    this.optionsList.sourceCollection.forEach((item) => {
      if (this.applyFilters(item)) {
        this.setSelectedItem(item.id, status);
      }
    });
    this.checkGroupSelection();
  }

  getItemSelectionStatus(id: any) {
    return this.selectedValue[id] ? true : false;
  }

  toggleItem(id: any, event: any, groupid: string = '') {
    if (this.disabled) return;
    const ctrKey = event.ctrlKey;
    if (!this.selectedValue[id]) {
      this.selectedValue[id] = true;
      if (ctrKey) {
        this.optionsList.sourceCollection.forEach((item) => {
          if (this.applyFilters(item)) {
            if (groupid == '' || item.group_id == groupid) {
              this.setSelectedItem(item.id, true);
            }
          }
        });
      }
    } else {
      delete this.selectedValue[id];
      if (ctrKey) {
        this.optionsList.sourceCollection.forEach((item) => {
          if (this.applyFilters(item)) {
            if (groupid == '' || item.group_id == groupid) {
              this.setSelectedItem(item.id, false);
            }
          }
        });
      }
    }
    this.checkGroupSelection();
  }

  pushValue() {
    if (this.writing) return;
    const ctrValue = Object.keys(this.selectedValue);
    this.propagateChange(ctrValue);
  }

  writeValue(value: any) {
    if (value) {
      this.writing = true;
      this.selectedValue = {};
      value.forEach((item: any) => {
        this.selectedValue[item] = true;
      });
      this.checkGroupSelection(false);
      this.writing = false;
    }
  }

  onBlur() {
    if (this.ctl.untouched) this.ctl.markAsTouched();
  }
}
