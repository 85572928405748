import { animation, style, animate } from '@angular/animations';

export const showAnimation = animation([
  style({ opacity: 0, transform: 'scale(0)', 'transform-origin': '50% 50%' }),
  animate(
    '{{ timings }}',
    style({ opacity: 1, transform: 'scale(1)', 'transform-origin': '50% 50%' })
  ),
]);

export const HideAnimation = animation([
  animate(
    '{{ timings }}',
    style({ opacity: 0, transform: 'scale(0)', 'transform-origin': '50% 50%' })
  ),
]);
