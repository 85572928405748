import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { ZoneGroup } from '../models';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ZoneGroupService extends RootService {
  //,public zs :ZonesService
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  get limitForPageList() {
    return 0;
  }

  zoneGroupsList() {
    return this.getOptionsList('zones_groups');
  }

  routerPrefix(val: string = '') {
    return val ? val : 'zones_groups';
  }

  get modelInstance() {
    return new ZoneGroup();
  }
  getExportFileName(prms: any) {
    return (
      this.authService.user.username +
      '_' +
      'Geofence_Groups' +
      '_' +
      moment().format('YYYY-MM-DD')
    );
  }
}
