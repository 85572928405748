import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventRightClick]',
})
export class PreventRightClickDirective {
  constructor() {}

  @HostListener('contextmenu', ['$event']) onContextMenu(event: Event): void {
    event.preventDefault();
  }
}
