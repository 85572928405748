import { Injectable } from '@angular/core';
import {
  RootService,
  AuthService,
  ApiRequestService,
  AfaqyAlertService,
} from 'app/core/services';
import { NotificationsService } from 'angular2-notifications';
import { WasteContainersModel } from '../models/waste-containers.model';
import { AfaqyResponse } from 'app/core/classes';
import { BehaviorSubject } from 'rxjs';
import { UnitService } from 'app/modules/units/services';
import { ZonesService } from 'app/modules/zones/services';

@Injectable()
export class WasteContainersService extends RootService {
  zoneMap$: BehaviorSubject<any> = new BehaviorSubject(null);
  detectionOptions = {
    rfid: 'RFID',
    zone: 'Zone',
  };
  typeOptions = {
    '20yard': '20 yard',
    '30yard': '30 yard',
    '2yard': '​2 yard',
    '4yard': '4 yard',
    '6yard': '6 yard',
    '240l': '240 L',
    '1100': '1100',
    plastic: 'Plastic',
  };
  constructor(
    public authService: AuthService,
    public apiRequest: ApiRequestService,
    public alertService: AfaqyAlertService,
    public notificationsService: NotificationsService,
    private unitService: UnitService,
    private zonesService: ZonesService
  ) {
    super(authService, apiRequest, alertService, notificationsService);
    this.unitService.resources.subscribe({
      next: (updates) => {
        this.updateServicesRelations(updates, 'unit_name', 'unit_id');
      },
    });
    this.zonesService.resources.subscribe({
      next: (updates) => {
        this.updateServicesRelations(updates, 'zone_name', 'zone_id');
      },
    });
  }
  routerPrefix(val: string = '') {
    return val ? val : 'waste/containers';
  }

  get modelInstance() {
    return new WasteContainersModel();
  }
  applyAfterListTrashed(list) {
    return list.map((item) => this.refactorUpdatedData(item));
  }
  updateServicesRelations(updates: any, objName: string, objId: string) {
    if (updates['action'] == 'list') {
      let resourceObject = {};
      for (const obj of updates['data']) {
        resourceObject[obj.id] = obj;
      }
      for (const key in this._resourcesList) {
        let item = this._resourcesList[key][objId];
        // to avoid errors of points and zones confusion
        // it will find its value in one and skip if not found in the other
        if (Array.isArray(item)) {
          if (!resourceObject[item[0]]) continue;
          let result = item.map((i) => {
            return resourceObject[i] ? resourceObject[i]['name'] : null;
          });
          this._resourcesList[key][objName] = result.join(', ');
        } else {
          if (resourceObject[item])
            this._resourcesList[key][objName] = resourceObject[item]['name'];
        }
      }
    } else if (updates['action'] == 'remove') {
      for (const key in this._resourcesList) {
        if (this._resourcesList[key][objId] == updates['data']) {
          this._resourcesList[key][objName] = '';
        }
      }
    } else if (updates['action'] == 'update' && updates['data']) {
      for (const key in this._resourcesList) {
        if (this._resourcesList[key][objId] == updates['data']['id']) {
          this._resourcesList[key][objName] = updates['data']['name'];
        }
      }
    }
    this.pushChanges();
  }

  updateObjectsRelations(updates: any) {
    if (
      (updates['action'] == 'list' || updates['action'] == 'addMulti') &&
      Array.isArray(updates['data'])
    ) {
      for (const data of updates['data']) {
        let result = this.refactorUpdatedData(data);
        this.setResourceObject(result);
      }
    } else if (
      (updates['action'] == 'update' || updates['action'] == 'add') &&
      Array.isArray(updates['data'])
    ) {
      updates['data'].forEach((item) => {
        let data = this.refactorUpdatedData(item);
        this.setResourcesList(data);
      });
    } else if (
      (updates['action'] == 'update' || updates['action'] == 'add') &&
      !Array.isArray(updates['data'])
    ) {
      let data = this.refactorUpdatedData(updates['data']);
      this.setResourcesList([data]);
    }
  }

  refactorUpdatedData(data) {
    if (data['unit_id'])
      data['unit_name'] = this.unitService.getItemFromResources(
        data['unit_id']
      )['name'];
    if (data['zone_id'])
      data['zone_name'] = this.zonesService.getItemFromResources(
        data['zone_id']
      )['name'];
    if (data['type']) data['type_name'] = this.typeOptions[data['type']];
    if (data['detection'])
      data['detection_name'] = this.detectionOptions[data['detection']];
    if (data['district']) data['district_name'] = data['district'].n;
    if (data['neighborhood'])
      data['neighborhood_name'] = data['neighborhood'].n;
    // data['active_icon'] = data['active'] ? 'pointer mdi mdi-check green-color':'pointer mdi mdi-close red-color';
    return data;
  }
  addToResource(id) {
    this.getById(id).subscribe({
      next: (response: AfaqyResponse) => {
        if (response.success) {
          let data = response.data;
          if (data['unit_id'])
            data['unit_name'] = this.unitService.resourcesList.find(
              (i) => i.id == data['unit_id']
            )['name'];
          if (data['zone_id'])
            data['zone_name'] = this.zonesService.resourcesList.find(
              (i) => i.id == data['zone_id']
            )['name'];

          this.setResourcesList([data]);
          this.updateObjectsRelations({ action: 'add', data: data });
          this.addToOptionsList(data);
          this.pushChanges({ action: 'add', data: data });
        }
      },
    });
  }

  getActiveResourcesList(): any[] {
    return this.resourcesList;
  }

  setResourceObject(obj) {
    const vids = this.authService.preferences('waste_containers', 'visible');
    if (vids?.includes(obj?.id)) {
      obj['visible'] = 1;
    } else {
      obj['visible'] = 0;
    }
    super.setResourceObject(obj);
  }
}
