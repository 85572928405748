export class AfaqySubscriptions {
  subsList: any[];

  addToList(subscription) {
    this.subsList.push(subscription);
    return this.subsList.length - 1;
  }

  DestroyList() {
    this.subsList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  DestroyIndex(index) {
    if (this.subsList[index]) {
      this.subsList[index].unsubscribe();
    }
  }
}
