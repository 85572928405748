import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ApiRequestService } from '../../../../core/services';
import { UnitService } from '../../../../modules/units/services';

@Component({
  selector: 'unit-service-clone-form',
  templateUrl: './unit-form-service-clone-form.component.html',
  styleUrls: ['./unit-form-service-clone-form.component.scss'],
})
export class UnitFormServiceCloneFormComponent {
  public myGroup: UntypedFormGroup;
  posting: boolean;
  public unitService: UnitService;
  @Input() serviceId: string;
  // cid = "units-service-"
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @Input() unitID?: any;

  constructor(
    unitService: UnitService,
    private ApiRequestService: ApiRequestService
  ) {
    this.unitService = unitService;
    this.myGroup = new UntypedFormGroup({
      unitsList: new UntypedFormControl(),
    });
  }

  get fields(): UntypedFormArray {
    return this.myGroup.get('unitsList') as UntypedFormArray;
  }

  get form_fields(): any {
    return {
      unitsList: [Validators.required],
    };
  }

  modalClose($event: any) {
    this.closeForm.next($event);
  }

  onSubmit() {
    const payload = {
      units: this.fields.value,
      service_id: this.serviceId,
    };

    this.ApiRequestService.authPost(
      'unit_services/clone_service',
      payload
    ).subscribe({
      next: (res) => {
        if (res['status_code'] === 200) {
          this.modalClose({
            success: true,
            type: 'success',
            msg: 'services.service_is_cloned',
          });
        } else {
          this.unitService.alert('services.service_is_duplicated');
        }
      },
    });
  }
}
