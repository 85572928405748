import { AppModel } from '../../../common/classes';

export class CustomField extends AppModel {
  private _resource_type: string;
  private _key: string;
  private _type: string;
  private _typeEn: string;
  private _typeAr: string;
  private _default_value: string;
  private _measurement_unit: string;
  private _apply_edits_on_units: boolean;
  private _user_id: string;
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;
  private _is_used_in_units: boolean;
  private _unitIds: string[] = [];

  protected copyFields = [
    'id',
    'value',
    'resource_type',
    'key',
    'type',
    'typeEn',
    'typeAr',
    'default_value',
    'measurement_unit',
    'apply_edits_on_units',
    'user_id',
    'deleted_at',
    'users_list',
    'is_used_in_units',
    'unitIds',
  ];
  protected createFields = [
    'value',
    'resource_type',
    'key',
    'type',
    'typeEn',
    'typeAr',
    'default_value',
    'measurement_unit',
    'apply_edits_on_units',
    'users_list',
    'is_used_in_units',
    'unitIds',
  ];
  protected updateFields = [
    'id',
    'value',
    'resource_type',
    'key',
    'type',
    'typeEn',
    'typeAr',
    'default_value',
    'measurement_unit',
    'apply_edits_on_units',
    'users_list',
    'is_used_in_units',
    'unitIds',
  ];

  get resource_type(): string {
    return this._resource_type;
  }

  set resource_type(value: string) {
    this._resource_type = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get typeEn(): string {
    return this._typeEn;
  }

  set typeEn(value: string) {
    this._typeEn = value;
  }

  get typeAr(): string {
    return this._typeAr;
  }

  set typeAr(value: string) {
    this._typeAr = value;
  }
  get default_value(): string {
    return this._default_value;
  }

  set default_value(value: string) {
    this._default_value = value;
  }
  get measurement_unit(): string {
    return this._measurement_unit;
  }

  set measurement_unit(value: string) {
    this._measurement_unit = value;
  }

  get apply_edits_on_units(): boolean {
    return this._apply_edits_on_units;
  }

  set apply_edits_on_units(value: boolean) {
    this._apply_edits_on_units = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get unitIds(): string[] {
    return this._unitIds;
  }

  set unitIds(value: string[]) {
    this._unitIds = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get is_used_in_units(): boolean {
    return this._is_used_in_units;
  }

  set is_used_in_units(value: boolean) {
    this._is_used_in_units = value;
  }
}
