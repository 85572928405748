import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import {
  AfaqyAPIResponse,
  AfaqyResponse,
} from '../../../core/classes/afaqy-response';
import { catchError, map } from 'rxjs/operators';
import { EventStatus } from '../models/event-status';

@Injectable()
export class EventStatusService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  // statusList() {
  //     return this.getOptionsList('event_status');
  // }

  routerPrefix(val: string = '') {
    return val ? val : 'event_status';
  }

  get modelInstance() {
    return new EventStatus();
  }

  bindToUnit(status_id, unit_id) {
    let params: {} = { status_id: status_id, unit_id: unit_id };
    return this.apiRequest.authPost('units/set_status', params).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'name',
        minWidth: 75,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [
          {
            header: 'assign_user',
            permissions: this.cid + '-assign',
            code: 'assign',
            faclass: 'fa-link',
          },
          {
            header: 'unassign_user',
            permissions: this.cid + '-unassign',
            code: 'unassign',
            faclass: 'fa-unlink',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-status';
  }
}
