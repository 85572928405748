import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProtocolsService } from '../../protocols/services/protocols.service';

@Injectable()
export class ProtocolsResolver implements Resolve<any> {
  constructor(public service: ProtocolsService) {}

  resolve() {
    return this.service.systemProtocolsList();
  }
}
