import { AppModel } from '../../../common/classes';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';

export class Driver extends AppModel {
  private _name: string;
  private _name_ar: string;
  private _nid: string;
  private _ibtn: string;
  private _rfid: string;
  private _desc: string;
  private _address: string;
  private _phone: string;
  private _email: string;
  private _username: string;
  private _password: string;
  private _img: string;
  private _imgUrl: string;
  private _user_id: string;
  private _created: Date;
  private _deleted_at: Date;
  private _groupsList: string[];
  private _zone_id: string;
  private _driver_nationality: string;
  private _driver_id_number: string;
  private _driver_id_expiration: string;
  private _driver_insurance_company: string;
  private _driver_insurance_policy_no: string;
  private _driver_insurance_expiration_date: string;
  private _driver_type: string;
  private _contract_no: string;
  private _supplier: string;
  private _contract_start_date: string;
  private _contract_end_date: string;
  private _tamm_authorization_no: string;
  private _tamm_authorization_expiration_date: string;
  private _unit: any;

  protected copyFields = [
    'id',
    'name',
    'name_ar',
    'nid',
    'ibtn',
    'rfid',
    'desc',
    'address',
    'phone',
    'email',
    'img',
    'imgUrl',
    'created',
    'user_id',
    'username',
    'password',
    'created',
    'deleted_at',
    'users_list',
    'groupsList',
    'zone_id',
    'driver_nationality',
    'driver_id_number',
    'driver_id_expiration',
    'driver_insurance_company',
    'driver_insurance_policy_no',
    'driver_insurance_expiration_date',
    'driver_type',
    'contract_no',
    'supplier',
    'contract_start_date',
    'contract_end_date',
    'tamm_authorization_no',
    'tamm_authorization_expiration_date',
    'unit',
  ];
  protected createFields = [
    'name',
    'name_ar',
    'nid',
    'ibtn',
    'rfid',
    'desc',
    'address',
    'phone',
    'email',
    'username',
    'password',
    'users_list',
    'groupsList',
    'zone_id',
    'driver_nationality',
    'driver_id_number',
    'driver_id_expiration',
    'driver_insurance_company',
    'driver_insurance_policy_no',
    'driver_insurance_expiration_date',
    'driver_type',
    'contract_no',
    'supplier',
    'contract_start_date',
    'contract_end_date',
    'tamm_authorization_no',
    'tamm_authorization_expiration_date',
    'unit',
  ];
  protected updateFields = [
    'name',
    'name_ar',
    'nid',
    'ibtn',
    'rfid',
    'desc',
    'address',
    'phone',
    'email',
    'username',
    'password',
    'users_list',
    'groupsList',
    'zone_id',
    'driver_nationality',
    'driver_id_number',
    'driver_id_expiration',
    'driver_insurance_company',
    'driver_insurance_policy_no',
    'driver_insurance_expiration_date',
    'driver_type',
    'contract_no',
    'supplier',
    'contract_start_date',
    'contract_end_date',
    'tamm_authorization_no',
    'tamm_authorization_expiration_date',
    'unit',
  ];

  get groupsList(): string[] {
    return this._groupsList;
  }

  set groupsList(value: string[]) {
    this._groupsList = value;
  }

  get name(): string {
    return this._name;
  }

  get name_ar(): string {
    return this._name_ar;
  }

  get nid(): string {
    return this._nid;
  }
  get ibtn(): string {
    return this._ibtn;
  }

  get rfid(): string {
    return this._rfid;
  }

  get desc(): string {
    return this._desc;
  }

  get address(): string {
    return this._address;
  }

  get phone(): string {
    return this._phone;
  }

  get email(): string {
    return this._email;
  }

  get img(): string {
    return this._img;
  }

  get user_id(): string {
    return this._user_id;
  }

  get created(): Date {
    return this._created;
  }

  get password(): string {
    return this._password;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  set name(value: string) {
    this._name = value;
  }

  set name_ar(value: string) {
    this._name_ar = value;
  }

  set nid(value: string) {
    this._nid = value;
  }

  set ibtn(value: string) {
    this._ibtn = value;
  }

  set rfid(value: string) {
    this._rfid = value;
  }

  set desc(value: string) {
    this._desc = value;
  }

  set address(value: string) {
    this._address = value;
  }

  set phone(value: string) {
    this._phone = value;
  }

  set email(value: string) {
    this._email = value;
  }

  set password(value: string) {
    this._password = value;
  }

  set img(value: string) {
    this._img = value;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  set created(value: Date) {
    this._created = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get imgUrl(): string {
    if (this._img) {
      return this._imgUrl;
    } else {
      return AfaqyHelper.getDefaultImagePath('p');
    }
  }

  set imgUrl(value: string) {
    this._imgUrl = value;
  }

  get zone_id(): string {
    return this._zone_id;
  }

  set zone_id(value: string) {
    this._zone_id = value;
  }

  get driver_nationality(): string {
    return this._driver_nationality;
  }

  set driver_nationality(value: string) {
    this._driver_nationality = value;
  }

  get driver_id_number(): string {
    return this._driver_id_number;
  }

  set driver_id_number(value: string) {
    this._driver_id_number = value;
  }

  get driver_id_expiration(): string {
    return this._driver_id_expiration;
  }

  set driver_id_expiration(value: string) {
    this._driver_id_expiration = value;
  }

  get driver_insurance_company(): string {
    return this._driver_insurance_company;
  }

  set driver_insurance_company(value: string) {
    this._driver_insurance_company = value;
  }

  get driver_insurance_policy_no(): string {
    return this._driver_insurance_policy_no;
  }

  set driver_insurance_policy_no(value: string) {
    this._driver_insurance_policy_no = value;
  }

  get driver_insurance_expiration_date(): string {
    return this._driver_insurance_expiration_date;
  }

  set driver_insurance_expiration_date(value: string) {
    this._driver_insurance_expiration_date = value;
  }

  get driver_type(): string {
    return this._driver_type;
  }

  set driver_type(value: string) {
    this._driver_type = value;
  }

  get contract_no(): string {
    return this._contract_no;
  }

  set contract_no(value: string) {
    this._contract_no = value;
  }

  get supplier(): string {
    return this._supplier;
  }

  set supplier(value: string) {
    this._supplier = value;
  }

  get contract_start_date(): string {
    return this._contract_start_date;
  }

  set contract_start_date(value: string) {
    this._contract_start_date = value;
  }

  get contract_end_date(): string {
    return this._contract_end_date;
  }

  set contract_end_date(value: string) {
    this._contract_end_date = value;
  }

  get tamm_authorization_no(): string {
    return this._tamm_authorization_no;
  }

  set tamm_authorization_no(value: string) {
    this._tamm_authorization_no = value;
  }

  get tamm_authorization_expiration_date(): string {
    return this._tamm_authorization_expiration_date;
  }

  set tamm_authorization_expiration_date(value: string) {
    this._tamm_authorization_expiration_date = value;
  }

  get unit(): any {
    return this._unit;
  }

  set unit(value: any) {
    this._unit = value;
  }
}
