
<ng-container [ngSwitch]="type">
    <div
      *ngSwitchCase="'general'"
      [class.has-danger]="
                  (currentControl?.dirty ||
                    currentControl?.touched) &&
                  currentControl?.errors
                "
      class="input-group form-group-hint"
    >
      <input
        class="form-control form-control-sm"
        [formControl]="currentControl"
      />
      <control-errors-hint
        *ngIf="showControlError"
        [ctl]="currentControl"
      ></control-errors-hint>
    </div>
    <div
      *ngSwitchCase="'email'"
      [class.has-danger]="
                  (currentControl?.dirty ||
                    currentControl?.touched) &&
                  currentControl?.errors
                "
      class="input-group form-group-hint"
    >
      <input
        class="form-control form-control-sm"
        [formControl]="currentControl"
      />
      <control-errors-hint
        *ngIf="showControlError"
        [ctl]="currentControl"
      ></control-errors-hint>
    </div>
    <div
      *ngSwitchCase="'phone'"
      [class.has-danger]="
                  (currentControl?.dirty ||
                    currentControl?.touched) &&
                  currentControl?.errors
                "
      class="input-group form-group-hint"
    >
      <input
        class="form-control form-control-sm"
        [formControl]="currentControl"
      />
      <control-errors-hint
        *ngIf="showControlError"
        [ctl]="currentControl"
      ></control-errors-hint>
    </div>
    <div
      *ngSwitchCase="'date'"
      [class.has-danger]="
                    currentControl?.touched &&
                  currentControl?.errors
                "
      class="input-group form-group-hint"
    >
      <wj-input-date
        class="form-control form-control-sm"
        [formControl]="currentControl"
        [format]="'yyyy-MM-dd'"
      >
      </wj-input-date>
      <control-errors-hint *ngIf="showControlError"
        [ctl]="currentControl"
      ></control-errors-hint>
    </div>


</ng-container>

