<div class="unit-tooltip-container" *ngIf="unit">
  <div class="card forecolor">
    <div class="tooltip-card-header">
      <div class="card-header d-flex">
        <div class="col-auto p-0">
          <div class="unit-img-container">
            <div
              class="status {{
                unitService?.resourceTrackingDetails[unitId]?.motionIconState
              }}"
            >
              <i class="afaqy-icon-circle-top"></i>
            </div>
            <!--  ICON -->
            <div
              class="d-flex flex-row justify-content-center align-items-center rounded-circle unit-img"
            >
              <div class="unit_icon container_box">
                <img class="img-fluid" alt="Unit Image" [src]="unit?.iconUrl" />
              </div>
            </div>
            <!-- END ICON -->
          </div>
        </div>
        <div class="col pl-3 pr-0">
          <!-- NAME -->
          <h5 class="unit-name">
            {{ getUnitNameWithPlateNumber() }}
          </h5>
          <!-- Event Desc -->
          <h5 class="event-desc" *ngIf="this.isEventPopUpStyle">
            <i class="afaqy-icon-notifications icon-14px">
              {{ this.EventData.desc }}</i
            >
          </h5>
          <!-- END NAME -->
          <!-- Time -->
          <h6
            class="update-time pb-0"
            [timeago]="unit?.last_update.dtt"
            limitDays="60"
            [attr.testid]="'last_update'"
            *ngIf="!this.isEventPopUpStyle"
          >
            <i class="afaqy-icon-tracking icon-12px"></i>
          </h6>
          <h6
            class="update-time pb-0"
            *ngIf="this.isEventPopUpStyle"
            [attr.testid]="'last_update'"
          >
            <i class="afaqy-icon-clock icon-14px">
              {{ this.EventData.pos.dtt | afaqyDate }}
            </i>
          </h6>
          <!-- Time -->
          <!-- Address -->
          <h6 class="location address" *ngIf="!this.isEventPopUpStyle">
            <i class="afaqy-icon-tracking icon-12px px-2"></i>
            <span
              class="pointer"
              [attr.testid]="'unit_address'"
              *ngIf="unitService.resourceTrackingDetails[unitId]['address']"
              (click)="navigateToGoogleMaps()"
            >
              {{
                unitService.resourceTrackingDetails[unitId]['address'].length >
                80
                  ? unitService.resourceTrackingDetails[unitId][
                      'address'
                    ].substring(0, 80) + '...'
                  : unitService.resourceTrackingDetails[unitId]['address']
              }}
            </span>
            <span
              class="pointer"
              [attr.testid]="'unit_address'"
              *ngIf="!unitService.resourceTrackingDetails[unitId]['address']"
              (click)="navigateToGoogleMaps()"
            >
              {{ 'fetching_address' | translate }}
            </span>
          </h6>
          <h6
            class="location address"
            *ngIf="this.isEventPopUpStyle"
            (click)="
              navigateToGoogleMapsUsingEventData(
                this.EventData.pos.loc.coordinates[1],
                this.EventData.pos.loc.coordinates[0]
              )
            "
          >
            <i class="afaqy-icon-tracking icon-14px"></i>
            {{ this.EventData.pos.loc.coordinates[1] }} ,
            {{ this.EventData.pos.loc.coordinates[0] }}
          </h6>
          <!-- Address -->

          <!-- Last Known Location -->
          <ng-container
            *ngIf="
              !unitService.resourceTrackingDetails[unitId]['validLatLng'] &&
              unit?.last_update?.dtt &&
              unit?.last_update?.lastloc?.lat &&
              unit?.last_update?.lastloc?.lng
            "
          >
            <h6 class="location address">
              <mat-icon
                class="avl-icon last-known-icon px-2"
                [svgIcon]="'last-known-icon'"
                [attr.size]="'12px'"
              ></mat-icon>
              <span class="px-3">
                {{ 'last_known_location' | translate }}
              </span>

              <ng-container
                *ngIf="
                  unitService.resourceTrackingDetails[unitId][
                    'lastKnownAddress'
                  ];
                  else loadingLastKnownLocation
                "
              >
                <span
                  class="pointer"
                  [attr.testid]="'unit_address'"
                  (click)="
                    navigateToGoogleMapsUsingEventData(
                      unit?.last_update?.lastloc?.lat,
                      unit?.last_update?.lastloc?.lng
                    )
                  "
                >
                  {{
                    unitService.resourceTrackingDetails[unitId][
                      'lastKnownAddress'
                    ].length > 80
                      ? unitService.resourceTrackingDetails[unitId][
                          'lastKnownAddress'
                        ].substring(0, 80) + '...'
                      : unitService.resourceTrackingDetails[unitId][
                          'lastKnownAddress'
                        ]
                  }}
                </span>
              </ng-container>
              <ng-template #loadingLastKnownLocation>
                <span
                  class="pointer"
                  [attr.testid]="'unit_address'"
                  (click)="
                    navigateToGoogleMapsUsingEventData(
                      unit?.last_update?.lastloc?.lat,
                      unit?.last_update?.lastloc?.lng
                    )
                  "
                >
                  {{ 'fetching_address' | translate }}
                </span>
              </ng-template>
            </h6>
          </ng-container>

          <!-- Last Known Location -->
        </div>
      </div>

      <div class="row bg-primary-odd m-0" *ngIf="!isEventPopUpStyle">
        <div
          class="breakText col d-flex align-items-center col-4"
          [attr.testid]="'unit-speed'"
        >
          <span>
            <i class="afaqy-icon-speed icon-28px"></i>
            {{ unit?.last_update.spd }}
            {{ 'measurements.km' | translate }}
            /{{ 'measurements.h' | translate }}
          </span>
        </div>
        <div
          class="breakText col d-flex align-items-center col-4"
          [attr.testid]="'unit-angle'"
        >
          <span>
            <i class="afaqy-icon-compass icon-28px"></i>
            {{ unit?.last_update.ang }} &deg;
          </span>
        </div>
        <div
          class="breakText col d-flex align-items-center col-4"
          [attr.testid]="'unit-sealevel'"
        >
          <span>
            <i class="afaqy-icon-sea-level icon-28px"></i>
            {{ unit?.last_update.alt }} {{ 'measurements.m' | translate }}</span
          >
        </div>
      </div>
      <div class="row bg-primary-even m-0" *ngIf="!isEventPopUpStyle">
        <div
          class="breakText col d-flex align-items-center col-4"
          [attr.testid]="'unit-enginehours'"
        >
          <span>
            <i class="afaqy-icon-car-key icon-28px"></i>
            {{
              (unit?.counters.engine_hours
                ? unit?.counters.engine_hours / 3600
                : 0
              ) | afaqyRound
            }}
            {{ 'measurements.h' | translate }}
          </span>
        </div>
        <div
          class="breakText col d-flex align-items-center col-4"
          [attr.testid]="'unit-odometer'"
        >
          <span>
            <i class="afaqy-icon-car icon-28px"></i>
            {{
              unit?.counters.odometer ? unit?.counters.odometer.toFixed(0) : 0
            }}
            {{ 'measurements.km' | translate }}
          </span>
        </div>
        <div
          class="breakText col d-flex align-items-center col-4"
          [attr.testid]="'unit-satalite'"
        >
          <span>
            <i class="afaqy-icon-satalite-signal icon-28px"></i>
            {{
              unit?.last_update.prms['sat'] ? unit?.last_update.prms['sat'] : 0
            }}
          </span>
        </div>
      </div>
      <div
        class="row bg-primary-odd m-0"
        *ngIf="
          unitService.resourceTrackingDetails[unitId]['nearest_zone'] &&
          !isEventPopUpStyle
        "
      >
        <div
          class="breakText col d-flex align-items-center col-6"
          [attr.testid]="'unit-nearest_zone'"
        >
          <i class="afaqy-icon-zone-geolocation icon-28px"></i>
          <div class="d-flex flex-column">
            <span>{{
              unitService.resourceTrackingDetails[unitId]['nearest_zone']['n']
            }}</span>
            <span class="n-distance">{{
              unitService.resourceTrackingDetails[unitId]['nearest_zone']['d']
            }}</span>
          </div>
        </div>
        <div
          class="breakText col d-flex align-items-center col-6 geofences"
          [attr.testid]="'unit-nearest_marker'"
          *ngIf="unitService.resourceTrackingDetails[unitId]['nearest_marker']"
        >
          <i class="afaqy-icon-building-landmark icon-28px"></i>
          <!-- <p *ngIf="!unitService.resourceTrackingDetails[unitId]['nearest_marker']['icon_url']" class="sm-icons"><i class="fa fa-flag-o"></i></p> -->
          <!-- <img *ngIf="unitService.resourceTrackingDetails[unitId]['nearest_marker']['icon_url']" class="list-icon" src="{{ unitService.resourceTrackingDetails[unitId]['nearest_marker']['icon_url'] }}"> -->
          <div class="d-flex flex-column">
            <span>{{
              unitService.resourceTrackingDetails[unitId]['nearest_marker']['n']
            }}</span>
            <span class="n-distance">{{
              unitService.resourceTrackingDetails[unitId]['nearest_marker']['d']
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="!isEventPopUpStyle">
      <div class="row m-0 tablelike text-dark">
        <!--Start Tracker Date > Using Date Pipe-->
        <div
          class="col col-6"
          [attr.testid]="'unit-date_tracker'"
          *ngIf="unitAdditionalInfo.dtt[index] && unit?.last_update.dtt"
        >
          <span class="font-weight-bold">
            {{ 'monitoring.date_tracker' | translate }}:
          </span>
          <span>{{ unit?.last_update.dtt | afaqyDate }}</span>
        </div>
        <!--End Tracker Date > Using Date Pipe-->
        <!--Start Server Date > Using Date Pipe -->
        <div
          class="col col-6"
          [attr.testid]="'unit-date_server'"
          *ngIf="unitAdditionalInfo.dts[index] && unit?.last_update.dts"
        >
          <span class="font-weight-bold">
            {{ 'monitoring.date_server' | translate }}:
          </span>
          <span>{{ unit?.last_update.dts | afaqyDate }}</span>
        </div>
        <!--End Server Date > Using Date Pipe -->
        <div
          class="col col-6"
          [attr.testid]="'unit-imei'"
          *ngIf="hasDeviceAdmin && unitAdditionalInfo.imei[index]"
        >
          <span class="font-weight-bold">
            {{ 'units.imei' | translate }}:
          </span>
          <span>{{ unit?.imei }}</span>
        </div>
        <div
          class="col col-6"
          [attr.testid]="'unit-sim'"
          *ngIf="hasDeviceAdmin && unitAdditionalInfo.sim[index]"
        >
          <span class="font-weight-bold"> {{ 'units.sim' | translate }}: </span>
          <span>{{ unit?.sim_number }}</span>
        </div>
        <div
          class="col col-6"
          [attr.testid]="'unit-device'"
          *ngIf="hasDeviceAdmin && unitAdditionalInfo.device[index]"
        >
          <span class="font-weight-bold">
            {{ 'units.device' | translate }}:
          </span>
          <span>{{ unit?.device }}</span>
        </div>
        <div
          class="col col-6"
          [attr.testid]="'unit-statue_id'"
          *ngIf="unit?.status_id"
        >
          <span class="font-weight-bold">
            {{ 'units.status_id' | translate }}:
          </span>
          <span>{{ unit?.status.name }}</span>
        </div>

        <div class="col col-6" [attr.testid]="'unit-company'">
          <span class="font-weight-bold">
            {{ 'units.company' | translate }}:
          </span>
          <span>{{ unit?.company }}</span>
        </div>
        <div class="col col-6" [attr.testid]="'unit-operation_code'">
          <span class="font-weight-bold">
            {{ 'units.operation_code' | translate }}:
          </span>
          <span>{{ unit?.operation_code }}</span>
        </div>
      </div>
      <div class="row m-0 text-dark">
        <!-- Driver -->
        <div
          class="col-6"
          *ngIf="unit?.driver.id && unitAdditionalInfo.driver[index]"
        >
          <div class="row tablelike">
            <div class="col col-12 title font-weight-bold">
              {{ 'units.driver_information' | translate }}
            </div>
            <div class="col col-12" *ngIf="unit?.driver.imgUrl">
              <img
                class="alignPadding py-2"
                width="45"
                height="45"
                [src]="unit?.driver.imgUrl"
                [alt]="unit?.driver.name"
              />
            </div>
            <div class="col col-12" *ngIf="unit?.driver.name">
              <span class="font-weight-bold">
                {{ 'monitoring.name' | translate }}:
              </span>
              {{ unit?.driver.name }}
            </div>
            <div class="col col-12" *ngIf="unit?.driver.phone">
              <span class="font-weight-bold">
                {{ 'monitoring.phone' | translate }}:
              </span>
              {{ unit?.driver.phone }}
            </div>
          </div>
        </div>
        <!-- Trailer -->
        <div
          class="col-6"
          *ngIf="unit?.tailer.id && unitAdditionalInfo.trailer[index]"
        >
          <div class="row tablelike">
            <div class="col col-12 title font-weight-bold">
              {{ 'units.trailer_information' | translate }}
            </div>
            <div class="col col-12" *ngIf="unit?.tailer.imgUrl">
              <img
                class="alignPadding py-2"
                width="45"
                height="45"
                [src]="unit?.tailer.imgUrl"
                [alt]="unit?.tailer.name"
              />
            </div>
            <div class="col col-12" *ngIf="unit?.tailer.name">
              <span class="font-weight-bold">
                {{ 'monitoring.name' | translate }}:
              </span>
              {{ unit?.tailer.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- sensors -->

      <div class="row m-0 tablelike text-dark" *ngIf="showAllSensors()">
        <div class="col col-12 title">{{ 'units.sensors' | translate }}</div>
        <ng-container *ngFor="let unitSensor of unit?.sensors">
          <div *ngIf="unitSensor.tooltip_show" class="col col-6">
            <span class="font-weight-bold">
              <i class="{{ unitSensor.icon }}"></i> {{ unitSensor.name }}:
            </span>
            <span>
              {{ processSensor(unitSensor) }}
              <small *ngIf="showSensorLastUpdateTime(unitSensor)"
                >(<span [timeago]="getSensorLastUpdateTime(unitSensor)"></span
                >)</small
              >
            </span>
          </div>
        </ng-container>
      </div>
      <!-- services -->
      <div
        class="row m-0 tablelike text-dark"
        *ngIf="unit?.services.length > 0 && unitAdditionalInfo.services[index]"
      >
        <div class="col col-12 title">{{ 'units.services' | translate }}</div>
        <div class="col col-12" *ngFor="let unitServices of unit?.services">
          <span class="font-weight-bold">{{ unitServices.name }}:</span>
          <div [innerHtml]="unitServices.status"></div>
        </div>
      </div>
      <!-- Custom Fields -->
      <div
        class="row m-0 tablelike text-dark"
        *ngIf="
          unit?.custom_fields.length > 0 &&
          unit?.custom_fields &&
          unitAdditionalInfo.custom_fields[index]
        "
      >
        <div class="col col-12 title">
          {{ 'units.custom_fields_list' | translate }}
        </div>
        <div class="col col-6" *ngFor="let cf of unit?.custom_fields">
          <span class="font-weight-bold"> {{ cf.key }}: </span>
          <span>{{ cf.value }}</span>
        </div>
      </div>
      <!-- Signal Params -->
      <div
        class="row m-0 tablelike text-dark"
        *ngIf="!emptyprms && unitAdditionalInfo.signal_prams[index]"
      >
        <div class="col col-12 title">
          {{ 'units.signal_params' | translate }}
        </div>
        <div
          class="col col-6"
          *ngFor="let param of unit?.last_update.chPrams | keyvalue"
        >
          <span class="font-weight-bold">{{ param.key }}: </span>
          <span>
            {{ param.value ? param.value.v : 'N/A' }}
            <small *ngIf="unitAdditionalInfo.prams_time[index] && param.value"
              >(<span [timeago]="param.value.cdt"></span>)</small
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
