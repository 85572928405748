import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'afaqy-time-picker',
  templateUrl: './time-picker.component.html',
})
export class TimePickerComponent implements OnInit, AfterViewInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() ctl: UntypedFormControl;
  @Input() ctlName: string;
  @Input() ctlLabel: string;
  private defaultValue: any;
  selectedLang: string;

  constructor(protected translate: TranslateService) {
    this.selectedLang = this.translate.currentLang;
  }

  ngOnInit() {}

  resetValue() {
    if (this.ctl.hasError) {
      const ctrError: any = this.ctl.errors;
      if (ctrError) {
        if (ctrError.invalid_time && this.ctl.value)
          this.ctl.setValue(this.defaultValue);
      }
    }
  }

  ngAfterViewInit() {
    this.defaultValue = this.ctl.value;
  }
}
