import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { asArray } from 'ol/color';
import { getPointResolution, METERS_PER_UNIT } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { MapService } from '../../map/map.service';
import { MapModel } from '../../map/mapModel';
import { MapLayerService } from '../../map/map_layer.service';
import { Warehouses } from '../models/warehouses';
import { WarehousesService } from './warehouses.service';

@Injectable()
export class WarehousesMapLayerService extends MapLayerService {
  layerId = 'warehouses';
  features = {};
  constructor(
    mapService: MapService,
    protected warehousesService: WarehousesService,
    translate: TranslateService
  ) {
    super(mapService, warehousesService, translate);
    warehousesService.resources.subscribe({
      next: (res) => {
        switch (res.action) {
          case 'list':
          case 'addMulti':
            const warehouses: Warehouses[] = res.data;
            this.generateFeatures(warehouses);
            break;
          case 'update':
          case 'add':
            if (!res.relations) {
              const warehouse: Warehouses = res.data;
              const feature = this.createFeature(warehouse);
              this.features[warehouse.id] = feature;
              if (warehouse['visible']) {
                this.updateFeature(warehouse.id, this.features[warehouse.id]);
              }
            }
            break;
          case 'remove':
            for (const id of res.data) {
              this.removeFeature(id);
              delete this.features[id];
            }
            break;
          case 'visible':
            if (
              !(this.features instanceof Object) ||
              !Object.keys(this.features).length
            )
              this.generateFeatures();
            this.refresh();
            break;
          case 'refresh':
            this.removeAllFeatures();
            this.features = {};
            break;
        }
      },
    });
  }

  createFeature(warehouse: Warehouses) {
    let feature: any = {};
    const style = this.createStyle(warehouse);
    this.mapService.action((mapModel: MapModel) => {
      switch (warehouse.type) {
        case 'circle':
          feature = mapModel.createCircle(
            warehouse.vertices[0],
            Math.ceil(
              this.recalculateMapRadius(mapModel.map, warehouse.radius)
            ),
            style
          );
          break;
        case 'polygon':
          feature = mapModel.createPolygon(warehouse.vertices, style);
          break;
        case 'line':
          feature = mapModel.createLine(warehouse.vertices, style);
          break;
      }
      feature.setId(warehouse.id);
      feature.set('label', warehouse.name);
      feature.set('maxResolutionForLabel', 1400);
    });

    return feature;
  }
  recalculateMapRadius(map, radius) {
    let view = map.getView();
    let projection = view.getProjection();
    let resolutionAtEquator = view.getResolution();
    let center = view.getCenter();
    let pointResolution = getPointResolution(
      projection,
      resolutionAtEquator,
      center
    );
    let resolutionFactor = resolutionAtEquator / pointResolution;
    radius = (radius / METERS_PER_UNIT.m) * resolutionFactor;

    return radius;
  }

  private createStyle(warehouse) {
    const textStyle = new Text({
      placement: 'point',
      font: 'bold 16px Arial',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    });
    return (feature, resolution) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      const color = asArray(warehouse.color);
      color[3] = 0.3;
      const style = new Style({
        text: textStyle,
        fill: new Fill({
          color: color,
          fillOpacity: 0.3,
        } as any),
        stroke: new Stroke({
          color: color,
          width: warehouse.type === 'line' ? warehouse.radius : 0,
        }),
      });

      return [style];
    };
  }
}
