import { RxStompConfig } from '@stomp/rx-stomp';
import { AppConfig } from '../../../common';
import * as SockJS from 'sockjs-client';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'any',
})
export class UnitsSocketConfig {
  sURL = AppConfig.WSStompUnitsURL;
  public sessionId = uuidv4();

  generateNewSessionId() {
    this.sessionId = uuidv4();
  }

  config: RxStompConfig = {
    // Which server?
    // brokerURL: sURL,
    webSocketFactory: () =>
      new SockJS(this.sURL, [], {
        sessionId: () => {
          return this.sessionId;
        },
      }),

    // Headers
    // Typical keys: login, passcode, host
    // connectHeaders: {
    //     login: 'guest',
    //     passcode: 'guest',
    // },

    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 0, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 500 (500 milli seconds)
    reconnectDelay: 5000,

    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    // debug: (msg: string): void => {
    //     console.log(new Date(), msg);
    // },
  };
}
