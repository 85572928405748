<section
  id="user-apps-container"
  *ngIf="authService?.user?.account_apps?.length || authService?.isAdmin"
>
  <!-- user apps button -->
  <button
    class="test-theme-color btn btn-link user-apps-btn clickOutsideExcept"
    (click)="toggleUserApps()"
    afaqy-tooltip
    [tooltipText]="'navbar.user_apps.title' | translate"
  >
    <i class="mdi mdi-grid icon-18px clickOutsideExcept test-theme-color"></i>
  </button>

  <!-- user apps -->
  <section
    id="user-apps"
    *ngIf="displayUserApps"
    clickOutside
    (clickOutside)="closeUserApps()"
  >
    <!-- header -->
    <h6>{{ 'navbar.user_apps.switch' | translate }}</h6>
    <!-- navigations -->
    <div id="user-apps-navigations">
      <div *ngFor="let userApp of userApps">
        <ng-container *ngIf="userApp.enable || authService.isAdmin">
          <button
            class="btn btn-link user-apps-btn"
            (click)="goToLink(userApp.url)"
          >
            <a afaqy-tooltip [tooltipText]="userApp.tooltipText | translate">
              <i *ngIf="!userApp.svgIcon" class="{{ userApp.iconClass }}"></i>
              <mat-icon
                *ngIf="userApp.svgIcon"
                class="avl-icon {{
                  userApp?.cssClass ? userApp?.cssClass : ''
                }}"
                [svgIcon]="userApp.iconClass"
                [attr.size]="'24px'"
              ></mat-icon>
            </a>
          </button>
        </ng-container>
      </div>
    </div>
  </section>
</section>
