import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components';
import { AuthGuard, SelectivePreloadingStrategy } from './core/services';
import { SharedLocationsSessionResolver } from 'app/shared-locations/services/shared-locations-session-resolver.service';
import { SessionsResolver } from './core/resolvers/session-resolver.service';

const appRoutes: Routes = [
  {
    path: 'auth',
    data: { permissions: [], preload: true },
    // canLoad: [() => inject(AuthGuard).canLoad],
    loadChildren: () =>
      import('app/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    data: { permissions: [], preload: true },
    canLoad: [() => inject(AuthGuard).canLoad],
    resolve: { user: SessionsResolver },
    loadChildren: () =>
      import('app/modules/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'portal',
    data: { permissions: [], preload: false },
    canLoad: [() => inject(AuthGuard).canLoad],
    loadChildren: () => import('app/cms.module').then((m) => m.CmsModule),
  },
  {
    path: 'share-locations',

    resolve: {
      user: SharedLocationsSessionResolver,
    },
    loadChildren: () =>
      import('app/shared-locations/shared-locations.module').then(
        (m) => m.SharedLocationsModule
      ),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      preloadingStrategy: SelectivePreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategy],
})
export class AppRoutingModule {}
