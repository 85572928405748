import { Component, HostListener, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../modules/users/models';
import { AppConfig, AfaqyHelper, Message } from '../../../../common/classes';
import { AuthService } from '../../../services/auth.service';
import { takeWhile } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
})
export class CmsComponent {
  alive: boolean = true;
  configs = AppConfig;
  tabs: any[] = [];
  formNewTab = false;
  tabIndex = 0;
  user: User;
  colors = [];
  syslang: string;
  sysColor: string;
  userTime = AfaqyHelper.getUserCurrentTime()?.clone();
  menu: any[] = [
    {
      code: 'units',
      label: 'menu.units',
      permissions: 'units-lists',
      icon: 'afaqy-icon-units',
    },
    {
      code: 'unit_groups',
      label: 'menu.unit_groups',
      permissions: 'units-lists',
      icon: 'afaqy-icon-units',
    },
    {
      code: 'users',
      label: 'menu.users',
      permissions: 'users-lists',
      icon: 'afaqy-icon-users',
    },
    {
      code: 'status',
      label: 'menu.status',
      permissions: 'status-lists',
      icon: 'afaqy-icon-status',
    },
    {
      code: 'event_status',
      label: 'menu.event_status',
      permissions: 'event_status-lists',
      icon: 'afaqy-icon-status',
    },
    {
      code: 'tailers',
      label: 'menu.tailers',
      permissions: 'tailers-lists',
      icon: 'afaqy-icon-trailers',
    },
    {
      code: 'package',
      label: 'menu.package',
      permissions: 'package-lists',
      icon: 'afaqy-icon-user-preferences',
    },
    {
      code: 'events',
      label: 'menu.events',
      permissions: 'events-lists',
      icon: 'afaqy-icon-events',
    },
    {
      code: 'drivers',
      label: 'menu.drivers',
      permissions: 'drivers-lists',
      icon: 'afaqy-icon-drivers',
    },
    {
      code: 'driver_groups',
      label: 'menu.driver_groups',
      permissions: 'drivers-lists',
      icon: 'afaqy-icon-drivers',
    },
    {
      code: 'roles_permissions',
      label: 'menu.roles_permissions',
      permissions: 'roles_permissions-lists',
      icon: 'afaqy-icon-user-preferences',
    },
    {
      code: 'protocol_commands',
      label: 'menu.protocol_commands',
      permissions: 'protocol_commands-lists',
      icon: 'afaqy-icon-user-preferences',
    },
    {
      code: 'protocols',
      label: 'menu.protocols',
      permissions: 'protocols-lists',
      icon: 'afaqy-icon-user-preferences',
    },
    {
      code: 'reports',
      label: 'menu.reports',
      permissions: '',
      icon: 'afaqy-icon-reports',
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    AfaqyHelper.calcModalHeight();
  }

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    protected router: Router
  ) {
    AfaqyHelper.cmsTabs.subscribe({
      next: (event) => {
        this.addNewTab(event.code, event.data);
      },
    });
    this.formNewTab = this.authService.preferences('cms', 'formsintab', true);
    this.user = authService.user;
    this.colors = authService.colors;

    this.syslang = translate.currentLang;
    this.colors = authService.colors;
    this.user = authService.user;
    if (this.authService.isLoggedIn) {
      this.updateTheme();
    } else {
      this.authService.loadedSession
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: () => {
            this.updateTheme();
            this.user = authService.user;
          },
        });
    }
    setInterval(() => {
      this.userTime = AfaqyHelper.getUserCurrentTime()?.clone();
    }, 1000);
  }

  addNewTab(code, data = {}): void {
    this.tabs.map((tab) => {
      tab.active = false;
      return tab;
    });
    this.tabIndex += 1;
    this.tabs.push({
      tidx: this.tabIndex,
      code: code,
      data: data,
      active: true,
    });
  }

  updateTheme() {
    this.updateColor();
  }

  fullscreen() {
    // window["screenfull"].toggle();
    setTimeout(function () {
      AfaqyHelper.windowResize('fullscreen');
    }, 1);
  }

  removeTabHandler(index): void {
    this.tabs.splice(index, 1);
    if (index !== 0) {
      this.tabs[index - 1].random = AfaqyHelper.afaqyRandom();
      this.tabs[index - 1].active = true;
    } else if (this.tabs.length != 0) {
      this.tabs[index].random = AfaqyHelper.afaqyRandom();
      this.tabs[index].active = true;
    }
  }

  close(tab) {
    const tabidx = this.tabs.findIndex((red) => {
      return tab.tidx === red.tidx;
    });
    this.removeTabHandler(tabidx);
  }

  selectTab(index) {
    this.tabs[index].active = true;
    this.tabs[index].random = AfaqyHelper.afaqyRandom();
  }

  toggle() {
    this.formNewTab = !this.formNewTab;
    this.authService.updatePreferences('cms', 'formsintab', this.formNewTab);
  }

  choose(newValue) {
    this.authService.color = newValue.target.value;
    this.updateColor();
  }

  updateColor() {
    const color = this.authService.color;
    for (const k in this.colors) {
      if (this.colors[k].name === color) {
        this.colors[k].active = true;
        this.sysColor = color;
        AfaqyHelper.updateThemeBodyClass(this.colors[k]);
      } else {
        document
          .getElementsByTagName('body')[0]
          .classList.remove('theme-' + this.colors[k].name);
        this.colors[k].active = false;
      }
    }
  }

  logout() {
    this.authService.redirectTOLogin('Home Logout');
  }
}
