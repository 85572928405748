import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { MapService } from '../../map/map.service';
import { MapModel } from '../../map/mapModel';
import { MapLayerService } from '../../map/map_layer.service';
import { Markers } from '../models/markers';
import { MarkersService } from './markers.service';

@Injectable()
export class MarkerMapLayerService extends MapLayerService {
  constructor(
    mapService: MapService,
    private markersService: MarkersService,
    translate: TranslateService
  ) {
    super(mapService, markersService, translate);
    markersService.resources.subscribe((res) => {
      switch (res.action) {
        case 'list':
          let markers: Markers[] = res.data;
          let features = [];
          for (let marker of markers) {
            let feature = this.createFeature(marker);
            this.features[marker.id] = feature;
            if (marker['visible']) {
              features.push(this.features[marker.id]);
            }
          }
          this.addFeatures(features);
          break;
        case 'update':
        case 'add':
          if (!res.relations) {
            let marker: Markers = res.data;
            let feature = this.createFeature(marker);
            this.features[marker.id] = feature;
            if (marker['visible']) {
              this.updateFeature(marker.id, this.features[marker.id]);
            }
          }
          break;
        case 'remove':
          for (let id of res.data) {
            this.removeFeature(id);
            delete this.features[id];
          }
          break;
        case 'visible':
          this.refresh();

          break;
        case 'refresh':
          this.removeAllFeatures();
          this.features = {};
          break;
      }
    });
  }

  layerId: string = 'markers';
  features = {};

  createFeature(marker) {
    let feature: any = {};
    this.mapService.action((mapModel: MapModel) => {
      feature = mapModel.createPoint(
        [marker.location.coordinates[0], marker.location.coordinates[1]],
        {}
      );
      feature.setId(marker.id);
      feature.set('label', marker.name);
      feature.set('maxResolutionForLabel', 1400);
      feature.setStyle(this.createStyle(marker));
    });
    return feature;
  }

  private createStyle(marker) {
    let textStyle = new Text({
      placement: 'point',
      font: 'bold 16px Arial',
      fill: new Fill({ color: '#000' }),
      offsetY: 22,
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    });
    let iconStyle = new Icon({
      src: marker.iconUrl,
      rotation: 0,
    });
    return (feature, resolution) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      let styles = [];
      styles.push(
        new Style({
          image: iconStyle,
          text: textStyle,
        })
      );
      return styles;
    };
  }
}
