import { AfaqyObject } from '../../../common/classes';
export class UnitShipment extends AfaqyObject {
  private _product_category: string;
  private _truck_type: string;
  private _shipment_type: string;
  private _shipment_payment_type: string;
  private _transporter_type: Number;
  private _operating_area: Number;
  private _weight_kilograms_per_day: Number;
  private _kms_per_day: Number;
  private _shipment_trip_drive_hrs_day: Number;
  private _allowable_delay_drive_hrs_day: Number;
  private _expected_shipment_trip_day: Number;
  private _car_pay_per_day: Number;
  private _exceeding_temperature_limit_per_day: Number;
  private _temperature_upper_limit: Number;
  private _exceeding_humidity_limit_per_day: Number;
  private _humidity_upper_limit: Number;

  protected copyFields = [
    'product_category',
    'truck_type',
    'shipment_type',
    'shipment_payment_type',
    'transporter_type',
    'operating_area',
    'weight_kilograms_per_day',
    'kms_per_day',
    'shipment_trip_drive_hrs_day',
    'allowable_delay_drive_hrs_day',
    'expected_shipment_trip_day',
    'car_pay_per_day',
    'exceeding_temperature_limit_per_day',
    'temperature_upper_limit',
    'exceeding_humidity_limit_per_day',
    'humidity_upper_limit',
  ];

  get transporter_type(): Number {
    return this._transporter_type;
  }

  set transporter_type(value: Number) {
    this._transporter_type = value;
  }

  get operating_area(): Number {
    return this._operating_area;
  }

  set operating_area(value: Number) {
    this._operating_area = value;
  }

  get weight_kilograms_per_day(): Number {
    return this._weight_kilograms_per_day;
  }

  set weight_kilograms_per_day(value: Number) {
    this._weight_kilograms_per_day = value;
  }

  get kms_per_day(): Number {
    return this._kms_per_day;
  }

  set kms_per_day(value: Number) {
    this._kms_per_day = value;
  }

  get shipment_trip_drive_hrs_day(): Number {
    return this._shipment_trip_drive_hrs_day;
  }

  set shipment_trip_drive_hrs_day(value: Number) {
    this._shipment_trip_drive_hrs_day = value;
  }

  get allowable_delay_drive_hrs_day(): Number {
    return this._allowable_delay_drive_hrs_day;
  }

  set allowable_delay_drive_hrs_day(value: Number) {
    this._allowable_delay_drive_hrs_day = value;
  }

  get product_category(): string {
    return this._product_category;
  }

  set product_category(value: string) {
    this._product_category = value;
  }
  get truck_type(): string {
    return this._truck_type;
  }

  set truck_type(value: string) {
    this._truck_type = value;
  }
  get shipment_type(): string {
    return this._shipment_type;
  }

  set shipment_type(value: string) {
    this._shipment_type = value;
  }
  get shipment_payment_type(): string {
    return this._shipment_payment_type;
  }

  set shipment_payment_type(value: string) {
    this._shipment_payment_type = value;
  }

  get expected_shipment_trip_day(): Number {
    return this._expected_shipment_trip_day;
  }

  set expected_shipment_trip_day(value: Number) {
    this._expected_shipment_trip_day = value;
  }
  get car_pay_per_day(): Number {
    return this._car_pay_per_day;
  }

  set car_pay_per_day(value: Number) {
    this._car_pay_per_day = value;
  }
  get exceeding_temperature_limit_per_day(): Number {
    return this._exceeding_temperature_limit_per_day;
  }

  set exceeding_temperature_limit_per_day(value: Number) {
    this._exceeding_temperature_limit_per_day = value;
  }
  get temperature_upper_limit(): Number {
    return this._temperature_upper_limit;
  }

  set temperature_upper_limit(value: Number) {
    this._temperature_upper_limit = value;
  }
  get exceeding_humidity_limit_per_day(): Number {
    return this._exceeding_humidity_limit_per_day;
  }

  set exceeding_humidity_limit_per_day(value: Number) {
    this._exceeding_humidity_limit_per_day = value;
  }
  get humidity_upper_limit(): Number {
    return this._humidity_upper_limit;
  }

  set humidity_upper_limit(value: Number) {
    this._humidity_upper_limit = value;
  }
}
