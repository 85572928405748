import { Injectable } from '@angular/core';
import { EventData } from '../../modules/events/models/event-data';
import { BehaviorSubject } from 'rxjs';
import { MapService } from '../../modules/map/map.service';
import { MapModel } from '../../modules/map/mapModel';
import { Overlay } from 'ol';

@Injectable()
export class NotificationDetailsService {
  private eventDataSource = new BehaviorSubject<EventData>(null);
  private mapService: MapService;
  private coordinates;

  expandedEvent = null;
  eventDetailsOverlay = null;

  constructor() {
    this.expandedEvent = this.eventDataSource.asObservable();
  }

  setMapService(mapService: MapService) {
    this.mapService = mapService;
  }

  setEventDetails(expandedEvent: EventData) {
    this.eventDataSource.next(expandedEvent);
    this.coordinates = expandedEvent.pos.loc.coordinates;
  }

  getEventDetails() {
    return this.expandedEvent;
  }

  displayNotification() {
    // display on map
    this.mapService.action((map: MapModel) => {
      // Convert coordinates to map points
      const mapPoints = map.lonLatToMapPoint(this.coordinates);
      let popup = document.getElementById('event-popup');
      this.eventDetailsOverlay = new Overlay({
        position: mapPoints,
        positioning: 'top-left',
        element: popup,
        stopEvent: false,
      });

      map.getMap().addOverlay(this.eventDetailsOverlay);
      map.getMap().getView().setCenter(mapPoints);
    });
  }

  hideNotification() {
    this.mapService.action((map: MapModel) => {
      // remove overlay from map
      //map.getMap().addOverlay(this.eventDetailsOverlay);
      this.eventDetailsOverlay.setPosition(undefined);
      const closer = document.getElementById('popup-closer');
      closer.blur();
      return false;
    });
  }
}
