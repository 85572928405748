import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { SensorColors } from '../../interfaces/sensor-colors.interfaces';
import { SensorColorsFormService } from '../sensor-colors-form/sensor-colors-form.service';
import { IntervalColorsService } from './interval-colors.service';

@Component({
  selector: 'interval-colors',
  templateUrl: './interval-colors.component.html',
  styleUrls: ['./interval-colors.component.scss'],
})
export class IntervalColorsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  alive: boolean = true;
  intervals: SensorColors;
  @ViewChildren('intervalFrom') intervalFromElements: QueryList<ElementRef>;

  constructor(
    private intervalColorsService: IntervalColorsService,
    private sensorColorsFormService: SensorColorsFormService
  ) {}

  ngOnInit(): void {
    this.setIntervals();
  }

  ngAfterViewInit(): void {
    this.setSpanMargin();
  }

  /** Subscribe to intervalColors and set its value to intervals variables. */
  private setIntervals(): void {
    this.intervalColorsService.intervalColors
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (value: SensorColors) => {
          if (!value) return;
          this.intervals = { ...value };
          setTimeout(() => {
            this.setSpanMargin();
          });
        },
      });
  }

  /** Set left-margin for every analog interval 'from span' element depends on its width. */
  private setSpanMargin(): void {
    if (!this.intervalFromElements) {
      return;
    }

    let span: HTMLElement;
    this.intervalFromElements.forEach((el: ElementRef) => {
      span = <HTMLElement>el.nativeElement;
      span.style.marginLeft = `-${span.clientWidth / 2}px`;
    });
  }

  /** Check if the received analog interval has the same values, return a boolean value. */
  hasSameValues(analogInterval: any): boolean {
    if (
      !analogInterval ||
      parseFloat(analogInterval.from) !== parseFloat(analogInterval.to)
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Close sensor color form if it's opened and open it with the param value.
   * @param value UnitSensorColor.
   */
  editInterval(value?: any): void {
    if (this.sensorColorsFormService.isOpen) {
      this.sensorColorsFormService.closeSensorForm();
    }
    this.sensorColorsFormService.openSensorForm(value);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
