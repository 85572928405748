import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'afaqy-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent implements OnInit {
  private _loading: boolean = false;

  @Input()
  set loading(value: boolean) {
    this._loading = value;
  }

  get loading(): boolean {
    return this._loading;
  }

  constructor() {}

  ngOnInit() {}
}
