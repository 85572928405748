import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/services';
import { combineLatest, fromEvent, merge, Subscription } from 'rxjs';
import { take, takeWhile, filter, map } from 'rxjs/operators';
import { AfaqyHelper, AppConfig } from '../../../common';
import { SessionStatusEnum } from '../../enums';

@Component({
  selector: 'app-validate-form',
  styleUrls: ['./validate-token.component.scss'],
  templateUrl: './validate-token.component.html',
})
export class ValidateTokenComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  isLoading = false;
  configs = AppConfig;
  eventSubscription: Subscription;
  backgroundEvent: Subscription;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: false,
    pauseOnHover: false,
  };

  slides = [
    {
      heading: 'Everything',
      text: 'We Calculate',
      image: 'assets/images/login-side-images/Illustration1.png',
    },
    {
      heading: 'Live Monitoring',
      text: 'Tracking Units with a',
      image: 'assets/images/login-side-images/Illustration3.png',
    },
    {
      heading: 'Accessible',
      text: 'All Accurate and',
      image: 'assets/images/login-side-images/Illustration2.png',
    },
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private router: Router
  ) {
    this.getAccessTokenFromAvlLogin();
    this.validateEvents();
  }

  ngOnInit(): void {}
  validateEvents() {
    this.backgroundEvent = fromEvent(document, 'visibilitychange').subscribe({
      next: (val: any) => {
        if (
          document.hidden &&
          this.authService._sessionStatusObserver.getValue() ==
            SessionStatusEnum.ACTIVE
        ) {
          this.router.navigate(['/']).then(() => {
            this.authService.preventBackButton();
            AfaqyHelper.clearBrowserHistory();
          });
        }
      },
    });

    this.eventSubscription = fromEvent(document, 'load').subscribe({
      next: (val: any) => {
        if (
          document.hidden &&
          this.authService._sessionStatusObserver.getValue() ==
            SessionStatusEnum.ACTIVE
        ) {
          this.router.navigate(['/']).then(() => {
            this.authService.preventBackButton();
            AfaqyHelper.clearBrowserHistory();
          });
        }
      },
    });

    /**
     * Observe on session status while loginAs function, in order to handle redirect behaviour
     */
    this.authService._sessionStatusObserver
      .pipe(
        takeWhile(() => this.alive),
        filter((status) => status !== SessionStatusEnum.LOADING)
      )
      .subscribe({
        next: (status) => {
          if (status === SessionStatusEnum.ACTIVE) {
            this.router.navigate(['/']).then(() => {
              this.authService.preventBackButton();
              AfaqyHelper.clearBrowserHistory();
            });
          } else if (status === SessionStatusEnum.INVALID) {
            // TODO handle invalid case, !!!TEMPORARY!!! navigate to login
            this.authService.redirectTo('/auth/login');
          } else {
            this.authService.redirectTo('/auth/login');
          }
        },
      });
  }

  getAccessTokenFromAvlLogin() {
    if (this.activatedRoute.snapshot.params.token) {
      this.getSessionDataWithNewToken(
        this.activatedRoute.snapshot.params.token
      );
    }
  }

  getSessionDataWithNewToken(token: any) {
    this.authService.setAuthScope('validate');
    this.authService.authToken = token;
    this.authService.loadSessionData('/', 'validate');
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.backgroundEvent) {
      this.backgroundEvent.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
