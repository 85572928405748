import { AfaqyObject } from '../../../common/classes';
export class UnitCCTV extends AfaqyObject {
  private _channels: number = 1;
  private _dvr_id: number;

  protected copyFields = ['dvr_id', 'channels'];

  get channels(): number {
    return this._channels;
  }

  set channels(value: number) {
    this._channels = value ? value : 1;
  }

  get dvr_id(): number {
    return this._dvr_id;
  }

  set dvr_id(value: number) {
    this._dvr_id = value;
  }
}
