<div class="adjustHeightModal">
  <afaqy-modal
    [title]="title"
    (modalClose)="modalClose($event)"
    #modal="afaqyModal"
    [sizeClass]="modalSizeClass"
    [draggable]="draggable"
    [hasbackground]="hasbackground"
  >
    <div class="modalbody">
      <div
        class="grid-container"
        id="FlexGridPopupSelect"
        *ngIf="columns.length"
      >
        <flash-message [message]="message"></flash-message>
        <afaqy-spinner [loading]="loading || posting"></afaqy-spinner>
        <wj-flex-grid
          #sgrid
          [itemsSource]="cvData"
          [selectionMode]="'Row'"
          [isReadOnly]="true"
          [allowSorting]="true"
        >
          <wj-flex-grid-filter
            [filterColumns]="filtersCols"
          ></wj-flex-grid-filter>
          <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'">
            <input
              *ngIf="is_assignation"
              type="checkbox"
              class="column_check"
              [(ngModel)]="isAllChecked"
              (change)="doToggleCheck($event.target.checked)"
            />
            <span *ngIf="!is_assignation">#</span>
          </ng-template>

          <ng-template
            wjFlexGridCellTemplate
            [cellType]="'RowHeader'"
            let-cell="cell"
            let-item="item"
          >
            <span class="row-header">
              <input
                *ngIf="is_assignation"
                type="checkbox"
                [checked]="item.checked"
                (change)="doUpdateIds($event, item.id, cell.row.index)"
                class="column_check"
              />
              <span *ngIf="!is_assignation">{{ cell.row.index + 1 }}</span>
            </span>
          </ng-template>

          <wj-flex-grid-column
            *ngFor="let col of columns"
            [allowSorting]="
              col.colValue == 'actions' || col.sorting === false ? false : true
            "
            [header]="col.header | translate"
            [binding]="col.colValue"
            [isReadOnly]="true"
            [minWidth]="col.minWidth ? col.minWidth : 100"
            [width]="col.width"
          >
            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              let-cell="cell"
              *ngIf="col.translate"
            >
              <span> {{ cell.item[col.colValue] | translate }}</span>
            </ng-template>

            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              let-cell="cell"
              *ngIf="col.type == 'boolean'"
            >
              <span>
                {{ cell.item | objectBoolean : col.colValue | translate }}</span
              >
            </ng-template>

            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              cellClass="class"
              let-item="item"
              *ngIf="col.colValue == 'actions'"
            >
              <div class="list-actions">
                <a
                  afaqy-tooltip
                  *ngIf="!is_assignation"
                  href="javascript:void(0)"
                  [tooltipText]="'tooltips.bind' | translate"
                  (click)="selectObject(item)"
                >
                  <i
                    [attr.testid]="'bind-btn'"
                    class="mdi mdi-content-save mdi-18px"
                  ></i>
                </a>
                <a
                  *ngIf="is_assignation"
                  href="javascript:void(0)"
                  (click)="doAssign($event, op, [item.id])"
                >
                  <i
                    *ngIf="op == 'assign'"
                    class="fa fa-link"
                    title="{{ 'headings.' + op | translate }}"
                  ></i>
                  <i
                    *ngIf="op == 'unassign'"
                    class="fa fa-unlink"
                    title="{{ 'headings.' + op | translate }}"
                  ></i>
                </a>
              </div>
            </ng-template>
          </wj-flex-grid-column>
        </wj-flex-grid>
      </div>
    </div>
    <div class="modalfooter">
      <button
        *ngIf="is_assignation"
        [disabled]="!multiple_ids.length && !isAllChecked"
        type="button"
        class="btn btn-sm btn-success btn-round-left"
        (click)="doAssignation(op)"
      >
        <i *ngIf="op == 'assign'" class="fa fa-link"></i>
        <i *ngIf="op == 'unassign'" class="fa fa-unlink"></i>
        {{ 'headings.' + op | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-warning"
        (click)="modal.closeModal()"
      >
        <i class="fa fa-close"></i> {{ 'Cancel' | translate }}
      </button>
      <button
        *ngIf="is_assignation && !config.isCMS"
        type="button"
        class="btn btn-sm btn-info btn-round-right"
        (click)="doRefresh()"
      >
        <i class="fa fa-refresh"></i> {{ 'refresh' | translate }}
      </button>
    </div>
  </afaqy-modal>
</div>
