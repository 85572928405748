import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Logger, AfaqyHelper } from '../../../common/classes';
import { AppConfig } from '../../../common/classes';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'afaqy-modal',
  exportAs: 'afaqyModal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class AfaqyModalComponent implements OnInit {
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.escape) {
      event.preventDefault();
      event.stopPropagation();
      this.closeModal({});
    }
  }

  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() hasCloseIcon: boolean = true;
  @Input() staticHeight: boolean = true;
  @Input() escape: boolean = true;
  @Input() draggable: boolean = true;
  @Input() hasbackground: boolean = true;
  @Input() insidetab: boolean = false;
  @Input() modalCentered: boolean = false;

  @ViewChild('demoHandle', { read: ElementRef }) handle: ElementRef;
  modalTitle = '';

  _sizeClass = '';
  height: number = window.innerHeight / 1.5;

  @Input()
  set title(value: string) {
    this.modalTitle = this.translate.instant(value);
  }

  @Input()
  set varietyTitle(value: string) {
    this.modalTitle = value;
  }

  @Input()
  set sizeClass(value: string) {
    this._sizeClass = value;
  }

  get sizeClass(): string {
    return this._sizeClass;
  }

  ngOnInit(): void {}

  configs = AppConfig;

  constructor(private elm: ElementRef, private translate: TranslateService) {}

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //     this.calcmodalHeight();
  // }
  //
  // calcmodalHeight() {
  //     const windowHeight = window.innerHeight;
  //     if (AfaqyHelper.isSmallWindow()) {
  //         this.height = windowHeight / 1.25;
  //     } else {
  //         this.height = windowHeight / 1.5;
  //     }
  // }

  // ngOnInit(): void {
  //     this.calcmodalHeight();
  // }
  //
  // constructor(private router: Router) {
  //     this.calcmodalHeight();
  // }

  closeModal($event) {
    if (!this.elm.nativeElement.querySelector('afaqy-modal')) {
      this.modalClose.next($event);
    }
  }
  checkEdge(event) {
    this.edge = event;
  }
}
