import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ComponentFactoryResolver,
  TemplateRef,
} from '@angular/core';
import { AppConfig } from '../../../common/classes/app-config';
import { ActionsOptions } from './interfaces';
import { ApiRequestService, RootService } from 'app/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'afaqy-actions',
  templateUrl: './afaqy-actions.component.html',
  styleUrls: ['./afaqy-actions.component.scss'],
})
export class AfaqyActionsComponent implements OnInit {
  showExport: boolean = false;
  showDownloadTemplate: boolean = false;
  appConfig = AppConfig;
  @Input() headerOptions: boolean = true;
  @Input() controller: string = '';
  @Input() cidPermissionKey: string;
  @Input() loading: boolean = false;
  @Input() isRoot: boolean = false;
  @Input() is_trashed: boolean = false;
  @Input() downloadTemplateOptions;
  @Input() actionsOptions: ActionsOptions = {
    reload: true,
    add: true,
    trash: true,
    import: true,
    export: true,
    downloadTemplate: false,
    importToFill: false,
  };
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() restoreAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleOptions: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() import: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyAction: EventEmitter<any> = new EventEmitter<any>();

  @Output() multipleDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() multiple_ids: string[];
  @Input() count: number[];
  @Output() importToFill: EventEmitter<any> = new EventEmitter<any>();
  @Output() shareLocation: EventEmitter<any> = new EventEmitter<any>();
  exportOptions;
  dialogRef: any;
  @ViewChild('myInfoDialog') infoDialog = {} as TemplateRef<string>;
  exportOptionList = [
    {
      id: 'xlsx',
      name: 'xlsx',
    },
    {
      id: 'xls',
      name: 'xls',
    },
    {
      id: 'csv',
      name: 'csv',
    },
  ];
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private rootService: RootService,
    private apiRequest: ApiRequestService
  ) {}

  ngOnInit() {
    this.exportOptions = this.actionsOptions.exportOptions
      ? this.actionsOptions.exportOptions
      : this.exportOptionList;
  }

  /** Calls `closeExportActions` fn and sends the received type to `export` event emitter. */
  doExport(type: any) {
    this.closeExportActions();
    this.export.next(type);
  }

  /** Calls `closeDownloadTemplateActions` fn and Sets the opposite current `showExport` value to itself. */
  toggleExportActions(): void {
    this.closeDownloadTemplateActions();
    this.showExport = !this.showExport;
  }

  /** Sets false value to `showExport` to close its actions. */
  closeExportActions(): void {
    this.showExport = false;
  }

  /** Calls `closeDownloadTemplateActions` fn and sends the received type to `downloadTemplate` event emitter. */
  doDownloadTemplate(type: any) {
    this.closeDownloadTemplateActions();
    this.downloadTemplate.next(type);
  }

  /** Calls `closeExportActions` fn and Sets the opposite current `showDownloadTemplate` value to itself. */
  toggleDownloadTemplateActions(): void {
    this.closeExportActions();
    this.showDownloadTemplate = !this.showDownloadTemplate;
  }

  /** Sets false value to `showDownloadTemplate` to close its actions. */
  closeDownloadTemplateActions(): void {
    this.showDownloadTemplate = false;
  }

  options() {
    this.toggleOptions.next();
  }

  /*
   * Upon clicking import icon, check if there is an in-progress operation or not
   * In case there is, show a dialog with text, else open import dialog
   * */
  checkOperationProcess(controller) {
    this.loading = true;

    // this.dialogRef.afterClosed().subscribe(() => {
    //   console.log('The Info dialog was closed.');
    // });

    this.apiRequest.getRequest('general/check_running_operation', {}).subscribe(
      (res: any) => {
        this.loading = false;
        if (res?.data.hasOperation) {
          this.dialogRef = this.dialog.open(this.infoDialog, {
            data: this.translateService.instant('hasRunningOperation'),
            height: '',
            width: '',
          });
        } else {
          this.importToFill.next(controller);
        }
      },
      (err) => {
        this.loading = false;
        this.rootService.pushNotification('Something went wrong', '', 'error');
      }
    );
  }

  onCancelCityDialog() {
    this.dialogRef.close();
  }
}
