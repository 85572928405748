import {
  Directive,
  ElementRef,
  HostBinding,
  OnInit,
  Input,
} from '@angular/core';
import { Logger } from '../../common/classes';
import { AuthService } from '../../core/services/auth.service';

@Directive({
  selector: '[secured]',
})
export class SecuredDirective implements OnInit {
  _hideRouterLink: boolean = true;
  _revertsecured: boolean = false;

  set hideRouterLink(val: boolean) {
    this._hideRouterLink = val;
  }

  get hideRouterLink() {
    return this._hideRouterLink;
  }

  @Input('routerLink') routeParams: any[];
  @Input('secured') permissions: string;

  @Input('revertsecured') set revertsecured(value) {
    this._revertsecured = value;
    this.ngOnInit();
  }

  constructor(private authService: AuthService, private eltRef: ElementRef) {}

  ngOnInit(): void {
    if (this._revertsecured) {
      return;
    }
    let permissions = this.permissions.trim().split(',');
    if (this.permissions.trim() == '') {
      this.hideRouterLink = false;
    } else {
      for (let key of permissions) {
        if (
          this.authService.checkPermissions(key, '') ||
          this.authService.checkWASLPermissions(key, '')
        ) {
          this.hideRouterLink = false;
        }
      }
    }

    //Logger.log(permissions , this.hideRouterLink , this.authService.getUserPermissions());
    let el: HTMLElement = this.eltRef.nativeElement;
    if (this.hideRouterLink) {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    } else {
      el.removeAttribute('secured');
    }
  }
}
