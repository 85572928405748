<!-- Custom Actions Start-->
<div class="list-custom-actions d-flex">
  <ng-container>
    <ng-container *ngFor="let action of options">
      <ng-container>
        <ng-container [ngSwitch]="action?.type">
          <ng-container *ngSwitchCase="null">
            <ng-container
              *ngIf="
                !action?.hideFn || (action?.hideFn && !action?.hideFn(row))
              "
            >
              <button
                afaqy-tooltip
                color="primary"
                fxFlex="nogrow"
                fxLayoutAlign="center center"
                [class.disabled]="
                  action?.disableFn ? action?.disableFn(row) : false
                "
                [disabled]="action?.disableFn ? action?.disableFn(row) : false"
                [ngClass]="action?.cssClass"
                [tooltipText]="
                  action?.tooltip?.translate
                    ? (action?.tooltip?.text | translate)
                    : action?.tooltip?.text
                "
                (click)="action?.fn(row)"
              >
                <ng-container *ngIf="action?.icon">
                  <mat-icon
                    *ngIf="action?.icon?.svgIcon"
                    class="avl-icon"
                    [svgIcon]="action?.icon?.name"
                    [attr.size]="'14px'"
                  ></mat-icon>
                  <mat-icon *ngIf="!action?.icon?.svgIcon">{{
                    action?.icon?.name
                  }}</mat-icon>
                </ng-container>
                <span *ngIf="action?.title?.name">
                  {{
                    action?.title?.translate
                      ? (action?.title?.name | translate)
                      : action?.title?.name
                  }}
                </span>
              </button>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'toggle'">
            <ng-container
              *ngIf="
                !action?.hideFn || (action?.hideFn && !action?.hideFn(row))
              "
            >
              <mat-slide-toggle
                *ngIf="
                  !options?.checkFields?.toggleField ||
                  !row[options?.checkFields?.toggleField]
                "
                fxFlex="nogrow"
                fxLayoutAlign="center center"
                color="primary"
                [class.disabled]="
                  action?.disableFn ? action?.disableFn(row) : false
                "
                [disabled]="action?.disableFn ? action?.disableFn(row) : false"
                [ngClass]="action?.cssClass"
                [tooltipText]="
                  action?.tooltip?.translate
                    ? (action?.tooltip?.text | translate)
                    : action?.tooltip?.text
                "
                (click)="
                  action?.fn
                    ? action?.fn(row)
                    : service.toggleKey(
                        row?.id,
                        action?.toggleOptions?.key,
                        service?.getValue(row, action?.toggleOptions?.key)
                      )
                "
                [checked]="!!service?.getValue(row, action?.toggleOptions?.key)"
              >
                <span *ngIf="action?.title?.name">
                  {{
                    action?.title?.translate
                      ? (action?.title?.name | translate)
                      : action?.title?.name
                  }}
                </span>
              </mat-slide-toggle>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'dropdown'">
            <ng-container *ngIf="action?.dropdownActions?.length">
              <ng-container
                *ngIf="
                  !action?.hideFn || (action?.hideFn && !action?.hideFn(row))
                "
              >
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                  fxFlex="nogrow"
                  fxLayoutAlign="center center"
                  [class.disabled]="
                    action?.disableFn ? action?.disableFn(row) : false
                  "
                  [disabled]="
                    action?.disableFn ? action?.disableFn(row) : false
                  "
                  [ngClass]="action?.cssClass"
                  [tooltipText]="
                    action?.tooltip?.translate
                      ? (action?.tooltip?.text | translate)
                      : action?.tooltip?.text
                  "
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <ng-container
                    *ngFor="let dropdownAction of action?.dropdownActions"
                  >
                    <ng-template
                      [ngTemplateOutlet]="actionTemplateRef"
                      [ngTemplateOutletContext]="{ action: dropdownAction }"
                    >
                    </ng-template>
                  </ng-container>
                </mat-menu>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<!--Custom Actions End-->

<!-- Start Single Action Template -->
<ng-template #actionTemplateRef let-action="action">
  <button
    mat-menu-item
    *ngIf="!action?.hideFn || (action?.hideFn && !action?.hideFn(row))"
    [disabled]="action?.disableFn ? action?.disableFn(row) : false"
    [ngClass]="action?.cssClass"
    (click)="action?.fn(row)"
    [afaqyHeatmap]="{
      page: service?.cid,
      target: 'action-list-' + action.name
    }"
  >
    <ng-container *ngIf="action?.icon">
      <mat-icon
        *ngIf="action?.icon?.svgIcon"
        class="mdi-icon"
        [svgIcon]="action?.icon?.name"
        [attr.size]="'14px'"
      ></mat-icon>
      <mat-icon *ngIf="!action?.icon?.svgIcon">{{
        action?.icon?.name
      }}</mat-icon>
    </ng-container>

    <span *ngIf="action?.title?.name">
      {{
        action?.title?.translate
          ? (action?.title?.name | translate)
          : action?.title?.name
      }}
    </span>
  </button>
</ng-template>
<!-- End Single Action Template -->
