import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/services';
import { AppConfig } from '../../../common';
import { UserApp } from 'app/shared/interfaces/interfaces';

@Component({
  selector: 'user-apps',
  templateUrl: './user-apps.component.html',
  styleUrls: ['./user-apps.component.scss'],
})
export class UserAppsComponent implements OnInit {
  displayUserApps: boolean = false;
  userApps: UserApp[] = [];

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.setUserApps();
  }

  private setUserApps(): void {
    const cctvUrl = this.authService?.user.account_apps
      ? this.authService?.user.account_apps.find((x) => x.name === 'cctv')?.url
      : '';

    this.userApps = [
      {
        id: 'cctv',
        enable: cctvUrl && this.isEnabled('cctv'),
        url: cctvUrl,
        tooltipText: 'navbar.user_apps.cctv',
        iconClass: 'mdi mdi-video-vintage icon-20px',
      },
      {
        id: 'routes',
        enable: this.isEnabled('routes'),
        url: `${AppConfig?.apps?.route}${this.authService.authToken}`,
        tooltipText: 'navbar.user_apps.routes',
        iconClass: 'fa-route fas icon-16px',
      },
      {
        id: 'fleets',
        enable: this.isEnabled('fleets'),
        url: 'https://fleets.afaqy.sa/',
        tooltipText: 'navbar.user_apps.fleets',
        iconClass: 'mdi mdi-car-multiple icon-20px',
      },
      {
        id: 'smart_taxi',
        enable: this.isEnabled('smart_taxi'),
        url: `${AppConfig?.apps?.smartTaxi}`,
        tooltipText: 'navbar.user_apps.smart_taxi',
        iconClass: 'mdi mdi-taxi icon-20px',
      },
      {
        id: 'senseware',
        enable: this.isEnabled('senseware'),
        url: `${AppConfig?.apps?.senseware}${this.authService.authToken}`,
        tooltipText: 'navbar.user_apps.senseware',
        iconClass: 'icon-senseware-app',
        svgIcon: true,
        cssClass: 'd-flex',
      },
    ];
  }

  private isEnabled(userApp: string): boolean {
    const found = this.authService?.user.account_apps
      ? this.authService?.user.account_apps.find((x) => x.name === userApp)
      : '';
    return !!found;
  }

  toggleUserApps(): void {
    this.displayUserApps = !this.displayUserApps;
  }

  closeUserApps(): void {
    this.displayUserApps = false;
  }

  goToLink(url: string): void {
    window.open(url, '_blank');
    this.toggleUserApps();
  }
}
