import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ZonesService } from '../../modules/zones/services';

@Injectable()
export class ZonesResolver implements Resolve<any> {
  constructor(public service: ZonesService) {}

  resolve() {
    return this.service.zonesList();
  }
}
