<div class="validationMsg" *ngIf="this.ctl.dirty || this.ctl.touched">
  <ng-container *ngFor="let errorMsg of errorMessages">
    <div class="form-control-feedback">
      {{
        'validations.' + translationKey + errorMsg.message
          | translate : errorMsg.params
      }}
    </div>
  </ng-container>
</div>
<!--<div *ngIf="!ctl.pristine && ctl.errors" class="alert alert-danger">-->
<!--<div *ngFor="let error of ctl['errors'] | keys">-->
<!--<span translate [translateParams]="{value: 'world'}">{{ 'validations.'+translationKey + error }}</span>-->
<!--</div>-->
<!--</div>-->
