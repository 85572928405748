import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { ApiRequestService } from './api-request-service';
import { AfaqyAlertService } from './alert.service';
import { RootService } from './rootService';
import { AuthService } from './auth.service';

@Injectable()
export class AssignableResourcesService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  get autoLoad() {
    return false;
  }
}
