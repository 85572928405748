<div class="row">
  <div class="col-12 col-lg-5">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="useDropDownFilter; else sourceDropdownFilter">
          <div class="form-group col-12 filter-inputs-wrapper">
            <div class="col-6 dropdown-filter">
              <select
                *ngIf="useDropDownFilter"
                class="form-control form-control-sm"
                #teams
                (change)="leftDropDownOnSelect(teams.value)"
              >
                <option *ngFor="let type of options" [value]="type.value">
                  {{ type.title }}
                </option>
              </select>
            </div>
            <div class="col-6 search-ctrl-label text-filter">
              <input
                type="text"
                class="form-control form-control-sm pad-2"
                [attr.testId]="
                  tabName ? tabName + '-searchCtrl1' : 'searchCtrl1'
                "
                (keyup)="setSourceSearchText($event)"
                [(ngModel)]="leftSearchInputValue"
              />
            </div>
          </div>
        </ng-container>
        <ng-template #dropdownFilter>
          <ng-container>
            <div class="search-ctrl-label text-filter">
              <input
                type="text"
                class="form-control form-control-sm"
                [attr.testId]="
                  tabName ? tabName + '-searchCtrl1' : 'searchCtrl1'
                "
                (keyup)="setSourceSearchText($event)"
              />
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div class="col-12">
        <div class="flexgridSelect">
          <wj-flex-grid
            #sgrid
            [attr.testId]="'selectedDataList'"
            [itemsSource]="availableItems"
            [selectionMode]="'ListBox'"
            [isReadOnly]="true"
            [allowSorting]="true"
            [headersVisibility]="'None'"
          >
            <wj-flex-grid-column
              [allowSorting]="true"
              header=""
              [binding]="titleField"
              [isReadOnly]="true"
              [width]="'*'"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-lg-2 px-lg-0 d-flex justify-content-center flex-lg-column assign-buttons"
  >
    <afaqy-spinner [loading]="isLoading"></afaqy-spinner>
    <!-- add all  -->
    <button
      [disabled]="isView"
      type="button"
      [attr.testId]="'addAll'"
      class="btn btn-outline-primary"
      afaqy-tooltip
      [tooltipText]="'select_control.add_all' | translate"
      (click)="addAllColumns()"
    >
      <span class="mdi mdi-18px mdi-chevron-double-right"></span>
    </button>
    <!-- add all  -->

    <button
      [disabled]="isView"
      type="button"
      class="btn btn-outline-primary"
      afaqy-tooltip
      [tooltipText]="'select_control.add_group' | translate"
      (click)="addColumns()"
      [attr.testId]="'addItem'"
    >
      <span class="mdi mdi-18px mdi-chevron-right"></span>
    </button>
    <button
      [disabled]="isView"
      type="button"
      class="btn btn-outline-primary"
      afaqy-tooltip
      [tooltipText]="'select_control.remove_group' | translate"
      (click)="removeColumns()"
      [attr.testId]="'removeItem'"
    >
      <span class="mdi mdi-18px mdi-chevron-left"></span>
    </button>

    <!-- remove all  -->
    <button
      [disabled]="isView"
      type="button"
      class="btn btn-outline-primary"
      afaqy-tooltip
      [tooltipText]="'select_control.remove_all' | translate"
      (click)="removeAllColumns()"
      [attr.testId]="'removeAll'"
    >
      <span class="mdi mdi-18px mdi-chevron-double-left"></span>
    </button>
    <!-- remove all  -->
  </div>
  <div class="col-12 col-lg-5">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="useDropDownFilter; else selectedDropdownFilter">
          <div class="form-group col-12 filter-inputs-wrapper">
            <div class="col-6 dropdown-filter">
              <select
                *ngIf="useDropDownFilter"
                class="form-control form-control-sm"
                #teams
                (change)="rightDropDownOnSelect(teams.value)"
              >
                <option *ngFor="let type of options" [value]="type.value">
                  {{ type.title }}
                </option>
              </select>
            </div>
            <div class="col-6 pad-2 search-ctrl-label text-filter">
              <input
                type="text"
                class="form-control form-control-sm"
                [attr.testId]="'searchCtrl2'"
                (keyup)="setSelectedSearchText($event, 'left')"
                [(ngModel)]="rightSearchInputValue"
              />
            </div>
          </div>
        </ng-container>
        <ng-template #selectedDropdownFilter>
          <ng-container>
            <div
              class="search-ctrl-label text-filter"
              [ngClass]="{
                'input-group form-group-hint': SearchWithMultipleValues
              }"
              [style.display]="SearchWithMultipleValues ? 'flex' : ''"
            >
              <input
                type="text"
                class="form-control form-control-sm"
                [attr.testId]="'searchCtrl2'"
                (keyup)="setSelectedSearchText($event, 'right')"
              />
              <div
                class="input-group-append hint"
                *ngIf="SearchWithMultipleValues"
              >
                <span class="input-group-text">
                  <i
                    class="fa fa-exclamation-circle"
                    afaqy-tooltip
                    [tooltipText]="'search.multiple_search' | translate"
                  ></i>
                </span>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #sourceDropdownFilter>
          <ng-container>
            <div
              class="search-ctrl-label text-filter"
              [ngClass]="{
                'input-group form-group-hint': SearchWithMultipleValues
              }"
              [style.display]="SearchWithMultipleValues ? 'flex' : ''"
            >
              <input
                type="text"
                class="form-control form-control-sm"
                [attr.testId]="'searchCtrl1'"
                (keyup)="setSourceSearchText($event, 'left')"
              />
              <div
                class="input-group-append hint"
                *ngIf="SearchWithMultipleValues"
              >
                <span class="input-group-text">
                  <i
                    class="fa fa-exclamation-circle"
                    afaqy-tooltip
                    [tooltipText]="'search.multiple_search' | translate"
                  ></i>
                </span>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div class="col-12">
        <div class="flexgridSelect">
          <wj-flex-grid
            #dgrid
            [attr.testId]="'dragedDataList'"
            [itemsSource]="selectedItems"
            [selectionMode]="'ListBox'"
            [isReadOnly]="true"
            [allowSorting]="true"
            [headersVisibility]="'None'"
          >
            <wj-flex-grid-column
              [allowSorting]="true"
              header=""
              [binding]="titleField"
              [isReadOnly]="true"
              [width]="'*'"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
      </div>
    </div>
  </div>
</div>
