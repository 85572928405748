import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AfaqyHelper, AppConfig, Logger } from '../../common/classes';
import { UnitService } from '../../modules/units/services/unit.service';
import { EventsService } from '../../modules/events/services/events.service';
import { ChatService } from '../../modules/chat/services/chat.service';
import { Router } from '@angular/router';
import { RxStompState } from '@stomp/rx-stomp';
import { RxNotificationsStompService } from './stomp-socket-services/rx-notifications-stomp.service';
import { RxUnitsStompService } from './stomp-socket-services/rx-units-stomp.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { find } from 'rxjs/operators';
import { PusherSocketService } from './pusher-socket.service';

enum Listeners {
  updates = 'updates',
  events = 'events',
  status = 'status',
  bindDriver = 'bind_driver',
  bindTrailer = 'bind_trailer',
  chat = 'chat',
  waste = 'waste',
}

@Injectable({ providedIn: 'root' })
export class SocketService {
  public unitsSocketConnectionState: string;
  public notificationsSocketConnectionState: string;
  private webSocket: any;
  private reconnectSeconds: number = 180;
  private chunklimit: number = 1000;
  private currentUnitsIds: string[] = null;
  wastePlanData$ = new BehaviorSubject(null);
  private toBeUpdated: any = {};
  private lastUpdateTime: number = 0;
  unitCopyInfoSubmitted: BehaviorSubject<any> = new BehaviorSubject(false);
  importToFill: BehaviorSubject<any> = new BehaviorSubject(false);
  showCopyInfoSpinner: BehaviorSubject<any> = new BehaviorSubject(false);
  public notification: Subject<any> = new Subject<any>();

  private unitsSubscription: Subscription;
  private webEventsSubscription: Subscription;
  private autoBindSubscription: Subscription;
  private jobOrderSubscription: Subscription;
  private wasteSubscription: Subscription;

  constructor(
    protected authService: AuthService,
    protected unitService: UnitService,
    protected router: Router,
    protected eventService: EventsService,
    protected chatService: ChatService,
    public rxUnitsStompService: RxUnitsStompService,
    public rxNotificationsStompService: RxNotificationsStompService,
    private pusherSocketService: PusherSocketService
  ) {}

  initializeSocketSubscriptions() {
    // console.log('initializeSocketSubscriptions')
    /* Connect to WebSocket if all units loaded and the WebSocket not connected. */
    this.unitService.finishedLoading.pipe(find((x) => x)).subscribe(() => {
      // console.log('finishedLoading')
      if (!this.isUnitsConnected()) {
        this.connect();
      }
    });

    /* Toggle Socket Connectivity depends on user status. */
    this.authService.sessionObserver.subscribe((status: any) => {
      // console.log('sessionObserver', status)
      status = this.authService.isActiveLogin();

      if (!status) {
        if (this.isUnitsConnected()) {
          // this.disconnect();
          this.rxUnitsStompService.deactivate().then(() => {
            // console.log('Units socket deactivated');
          });
        }
        if (this.isNotificationsConnected()) {
          this.rxNotificationsStompService.deactivate().then(() => {
            // console.log('Notifications socket deactivated');
          });
        }
      } else if (this.unitService.allUnitsLoaded) {
        // console.log('this.unitService.allUnitsLoaded', this.unitService.allUnitsLoaded)
        this.connect();
      }

      if (!this.pusherSocketService?.pusherClient?.connection?.state) {
        this.openConnectionPusherSocket();
      }
    });

    /**
     * on socket connected subscribe to target topic
     */
    this.rxUnitsStompService.connected$.subscribe(() => {
      this.unitsSubscription = this.rxUnitsStompService
        .watch('/topic/' + this.rxUnitsStompService.unitsSocketConfig.sessionId)
        .subscribe(
          (message) => {
            // console.log('UnitsSubscription message', message.body);
            // this.receive(message.body)
            this.publish(
              Listeners.updates,
              this.refactorUpdatesMessage(message.body)
            );
          },
          (error) => {
            // console.log('error', error);
          }
        );
    });
    this.rxNotificationsStompService.connected$.subscribe(() => {
      const userId = this.authService.userID;
      const webEvents = `/topic/notification.${userId}.web`;
      const autoBind = `/topic/notification.${userId}.autobind`;
      const jobOrder = `/topic/notification.${userId}.joborder`;
      const waste = `/topic/notification.${userId}.waste`;

      this.webEventsSubscription = this.rxNotificationsStompService
        .watch(webEvents)
        .subscribe(
          (message) => {
            // console.log('webEventsSubscription message', message);
            const data = JSON.parse(message.body);
            this.events(data);
          },
          (error) => {
            // console.log('webEventsSubscription error', error)
          }
        );
      this.autoBindSubscription = this.rxNotificationsStompService
        .watch(autoBind)
        .subscribe(
          (message) => {
            // console.log('autoBindSubscription message', message);
            const data = JSON.parse(message.body);
            this.binding(data);
          },
          (error) => {
            // console.log('autoBindSubscription error', error)
          }
        );
      // this.jobOrderSubscription = this.rxNotificationsStompService.watch(jobOrder).subscribe((message) => {
      //     // console.log('jobOrderSubscription message', message);
      //     this.receive(message.body)
      // }, (error) => {
      //     // console.log('jobOrderSubscription error', error)
      // });
      this.wasteSubscription = this.rxNotificationsStompService
        .watch(waste)
        .subscribe(
          (message) => {
            // console.log('wasteSubscription message', message);
            // this.receive(message.body)
            const data = JSON.parse(message.body);
            this.wastePlanData$.next(data);
          },
          (error) => {
            // console.log('wasteSubscription error', error)
          }
        );
    });
    this.rxUnitsStompService.connectionState$.subscribe((connectionState) => {
      this.unitsSocketConnectionState = RxStompState[connectionState];
      if (connectionState === RxStompState.CLOSED) {
        this.generateNewUnitsSession();
      }
    });
    this.rxNotificationsStompService.connectionState$.subscribe(
      (connectionState) => {
        this.notificationsSocketConnectionState = RxStompState[connectionState];
        if (connectionState === RxStompState.CLOSED) {
          this.generateNewNotificationsSession();
        }
      }
    );
  }

  mockSocketUpdates(updates: any[], options?: any) {
    updates?.length &&
      updates.forEach((update, index) => {
        setTimeout(() => {
          // console.log(
          //   '%cmockSocketUpdates',
          //   'color: red; font-weight: bold; background: black;'
          // );
          // console.log(update, new Date());
          update.d[0] = Date.now();
          update.d[1] = Date.now();
          this.publish(
            Listeners.updates,
            this.refactorUpdatesMessage(JSON.stringify(update))
          );
          options?.callback && options.callback();
        }, (options?.interval ? options?.interval : 5000) * (index + 1));
      });
  }

  generateNewUnitsSession() {
    // console.log('generateNewUnitsSession')
    this.unitsSubscription?.unsubscribe();
    this.rxUnitsStompService.unitsSocketConfig.generateNewSessionId();
  }

  generateNewNotificationsSession() {
    // console.log('generateNewNotificationsSession')
    this.webEventsSubscription?.unsubscribe();
    this.autoBindSubscription?.unsubscribe();
    this.jobOrderSubscription?.unsubscribe();
    this.wasteSubscription?.unsubscribe();
    this.rxNotificationsStompService.notificationsSocketConfig.generateNewSessionId();
  }

  refactorUpdatesMessage(message) {
    const data = JSON.parse(message);
    return {
      [data.id]: data,
    };
  }

  onConnected() {
    // Logger.log('Connected');
    this.checkMonitoringActivation();
  }

  receive(item: any) {
    item = JSON.parse(item.data);
    // console.log(item);
    var type = item.type;
    var data = item.data;
    this.publish(type, data);
  }

  close(e: any) {
    // Logger.log(
    //   'Socket is closed. Reconnect will be attempted in ' +
    //     this.reconnectSeconds +
    //     ' seconds.',
    //   e.reason
    // );
    let service = this;
    setTimeout(function () {
      service.currentUnitsIds = null;
      service.webSocket = null;
      service.connect();
    }, this.reconnectSeconds * 1000);
  }

  error(err: any) {
    // Logger.error('Socket encountered error: ', err);
    // if (this.webSocket) {
    //   this.webSocket.close();
    //   Logger.log('Disconnected');
    // }
  }

  publish(type: any, data: any) {
    // console.log(type, data);

    const scope = this;
    switch (type) {
      case Listeners.updates:
        scope.updates(data);
        break;
      case Listeners.events:
        scope.events(data);
        break;
      case Listeners.status:
        scope.status(data);
        break;
      case Listeners.bindDriver:
      case Listeners.bindTrailer:
        scope.binding(data);
        break;
      case Listeners.chat:
        scope.chat(data);
        break;
      case Listeners.waste:
        this.wastePlanData$.next(data);
        break;
      default:
        // Logger.error('Socket not received a valid type');
        break;
    }
  }

  isUnitsConnected(): boolean {
    // return this.webSocket.readyState === this.webSocket.OPEN;
    // console.log('isUnitsConnected', this.rxUnitsStompService.connected())
    return this.rxUnitsStompService.connected();
  }

  isNotificationsConnected(): boolean {
    return this.rxNotificationsStompService.connected();
  }

  disconnect() {
    if (this.isUnitsConnected()) {
      // Logger.log('ioClient Disconnected');
      this.webSocket.close();
    }
  }

  /**
   * Send updated data list to the server through the WebSocket depends on its type.
   * @param type Listener type.
   * @param data Data list.
   */
  send(type: any, data: any[]) {
    // console.log('send', type, data);
    if (!type || !data) {
      return;
    }
    if (this.isUnitsConnected() && type == Listeners.updates) {
      this.sendActiveUnits(data);
    }
  }

  private connect() {
    if (!AppConfig.socket) {
      return;
    }
    if (this.isUnitsConnected()) {
      return;
    }

    // enable/disable socket connection for admin user
    // if (this.authService.isAdmin == true) {
    //   return;
    // }

    this.lastUpdateTime = Date.now();

    // this.stompSubscribe();
    // const sURL = AppConfig.WSURL + "?userId=" + this.authService.userID; // + "&admin=" + this.authService.isAdmin;
    // this.webSocket = new WebSocket(sURL);

    // const WSService = this;
    // this.webSocket.onopen = function () {
    //     WSService.onConnected();
    // };

    // this.webSocket.onmessage = function (item: any) {
    //     WSService.receive(item);
    // };

    // this.webSocket.onclose = function (e: any) {
    //     WSService.close(e);
    // };

    // this.webSocket.onerror = function (err: any) {
    //     WSService.error(err);
    // };

    // Attach connection configs.
    this.rxUnitsStompService.configure(
      this.rxUnitsStompService.unitsSocketConfig.config
    );
    this.rxNotificationsStompService.configure(
      this.rxNotificationsStompService.notificationsSocketConfig.config
    );

    if (AppConfig.WSStompUnitsURL && !this.isUnitsConnected()) {
      this.rxUnitsStompService.activate();
    }
    if (AppConfig.WSStompNotificationsURL && !this.isNotificationsConnected()) {
      // Attempt to connect
      this.rxNotificationsStompService.activate();
    }
  }

  closeSocketConnection() {
    this.rxUnitsStompService.deactivate();
    this.rxNotificationsStompService.deactivate();
  }

  private status(data: any) {
    if (!data) {
      return;
    }
    // Logger.log('Status: ', data);
    this.unitService.updateObjectStatus(data);
  }

  private chat(data: any) {
    if (!data) {
      return;
    }
    // Logger.log('Chat: ', data);
    this.chatService.log(data);
  }

  private updates(data: any) {
    if (!data) {
      return;
    }
    //Logger.log('Updates Length: ' + Object.keys(data).length, data);

    if (this.unitService.resourcesList.length >= 500) {
      for (let id in data) {
        this.toBeUpdated[id] = data[id];
      }

      let t = Date.now();
      if (t - this.lastUpdateTime > 1000) {
        this.unitService.updateObjectsTracking(this.toBeUpdated);
        this.lastUpdateTime = t;
        this.toBeUpdated = {};
      }
    } else {
      this.unitService.updateObjectsTracking(data);
    }
  }

  private binding(data: any) {
    if (!data) {
      return;
    }
    // Logger.log('Binding Length: ' + Object.keys(data).length, data);
    this.unitService.updateObjectsBinding(data);
  }

  private events(data: any) {
    if (!data) {
      return;
    }
    // Logger.log('Events Length: ' + Object.keys(data).length, data);
    this.eventService.pushNewEvent(data);
  }

  /**
   * Send active units ids to the server through the WebSocket.
   * @param data Active units list.
   */
  private sendActiveUnits(data: any[]) {
    if (!data) {
      return;
    }
    const activeUnitsIds: string[] = AfaqyHelper.getUniqueListIds(data, 'imei');
    const allUnitsIds: string[] = AfaqyHelper.getUniqueListIds(
      this.unitService.resourcesList,
      'imei'
    );
    const activeUnitsNotifiers: any[] = this.activeUnitsNotifiers(
      activeUnitsIds,
      this.currentUnitsIds,
      allUnitsIds
    );
    if (activeUnitsNotifiers && activeUnitsNotifiers.length > 0) {
      // Logger.log('Units Notifiers: ', activeUnitsNotifiers);
      activeUnitsNotifiers.forEach((notifierObj: any) => {
        // this.webSocket.send(JSON.stringify(notifierObj))
        // todo: Send the unit IMEIs instead if Ids..
        this.rxUnitsStompService.publish(notifierObj);
      });
      this.currentUnitsIds = [...activeUnitsIds];
    }
  }

  /**
   * generate stomp message
   * @param units string[]
   * @param action add | delete
   * @private
   */
  private generateStompMessage(
    units: string[],
    action: string
  ): { destination: string; body: string } {
    const msg = {
      destination: `/devices/${action}`,
      body: JSON.stringify(units.join(',')),
    };

    // if (action === 'delete') {
    //   msg.destination = `/devices/${action}/${this.rxUnitsStompService.unitsSocketConfig.sessionId}`;
    // }

    // console.log('msg', msg);
    return msg;
  }

  /** Return array of active units notifiers. */
  private activeUnitsNotifiers(
    activeUnitsIds: string[],
    currentUnitsIds: string[],
    allUnitsIds: string[]
  ): any[] {
    if (!currentUnitsIds) {
      return this.initializeUnitsNotifiers(activeUnitsIds, allUnitsIds);
    }
    if (
      AfaqyHelper.arraysEqual(activeUnitsIds, this.currentUnitsIds) ||
      !activeUnitsIds ||
      !allUnitsIds
    ) {
      return;
    }
    const activeUnitsEqualAllUnits: boolean = AfaqyHelper.arraysEqual(
      activeUnitsIds,
      allUnitsIds
    );
    const notifiers: any[] = [];
    if (activeUnitsIds.length == 0 && currentUnitsIds.length > 0) {
      // notifiers.push({action: "REMOVEALL", units: []});
      notifiers.push(...this.addUnitsNotifiers(currentUnitsIds, 'delete'));
    } else if (
      activeUnitsEqualAllUnits &&
      activeUnitsIds.length > 0 &&
      currentUnitsIds.length == 0
    ) {
      // notifiers.push({action: "ADDALL", units: []});
      notifiers.push(...this.addUnitsNotifiers(activeUnitsIds, 'add'));
    } else if (
      activeUnitsEqualAllUnits &&
      activeUnitsIds.length > 0 &&
      currentUnitsIds.length > 0
    ) {
      // notifiers.push({action: "REMOVEALL", units: []});
      // notifiers.push({action: "ADDALL", units: []});
      notifiers.push(...this.addUnitsNotifiers(currentUnitsIds, 'delete'));
      notifiers.push(...this.addUnitsNotifiers(activeUnitsIds, 'add'));
    } else if (
      activeUnitsEqualAllUnits === false &&
      activeUnitsIds.length > 0 &&
      currentUnitsIds.length == 0
    ) {
      notifiers.push(...this.addUnitsNotifiers(activeUnitsIds, 'add'));
    } else if (
      activeUnitsEqualAllUnits === false &&
      activeUnitsIds.length > 0 &&
      currentUnitsIds.length > 0
    ) {
      const addedUnitsIds: string[] = this.addedUnitsIds(
        activeUnitsIds,
        currentUnitsIds
      );
      const removedUnitsIds: string[] = this.removedUnitsIds(
        activeUnitsIds,
        currentUnitsIds
      );
      if (addedUnitsIds && addedUnitsIds.length > 0) {
        notifiers.push(...this.addUnitsNotifiers(addedUnitsIds, 'add'));
      } else if (removedUnitsIds && removedUnitsIds.length <= this.chunklimit) {
        // notifiers.push({action: "REMOVE", units: removedUnitsIds});
        notifiers.push(...this.addUnitsNotifiers(removedUnitsIds, 'delete'));
      } else {
        // notifiers.push({action: "REMOVEALL", units: []});
        notifiers.push(...this.addUnitsNotifiers(currentUnitsIds, 'delete'));
        notifiers.push(...this.addUnitsNotifiers(activeUnitsIds, 'add'));
      }
    }
    return notifiers;
  }

  /** Return array of active units notifiers for initialization. */
  private initializeUnitsNotifiers(
    activeUnitsIds: string[],
    allUnitsIds: string[]
  ): any[] {
    // console.log('initializeUnitsNotifiers')
    if (!activeUnitsIds || !allUnitsIds) {
      return;
    }
    const activeUnitsEqualAllUnits: boolean = AfaqyHelper.arraysEqual(
      activeUnitsIds,
      allUnitsIds
    );
    const notifiers: any[] = [];
    // console.log('activeUnitsIds', activeUnitsIds.length);
    // console.log('allUnitsIds', allUnitsIds.length);
    if (activeUnitsIds.length == 0) {
      // console.log('activeUnitsIds.length == 0');
      // notifiers.push({action: "REMOVEALL", units: []});
      notifiers.push(...this.addUnitsNotifiers(allUnitsIds, 'delete'));
    } else if (activeUnitsEqualAllUnits && activeUnitsIds.length > 0) {
      // notifiers.push({action: "ADDALL", units: []});
      notifiers.push(...this.addUnitsNotifiers(allUnitsIds, 'add'));
    } else if (
      activeUnitsEqualAllUnits === false &&
      activeUnitsIds.length > 0
    ) {
      // notifiers.push(...this.addUnitsNotifiers(activeUnitsIds));
      notifiers.push(...this.addUnitsNotifiers(activeUnitsIds, 'add'));
    }
    return notifiers;
  }

  /**
   * Return array of add units notifiers depends on chunk limit.
   * @param addedUnitsIds Array of added units ids that will send to the server through the WebSocket.
   */
  private addUnitsNotifiers(addedUnitsIds: string[], action: string): any[] {
    if (!addedUnitsIds) {
      return;
    }
    const unitsIds: string[] = [...addedUnitsIds];
    const addNotifiers: any[] = [];
    while (unitsIds.length) {
      // addNotifiers.push({ action: "ADD", units: unitsIds.splice(0, this.chunklimit) });
      addNotifiers.push(
        this.generateStompMessage(unitsIds.splice(0, this.chunklimit), action)
      );
    }
    return addNotifiers;
  }

  /** Return list of added units ids, if active units ids has all current units ids. */
  private addedUnitsIds(
    activeUnitsIds: string[],
    currentUnitsIds: string[]
  ): string[] {
    if (!activeUnitsIds || !currentUnitsIds) {
      return;
    }
    const activeContainsCurrent: boolean = currentUnitsIds.every((id: string) =>
      activeUnitsIds.includes(id)
    );
    if (!activeContainsCurrent) {
      return;
    }
    const addedIds: string[] = activeUnitsIds.filter(
      (id: string) => !currentUnitsIds.includes(id)
    );
    return addedIds;
  }

  /** Return list of removed units ids, if current units ids has all active units ids. */
  private removedUnitsIds(
    activeUnitsIds: string[],
    currentUnitsIds: string[]
  ): string[] {
    if (!activeUnitsIds || !currentUnitsIds) {
      return;
    }
    const currentContainsActive: boolean = activeUnitsIds.every((id: string) =>
      currentUnitsIds.includes(id)
    );
    if (!currentContainsActive) {
      return;
    }
    const removedIds: string[] = currentUnitsIds.filter(
      (id: string) => !activeUnitsIds.includes(id)
    );
    return removedIds;
  }

  /** If monitoring active send active units ids to the server through the WebSocket, else send remove all.*/
  private checkMonitoringActivation() {
    const activeURL: string = this.router.routerState.snapshot.url;
    let isMonitoringActive: boolean = activeURL.includes('/?');
    isMonitoringActive = isMonitoringActive
      ? isMonitoringActive
      : activeURL.includes('/monitoring') || activeURL.includes('/plans');
    if (isMonitoringActive) {
      this.send('updates', this.unitService.trackingObjects);
    } else {
      this.send('updates', []);
    }
  }

  openConnectionPusherSocket() {
    this.pusherSocketService.openConnection(this.authService.userID);
  }

  closeConnectionPusherSocket() {
    this.pusherSocketService.closeConnection(this.authService.userID);
  }
}
