<select
  class="custom-select form-control-sm"
  [(ngModel)]="perpage"
  (change)="pushChanges()"
>
  <option
    *ngFor="let value of [1, 2, 3, 5, 10, 15, 25, 50, 100, 200, 250, 500, 1000]"
    value="{{ value }}"
  >
    {{ value }}
  </option>
</select>
