export class Message {
  private _type: string = '';
  private _message: string = '';
  private _params: any = {};
  private _dismissible: boolean = false;
  private _timeout: number = 0;
  private _validationMessages: any = {};

  clear() {
    this.type = '';
    this.message = '';
    this.validationMessages = {};
  }

  fill(
    message: string,
    params = {},
    type: string = 'error',
    dismissible = true
  ) {
    this.type = type;
    this.message = message;
    this.params = params;
    this.dismissible = dismissible;
  }

  set type(value: string) {
    this._type = value;
  }

  set message(value: string) {
    this._message = value;
  }

  set validationMessages(value: {}) {
    this._validationMessages = value;
  }

  set dismissible(value: boolean) {
    this._dismissible = value;
  }

  set timeout(value: number) {
    this._timeout = value;
  }

  set params(value: any) {
    this._params = value;
  }

  get timeout(): number {
    return this._timeout;
  }

  get type(): string {
    return this._type;
  }

  get message(): string {
    return this._message;
  }

  get validationMessages(): {} {
    return this._validationMessages;
  }

  get dismissible(): boolean {
    return this._dismissible;
  }

  get params(): any {
    return this._params;
  }
}
