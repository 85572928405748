<div
  [formGroup]="formGroup"
  class="row form-group"
  [class.has-danger]="(ctl.dirty || ctl.touched) && ctl.errors"
>
  <div class="col-12">
    <label class="form-control-label">{{ ctlLabel | translate }} :</label>
  </div>
  <div
    [ngClass]="{ 'atp-time-picker-container': !ctl.errors }"
    class="col-12 input-group form-group-hint"
  >
    <input
      type="text"
      atp-time-picker
      [attr.locale]="selectedLang"
      class="form-control form-control-sm"
      [formControlName]="ctlName"
      (change)="resetValue()"
    />
    <control-errors-hint [ctl]="ctl"></control-errors-hint>
    <i *ngIf="!ctl.errors" class="fa fa-clock-o" aria-hidden="false"></i>
  </div>
</div>
