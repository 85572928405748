import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { AfaqyAlertService } from './alert.service';
import { ApiRequestService } from './api-request-service';
import { AuthService } from './auth.service';
import { RootService } from './rootService';

import { User } from '../../modules/users/models';
import { UserService } from '../../modules/users/services/user.service';

@Injectable()
export class AssignationService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    public userService: UserService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.userService.resources.subscribe({
      next: () => {
        this.resourcesList = userService.resourcesList;
        this.pushChanges();
      },
    });
  }

  // updateResources(response: AfaqyResponse) {
  //     this.prepareAssigned(response.list);
  //     this.updateObjectsRelations();
  //     this.pagination = response.pagination;
  //     this.pushChanges();
  // }
  //
  // prepareAssigned(list) {
  //     this.resourcesList = list;
  //     // let l = [];
  //     // for(let user of list) {
  //     //     if(this.op == 'assign' && !this.assignedUsers.includes(user._id)) {
  //     //         l.push(user);
  //     //     }
  //     //     if(this.op == 'unassign' && this.assignedUsers.includes(user._id)) {
  //     //         l.push(user);
  //     //     }
  //     // }
  //     // this.resourcesList = l;
  // }

  routerPrefix() {
    return 'user';
  }

  get modelInstance(): any {
    return new User();
  }

  // get allowedIcons() {
  //     return ["icon", "arrow"];
  // }
}
