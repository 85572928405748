import { AfaqyObject } from '../../../common/classes';

export class DomainSettings extends AfaqyObject {
  private _domain: string;
  private _title: string;
  private _copyrights: string;
  private _copyrights_url: string;
  private _help_url: string;
  private _support_url: string;
  private _support_email: string = '';
  private _logo: string;
  private _logo_url: string;
  private _logo_default: boolean;
  private _android_app_default: boolean;
  private _android_app: string;
  private _ios_app_default: boolean;
  private _ios_app: string;
  private _login_logo: string;
  private _login_logo_url: string;
  private _login_logo_default: boolean;
  private _reports_logo: string;
  private _reports_logo_url: string;
  private _reports_logo_default: boolean;
  private _favicon: string;
  private _favicon_url: string;
  private _favicon_default: boolean;
  private _protocols: string;
  private _langs: string[];
  private _maps: string[];
  private _login_logo_bgColor: string;

  protected copyFields = [
    'domain',
    'title',
    'copyrights',
    'copyrightsURL',
    'help_url',
    'support_url',
    'support_email',
    'logo',
    'logo_url',
    'logo_default',
    'android_app',
    'android_app_default',
    'ios_app',
    'ios_app_default',
    'login_logo',
    'login_logo_url',
    'login_logo_default',
    'reports_logo',
    'reports_logo_url',
    'reports_logo_default',
    'favicon',
    'favicon_url',
    'favicon_default',
    'protocols',
    'langs',
    'maps',
    'login_logo_bgColor',
  ];

  get domain(): string {
    return this._domain;
  }

  set domain(value: string) {
    this._domain = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get copyrights(): string {
    return this._copyrights;
  }

  set copyrights(value: string) {
    this._copyrights = value;
  }

  get copyrights_url(): string {
    return this._copyrights_url;
  }

  set copyrights_url(value: string) {
    this._copyrights_url = value;
  }

  get help_url(): string {
    return this._help_url;
  }

  set help_url(value: string) {
    this._help_url = value;
  }

  get support_url(): string {
    return this._support_url;
  }

  set support_url(value: string) {
    this._support_url = value;
  }

  get support_email(): string {
    return this._support_email;
  }

  set support_email(value: string) {
    this._support_email = value;
  }

  get logo(): string {
    return this._logo;
  }

  set logo(value: string) {
    this._logo = value;
  }

  get logo_url(): string {
    return this._logo_url;
  }

  set logo_url(value: string) {
    this._logo_url = value;
  }

  get logo_default(): boolean {
    return this._logo_default == undefined ? true : this._logo_default;
  }

  set logo_default(value: boolean) {
    this._logo_default = value;
  }

  get android_app_default(): boolean {
    return this._android_app_default == undefined
      ? true
      : this._android_app_default;
  }

  set android_app_default(value: boolean) {
    this._android_app_default = value;
  }

  get android_app(): string {
    return this._android_app;
  }

  set android_app(value: string) {
    this._android_app = value;
  }

  get ios_app_default(): boolean {
    return this._ios_app_default == undefined ? true : this._ios_app_default;
  }

  set ios_app_default(value: boolean) {
    this._ios_app_default = value;
  }

  get ios_app(): string {
    return this._ios_app;
  }

  set ios_app(value: string) {
    this._ios_app = value;
  }

  get login_logo(): string {
    return this._login_logo;
  }

  set login_logo(value: string) {
    this._login_logo = value;
  }

  get login_logo_url(): string {
    return this._login_logo_url;
  }

  set login_logo_url(value: string) {
    this._login_logo_url = value;
  }

  get login_logo_default(): boolean {
    return this._login_logo_default == undefined
      ? true
      : this._login_logo_default;
  }

  set login_logo_default(value: boolean) {
    this._login_logo_default = value;
  }

  get reports_logo(): string {
    return this._reports_logo;
  }

  set reports_logo(value: string) {
    this._reports_logo = value;
  }

  get reports_logo_url(): string {
    return this._reports_logo_url;
  }

  set reports_logo_url(value: string) {
    this._reports_logo_url = value;
  }

  get reports_logo_default(): boolean {
    return this._reports_logo_default == undefined
      ? true
      : this._reports_logo_default;
  }

  set reports_logo_default(value: boolean) {
    this._reports_logo_default = value;
  }

  get favicon(): string {
    return this._favicon;
  }

  set favicon(value: string) {
    this._favicon = value;
  }

  get favicon_url(): string {
    return this._favicon_url;
  }

  set favicon_url(value: string) {
    this._favicon_url = value;
  }

  get favicon_default(): boolean {
    return this._favicon_default == undefined ? true : this._favicon_default;
  }

  set favicon_default(value: boolean) {
    this._favicon_default = value;
  }

  get protocols(): string {
    return this._protocols;
  }

  set protocols(value: string) {
    this._protocols = value;
  }

  get langs(): string[] {
    return this._langs ? this._langs : [];
  }

  set langs(value: string[]) {
    this._langs = value;
  }

  get maps(): string[] {
    return this._maps ? this._maps : [];
  }

  set maps(value: string[]) {
    this._maps = value;
  }

  get login_logo_bgColor(): string {
    return this._login_logo_bgColor;
  }

  set login_logo_bgColor(value: string) {
    this._login_logo_bgColor = value;
  }
}
