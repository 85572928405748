import {
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  Component,
  Inject,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AfaqyResponse } from '../core/classes';
import { AfaqyHelper, AppConfig, AppModel, Message } from './classes';

import { BehaviorSubject } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { AuthService, RootService } from '../core/services';

@Component({
  selector: 'afaqy-core-form',
  template: ``,
})
export class CoreFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() insidetab: boolean = false;
  @Input() resource_id: any;
  @Input() action: any;
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  showValidation: boolean = false;
  alive: boolean = true;
  cid: string = '';
  resourceID = '';
  _add_title: string = 'add';
  _edit_title: string = 'edit';
  _view_title: string = 'view';
  _isAdd: boolean = true;

  form: UntypedFormGroup;
  formControls: UntypedFormControl[];
  message: Message;
  object: any;
  editObject: any;
  files: any = {};
  filesPaths: any = {};
  selectedFileNames: string[] = [];

  posting: boolean = false;
  loading: boolean = false;
  forceDeactivate: boolean = false;
  hasAssignPermissions = false;
  random: any;
  accessTabSelectedUsers: any = [];
  isView: boolean = false;
  moduleName: string;
  auth = inject(AuthService);
  isViewLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // simplify = 1;
  protected _validationMessages: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  get resourceName() {
    return 'resource';
  }

  get notificationTitle(): any {
    return 'resources';
  }

  get title(): string {
    return this.isView
      ? this.view_title
      : this.isAdd
      ? this.add_title
      : this.edit_title;
  }

  get form_fields(): any {
    return {};
  }

  get formInitValue() {
    return this.fb.group(this.form_fields).value;
  }

  get formFiles() {
    return [];
  }

  get validationMessages() {
    return this._validationMessages;
  }

  updateValidationMessages(changeType: any) {
    this._validationMessages.next(changeType);
  }

  getNotificationMsg(type: string): any {
    let msg = '';
    switch (type) {
      case 'add':
        msg = this.resourceName + '.added';
        break;
      case 'update':
        msg = this.resourceName + '.updated';
        break;
      case 'send':
        msg = this.resourceName + '.sent';
        break;
    }
    return 'notifications.' + msg;
  }

  set isAdd(value: boolean) {
    this._isAdd = value;
  }

  set add_title(value: string) {
    this._add_title = value;
  }

  set edit_title(value: string) {
    this._edit_title = value;
  }

  set view_title(value: string) {
    this._view_title = value;
  }

  get isAdd(): boolean {
    return this._isAdd;
  }

  get isCopy(): boolean {
    return this.getUrlAction() === 'copy';
  }

  get add_title(): string {
    return this._add_title;
  }

  get edit_title(): string {
    return this._edit_title;
  }

  get view_title(): string {
    return this._view_title;
  }

  constructor(
    protected translate: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    public service: RootService
  ) {
    this.object = this.modelObject;
    this.editObject = this.modelObject;
    this.hasAssignPermissions = this.service.checkAssignationPermissions();
    this.isAdd = true;
    this.createForm();
    const urlAction = this.getUrlAction();
    if (urlAction === 'view') {
      this.isView = true;
    }
    this.moduleName = service.cid;
  }

  get modelObject() {
    return new AppModel();
  }

  get listURL() {
    return '/';
  }

  getControl(name: any): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  refreshRandom() {
    this.random = Math.random();
  }

  getUrlAction() {
    if (AppConfig.isCMS) {
      return this.action;
    }
    if (this.route.snapshot.url.length) {
      return this.route.snapshot.url[0].path;
    }
    return '';
  }

  getResourceID() {
    if (AppConfig.isCMS) {
      return this.resource_id;
    }
    return this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.message = new Message();
    this.updateValidationMessages('');
    let id = this.getResourceID();
    if (id) {
      let urlAction = this.getUrlAction();
      this.loading = true;
      if (urlAction === 'edit') {
        this.isAdd = false;
      }
      if (urlAction === 'view') {
        this.isView = true;
      }
      // this.auth.loadedSession.subscribe({
      //   next: (flag) => {
      //     if (flag) {
      this.service
        .getById(id)
        .pipe(
          map((res) => this.refactorViewRespose(res)),
          takeWhile(() => this.alive)
        )
        .subscribe(
          (response: AfaqyResponse) => {
            if (response.success) {
              this.object = response.data;
              this.editObject = response.data;
              this.resourceID = this.object.id;
              if (urlAction === 'copy' || urlAction === 'add') {
                this.object.id = '';
              }
              this.fillFormValues();
              if (urlAction === 'copy') {
                // this.form.markAsDirty();
              }
              this.applyAfterLoad(response);
            }
            this.isViewLoaded.next(true);
            this.loading = false;
            this.resize();
          },
          (error) => {
            this.loading = false;
            this.router.navigate([this.listURL], {
              skipLocationChange: AppConfig.skipLocationChange,
            });
            this.isViewLoaded.next(false);
          }
        );
      //     }
      //   },
      // });
    }
  }

  refactorViewRespose(response) {
    return response;
  }

  resize() {
    setTimeout(function () {
      AfaqyHelper.windowResize();
    }, 1000);
  }

  ngAfterViewInit() {}

  applyAfterLoad(response: any) {}

  applySideEffects() {
    this.accessTabSelectedUsers = this.object.users_list;
    this.object.users_list = AfaqyHelper.cloneObject(this.object.users_list);
  }

  canDeactivate(): any {
    if (this.forceDeactivate || this.form.pristine || !this.form.touched) {
      return true;
    }
    return this.service.confirm();
  }

  modalClose($event?: any) {
    if (this.forceDeactivate || this.form.pristine || !this.form.touched) {
      this.navigateToList();
    } else {
      const dialog = this.service.confirm().subscribe((value) => {
        if (value) this.navigateToList(false);
        else return;
      });
    }
  }

  navigateToList(refresh: boolean = false) {
    if (AppConfig.isCMS) {
      this.closeForm.next();
      return;
    }
    this.router.navigate([this.listURL], {
      skipLocationChange: AppConfig.skipLocationChange,
    });
  }

  fillFormValues() {
    let fobj = {};
    for (let field in this.form_fields) {
      fobj[field] = this.object[field];
    }
    this.form.reset(fobj);
    this.applySideEffects();
  }

  createForm() {
    this.form = this.fb.group(this.form_fields);
    this.applySideEffects();
    this.forceDeactivate = false;
  }

  revert() {
    this.fillFormValues();
  }

  reset() {
    if (this.isAdd && !this.isCopy) {
      this.object = this.modelObject;
      this.deleteFile('file', 'image');
    }
    if (!this.isAdd || this.isCopy) {
      this.object = this.editObject;
      if (!this.editObject.img) {
        this.deleteFile('file', 'image');
      }
    }
    this.fillFormValues();
    // this.form.reset();

    this.form.markAsPristine();

    this.message.clear();
  }

  beforePrepareSave() {}

  prepareSave(): AppModel {
    this.beforePrepareSave();
    const formModel = this.form.value;
    const saveObj = this.service.modelInstance;
    saveObj.id = this.object.id;
    let formField: any;
    for (let field in this.form_fields) {
      formField = formModel[field];
      if (typeof formField === 'string') {
        formField = formField.trim();
      }
      saveObj[field] = formField;
    }
    saveObj.updloadfiles = this.files;
    saveObj.users_list = this.accessTabSelectedUsers;
    return this.afterPrepareSave(saveObj);
  }

  afterPrepareSave(obj: any): any {
    return obj;
  }

  prepareErrors(response: AfaqyResponse) {
    return response;
  }

  afterFail(err: any) {
    this.posting = false;
    let error = new AfaqyResponse();
    error.copyInto(JSON.parse(err));
    error = this.prepareErrors(error);
    let errorsList = error.errors;
    AfaqyHelper.setFGErrors(this.form, errorsList);
    this.message.type = 'danger';
    this.message.message = error.message || 'please-try-again';
    this.message.validationMessages = this.prepareValidationMessages({
      ...errorsList,
    });

    this.updateValidationMessages('exist');

    // this.message = JSON.parse(JSON.stringify(this.message))
    AfaqyHelper.calcModalHeight(true);
  }

  prepareValidationMessages(msgs: any) {
    return msgs;
  }

  afterSuccess(msg: any, isAdd: boolean = true) {
    AfaqyHelper.calcModalHeight(false);
    this.posting = false;
    this.forceDeactivate = true;
    this.notify(msg, this.notificationTitle);
    this.navigateToList(true);
  }

  notify(msg: any, title: any, type: any = 'success') {
    const transMsg = this.translate.instant(msg);
    const transTitle = this.translate.instant(title);
    this.service.pushNotification(transMsg, transTitle, type);
  }

  deleteFile(key: any, property: any = '') {
    this.selectedFileNames[key] = '';
    delete this.files[key];
    if (property) {
      this.filesPaths[property] = {
        url: this.object[property + 'Url'],
        upload: false,
      };
      this.removeInputValue(property);
    }
  }

  removeInputValue(key: any) {}

  fileChange(event: any, key: any, property: any = '') {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.files[key] = { file: file, name: file.name };
      this.selectedFileNames[key] = file.name;

      var reader = new FileReader();
      reader.onload = (e: any) => {
        this.filesPaths[property] = this.filesPaths[key] = {
          url: e.target.result,
          upload: true,
        };
      };
      reader.readAsDataURL(event.target.files[0]);
      this.form.markAsDirty();
    } else {
      this.selectedFileNames[key] = '';
      delete this.files[key];
      if (property) {
        property == 'img' ? (property = 'imgUrl') : (property = property);
        this.filesPaths[property] = {
          url: this.object[property],
          upload: false,
        };
      }
    }
    this.form.markAsDirty();
  }

  doCustomValidation() {
    return true;
  }

  /**
   * Trim form controls values that have a string value.
   */
  protected trimFormValues(): void {
    if (!this.formControls || !this.formControls.length) return;
    this.formControls.map((control: UntypedFormControl) => {
      if (typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  onSubmit(event?) {
    this.trimFormValues();
    this.showValidation = true;
    if (!this.form.valid || !this.doCustomValidation()) {
      AfaqyHelper.touchAll(this.form);
      return;
    }

    this.object = this.prepareSave();
    this.posting = true;
    if (this.message) {
      this.message.clear();
      this.updateValidationMessages('');
    }
    if (this.object.id) {
      this.object = this.refactorObjectBeforeSubmit(this.object);
      this.service
        .update(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response) => {
            this.updateResourcesAfterSave(this.object.id);
            this.afterSuccess(this.getNotificationMsg('update'));
          },
          (error) => {
            //Logger.error(error);
            this.afterFail(error);
          }
        );
    } else {
      this.object = this.refactorObjectBeforeSubmit(this.object);
      this.service
        .create(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response: AfaqyResponse) => {
            this.updateResourcesAfterSave(response.data.id, true);
            this.afterSuccess(this.getNotificationMsg('add'));
          },
          (error) => {
            //Logger.error(error);
            this.afterFail(error);
          }
        );
    }
  }

  refactorObjectBeforeSubmit(object) {
    return object;
  }

  updateResourcesAfterSave(id: any, add: boolean = false) {
    if (AppConfig.isCMS) {
      this.object.id = id;
      this.service.updateOptionsList(this.object);
    } else {
      if (add) {
        this.service.addToResource(id);
      } else {
        this.service.updateResourceObject(id);
      }
    }
  }

  updateUsersSelection($event: any) {
    this.accessTabSelectedUsers = $event['ids'];
    this.form.markAsDirty();
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
