import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { CustomerTypes } from '../models';

@Injectable()
export class CustomerTypesService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  get limitForPageList() {
    return 0;
  }

  customerTypesList() {
    return this.getOptionsList('customer_type');
    // return this.apiRequest.authPost("customer_type/lists", {limit: 0, projection: ['id', 'name']}).map((result: AfaqyAPIResponse) => {
    //     //let result = response.json();
    //     if (result.status_code == 200) {
    //         let list = [];
    //         for (let obj of result.data) {
    //             let object = new CustomerTypes();
    //             object.copyInto(obj);
    //             list.push(object);
    //         }
    //         return list;
    //     }
    //     return [];
    // });
  }

  routerPrefix(val: string = '') {
    return val ? val : 'customer_type';
  }

  get modelInstance() {
    return new CustomerTypes();
  }
}
