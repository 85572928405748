import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActionsOptions } from 'app/shared/components/afaqy-actions/interfaces';
import { ShareLocationModalComponent } from 'app/shared/components/share-location-modal/share-location-modal.component';
import { ListCustomAction } from 'app/shared/interfaces/interfaces';
import { takeWhile } from 'rxjs/operators';
import { AfaqyResponse } from '../core/classes';
import { AfaqyAPIResponse } from '../core/classes/afaqy-response';
import { AuthService, RootService } from '../core/services';
import { AfaqyFlexGridComponent } from '../shared/components';
import { ImportToFillComponent } from '../shared/components/import-to-fill/import-to-fill.component';
import { TemplateHostDirective } from '../shared/directives';
import { AfaqyHelper, AppConfig, Message } from './classes';
import { ZoneGroupService } from '../modules/zones/services';

@Component({
  selector: 'afaqy-core-list',
  template: ``,
})
export class CoreListComponent implements OnInit, AfterViewInit, OnDestroy {
  alive: boolean = true;
  cid = '';
  cidPermissionKey: string;
  allColumns: any[] = [];
  reload: boolean = true;
  loading: boolean = false;
  message: Message;
  staticHeader: boolean = true;
  customHeader: any = {};
  is_trashed: boolean = false;
  multiple_ids: string[] = [];
  isRoot = false;
  count: number;
  config = AppConfig;
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('listgrid', { static: false, read: AfaqyFlexGridComponent })
  protected listgrid: AfaqyFlexGridComponent;
  @ViewChild(TemplateHostDirective)
  headerHost: TemplateHostDirective;
  autoUpdatetitle = true;
  gridOptimizer = {
    gidOptimLeft: '',
    gidOptimTop: '',
    status: false,
  };
  showGridCustomizer: boolean;
  refreshGrid: boolean;
  backToListRouterLink = '../';
  actionsOptions: ActionsOptions = {
    reload: true,
    add: true,
    trash: true,
    import: true,
    export: true,
    importToAdd: false,
    importToFill: false,
    showBulkDelete: true,
  };
  downloadTemplateOptions = { xlsx: true, csv: true, xls: true };
  // public showOutlet:boolean = false;
  customActions: ListCustomAction[];

  @ViewChild('stepperModel', { read: ViewContainerRef })
  stepperModel: ViewContainerRef;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected translate: TranslateService,
    protected route: ActivatedRoute,
    public service: RootService,
    protected router: Router,
    public authService?: AuthService
  ) {
    this.message = new Message();

    this.cid = this.service.cid;
    this.cidPermissionKey = this.service.cidPermissionKey;

    this.isRoot = this.service.authService.isRoot();
    if (!this.config.isCMS) {
      this.authService.loadedSession
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (flag) => {
            if (flag) this.service.startAutoload();
          },
        });
    }
  }

  ngAfterViewInit() {
    this.authService?.loadedSession
      .pipe(takeWhile(() => this.alive))
      .subscribe((load) => {
        if (load) {
          if (!this.staticHeader && this.customHeader['component']) {
            let componentFactory =
              this.componentFactoryResolver.resolveComponentFactory(
                this.customHeader['component']
              );
            let viewContainerRef = this.headerHost.viewContainerRef;
            viewContainerRef.clear();
            let componentRef =
              viewContainerRef.createComponent(componentFactory);
            componentRef.instance['data'] = this.customHeader['data'];
          }
        }
      });
  }

  ngOnInit() {
    const colsFromService = this.service.gridColumns(this.is_trashed);

    if (colsFromService.length) {
      this.allColumns = colsFromService;
    }
    if (this.autoUpdatetitle) {
      AfaqyHelper.setTitle({ pageIcon: this.listingIcon, pageTitle: this.cid });
    }
    AfaqyHelper.windowResize();
    if (this.service.failedLoading) {
      this.refresh();
    }

    this.service.resources
      .pipe(takeWhile(() => this.alive))
      .subscribe((event) => {
        if (Object.keys(event).length > 0) {
          if (this.service.pages && this.service.pages.hasNext()) {
            this.loading = true;
          } else {
            this.loading = false;
          }
        } else {
          this.reload = false;
        }
        AfaqyHelper.windowResize();
      });
    AfaqyHelper.setListingIcon(this.listingIcon);
  }
  archive(id) {
    let subscription = this.service
      .confirm(
        'confirm-delete-params',
        this.multiple_ids.length,
        this.translate.instant('multipleDelete.' + this.cid)
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (response) {
          this.loading = true;
          this.service
            .archive(id)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                this.notify(this.getDeletedMsg(id), 'headings.' + this.cid);
                this.service.totalItemsList =
                  this.service.totalItemsList - this.multiple_ids.length;
                this.service.removeFromResources(id);
                this.applyAfterDelete(id);
                this.loading = false;
                this.multiple_ids = [];
                // this.service.autoLoadResources(1);
              },
              (error) => {
                this.archiveErrorHandler(error);
              },
              () => {
                this.loading = false;
              }
            );
        }
        subscription.unsubscribe();
      });
    return false;
  }

  /**
   * archive error handling logic
   * @param error
   */
  archiveErrorHandler(error) {
    this.notify(
      this.getErrorDeletedMsg(error),
      'headings.' + this.cid,
      'error'
    );
    this.loading = false;
  }

  multipleDelete() {
    if (this.multiple_ids.length == 0) {
      this.notify('please_select_items', 'headings.' + this.cid, 'error');
    } else {
      this.archive(this.multiple_ids);
    }
  }
  multipleDeleteForever() {
    if (this.multiple_ids.length == 0) {
      this.notify('please_select_items', 'headings.' + this.cid, 'error');
    } else {
      this.deleteAllForever(this.multiple_ids);
    }
  }
  loaded(event: any) {
    this.reload = false;
  }

  get listingIcon() {
    return this.service.listingIcon
      ? this.service.listingIcon
      : 'afaqy-icon-reports';
  }

  filteredItems(items: any[]): void {
    if (items && items.length > 0) {
      this.service.filteredResourcesList = items;
      this.service.filterResourcesApplied.next();
    }
  }

  applyAction(event: any) {
    if (this.service.customActions.includes(event.action)) {
      this.service.applyCustomAction(event.action, event.item.id);
    } else if (event.action == 'login_as') {
      this.loginAs(event);
    } else if (event.action == 'loginAsNewWindow') {
      this.service.authService
        .loginAs(event.item.id, false)
        .subscribe((response) => {
          if (response && response.status_code === 200) {
            window.open('/auth/validate/' + response.data.token);
            // this.service.authService.loadSessionData()
          } else {
            this.service.pushNotification(
              '',
              this.translate.instant('messages.' + response.message),
              'error'
            );
          }
        });
    } else if (event.action == 'update_flag') {
      const itemID = event.item.id;
      const value = !event.item[event.key];
      this.service
        .updateFlag({ id: itemID, key: event.key, value: value })
        .pipe(takeWhile(() => this.alive))
        .subscribe(() => {
          this.service.updateResourceField(itemID, event.key, value);
          this.service.pushChanges();
        });
    } else {
      const baseUrl = this.router.url;
      // .replace(
      // "?sid=" + this.service.authService.sessionID,
      // ""
      // );
      this.router.navigate(
        ['/' + baseUrl + '/' + event.action, event.item.id],
        {
          skipLocationChange: AppConfig.skipLocationChange,
        }
      );
    }
  }

  loginAs(event: any) {
    let subscription = this.service
      .confirm('confirm-login-as')
      .subscribe((response) => {
        if (response) {
          this.service.authService
            .loginAs(event.item.id, true)

            .subscribe(
              (response: AfaqyAPIResponse) => {
                if (response.status_code == 200) {
                  AfaqyHelper.clearBrowserHistory();
                  window.open('/auth/validate/' + response.data.token, '_self');
                  // this.service.authService.loadSessionData()
                } else {
                  this.service.pushNotification(
                    '',
                    this.translate.instant(`messages.${response.message}`),
                    'error'
                  );
                  this.loading = false;
                }
              },
              (error) => {
                this.message.type = 'danger';
                this.message.message = error;
                this.loading = false;
              }
            );
        }
        subscription.unsubscribe();
      });
    return false;
  }

  notify(msg: any, title: any, type: string = 'success') {
    let params = {};
    if (typeof msg == 'object') {
      params = msg['params'];
      msg = msg['msg'];
    }
    this.service.pushNotification(
      this.translate.instant(msg, params),
      this.translate.instant(title),
      type
    );
  }

  updateCols($event: any) {
    this.allColumns[$event.index].active = $event.status;
    this.listgrid.updateActiveColumns();
  }

  toggleOptions() {
    this.showGridCustomizer = !this.gridOptimizer.status;
    const gidOptimLeft = AfaqyHelper.getElm('#left-content').clientWidth;
    const gidOptimTop =
      AfaqyHelper.getElm('#page-headers').offsetHeight +
      AfaqyHelper.getElm('#header').offsetHeight;
    if (gidOptimLeft && gidOptimTop) {
      this.gridOptimizer = {
        gidOptimLeft: gidOptimLeft,
        gidOptimTop: gidOptimTop,
        status: this.showGridCustomizer,
      };
    }
  }

  removeActionsOption($event: any) {
    this.allColumns = this.allColumns.filter((item) => {
      return item['colValue'] != 'actions';
    });
  }

  refresh() {
    this.reload = this.loading = true;
    if (this.is_trashed) {
      this.service.pushChanges({ action: 'refresh' });
      this.multiple_ids = [];
    } else {
      this.service.autoLoadResources(1);
      this.multiple_ids = [];
    }
  }

  doAssignation(op: any) {
    if (this.multiple_ids.length == 0) {
      this.notify('please_select_items', 'headings.' + this.cid, 'error');
    } else {
      this.assignation(this.multiple_ids, op);
    }
  }

  doAssign(data: any) {
    this.assignation(data.id, data.op);
  }

  assignation(ids: any, op: any) {}

  getDeletedMsg(id: any): any {
    return 'notifications.resource.deleted';
  }
  getDeactivatedMsg(id: any): any {
    return 'notifications.resource.deactivated';
  }

  getErrorDeletedMsg(error: any) {
    return 'notifications.tryagain';
  }

  getRestoredMsg(ids: any[]): any {
    return ids && ids.length > 1
      ? 'all_restored'
      : 'notifications.resource.restored';
  }

  getErrorRestoredMsg(error: any) {
    if (error instanceof Object) {
      return error[error.key];
    }
    return 'notifications.tryagain';
  }

  getAssignationMsg(ids: any, op: any) {
    return 'notifications.resource.' + op;
  }

  getErrorAssignationMsg(error: any) {
    return 'notifications.tryagain';
  }

  applyAfterDelete(id: any) {}

  deleteItem(id: number, params?: object) {
    let subscription = this.service
      .confirm('confirm-delete')
      .subscribe((response) => {
        if (response) {
          this.loading = true;
          this.service.delete(id, params).subscribe(
            (response) => {
              this.multiple_ids.splice(id, 1);
              this.notify(this.getDeletedMsg(id), 'headings.' + this.cid);
              this.service.removeFromResources(id);
              this.applyAfterDelete(id);
              this.service.deductByOneList();
              this.loading = false;
            },
            (error) => {
              this.deleteErrorHandler(error);
            },
            () => {
              this.loading = false;
            }
          );
        }
        subscription.unsubscribe();
      });
    return false;
  }

  /**
   * delete error handling logic
   * @param error
   */
  deleteErrorHandler(error) {
    this.notify(
      this.getErrorDeletedMsg(error),
      'headings.' + this.cid,
      'error'
    );
    this.loading = false;
  }

  deleteAllForever(id: any) {
    let subscription = this.service
      .confirm(
        'confirm-delete-forever-params',
        this.multiple_ids.length,
        this.translate.instant('multipleDelete.' + this.cid)
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (response) {
          this.loading = true;
          this.service
            .deleteAllForever(id)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                this.notify(this.getDeletedMsg(id), 'headings.' + this.cid);
                this.service.removeForeverFromResources(id);
                this.multiple_ids = [];
                this.refresh();
                this.loading = false;
              },
              (error) => {
                this.notify(
                  this.getErrorDeletedMsg(error),
                  'headings.' + this.cid,
                  'error'
                );
                this.loading = false;
              },
              () => {
                this.loading = false;
              }
            );
        }
        subscription.unsubscribe();
      });
    return false;
  }
  deleteForever(id: any) {
    let subscription = this.service
      .confirm('confirm-delete-forever')
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (response) {
          this.loading = true;
          this.service
            .deleteForever(id)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                this.notify(this.getDeletedMsg(id), 'headings.' + this.cid);
                this.multiple_ids.splice(id, 1);
                this.service.removeForeverFromResources(id);
                this.multiple_ids = [];

                this.loading = false;
              },
              (error) => {
                this.notify(
                  this.getErrorDeletedMsg(error),
                  'headings.' + this.cid,
                  'error'
                );
                this.loading = false;
              },
              () => {
                this.loading = false;
              }
            );
        }
        subscription.unsubscribe();
      });
    return false;
  }

  toggleCheck(event: any) {
    this.multiple_ids = event['ids'];
  }

  updateIds(data: any) {
    if (data.checked) this.multiple_ids.push(data.id);
    else this, this.removeMultipleIdsItem(data.id);
  }

  /**
   * Remove a specific `id` from the `multiple_ids` list.
   * @param id Id that will be removed.
   */
  private removeMultipleIdsItem(id: string): void {
    if (!id || !this.multiple_ids || !this.multiple_ids.includes(id)) return;
    const index: number = this.multiple_ids.indexOf(id);
    this.multiple_ids.splice(index, 1);
  }

  restoreAll() {
    if (this.multiple_ids.length == 0) {
      this.notify('please_select_items', 'headings.' + this.cid, 'error');
    } else {
      this.restoreAllItem(this.multiple_ids);
    }
  }
  restoreAllItem(ids: any[]) {
    let subscription = this.service
      .confirm('confirm-restore')
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (response) {
          this.loading = true;
          this.service
            .restore(ids)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                let msg =
                  ids && ids.length > 1
                    ? 'all_restored'
                    : 'notifications.resource.restored';
                this.notify(msg, 'headings.' + this.cid);
                this.service.addMultiToResource(ids);
                this.service.totalItemsTrashedList =
                  this.service.totalItemsTrashedList - this.multiple_ids.length;
                this.multiple_ids = [];
                ids.map((id: string) => this.removeMultipleIdsItem(id));
              },
              (error) => {
                this.restoreErrorValidation(error);
                this.loading = false;
              },
              () => {
                this.loading = false;
              }
            );
        }
        subscription.unsubscribe();
      });
    return false;
  }
  restoreErrorValidation(error: any) {
    this.notify(
      this.getErrorRestoredMsg(error),
      'headings.' + this.cid,
      'error'
    );
  }

  restore(ids: any[]) {
    let subscription = this.service
      .confirm('confirm-restore')
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (response) {
          this.loading = true;
          this.service
            .restore(ids)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                if (ids.length > 1) {
                  this.multiple_ids = [];
                }
                ids.map((id: string) => this.removeMultipleIdsItem(id));
                let msg =
                  ids && ids.length > 1
                    ? 'all_restored'
                    : 'notifications.resource.restored';
                this.notify(msg, 'headings.' + this.cid);
                this.service.addMultiToResource(ids);
                this.service.deductByOneTrashedList();
              },
              (error) => {
                this.restoreErrorValidation(error);
              },
              () => {
                this.loading = false;
              }
            );
        }
        subscription.unsubscribe();
      });
    return false;
  }

  restructureObject(originalObject, translationKey) {
    const newObj = {
      key: '',
    };

    for (const key in originalObject) {
      if (originalObject.hasOwnProperty(key)) {
        const [prefix, index] = key.split('.');

        // Check if the key has a dot and the value is an array with one element
        if (
          prefix &&
          index &&
          !isNaN(index as any) &&
          Array.isArray(originalObject[key]) &&
          originalObject[key].length === 1
        ) {
          const newKey = prefix + index;
          const newValue = translationKey;
          newObj[newKey] = newValue;
          newObj.key = newKey;
        } else {
          newObj[key] = originalObject[key];
        }
      }
    }

    return newObj;
  }

  export(type: string) {
    this.loading = true;
    const ids: string[] = this.service.getFilteredIds();
    this.service
      .export(type, ids)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          const err = new AfaqyResponse();
          err.copyInto(JSON.parse(error));
          const errorsList = err.errors;
          const messagesList = [];
          for (const key in errorsList) {
            for (const index in errorsList[key]) {
              messagesList.push({
                message: 'serverValidations.' + errorsList[key][index],
                params: { field: key },
              });
            }
          }
          this.service.popup(messagesList);
          this.loading = false;
        }
      );
  }

  downloadTemplate(type: string) {
    this.loading = true;
    this.service
      .downloadTemplate(type)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.loading = false;
        },
        (error) => {
          const err = new AfaqyResponse();
          err.copyInto(JSON.parse(error));
          const errorsList = err.errors;
          const messagesList = [];
          for (const key in errorsList) {
            for (const index in errorsList[key]) {
              messagesList.push({
                message: 'serverValidations.' + errorsList[key][index],
                params: { field: key },
              });
            }
          }
          this.service.popup(messagesList);
          this.loading = false;
        }
      );
  }

  import(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.loading = true;
      let file: File = fileList[0];
      let params = { updloadfiles: { file: { file: file, name: file.name } } };
      this.service
        .import(params)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response: AfaqyResponse) => {
            let errorsList = response.data.errors;
            let messagesList = [];
            messagesList.push({
              message: 'success_count',
              params: { count: response.data.success_count },
            });
            messagesList.push({
              message: 'error_count',
              params: { count: response.data.error_count },
            });
            this.setImportErrorList(errorsList, messagesList);
            this.service.popup(messagesList);
            if (response.data.success_count) {
              this.service.autoLoadResources();
            }
            this.loading = false;
            if (this.cid == 'zones') {
              this.service.applyAfterLoadResources();
            }
          },
          (error) => {
            let err = new AfaqyResponse();
            err.copyInto(JSON.parse(error));
            let errorsList = err.errors;
            let messagesList = [];
            for (let key in errorsList) {
              messagesList.push({
                message: 'serverValidations.' + errorsList[key],
                params: { field: key },
              });
            }
            /* If root service sends error of 504 */
            if (err.status_code == '504') {
              messagesList.push({
                message: 'serverValidations.failure_response',
                params: { field: '' },
              });
            }
            /* If root service sends error of 504 */
            this.service.popup(messagesList);
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }
  setImportErrorList(errorsList: any, messagesList: any) {
    for (let row in errorsList) {
      let rowIndex = parseInt(row.replace('row-', '')) + 1;
      let rowErrors = [];
      for (let key in errorsList[row]) {
        let keyErrors = errorsList[row][key];
        for (var i = 0; i < keyErrors.length; i++) {
          this.setImportRowError(rowErrors, keyErrors, key, i);
        }
      }
      messagesList.push({
        message: 'record',
        params: { index: rowIndex },
        childs: rowErrors,
      });
    }
  }

  setImportRowError(rowErrors: any, keyErrors: any, key: any, i: any) {
    rowErrors.push({
      message: 'serverValidations.' + keyErrors[i],
      params: { field: this.translate.instant(key) },
    });
  }

  importToFill(controller: any) {
    this.stepperModel.clear();
    let componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ImportToFillComponent
      );
    let componentRef = this.stepperModel.createComponent(componentFactory);
    componentRef.instance['componentName'] = controller;
  }

  shareLocation() {
    this.stepperModel.clear();
    let componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ShareLocationModalComponent
      );
    let componentRef = this.stepperModel.createComponent(
      ShareLocationModalComponent
    );
    // componentRef.instance["item"] = this.object;
  }

  public ngOnDestroy() {
    this.alive = false;
    this.service.resetFilteredResourcesList();
  }
}
