import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from '../../map/map.service';
import { MapLayerService } from '../../map/map_layer.service';
import { Regions } from '../models';
import { LocationsService } from './locations.service';
import { asArray } from 'ol/color';
import { Fill, Stroke, Style, Text } from 'ol/style';

@Injectable()
export class LocationMapLayerService extends MapLayerService {
  constructor(
    mapService: MapService,
    private LocationsService: LocationsService,
    translate: TranslateService
  ) {
    super(mapService, LocationsService, translate);
    LocationsService.resources.subscribe({
      next: (res) => {
        switch (res.action) {
          case 'list':
          case 'addMulti':
            const regions: Array<Regions> = res.data;
            this.generateFeatures(regions);
            break;
          case 'update':
          case 'add':
            if (!res.relations) {
              let regions: Regions = res.data;
              let feature = this.createFeature(regions);
              this.features[regions.id] = feature;
              if (regions['visible']) {
                this.updateFeature(regions.id, this.features[regions.id]);
              }
            }
            break;
          case 'remove':
            for (let id of res.data) {
              this.removeFeature(id);
              delete this.features[id];
            }
            break;
          case 'visible':
            if (
              !(this.features instanceof Object) ||
              !Object.keys(this.features).length
            )
              this.generateFeatures();
            this.refresh();
            break;
          case 'refresh':
            this.removeAllFeatures();
            this.features = {};
            break;
        }
      },
    });
  }

  layerId: string = 'locations';
  features = {};

  createFeature(regions: Regions) {
    // let feature: any = {};
    // let style = this.createStyle(customer);
    // this.mapService.action((mapModel: MapModel) => {
    //     switch (customer.type) {
    //         case 'circle':
    //             feature = mapModel.createCircle(customer.vertices[0], customer.radius, style);
    //             break;
    //         case 'polygon':
    //             feature = mapModel.createPolygon(customer.vertices, style);
    //             break;
    //         case 'line':
    //             feature = mapModel.createLine(customer.vertices, style);
    //             break;
    //     }
    // });
    // feature.setId(customer.id);
    // feature.set('label', customer.name);
    // feature.set('maxResolutionForLabel', 1400);
    // return feature;
  }

  private createStyle(customer) {
    let textStyle = new Text({
      placement: 'point',
      font: 'bold 16px Arial',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    });
    return (feature, resolution) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      let color = asArray(customer.color);
      color[3] = 0.3;
      let style = new Style({
        text: textStyle,
        fill: new Fill({
          color: color,
          fillOpacity: 0.3,
        } as any),
        stroke: new Stroke({
          color: color,
          width: customer.type == 'line' ? customer.radius : 0,
        }),
      });

      return [style];
    };
  }
}
