import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import { Point, SimpleGeometry } from 'ol/geom';
import { takeWhile } from 'rxjs/operators';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';
import { MapService } from '../../../modules/map/map.service';
import { MapModel } from '../../../modules/map/mapModel';
import { BookLogService } from '../../services/book-log.service';
@Component({
  selector: 'book-log',
  styleUrls: ['./book-log.scss'],
  templateUrl: './book-log.component.html',
})
export class BookLogComponent implements AfterViewInit, OnDestroy {
  private alive: boolean = true;
  data: wjcCore.CollectionView;
  filtersCols = ['resource', 'content'];
  @ViewChild('grid') grid: wjcGrid.FlexGrid;
  hideLocation = true;

  constructor(
    private mapService: MapService,
    private service: BookLogService
  ) {}
  ngAfterViewInit(): void {
    this.data = new wjcCore.CollectionView(this.service.logs);
    this.service.changes.subscribe({
      next: (event) => {
        if (event && event['item']) {
          this.data.sourceCollection.unshift(event['item']);
        } else if (event && event['items']) {
          this.data.sourceCollection = event['items'].concat(
            this.data.sourceCollection
          );
        }
        this.data.refresh();
      },
    });
    AfaqyHelper.resizer.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        if (this.grid) this.grid.refresh();
      },
    });
    AfaqyHelper.pushResizer();
    AfaqyHelper.calculateMapConenetSectionHeight();
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  detailsOverlay;

  fitTo(item) {
    this.mapService.action((map: MapModel) => {
      let point = map.lonLatToMapPoint([item.loc.lng, item.loc.lat]);
      let pointGeom = new Point(point);
      let feature = new Feature({
        label: '',
        geometry: pointGeom,
      });
      map
        .getMap()
        .getView()
        .fit(feature.getGeometry() as SimpleGeometry, { maxZoom: 15 });

      this.hideLocation = false;
      const popup = document.getElementById('map-overlay-flash');
      this.detailsOverlay = new Overlay({
        position: point,
        positioning: 'top-left',
        element: popup,
        stopEvent: false,
      });
      map.getMap().addOverlay(this.detailsOverlay);

      setTimeout(() => {
        this.detailsOverlay.setPosition(undefined);
        this.hideLocation = true;
      }, 1000);

      return false;
    });
  }
}
