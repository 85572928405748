import { AfaqyObject } from '../../../common/classes';
export class UnitCounters extends AfaqyObject {
  private _odometer_type: string = 'gps';
  private _engine_hours_type: string = 'acc';
  private _engine_hours: number;
  private _odometer: number;
  private _last_acc: number;
  private _last_acc_date: Date;

  protected copyFields = [
    'odometer_type',
    'engine_hours_type',
    'engine_hours',
    'odometer',
    'last_acc',
    'last_acc_date',
  ];

  get odometer_type(): string {
    return this._odometer_type;
  }

  set odometer_type(value: string) {
    this._odometer_type = value;
  }

  get engine_hours_type(): string {
    return this._engine_hours_type;
  }

  set engine_hours_type(value: string) {
    this._engine_hours_type = value;
  }

  get engine_hours(): number {
    return this._engine_hours ? this._engine_hours : 0;
  }

  set engine_hours(value: number) {
    this._engine_hours = value;
  }

  get odometer(): number {
    return this._odometer;
  }

  set odometer(value: number) {
    this._odometer = value;
  }

  get last_acc(): number {
    return this._last_acc;
  }

  set last_acc(value: number) {
    this._last_acc = value;
  }

  get last_acc_date(): Date {
    return this._last_acc_date;
  }

  set last_acc_date(value: Date) {
    this._last_acc_date = value;
  }
}
