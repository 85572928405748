import { AfaqyObject } from '../../../common/classes';
export class UnitFuelConsumptionRate extends AfaqyObject {
  private _enable: boolean = false;
  private _summer_rate: Number = 12;
  private _winter_rate: Number = 10;
  private _winter_from_month: Number = 12;
  private _winter_from_day: Number = 1;
  private _winter_to_month: Number = 3;
  private _winter_to_day: Number = 1;

  protected copyFields = [
    'enable',
    'summer_rate',
    'winter_rate',
    'winter_from_month',
    'winter_from_day',
    'winter_to_month',
    'winter_to_day',
  ];

  get enable(): boolean {
    return this._enable;
  }

  set enable(value: boolean) {
    this._enable = value;
  }

  get summer_rate(): Number {
    return this._summer_rate;
  }

  set summer_rate(value: Number) {
    this._summer_rate = value;
  }

  get winter_rate(): Number {
    return this._winter_rate;
  }

  set winter_rate(value: Number) {
    this._winter_rate = value;
  }

  get winter_from_month(): Number {
    return this._winter_from_month;
  }

  set winter_from_month(value: Number) {
    this._winter_from_month = value;
  }

  get winter_from_day(): Number {
    return this._winter_from_day;
  }

  set winter_from_day(value: Number) {
    this._winter_from_day = value;
  }

  get winter_to_month(): Number {
    return this._winter_to_month;
  }

  set winter_to_month(value: Number) {
    this._winter_to_month = value;
  }

  get winter_to_day(): Number {
    return this._winter_to_day;
  }

  set winter_to_day(value: Number) {
    this._winter_to_day = value;
  }
}
