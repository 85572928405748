<div>
  <button
    *ngIf="!isView"
    [attr.testId]="'save'"
    type="button"
    class="btn {{ customConfig.save.class }} btn-custom float-right mx-2"
    (click)="submit.next()"
    [disabled]="posting || form.pristine"
  >
    <i class="{{ customConfig.save.iconClass }}"></i>
    {{ customConfig.save.name | translate }}
  </button>
  <div class="float-right mx-2">
    <afaqy-spinner [loading]="posting"></afaqy-spinner>
  </div>
  <button
    *ngIf="cancelbutton || isView"
    [attr.testId]="'cancel'"
    type="button"
    class="btn {{ customConfig.cancel.class }} btn-custom float-left mx-2"
    (click)="cancel.next()"
  >
    <i class="{{ customConfig.cancel.iconClass }}"></i>
    {{ customConfig.cancel.name | translate }}
  </button>
  <button
    *ngIf="!isView"
    [attr.testId]="'reset'"
    class="btn btn-custom float-left mx-2"
    [ngClass]="
      cancelbutton ? customConfig.reset.class : customConfig.cancel.class
    "
    (click)="reset.next()"
    [disabled]="posting || form.pristine"
  >
    <i class="{{ customConfig.reset.iconClass }}"></i>
    {{ customConfig.reset.name | translate }}
  </button>
</div>
