import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'unit-form-fuel-consumption',
  templateUrl: './unit-form-fuel-consumption.component.html',
  animations: [
    trigger('showhide', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0)',
          'transform-origin': '0 0',
        }),
        animate(
          '0.2s linear',
          style({
            opacity: 1,
            transform: 'scale(1)',
            'transform-origin': '0 0',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '0.2s linear',
          style({
            opacity: 0,
            transform: 'scale(0)',
            'transform-origin': '0 0',
          })
        ),
      ]),
    ]),
  ],
})
export class UnitFormFuelConsumptionComponent implements OnInit, AfterViewInit {
  @Input() group: UntypedFormGroup;
  formOptions = {
    months: Array.from({ length: 12 }, (_, i) => i + 1),
    monthFromDays: [],
    monthToDays: [],
  };
  cid = 'units-fuel_consumption-';
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.setDefaultDays();
    this.onChangeFromMonth();
    this.onChangeToMonth();
  }

  toggleRate() {
    this.group.controls['rate']['controls']['enable'].setValue(
      !this.group.controls['rate']['controls']['enable'].value
    );
  }

  /*    toggleMath() {
        this.group.controls["math"]["controls"]["enable"].setValue(
            !this.group.controls["math"]["controls"]["enable"].value
        );
    }*/

  onChangeFromMonth() {
    this.winterFromMonthCtl.valueChanges.subscribe({
      next: (value: number) => {
        this.formOptions.monthFromDays = this.generateDaysList(value);
      },
    });
  }

  onChangeToMonth() {
    this.winterToMonthCtl.valueChanges.subscribe({
      next: (value: number) => {
        this.formOptions.monthToDays = this.generateDaysList(value);
      },
    });
  }

  private setDefaultDays(): void {
    this.formOptions.monthFromDays = this.generateDaysList(
      this.winterFromMonthCtl.value
    );
    this.formOptions.monthToDays = this.generateDaysList(
      this.winterToMonthCtl.value
    );
  }

  private daysInMonth(month: number) {
    if (!month || month > 12 || month < 1) return;
    const year: number = new Date().getFullYear();
    return new Date(year, month, 0).getDate();
  }

  private generateDaysList(month: number): number[] {
    if (!month || month > 12 || month < 1) return;
    const daysLength: number = this.daysInMonth(month);
    return Array.from({ length: daysLength }, (_, i) => i + 1);
  }

  get winterFromMonthCtl(): UntypedFormControl {
    return this.group.controls['rate']['controls'][
      'winter_from_month'
    ] as UntypedFormControl;
  }
  get winterToMonthCtl(): UntypedFormControl {
    return this.group.controls['rate']['controls'][
      'winter_to_month'
    ] as UntypedFormControl;
  }
}
