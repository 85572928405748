<interval-colors #intervalColors></interval-colors>

<section class="interval-action-container">
  <span
    *ngIf="intervalColorsService.resultType === 'value'"
    class="interval-action"
    #addInterval
    (click)="setFormVisibility()"
    afaqy-tooltip
    [tooltipText]="'tooltips.add' | translate"
  >
    <i class="fa fa-plus-circle clickOutsideExcept" aria-hidden="true"></i>
  </span>
  <span
    *ngIf="intervalColorsService.resultType !== 'codeValue'"
    class="interval-action"
    (click)="resetSensorColors()"
    afaqy-tooltip
    [tooltipText]="'tooltips.reset' | translate"
  >
    <i class="fa fa-undo" aria-hidden="true"></i>
  </span>
</section>

<sensor-colors-form
  *ngIf="sensorColorsFormService.isOpen"
  #sensorColorsForm
  clickOutside
  (clickOutside)="sensorColorsFormService.closeSensorForm()"
></sensor-colors-form>
