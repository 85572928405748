import { AfaqyHelper, AfaqyObject, AppModel } from '../../../common/classes';
import { UnitSensor } from './unit-sensor';
import { UnitService } from '../services';
export class UnitDriverBehavior extends AppModel {
  private _unit_id: string;
  private _name: string;
  private _sensor_id: string;
  private _penalty: number;
  private _minValue: number;
  private _maxValue: number;
  private _minSpeed: number;
  private _maxSpeed: number;
  private _minDuration: number;
  private _maxDuration: number;

  protected copyFields = [
    'id',
    'name',
    'unit_id',
    'sensor_id',
    'penalty',
    'minValue',
    'maxValue',
    'minSpeed',
    'maxSpeed',
    'minDuration',
    'maxDuration',
  ];
  protected createFields = [
    'name',
    'unit_id',
    'user_id',
    'sensor_id',
    'penalty',
    'minValue',
    'maxValue',
    'minSpeed',
    'maxSpeed',
    'minDuration',
    'maxDuration',
  ];
  protected updateFields = [
    'name',
    'unit_id',
    'user_id',
    'sensor_id',
    'penalty',
    'minValue',
    'maxValue',
    'minSpeed',
    'maxSpeed',
    'minDuration',
    'maxDuration',
  ];

  get unit_id(): string {
    return this._unit_id;
  }

  set unit_id(value: string) {
    this._unit_id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get sensor_id(): string {
    return this._sensor_id;
  }

  set sensor_id(value: string) {
    this._sensor_id = value;
  }

  get penalty(): number {
    return this._penalty;
  }

  set penalty(value: number) {
    this._penalty = value;
  }

  get minValue(): number {
    return this._minValue;
  }

  set minValue(value: number) {
    this._minValue = value;
  }

  get maxValue(): number {
    return this._maxValue;
  }

  set maxValue(value: number) {
    this._maxValue = value;
  }

  get minSpeed(): number {
    return this._minSpeed;
  }

  set minSpeed(value: number) {
    this._minSpeed = value;
  }

  get maxSpeed(): number {
    return this._maxSpeed;
  }

  set maxSpeed(value: number) {
    this._maxSpeed = value;
  }

  get minDuration(): number {
    return this._minDuration;
  }

  set minDuration(value: number) {
    this._minDuration = value;
  }

  get maxDuration(): number {
    return this._maxDuration;
  }

  set maxDuration(value: number) {
    this._maxDuration = value;
  }
}
