import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DriverService } from 'app/modules/drivers/services';
import { StatusService } from 'app/modules/status/services';
import { TailerService } from 'app/modules/tailers/services';
import { UnitService } from 'app/modules/units/services';
import { take, takeWhile } from 'rxjs/operators';

import * as wjcCore from '@grapecity/wijmo';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'unit-form-profile',
  templateUrl: './unit-form-profile.component.html',
  styleUrls: ['./unit-form-profile.component.scss'],
})
export class UnitFormProfileComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
  alive: boolean = true;
  cid = 'units-profile-';
  measurementTypes: string[] = ['weight', 'volume', 'length', 'count'];
  unitId: string;
  @Input() group: FormGroup;
  @Input() editObject;
  @Input() files: any = {};
  @Input() filesPaths: any = {};
  @Input() selectedFileNames: string[] = [];

  @Output() deleteIcon: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeIcon: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectIcon: EventEmitter<any> = new EventEmitter<any>();

  showIconLibrary = false;
  formOptions = {
    iconsList: [],
  };

  @Input() cctvApp = false;
  businessTypes: wjcCore.CollectionView;
  defatultBusinessTypes = {
    waste: false,
    transportation: false,
    water: false,
    hajj: false,
    taxi: false,
  };
  businessTypeModified = false;
  constructor(
    private translate: TranslateService,
    public route: ActivatedRoute,
    public service: UnitService,
    public driverService: DriverService,
    public tailerService: TailerService,
    public statusService: StatusService,
    private authService: AuthService
  ) {
    //this.driverService.autoLoadResources();

    this.businessTypes = new wjcCore.CollectionView([
      { id: 'waste', title: this.translate.instant('units.waste') },
      {
        id: 'transportation',
        title: this.translate.instant('units.transportation'),
      },
      { id: 'water', title: this.translate.instant('units.water') },
      { id: 'hajj', title: this.translate.instant('units.hajj') },
      { id: 'taxi', title: this.translate.instant('units.taxi') },
    ]);
  }

  ngOnInit() {
    //this.formOptions.driversList.sourceCollection = this.driverService.listForSelect;
    //this.formOptions.tailersList.sourceCollection = this.tailerService.listForSelect;

    if (this.service.iconsList.length) {
      this.formOptions.iconsList = this.service.iconsList;
    } else {
      this.service
        .icons()
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (icons) => {
            this.formOptions.iconsList = <Array<any>>icons;
          },
        });
    }
    this.unitId = this.route.snapshot.params['id'];

    if (this.isUseShifts()) {
      this.group.controls['auto_driver'].disable();
    }
  }

  isUseShifts(): boolean {
    const isUseShifts: boolean = this.authService.user.use_shifts
      ? true
      : false;
    return isUseShifts;
  }

  getDriverInputTooltip() {
    return this.isUseShifts()
      ? this.translate.instant('units.driver_with_shift_tooltip')
      : '';
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkSelected();
  }

  checkSelected() {
    const business = this.group.controls['business_types'].value;
    // set checked value for multiselect
    this.businessTypes.sourceCollection.map((item) => {
      item['checked'] = !!business[item.id];
      return item;
    });
  }
  // TODO Refactor ddl to be afaqy-custom-select instead of wj-multi-select to remove wj form
  businessTypeChange(value, update = true) {
    if (this.unitId) {
      this.businessTypeModified = true;
    }
    // set selected values as true
    const business = { ...this.defatultBusinessTypes };
    value?.forEach((item) => {
      business[item.id] = true;
    });
    this.group.controls['business_types'].setValue(business);
    if (update) {
      this.group.markAsDirty();
    }
  }

  closeBusinesDropdown(event) {
    if (this.businessTypeModified) {
      let change = false;
      const newValue = this.group.controls['business_types'].value;
      Object.keys(newValue).map((key) => {
        if (
          this.editObject['business_types'] &&
          newValue[key] !== this.editObject['business_types'][key]
        ) {
          change = true;
        }
      });
      if (change) {
        this.service
          .confirm('units.modify_business_types')
          .pipe(take(1))
          .subscribe((res) => {
            this.businessTypeModified = false;
            if (!res) {
              this.group.controls['business_types'].setValue(
                this.editObject['business_types']
              );
              setTimeout(() => {
                this.businessTypes = null;
                this.businessTypes = new wjcCore.CollectionView([
                  {
                    id: 'waste',
                    title: this.translate.instant('units.waste'),
                    checked: !!this.editObject['business_types']['waste'],
                  },
                  {
                    id: 'transportation',
                    title: this.translate.instant('units.transportation'),
                    checked:
                      !!this.editObject['business_types']['transportation'],
                  },
                  {
                    id: 'water',
                    title: this.translate.instant('units.water'),
                    checked: !!this.editObject['business_types']['water'],
                  },
                  {
                    id: 'hajj',
                    title: this.translate.instant('units.hajj'),
                    checked: !!this.editObject['business_types']['hajj'],
                  },
                  {
                    id: 'taxi',
                    title: this.translate.instant('units.taxi'),
                    checked: !!this.editObject['business_types']['taxi'],
                  },
                ]);
              }, 1);
            }
          });
      }
    }
  }
  ngAfterViewInit() {
    this.setUnitProfileDefaultValues();
    // this.tailerService.resources.pipe(takeWhile(() => this.alive)).subscribe(() => {
    //     let selectedValue = this.group.controls['tailer_id'].value;
    //     this.formOptions.tailersList.sourceCollection = this.tailerService.listForSelect;
    //     if (this.tailersAC)
    //         this.tailersAC.selectedValue = selectedValue;
    // });
    // this.driverService.resources.pipe(takeWhile(() => this.alive)).subscribe(() => {
    //     let selectedValue = this.group.controls['driver_id'].value;
    //     this.formOptions.driversList.sourceCollection = this.driverService.listForSelect;
    //     if (this.driversAC)
    //         this.driversAC.selectedValue = selectedValue;
    // });
  }

  setUnitProfileDefaultValues(): void {
    if (this.unitId) {
      const measurementTypeFC: FormControl =
        this.group.controls['profile']['controls']['measurement_type'];
      const maxCapacityFC: FormControl =
        this.group.controls['profile']['controls']['max_capacity'];
      if (!measurementTypeFC.value) measurementTypeFC.setValue(null);
      if (!maxCapacityFC.value) maxCapacityFC.setValue(0);
    }
  }

  resetUnitProfileDefaultValues(): void {
    const maxCapacityFC: FormControl =
      this.group.controls['profile']['controls']['max_capacity'];
    const measurementTypeFC: FormControl =
      this.group.controls['profile']['controls']['measurement_type'];
    if (!measurementTypeFC.value) measurementTypeFC.setValue(null);
    if (!maxCapacityFC.value) {
      maxCapacityFC.setValue(0);
    }
  }

  toggleAutoDriver() {
    this.group.controls['auto_driver'].setValue(
      !this.group.controls['auto_driver'].value
    );
  }

  toggleAutoTailer() {
    this.group.controls['auto_tailer'].setValue(
      !this.group.controls['auto_tailer'].value
    );
  }

  iconChange(event) {
    this.changeIcon.next(event);
  }

  iconDelete() {
    this.deleteIcon.next();
  }

  updateIcon(icon) {
    this.group.controls['icon'].setValue(icon.key);
    this.selectIcon.next({ url: icon.url });
    this.group.markAsDirty();
    this.hideIconLibs();
  }

  toggleIconLibs() {
    this.showIconLibrary = !this.showIconLibrary;
  }

  showIconLibs() {
    this.showIconLibrary = true;
  }

  hideIconLibs() {
    this.showIconLibrary = false;
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  uploadImageTrigging() {
    const elm = document.getElementById('uploadImageTrigger');
    elm.click();
  }
}
