import { AppModel } from '../../../common/classes';
export class UnitGroup extends AppModel {
  private _parent_id: string;
  private _name: string;
  private _parent: UnitGroup;
  private _unitsList: string[] = [];
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'parent_id',
    'unitsList',
    'deleted_at',
    'users_list',
  ];
  protected createFields = [
    'name',
    'parent_id',
    'user_id',
    'unitsList',
    'users_list',
  ];
  protected updateFields = [
    'name',
    'parent_id',
    'user_id',
    'unitsList',
    'users_list',
  ];

  get parent_id(): string {
    return this._parent_id;
  }

  set parent_id(value: string) {
    this._parent_id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get parent(): UnitGroup {
    return this._parent;
  }

  set parent(value: UnitGroup) {
    this._parent = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get unitsList(): string[] {
    if (this._unitsList == null) {
      return [];
    }
    return this._unitsList;
  }

  set unitsList(value: string[]) {
    if (value) {
      this._unitsList = value;
    }
  }

  get parentName() {
    if (this.parent) {
      return this.parent.name;
    }
    return '';
  }

  get gic() {
    return this.unitsList.length;
  }
}
