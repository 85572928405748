import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from 'app/modules/map/map.service';
import { MapModel } from 'app/modules/map/mapModel';
import { MapLayerService } from 'app/modules/map/map_layer.service';
import { Warehouses } from 'app/modules/warehouses/models/warehouses';
import { WarehousesService } from 'app/modules/warehouses/services';
import { StopData } from '../interfaces';
import { ManualRoute, PlanDetails } from '../models';
import { ManualRouteService } from './manual-route.service';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
const polyline = require('google-polyline');

@Injectable()
export class ManualRouteMapLayerService extends MapLayerService {
  allResourcesList: any[];
  lineVertices: any;
  stopData: StopData = { stopsVertices: [], stopsIds: [], stopsNames: [] };
  layerId: string = 'manualRoutes';
  features: any = {};

  constructor(
    public mapService: MapService,
    public translate: TranslateService,
    protected manualRouteService: ManualRouteService,
    private warehouseService: WarehousesService
  ) {
    super(mapService, manualRouteService, translate);
    this.onResourcesActions();
    this.setAllResourcesList();
  }

  private onResourcesActions() {
    this.manualRouteService.resources.subscribe({
      next: (res) => {
        switch (res.action) {
          case 'list':
          case 'addMulti':
            let manualRoutes: ManualRoute[] = res.data;
            let features: any[] = [];
            for (let manualRoute of manualRoutes) {
              this.features[manualRoute.id] = this.createFeature(manualRoute);
              if (manualRoute['visible']) {
                features.push(...this.features[manualRoute.id]);
              }
            }
            this.addFeatures(features);
            break;
          case 'update':
          case 'add':
            if (!res.relations) {
              const manualRoute: ManualRoute = res.data;
              this.features[manualRoute.id] = this.createFeature(manualRoute);
              this.refresh();
            }
            break;
          case 'remove':
            for (let id of res.data) {
              const featuresIds = this.getManualRouteFeatures(id);
              for (let featuresId of featuresIds) {
                this.removeFeature(featuresId);
                delete this.features[featuresId];
              }
            }
            break;
          case 'visible':
            this.refresh();
            break;
          case 'refresh':
            this.removeAllFeatures();
            this.features = {};
            break;
        }
      },
    });
  }

  private setAllResourcesList() {
    this.manualRouteService.allResourcesList.subscribe({
      next: (resourcesList: any[]) => {
        this.allResourcesList = resourcesList;
      },
    });
  }

  private getManualRouteFeatures(manualRouteId: string) {
    if (!manualRouteId) return;
    const manualRoute: ManualRoute = this.allResourcesList.filter(
      (manualRoute) => manualRoute.id == manualRouteId
    )[0];
    let features: string[] = [];
    features.push(manualRoute.id);
    for (let stopDetails of manualRoute.stops_details) {
      features.push(stopDetails['stop-id']);
    }
    return features;
  }

  createFeature(manualRoute: ManualRoute): any[] {
    this.setStopsData(manualRoute.stops_details);
    this.setLineVertices(manualRoute.polyline);
    let features: any[] = this.getFeatures(manualRoute);
    return features;
  }

  private getFeatures(manualRoute: ManualRoute): any[] {
    if (!this.lineVertices || !this.stopData.stopsVertices) return [];
    let features: any[] = [];
    const lineFeature: any = this.createLineFeature(
      this.lineVertices,
      manualRoute
    );
    features.push(lineFeature);
    let stopFeature: any;
    this.stopData.stopsVertices.map((stopVertices: any, index: number) => {
      stopFeature = this.createStopFeature(
        stopVertices,
        this.stopData.stopsIds[index],
        this.stopData.stopsNames[index],
        manualRoute
      );
      features.push(stopFeature);
    });
    return features;
  }

  private createLineFeature(vertices: any[], manualRoute: ManualRoute) {
    let feature: any = {};
    let style = this.createStyle(manualRoute);
    this.mapService.action((mapModel: MapModel) => {
      feature = mapModel.createLine(vertices, style);
      feature.setId(manualRoute.id);
      feature.set('label', manualRoute.name);
      feature.set('maxResolutionForLabel', 1400);
    });
    return feature;
  }

  private createStopFeature(
    vertices: any[],
    stopId: string,
    stopName: string,
    manualRoute: ManualRoute
  ) {
    let feature: any = {};
    let style = this.createStyle(manualRoute, 1);
    this.mapService.action((mapModel: MapModel) => {
      feature = mapModel.createCircle(vertices, 80, style);
      feature.setId(stopId);
      feature.set('label', stopName);
      feature.set('maxResolutionForLabel', 1400);
    });
    return feature;
  }

  private setStopsData(list: PlanDetails[]) {
    if (!list || list.length == 0) return;
    this.stopData.stopsIds = [];
    this.stopData.stopsNames = [];
    this.stopData.stopsVertices = [];
    list.map((planDetails: PlanDetails) => {
      this.stopData.stopsIds.push(planDetails['stop-id']);
      this.stopData.stopsNames.push(planDetails['stop-name']);
      this.stopData.stopsVertices.push([
        +planDetails['stop-longitude'],
        +planDetails['stop-latitude'],
      ]);
    });
    this.renameWarhouses();
  }

  private renameWarhouses() {
    const lastIndex: number = this.stopData.stopsIds.length - 1;
    this.stopData.stopsNames[0] = this.stopData.stopsNames[0] + ' (S)';
    const endWarehouse: any = this.warehouseService.resourcesList.filter(
      (warehouse: Warehouses) =>
        warehouse.id == this.stopData.stopsIds[lastIndex]
    )[0];
    if (
      endWarehouse &&
      this.stopData.stopsIds[0] != this.stopData.stopsIds[lastIndex]
    )
      this.stopData.stopsNames[lastIndex] =
        this.stopData.stopsNames[lastIndex] + ' (E)';
    else if (this.stopData.stopsIds[0] == this.stopData.stopsIds[lastIndex])
      this.stopData.stopsNames[0] = this.stopData.stopsNames[lastIndex] =
        this.stopData.stopsNames[lastIndex] + ' (S/E)';
  }

  private setLineVertices(planDetailsPolyline: string) {
    if (planDetailsPolyline) {
      this.lineVertices = polyline.decode(planDetailsPolyline);
      this.swapCoordinates(this.lineVertices);
    }
  }

  private swapCoordinates(arr: any[]) {
    arr.map(
      (element: any) => ([element[0], element[1]] = [element[1], element[0]])
    );
  }

  private createStyle(
    manualRoute: ManualRoute,
    featureStrokeWidth: number = 5
  ) {
    let textStyle = new Text({
      placement: '',
      font: 'bold 16px Arial',
      fill: new Fill({
        color: '#000',
      } as any),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    } as any);
    return (feature: any, resolution: any) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      let color = asArray(manualRoute.color);
      color[3] = 0.6;
      let style = new Style({
        text: textStyle,
        fill: new Fill({
          color: color,
          fillOpacity: 1,
        } as any),
        stroke: new Stroke({
          color: color,
          width: featureStrokeWidth,
        }),
      });
      return [style];
    };
  }
}
