import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AssignableResourcesService } from '../services';

@Injectable()
export class AssignedUsersResolver implements Resolve<any> {
  constructor(public service: AssignableResourcesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.getById(route.params['id'], route.data['routerPrefix']);
  }
}
