import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as wjcCore from '@grapecity/wijmo';

import { AuthService } from '../../../../core/services';
import { Message } from './../../../../common/classes';

import { Unit, UnitServiceItem } from 'app/modules/units/models';
import { UnitService, UnitServicesService } from 'app/modules/units/services';
import { UnitFormBaseSubComponent } from 'app/shared/components/unitFormSub/unit-form-base-sub.component';

@Component({
  selector: 'unit-form-services',
  templateUrl: './unit-form-services.component.html',
  styleUrls: ['./unit-form-services.component.scss'],
})
export class UnitFormServicesComponent
  extends UnitFormBaseSubComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() group: UntypedFormGroup;
  @Input() isViewFromParent;
  cid = 'units-service-';
  filtersCols = ['name'];
  controller = 'unit_services';
  moduleKey = 'services';

  selectedItem: UnitServiceItem;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public service: UnitServicesService,
    public unitService: UnitService,
    protected translate: TranslateService
  ) {
    super(route, authService, service, unitService);
  }

  notify(msg, title) {
    const transMsg = this.translate.instant(msg);
    const transTitle = this.translate.instant(title);
    this.service.pushNotification(transMsg, transTitle);
  }

  loadItems() {
    const servicesList = this.unitObject.services;
    servicesList.map((service) => {
      const serviceStatus = this.unitService.calcServiceStatus(
        service,
        this.unitObject.counters.odometer,
        this.unitObject.counters.engine_hours
      );
      service.status = serviceStatus['text'];
      return service;
    });
    this.itemsList.sourceCollection = servicesList;
  }

  update(event) {
    super.update(event);
    this.loadItems();
    this.selectedItem = new UnitServiceItem();
  }

  addForm() {
    super.addForm();
    this.selectedItem = new UnitServiceItem();
    this.openForm();
  }

  updateServicesList(item) {
    let service = item;
    this.unitObject.services = this.unitObject.services.filter(
      (sens) => sens.id != service.id
    );
    this.unitService.setResourceObject(this.unitObject);
  }

  afterDeleteError(item: any, error: any) {
    this.notify('notifications.tryagain', 'units.services');
  }

  afterDeleteSuccess(item) {
    super.afterDeleteSuccess(item);
    this.itemsList.remove(item);
    this.updateServicesList(item);
    this.notify('notifications.unit_services.deleted', 'units.services');
  }
}
