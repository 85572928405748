<div [formGroup]="form">
  <div class="row form-group" *ngIf="!hideButtons">
    <div class="col-12 col-md-12 reports-fix">
      <div
        class="d-flex btn-group w-100 mb-1"
        role="group"
        aria-label="Basic example"
        [attr.testid]="'chart-date'"
      >
        <button
          type="button"
          [attr.testId]="routerPrefix + '-today'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'today'"
          (click)="updateDate('today')"
        >
          <span>{{ cid + '.today' | translate }}</span>
        </button>
        <button
          type="button"
          [attr.testId]="routerPrefix + '-yesterday'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'yesterday'"
          (click)="updateDate('yesterday')"
        >
          <span>{{ cid + '.yesterday' | translate }}</span>
        </button>
        <button
          type="button"
          [attr.testId]="routerPrefix + '-week'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'weeks'"
          (click)="updateDate('weeks')"
        >
          <span>{{ cid + '.week' | translate }}</span>
        </button>
        <button
          type="button"
          [attr.testId]="routerPrefix + '-month'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'months'"
          (click)="updateDate('months')"
        >
          <span>{{ cid + '.month' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <!-- INTERVAL -->
  <div class="row mbx-9">
    <div class="col-md-4 labels d-flex align-items-center">
      <div class="form-group my-1">
        <label class="form-control-label"
          >{{ (cid + '.date_range' | translate) + ' ' + ':' }}
        </label>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['interval']?.dirty ||
            form?.controls['interval']?.touched) &&
          form?.controls['interval']?.errors
        "
      >
        <select
          class="form-control form-control-sm"
          formControlName="interval"
          (change)="toggleInterval(true)"
          [attr.testid]="routerPrefix + '-interval'"
        >
          <option *ngFor="let i of intervals" value="{{ i.val }}" translate>
            {{ i.txt }}
          </option>
        </select>
        <control-errors [ctl]="form?.controls['interval']"></control-errors>
      </div>
    </div>
  </div>
  <!-- END INTERVAL -->
  <div class="row mbx-9" [class.hidden]="showInterval">
    <div class="offset-4 col">
      <div class="form-group my-1">
        <wj-input-number
          [attr.testid]="routerPrefix + '-interval_count'"
          formControlName="interval_count"
          [min]="1"
          format="n0"
          [step]="1"
        >
        </wj-input-number>
      </div>
    </div>
    <div class="col pl-0">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['interval_unit']?.dirty ||
            form?.controls['interval_unit']?.touched) &&
          form?.controls['interval_unit']?.errors
        "
      >
        <select
          class="form-control form-control-sm"
          formControlName="interval_unit"
          [attr.testid]="routerPrefix + '-interval_unit'"
        >
          <option
            *ngFor="let iu of intervalUnits"
            value="{{ iu.val }}"
            translate
          >
            {{ iu.txt }}
          </option>
        </select>
        <control-errors
          [ctl]="form?.controls['interval_unit']"
        ></control-errors>
      </div>
    </div>
  </div>
  <div class="row mbx-9" [class.hidden]="showInterval">
    <div class="form-group-container">
      <div class="form-group my-1">
        <div
          class="checkbox-squared d-flex align-items-center justify-content-end"
        >
          <input
            type="checkbox"
            id="checkbox-including_current"
            formControlName="including_current"
            [attr.testid]="routerPrefix + '-including_current'"
          />
          <label for="checkbox-including_current"></label>
          <span>{{ cid + '.including_current' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- FROM -->
  <div class="row mbx-9" [class.hidden]="!showInterval">
    <div class="col-md-4 labels d-flex align-items-center">
      <div class="form-group my-1">
        <label class="form-control-label">{{
          (cid + '.from' | translate) + ' ' + ':'
        }}</label>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['from']?.dirty || form?.controls['from']?.touched) &&
          form?.controls['from']?.errors
        "
      >
        <wj-input-date-time
          #wjFrom
          class="form-control form-control-sm"
          formControlName="from"
          [attr.testid]="routerPrefix + '-from'"
          [format]="'yyyy-MM-dd HH:mm:ss'"
          [max]="maxFromDate"
          [timeMax]="maxFromTime"
          [timeStep]="10"
          (click)="wijmoInputClick(wjFrom, $event)"
          (valueChanged)="onFromDateValueChanged(wjFrom.value)"
        >
        </wj-input-date-time>
        <control-errors [ctl]="form?.controls['from']"></control-errors>
      </div>
    </div>
  </div>
  <!-- END FROM -->
  <!-- TO -->
  <div class="row mbx-9" [class.hidden]="!showInterval">
    <div class="col-md-4 labels d-flex align-items-center">
      <div class="form-group my-1">
        <label class="form-control-label">{{
          (cid + '.to' | translate) + ' ' + ':'
        }}</label>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['to']?.dirty || form?.controls['to']?.touched) &&
          form?.controls['to']?.errors
        "
      >
        <wj-input-date-time
          #wjTo
          class="form-control form-control-sm"
          formControlName="to"
          [attr.testid]="routerPrefix + '-to'"
          [format]="'yyyy-MM-dd HH:mm:ss'"
          [min]="$any(wjFrom.value)"
          [max]="maxDate"
          [timeMax]="maxToTime"
          [timeStep]="10"
          [isDisabled]="disableTo"
          (valueChanged)="onToDateValueChanged(wjTo.value)"
          (lostFocus)="setUserTimeAndMaxDate()"
          (click)="wijmoInputClick(wjTo, $event)"
        ></wj-input-date-time>
        <control-errors [ctl]="form?.controls['to']"></control-errors>
      </div>
    </div>
  </div>
  <!-- END TO -->
</div>
