import { AfaqyObject } from '../../../common/classes';
import { MonitoringStatus } from '../services/monitoring-status';
import { SensorColor } from '../services/sensor-color';

export class UnitTracking extends AfaqyObject {
  private _visible: boolean = false;
  private _follow: boolean = false;
  private _address: string = '';
  private _geofence: string = '';
  private _engine_hours: number;
  private _odemeter: number;
  private _connection_state: number = 0;
  private _motion_state: number;
  private _data_accuracy_last_message: number;
  private _data_accuracy_sorting: number;
  private _data_accuracy_sats: number;
  private _time_ago: string;
  private _nearest_zone: any;
  private _nearest_marker: any;
  private _sensors: SensorColor;
  public get sensors(): SensorColor {
    return this._sensors;
  }
  public set sensors(value: SensorColor) {
    this._sensors = value;
  }
  protected copyFields = [
    'address',
    'geofence',
    'visible',
    'follow',
    'connection_state',
    'motion_state',
    'data_accuracy_last_message',
    'data_accuracy_sats',
    'time_ago',
    'nearest_zone',
    'nearest_marker',
    'sensors',
  ];

  get data_accuracy_sorting(): number {
    return this._data_accuracy_sorting;
  }

  set data_accuracy_sorting(value: number) {
    this._data_accuracy_sorting = value;
  }

  get time_ago(): string {
    return this._time_ago;
  }

  set time_ago(value: string) {
    this._time_ago = value;
  }

  get motion_state(): number {
    return this._motion_state;
  }

  set motion_state(value: number) {
    this._motion_state = value;
  }

  get data_accuracy_last_message(): number {
    return this._data_accuracy_last_message;
  }

  set data_accuracy_last_message(value: number) {
    this._data_accuracy_last_message = value;
  }

  get data_accuracy_sats(): number {
    return this._data_accuracy_sats;
  }

  set data_accuracy_sats(value: number) {
    this._data_accuracy_sats = value;
  }

  get connection_state(): number {
    return this._connection_state;
  }

  set connection_state(value: number) {
    this._connection_state = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  get follow(): boolean {
    return this._follow;
  }

  set follow(value: boolean) {
    this._follow = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get geofence(): string {
    return this._geofence;
  }

  set geofence(value: string) {
    this._geofence = value;
  }

  get engine_hours(): number {
    return this._engine_hours;
  }

  set engine_hours(value: number) {
    this._engine_hours = value;
  }

  get odemeter(): number {
    return this._odemeter;
  }

  set odemeter(value: number) {
    this._odemeter = value;
  }

  get nearest_zone(): any {
    return this._nearest_zone;
  }

  set nearest_zone(value: any) {
    this._nearest_zone = value;
  }

  get nearest_marker(): any {
    return this._nearest_marker;
  }

  set nearest_marker(value: any) {
    this._nearest_marker = value;
  }

  get motionIconClass() {
    let classText = '';
    switch (this.motion_state) {
      case MonitoringStatus.MotionState.MovingOFF:
        classText = 'icon-device-move';
        if (this.connection_state) {
          classText += ' green-color';
        }
        break;
      case MonitoringStatus.MotionState.MovingON:
        classText = 'icon-device-move-sensor';
        if (this.connection_state) {
          classText += ' green-color';
        }
        break;
      case MonitoringStatus.MotionState.StationaryOFF:
        classText = 'icon-device-stop';
        if (this.connection_state) {
          classText += ' red-color';
        }
        break;
      case MonitoringStatus.MotionState.StationaryON:
        classText = 'icon-device-stop-sensor';
        if (this.connection_state) {
          classText += ' red-color';
        }
        break;
      case MonitoringStatus.MotionState.MovingFromTime:
        classText = 'icon-device-move';
        break;
      case MonitoringStatus.MotionState.StationaryFromTime:
        classText = 'icon-device-stop';
        break;
      case MonitoringStatus.MotionState.NoMessages:
      default:
        classText = 'icon-device-stop-no-msg';
        break;
    }
    return classText;
  }

  /**
   * to get the motion state icon names
   */
  get motionIconName() {
    let classText = '';
    switch (this.motion_state) {
      case MonitoringStatus.MotionState.MovingOFF:
        classText = 'icon-motion-state-last-message-an-hour-ago-unit-moving';
        if (this.connection_state) {
          classText = 'icon-motion-state-unit-moving';
        }
        break;
      case MonitoringStatus.MotionState.MovingON:
        classText = 'icon-motion-state-last-message-an-hour-ago-unit-moving';
        if (this.connection_state) {
          classText = 'icon-motion-state-unit-moving-engine-on';
        }
        break;
      case MonitoringStatus.MotionState.StationaryOFF:
        classText =
          'icon-motion-state-last-message-an-hour-ago-unit-stationary';
        if (this.connection_state) {
          classText = 'icon-motion-state-unit-stationary';
        }
        break;
      case MonitoringStatus.MotionState.StationaryON:
        classText =
          'icon-motion-state-last-message-an-hour-ago-unit-stationary';
        if (this.connection_state) {
          classText = 'icon-motion-state-unit-stationary-engine-on';
        }
        break;
      case MonitoringStatus.MotionState.MovingFromTime:
        classText = 'icon-motion-state-last-message-an-hour-ago-unit-moving';
        break;
      case MonitoringStatus.MotionState.StationaryFromTime:
        classText =
          'icon-motion-state-last-message-an-hour-ago-unit-stationary';
        break;
      case MonitoringStatus.MotionState.NoMessages:
      default:
        classText = 'icon-motion-state-data-obtained';
        break;
    }
    return classText;
  }

  get motionIconState() {
    switch (this.motion_state) {
      case MonitoringStatus.MotionState.MovingOFF:
        return 'text-info';
      case MonitoringStatus.MotionState.MovingON:
        return 'text-success';
      case MonitoringStatus.MotionState.StationaryOFF:
        return 'text-primary';
      case MonitoringStatus.MotionState.StationaryON:
        return 'text-primary';
      case MonitoringStatus.MotionState.MovingFromTime:
        return 'text-warning';
      case MonitoringStatus.MotionState.StationaryFromTime:
        return 'text-warning';
      case MonitoringStatus.MotionState.NoMessages:
      default:
        return 'text-danger';
    }
  }

  get satellitesClass() {
    switch (this.data_accuracy_sats) {
      case MonitoringStatus.DataAccuracySatellites.Available:
        return 'icon-gps-state-high';
      case MonitoringStatus.DataAccuracySatellites.NotAvailableForTime:
        return 'icon-gps-state-high icon-not-active';
      case MonitoringStatus.DataAccuracySatellites.NotAvailable:
      default:
        return 'icon-gps-state-low';
    }
  }

  /**
   * to get the satellites icon names
   */
  get satellitesIconName() {
    switch (this.data_accuracy_sats) {
      case MonitoringStatus.DataAccuracySatellites.Available:
        return 'icon-satellite-unit-satellites-are-available';
      case MonitoringStatus.DataAccuracySatellites.NotAvailableForTime:
        return 'icon-satellite-unit-satellite-not-available-for-period';
      case MonitoringStatus.DataAccuracySatellites.NotAvailable:
      default:
        return 'icon-satellite-unit-satellite-not-available';
    }
  }

  get dataAccuracyLastMessageClass() {
    switch (this.data_accuracy_last_message) {
      case MonitoringStatus.DataAccuracyLastMessage.FiveMinutes:
        return 'icon-gps-state-high';
      case MonitoringStatus.DataAccuracyLastMessage.LONGTIME:
        return 'icon-gps-state-low';
      case MonitoringStatus.DataAccuracyLastMessage.HOUR:
        return 'icon-gps-state-quater';
      case MonitoringStatus.DataAccuracyLastMessage.DAY:
        return 'icon-gps-state-middle';
      case MonitoringStatus.DataAccuracyLastMessage.NoOver:
      default:
        return 'icon-gps-state-high icon-not-active';
    }
  }

  get ConnectionTooltipText() {
    switch (this.connection_state) {
      case MonitoringStatus.ConnectionState.ON:
        return 'online';
      default:
        return 'offline';
    }
  }

  get motionTooltipText() {
    switch (this.motion_state) {
      case MonitoringStatus.MotionState.MovingOFF:
        return 'moving';
      case MonitoringStatus.MotionState.MovingON:
        return 'moving_engine_on';
      case MonitoringStatus.MotionState.StationaryOFF:
        return 'stationary';
      case MonitoringStatus.MotionState.StationaryON:
        return 'stationary_engine_on';
      case MonitoringStatus.MotionState.MovingFromTime:
        return 'moving_over_duration';
      case MonitoringStatus.MotionState.StationaryFromTime:
        return 'stop_over_duration';
      case MonitoringStatus.MotionState.NoMessages:
      default:
        return 'no_messages';
    }
  }

  get satsTooltipText() {
    switch (this.data_accuracy_sats) {
      case MonitoringStatus.DataAccuracySatellites.Available:
        return 'satellites_available';
      default:
        return 'satellites_not_available';
    }
  }

  get lastMessageTooltipText() {
    switch (this.data_accuracy_last_message) {
      case MonitoringStatus.DataAccuracyLastMessage.NoOver:
        return 'no_data_ever';
      default:
        return 'position_detected';
    }
  }
}
