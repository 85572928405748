import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { Roles } from '../models';
import { NotificationsService } from 'angular2-notifications';
import { AfaqyAPIResponse } from '../../../core/classes/afaqy-response';

@Injectable()
export class RolesService extends RootService {
  private plist = [];
  private rplist = [];

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  permissionsList() {
    if (this.plist.length) {
      return of(this.plist);
    }
    return this.apiRequest
      .authPost('role/list_system_permissions', { limit: 0 })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let list = [];
          if (result.status_code == 200) {
            for (let k in result.data) {
              list.push({
                k: k,
                v: Object.keys(result.data[k]).map(function (key) {
                  return result.data[k][key];
                }),
              });
            }
            this.plist = list;
            return list;
          }
          return [];
        })
      );
  }

  rolePermissionsList(roleId) {
    if (this.rplist && this.rplist[roleId]) {
      return of(this.rplist[roleId]);
    }
    return this.apiRequest
      .authPost('role/list_role_permissions', { roleId: roleId })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            this.rplist[roleId] = result.data;

            return result.data;
          }
          return [];
        })
      );
  }

  routerPrefix(val: string = '') {
    return val ? val : 'role';
  }

  get modelInstance() {
    return new Roles();
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'name',
        minWidth: 75,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: 'created_at',
        minWidth: 50,
        width: '*',
        colValue: 'created_at',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 30,
        width: 90,
        colValue: 'actions',
        active: false,
        default: true,
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-user-preferences';
  }

  get cid() {
    return 'roles_permissions';
  }
}
