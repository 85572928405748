import { AfaqyObject } from '../../../common/classes';
import { Last_known_location } from 'app/shared/interfaces/interfaces';

export class UnitLastUpdate extends AfaqyObject {
  private _valid: boolean;
  private _dtt: number = 0;
  private _dts: number = 0;
  private _spd: number = 0;
  private _ang: number = 0;
  private _alt: number = 0;
  private _lat: number = 0;
  private _lng: number = 0;
  private _prms: {};
  private _chPrams: {};
  private _devPrms: string[];
  private _vLoc: number = 0;
  private _vCon: number = 0;
  private _ip: string = '';
  private _rl: number = 0;
  private _trl: number = 0;
  private _mdu: number = 0;
  private _tmdu: number = 0;
  private _eidl: number = 0;
  private _teidl: number = 0;
  private _fc: number = 0;
  private _tfc: number = 0;
  private _acc: number = 0;
  private _accOn: number = 0;
  private _accOff: number = 0;
  private _sdu: number = 0;
  private _tsdu: number = 0;
  private _status: string = '';
  private _tail: any[] = [];
  private _addr: any = {};
  private _address: string = '';

  private _last_known_location: Last_known_location = {};

  protected copyFields = [
    'dtt',
    'dts',
    'spd',
    'ang',
    'alt',
    'lat',
    'lng',
    'prms',
    'chPrams',
    'status',
    'vLoc',
    'vCon',
    'ip',
    'rl',
    'trl',
    'mdu',
    'tmdu',
    'eidl',
    'teidl',
    'fc',
    'tfc',
    'acc',
    'accOn',
    'accOff',
    'sdu',
    'tsdu',
    'devPrms',
    'addr',
    'lastloc',
  ];

  constructor() {
    super();
    this.valid = false;
  }

  public doObjectChecks() {
    if (this.dtt) {
      this.valid = true;
      if (!this.tail.length) {
        if (this.lng && this.lat) {
          this.tail.push([this.lng, this.lat]);
        }
      }
    }
  }

  get valid(): boolean {
    return this._valid;
  }

  set valid(value: boolean) {
    this._valid = value;
  }

  get dtt(): number {
    if (this._dtt) {
      return parseInt('' + this._dtt);
    }
    return 0;
  }

  set dtt(value: number) {
    this._dtt = value;
  }

  get dts(): number {
    if (this._dts) {
      return parseInt('' + this._dts);
    }
    return 0;
  }

  set dts(value: number) {
    this._dts = value;
  }

  get spd(): number {
    return this._spd ? this._spd : 0;
  }

  set spd(value: number) {
    this._spd = value;
  }

  get ang(): number {
    return this._ang ? this._ang : 0;
  }

  set ang(value: number) {
    this._ang = value;
  }

  get alt(): number {
    return this._alt ? this._alt : 0;
  }

  set alt(value: number) {
    this._alt = value;
  }

  get lat(): number {
    return this._lat;
  }

  set lat(value: number) {
    this._lat = value ? value : 0;
  }

  get lng(): number {
    return this._lng;
  }

  set lng(value: number) {
    this._lng = value ? value : 0;
  }

  get prms(): {} {
    return this._prms ? this._prms : {};
  }

  set prms(value: {}) {
    this._prms = value;
  }

  get chPrams(): {} {
    return this._chPrams ? this._chPrams : {};
  }

  set chPrams(value: {}) {
    this._chPrams = value;
  }

  get vLoc(): number {
    return this._vLoc;
  }

  set vLoc(value: number) {
    this._vLoc = value;
  }

  get vCon(): number {
    return this._vCon ? this._vCon : 0;
  }

  set vCon(value: number) {
    this._vCon = value;
  }

  get ip(): string {
    return this._ip;
  }

  set ip(value: string) {
    this._ip = value;
  }

  get rl(): number {
    return this._rl;
  }

  set rl(value: number) {
    this._rl = value;
  }

  get trl(): number {
    return this._trl;
  }

  set trl(value: number) {
    this._trl = value;
  }

  get mdu(): number {
    return this._mdu;
  }

  set mdu(value: number) {
    this._mdu = value;
  }

  get tmdu(): number {
    return this._tmdu;
  }

  set tmdu(value: number) {
    this._tmdu = value;
  }

  get eidl(): number {
    return this._eidl;
  }

  set eidl(value: number) {
    this._eidl = value;
  }

  get teidl(): number {
    return this._teidl;
  }

  set teidl(value: number) {
    this._teidl = value;
  }

  get fc(): number {
    return this._fc;
  }

  set fc(value: number) {
    this._fc = value;
  }

  get tfc(): number {
    return this._tfc;
  }

  set tfc(value: number) {
    this._tfc = value;
  }

  get acc(): number {
    return this._acc;
  }

  set acc(value: number) {
    this._acc = value;
  }

  get accOn(): number {
    return this._accOn;
  }

  set accOn(value: number) {
    this._accOn = value;
  }

  get accOff(): number {
    return this._accOff;
  }

  set accOff(value: number) {
    this._accOff = value;
  }

  get sdu(): number {
    return this._sdu;
  }

  set sdu(value: number) {
    this._sdu = value;
  }

  get tsdu(): number {
    return this._tsdu;
  }

  set tsdu(value: number) {
    this._tsdu = value;
  }

  get devPrms(): string[] {
    return this._devPrms;
  }

  set devPrms(value: string[]) {
    this._devPrms = value;
  }

  get tail(): any[] {
    return this._tail;
  }

  set tail(value: any[]) {
    this._tail = value;
  }

  get addr(): any {
    return this._addr;
  }

  set addr(value: any) {
    this._addr = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  set lastloc(value: Last_known_location) {
    this._last_known_location = !Array.isArray(value) ? value : {};
  }

  get lastloc(): Last_known_location {
    return this._last_known_location;
  }
}
