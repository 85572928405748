import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { Tailer } from '../models';
import { NotificationsService } from 'angular2-notifications';
import { AfaqyAPIResponse, AfaqyResponse } from '../../../core/classes';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TailerService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  routerPrefix(val: string = '') {
    return val ? val : 'tailers';
  }

  get modelInstance() {
    return new Tailer();
  }

  get listProjectionFields() {
    return ['name', 'desc', 'ibtn', 'rfid'];
  }

  bindToUnit(tailer_id, unit_id) {
    let params: {} = { tailer_id: tailer_id, unit_id: unit_id };
    return this.apiRequest
      .authPost(this.getFunctionURL('set_unit_tailer'), params)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'multiple_select',
        minWidth: 28,
        width: 30,
        colValue: 'checkbox',
        active: true,
        immutable: false,
        default: true,
        type: 'checkbox',
        sorting: false,
        filters: false,
      },
      {
        header: 'name',
        minWidth: 100,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      // {header: "description", "minWidth": 75, "width": '*', colValue: "desc", active: false, default: true},
      {
        header: 'ibtn',
        minWidth: 75,
        width: '*',
        colValue: 'ibtn',
        active: false,
        default: true,
      },
      {
        header: 'rfid',
        minWidth: 75,
        width: '*',
        colValue: 'rfid',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [
          {
            header: 'assign_user',
            permissions: this.cid + '-assign',
            code: 'assign',
            faclass: 'fa-link',
          },
          {
            header: 'unassign_user',
            permissions: this.cid + '-unassign',
            code: 'unassign',
            faclass: 'fa-unlink',
          },
          {
            header: this.cid + '.bind_unit',
            permissions: this.cid + '-setUnitTailer',
            code: 'bindUnit',
            faclass: 'fa-truck',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
      allcols.shift();
    }
    if (!this.authService.checkPermissions(this.cid + '-bulkDelete')) {
      allcols.shift();
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-trailers';
  }
}
