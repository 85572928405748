import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../common/classes/app-config';
import { AfaqyAPIResponse } from '../classes';
import { contentHeaders } from '../../common/headers';

@Injectable()
export class LoginService {
  isIsuzuAgreementNeeded: boolean;

  constructor(private http: HttpClient) {}

  session(token) {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/session?token=' + token,
      { headers: contentHeaders }
    );
  }

  login(username, password, expireInHours, lang = '') {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/login',
      {
        data: JSON.stringify({
          username: username,
          password: password,
          lang: lang,
          expire: expireInHours,
        }),
      },
      { headers: contentHeaders }
    );
  }

  forgot(username) {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/forgot',
      { data: JSON.stringify({ username: username }) },
      { headers: contentHeaders }
    );
  }

  reset(code, password, username) {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/reset',
      {
        data: JSON.stringify({
          code: code,
          password: password,
          username: username,
        }),
      },
      { headers: contentHeaders }
    );
  }
  resetExpired(username, currentPassword, Password, confirmedPassword) {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/reset_expired_password',
      {
        data: JSON.stringify({
          username: username,
          current_password: currentPassword,
          password: Password,
          confirmed_password: confirmedPassword,
        }),
      },
      { headers: contentHeaders }
    );
  }

  loginAs(id, expireInHours, token, lang = '') {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/login_as?token=' + token,
      { data: { id: id, expire: expireInHours, lang: lang } },
      { headers: contentHeaders }
    );
  }

  logout(token) {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + 'auth/logout?token=' + token,
      {},
      { headers: contentHeaders }
    );
  }

  doPost(url, params) {
    return this.http.post<AfaqyAPIResponse>(
      AppConfig.baseURL + url,
      { data: JSON.stringify(params) },
      { headers: contentHeaders }
    );
  }
}
