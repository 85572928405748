import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SensorColors } from '../../interfaces/sensor-colors.interfaces';

@Injectable()
export class IntervalColorsService {
  unitSensorColors: any[] = [];
  resultType: string;
  intervalColors: BehaviorSubject<SensorColors> =
    new BehaviorSubject<SensorColors>({ resultType: undefined, colors: [] });
  doReset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  doSubmit: Subject<any> = new Subject<any>();

  constructor() {}

  /**
   * Send value to intervalColors BehaviorSubject.
   * Set value to resultType and unitSensorColors variables from the param.
   * @param value SensorColors.
   */
  updateIntervalColors(value: SensorColors): void {
    this.setIntervalColorsVariables(value);
    this.setIntervalColors(value);
  }

  /** Return default colors depends on result type value. */
  getDefaultColors(resultType: string): any {
    const colors: any[] = [];

    // set default digital.
    if (resultType === 'logic' || resultType === 'logicgte') {
      colors.push({ type: 0, color: '#FF0000' }, { type: 1, color: '#00FF00' });
    }
    // set default value.
    else if (resultType === 'value') {
      colors.push({ from: null, to: null, color: '#000000' });
    }

    return colors;
  }

  submit(): void {
    this.doSubmit.next();
  }

  reset(): void {
    this.doReset.next(true);
  }

  /**
   * Calling addIntervalColor fn or addLogicColor depends on resultType.
   * @param sensorColor UnitSensorColor.
   */
  addColor(sensorColor: any): void {
    if (this.resultType === 'value') {
      const obj = { ...sensorColor };
      delete obj.type;
      this.addIntervalColor(obj);
    } else if (this.resultType === 'logic' || this.resultType === 'logicgte') {
      const obj = { ...sensorColor };
      delete obj.from;
      delete obj.to;
      this.addLogicColor(obj);
    } else if (this.resultType === 'codeValue') {
      const obj = { ...sensorColor };
      delete obj.from;
      delete obj.to;
      this.addCodeColor(obj);
    }
  }

  /**
   * Regenerate array of analog intervals and assign it to updateIntervalColors fn.
   * @param sensorColor UnitSensorColor.
   */
  private addIntervalColor(sensorColor: any): void {
    if (!sensorColor) return;
    sensorColor.from = parseFloat(sensorColor.from);
    sensorColor.to = parseFloat(sensorColor.to);

    // Swap the received interval values, if it needs that.
    this.swapInterval(sensorColor);

    // Override intervals handler.
    if (this.overrideIntervalsHandler(sensorColor)) {
      return;
    }

    // Intersect interval handler.
    if (this.intersectIntervalHandler(sensorColor)) {
      return;
    }
  }

  /**
   * If from value greater than to value, swap them.
   * @param sensorColor UnitSensorColor.
   */
  private swapInterval(sensorColor: any): void {
    if (!sensorColor) return;
    const receivedFrom: number = parseFloat(sensorColor.from);
    const receivedTo: number = parseFloat(sensorColor.to);

    if (
      !isNaN(receivedFrom) &&
      !isNaN(receivedTo) &&
      receivedFrom > receivedTo
    ) {
      [sensorColor['from'], sensorColor['to']] = [
        sensorColor['to'],
        sensorColor['from'],
      ];
    }
  }

  /**
   * If received from and to values are empty, override current intervals with the received interval and return true.
   * @param sensorColor UnitSensorColor.
   */
  private overrideIntervalsHandler(sensorColor: any): boolean {
    if (!sensorColor) return;
    const intervalColors: SensorColors = {
      resultType: this.resultType,
      colors: [...this.unitSensorColors],
    };
    const receivedFrom: number = parseFloat(sensorColor.from);
    const receivedTo: number = parseFloat(sensorColor.to);
    if (
      sensorColor &&
      !receivedFrom &&
      !receivedTo &&
      receivedFrom !== 0 &&
      receivedTo !== 0
    ) {
      intervalColors.colors = [sensorColor];
      this.updateIntervalColors(intervalColors);
      return true;
    }
    return false;
  }

  /**
   * If range intersect the existing range, edit the intervals depends on its current value and return true.
   * @param sensorColor UnitSensorColor.
   */
  private intersectIntervalHandler(sensorColor: any): boolean {
    if (!sensorColor || !this.unitSensorColors || !this.unitSensorColors.length)
      return;
    const intervalColors: SensorColors = {
      resultType: this.resultType,
      colors: [...this.unitSensorColors],
    };
    const receivedFrom: number = parseFloat(sensorColor.from);
    const receivedTo: number = parseFloat(sensorColor.to);
    const lastIndex: number = intervalColors.colors.length - 1;
    const firstItemFrom: number = parseFloat(intervalColors.colors[0].from);
    const firstItemTo: number = parseFloat(intervalColors.colors[0].to);
    let secondtItemFrom: number;
    let secondItemTo: number;

    if (this.unitSensorColors.length > 1) {
      secondtItemFrom = parseFloat(intervalColors.colors[1].from);
      secondItemTo = parseFloat(intervalColors.colors[1].to);
    }

    let startIndex: number; // Index of the item that contains the received 'from' value.
    let endIndex: number; // Index of the item that contains the received 'to' value.

    /* Set zero to startIndex if one of these conditions is valid:
     * If the received 'from' and the first item 'from' has no value.
     * If the first item has no 'from' value and the received 'from' smaller than or equal the first item 'to' value.
     */
    if (
      (isNaN(receivedFrom) && isNaN(firstItemFrom)) ||
      (isNaN(firstItemFrom) && receivedFrom < firstItemTo) ||
      (isNaN(firstItemFrom) &&
        receivedFrom === firstItemTo &&
        receivedFrom !== receivedTo &&
        secondtItemFrom !== secondItemTo)
    ) {
      startIndex = 0;
    }

    /** Set the last index of intervals to endIndex if the received 'to' has no value.*/
    if (isNaN(receivedTo)) {
      endIndex = lastIndex;
    }

    /** Set zero to endIndex if the first item has no 'from' value and
        the received 'to' smaller than or equal the first item 'to' value. */
    if (
      isNaN(firstItemFrom) &&
      receivedTo <= firstItemTo &&
      receivedFrom !== receivedTo
    ) {
      endIndex = 0;
    }

    /* Set zero to startIndex and endIndex if the first item has no value 'infinity interval'. */
    if (isNaN(firstItemFrom) && isNaN(firstItemTo)) {
      startIndex = endIndex = 0;
    }

    /** Comparing received 'from' and 'to' with other intervals to get startIndex and endIndex. */
    for (let i = 0; i < intervalColors.colors.length; i++) {
      if (!isNaN(startIndex) && !isNaN(endIndex)) break;
      const itemFrom: number = parseFloat(intervalColors.colors[i].from);
      const itemTo: number = parseFloat(intervalColors.colors[i].to);

      /** If the item values are the same received interval values, set item index to startIndex and endIndex. */
      if (itemFrom === receivedFrom && itemTo === receivedTo) {
        startIndex = endIndex = i;
      }

      /* Set i to startIndex if one of these conditions is valid:
       * If the received 'from' greater than the item 'from' and the item has no 'to' value.
       * If the received 'from' greater than or equal the item 'from' and smaller than the item 'to'.
       */
      if (
        (isNaN(startIndex) && receivedFrom >= itemFrom && isNaN(itemTo)) ||
        (receivedFrom >= itemFrom && receivedFrom < itemTo)
      ) {
        startIndex = i;
      }

      /* Set i to endIndex if one of these conditions is valid:
       * If the received 'to' greater than or equal the item 'from' and the item has no 'to' value.
       * If the received 'to' greater than or equal the item 'from' and smaller than or equal the item 'to'.
       */
      if (
        (isNaN(endIndex) && receivedTo >= itemFrom && isNaN(itemTo)) ||
        (isNaN(itemFrom) && receivedTo <= itemTo) ||
        (receivedTo >= itemFrom && receivedTo <= itemTo)
      ) {
        endIndex = i;
      }
    }

    const currentColors: any[] = [...intervalColors.colors];
    let colorsBeforeIntersection: any[] = [
      ...currentColors.slice(0, startIndex),
    ];
    let colorsAfterIntersection: any[] = [];
    let intervalBeforeTheReceived: any;
    let intervalAfterTheReceived: any;

    /** One index intersected */
    /** If start index equal end index. */
    if (!isNaN(startIndex) && !isNaN(endIndex) && startIndex === endIndex) {
      const intersectedInterval: any = currentColors[startIndex];
      const intersectedFrom: number = parseFloat(intersectedInterval.from);
      const intersectedTo: number = parseFloat(intersectedInterval.to);
      colorsAfterIntersection = [
        ...currentColors.slice(startIndex + 1, currentColors.length),
      ];

      // If the intersected interval is the same received interval, change only the color.
      if (
        (intersectedFrom === receivedFrom && intersectedTo === receivedTo) ||
        (intersectedFrom === receivedFrom &&
          isNaN(intersectedTo) &&
          isNaN(receivedTo)) ||
        (isNaN(intersectedFrom) &&
          isNaN(receivedFrom) &&
          intersectedTo === receivedTo)
      ) {
        intersectedInterval.color = sensorColor.color;
        intervalColors.colors = currentColors;
        this.updateIntervalColors(intervalColors);
        return true;
      }

      // If the intersected interval 'from' equal the received 'from'.
      if (
        intersectedFrom === receivedFrom ||
        (isNaN(intersectedFrom) && isNaN(receivedFrom))
      ) {
        intervalAfterTheReceived = {
          from: receivedTo,
          to: intersectedTo,
          color: intersectedInterval.color,
        };
        intervalColors.colors = [
          ...colorsBeforeIntersection,
          sensorColor,
          intervalAfterTheReceived,
          ...colorsAfterIntersection,
        ];
        this.updateIntervalColors(intervalColors);
        return true;
      }

      // If intersected interval 'to' equal the received 'to'.
      if (
        intersectedTo === receivedTo ||
        (isNaN(intersectedTo) && isNaN(receivedTo))
      ) {
        intervalBeforeTheReceived = {
          from: intersectedFrom,
          to: receivedFrom,
          color: intersectedInterval.color,
        };
        intervalColors.colors = [
          ...colorsBeforeIntersection,
          intervalBeforeTheReceived,
          sensorColor,
          ...colorsAfterIntersection,
        ];
        this.updateIntervalColors(intervalColors);
        return true;
      }

      // If the received interval between 'from' and 'to' of the intersected interval.
      intervalBeforeTheReceived = {
        from: intersectedFrom,
        to: receivedFrom,
        color: intersectedInterval.color,
      };
      intervalAfterTheReceived = {
        from: receivedTo,
        to: intersectedTo,
        color: intersectedInterval.color,
      };
      intervalColors.colors = [
        ...colorsBeforeIntersection,
        intervalBeforeTheReceived,
        sensorColor,
        intervalAfterTheReceived,
        ...colorsAfterIntersection,
      ];
      this.updateIntervalColors(intervalColors);
      return true;
    }

    /** Two indexes intersected */
    colorsAfterIntersection = [
      ...currentColors.slice(endIndex + 1, currentColors.length),
    ];
    const secondIntersectedInterval: any = currentColors[endIndex];
    const secondIntersectedFrom: number = parseFloat(
      secondIntersectedInterval.from
    );
    const secondIntersectedTo: number = parseFloat(
      secondIntersectedInterval.to
    );
    let intervalsBeforeTheReceived: any[] = [];
    if (isNaN(startIndex)) {
      colorsBeforeIntersection = [];
    }

    if (startIndex >= 0) {
      const firstIntersectedInterval: any = currentColors[startIndex];
      const firstIntersectedFrom: number = parseFloat(
        firstIntersectedInterval.from
      );
      const firstIntersectedTo: number = parseFloat(
        firstIntersectedInterval.to
      );
      if (
        firstIntersectedFrom < receivedFrom ||
        (isNaN(firstIntersectedFrom) && receivedFrom <= firstIntersectedTo)
      ) {
        intervalsBeforeTheReceived = [
          {
            from: firstIntersectedFrom,
            to: receivedFrom,
            color: firstIntersectedInterval.color,
          },
        ];
      }
    }

    // If second intersected interval 'to' equal the received 'to'.
    if (
      secondIntersectedTo === receivedTo ||
      (isNaN(secondIntersectedTo) && isNaN(receivedTo))
    ) {
      intervalColors.colors = [
        ...colorsBeforeIntersection,
        ...intervalsBeforeTheReceived,
        sensorColor,
        ...colorsAfterIntersection,
      ];
      this.updateIntervalColors(intervalColors);
      return true;
    }

    // If the received 'to' between second intersected interval 'from' and 'to' values.
    if (
      receivedTo > secondIntersectedFrom &&
      (receivedTo < secondIntersectedTo || isNaN(secondIntersectedTo))
    ) {
      intervalAfterTheReceived = {
        from: receivedTo,
        to: secondIntersectedTo,
        color: secondIntersectedInterval.color,
      };
      intervalColors.colors = [
        ...colorsBeforeIntersection,
        ...intervalsBeforeTheReceived,
        sensorColor,
        intervalAfterTheReceived,
        ...colorsAfterIntersection,
      ];
      this.updateIntervalColors(intervalColors);
      return true;
    }

    return false;
  }

  /**
   * Regenerate an array of digital intervals and assign it to updateIntervalColors fn.
   * @param sensorColor UnitSensorColor.
   */
  private addLogicColor(sensorColor: any): void {
    if (!sensorColor || isNaN(sensorColor.type)) return;

    const intervalColors: SensorColors = {
      resultType: this.resultType,
      colors: [...this.unitSensorColors],
    };
    intervalColors.colors.map((item: any) => {
      if (item.type == sensorColor.type) {
        item.color = sensorColor.color;
      }
    });

    this.updateIntervalColors(intervalColors);
  }

  /**
   * Regenerate an array of Code Value and assign it to updateIntervalColors fn.
   * @param sensorColor UnitSensorColor.
   */
  private addCodeColor(sensorColor: any): void {
    if (!sensorColor) return;

    const intervalColors: SensorColors = {
      resultType: this.resultType,
      colors: [...this.unitSensorColors],
    };
    intervalColors.colors.map((item: any) => {
      if (item.type == sensorColor.type) {
        item.color = sensorColor.color;
      }
    });

    this.updateIntervalColors(intervalColors);
  }

  /** Send value to intervalColors BehaviorSubject. */
  private setIntervalColors(value: SensorColors): void {
    this.intervalColors.next(value);
  }

  /** Set value to resultType and unitSensorColors variables from the param. */
  private setIntervalColorsVariables(value: SensorColors): void {
    this.resultType = value.resultType;
    this.unitSensorColors = [...value.colors];
  }
}
