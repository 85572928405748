import { Pagination } from '../../common/classes';

export interface AfaqyAPIResponse {
  status_code: number;
  message: string;
  data: any;
  errors: any;
  extra: any;
  pagination: any;
  [key: string]: any;
}

export class AfaqyResponse {
  private _success: boolean = false;
  private _list: any[] = [];
  private _data: any = {};
  private _pagination: Pagination;
  private _status_code: string = '';
  private _message: string = '';
  private _errors: any[] = [];
  private _extra: any[] = [];
  private _objectList: any = {};

  public copyInto(obj) {
    this.success = obj['_success'];
    this.list = obj['_list'];
    this.data = obj['_data'];
    this.pagination = obj['_pagination'];
    this.status_code = obj['_status_code'];
    this.message = obj['_message'];
    this.errors = obj['_errors'];
    this.extra = obj['_extra'];
  }

  public clear() {
    this.success = false;
    this.list = [];
    this.data = {};
    this.pagination = new Pagination();
    this.status_code = '';
    this.message = '';
    this.errors = [];
    this.extra = [];
  }

  public paginate(obj: any, defaultLimit?: number) {
    const pagination: Pagination = new Pagination();
    pagination.copyInto(obj, defaultLimit);
    this.pagination = pagination;
  }

  public status(code: number) {
    this.success = code === 200;
  }

  get success(): boolean {
    return this._success;
  }

  get list(): any[] {
    return this._list;
  }

  get data(): any {
    return this._data;
  }

  get pagination(): Pagination {
    return this._pagination;
  }

  get status_code(): string {
    return this._status_code;
  }

  get message(): string {
    return this._message;
  }

  get errors(): any[] {
    return this._errors;
  }

  set success(value: boolean) {
    this._success = value;
  }

  set list(value: any[]) {
    this._list = value;
  }

  set data(value: any) {
    this._data = value;
  }

  set pagination(value: Pagination) {
    this._pagination = value;
  }

  set status_code(value: string) {
    this._status_code = value;
  }

  set message(value: string) {
    this._message = value;
  }

  set errors(value: any[]) {
    this._errors = value;
  }

  get extra(): any[] {
    return this._extra;
  }

  set extra(value: any[]) {
    this._extra = value;
  }

  get objectList(): any[] {
    return this._objectList;
  }

  set objectList(value: any[]) {
    this._objectList = value;
  }
}
