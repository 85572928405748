<ng-color-circle
  [(ngModel)]="color"
  [ngClass]="{ 'disable-round-circle': isBlackColor }"
  (ngModelChange)="onChangeColor($event.hexString)"
  [ngStyle]="{ width: width, height: height }"
>
</ng-color-circle>
<div class="colors-container">
  <div *ngFor="let color of colors">
    <div
      class="circle"
      [style.background]="color"
      (click)="changeColor(color)"
    ></div>
  </div>
</div>
