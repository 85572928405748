<div class="share-location-component">
  <afaqy-modal
    #modal="afaqyModal"
    (modalClose)="modalClose()"
    [hidden]="hideModal"
    [title]="'share-location.share-live-location'"
    sizeClass="modal-lg"
  >
    <div class="import-modal-wrapper-body modalbody">
      <form [formGroup]="shareLocationForm">
        <div class="row">
          <div class="col-md-6 margin-bottom">
            <div class="row form-group">
              <div class="col-12">
                <label class="form-control-label">{{
                  'share-location.expire_in' | translate
                }}</label>
              </div>
              <div class="col-12 input-group form-group-hint">
                <afaqy-custom-select
                  [attr.testid]="'expire_in'"
                  [clear]="true"
                  [ctl]="shareLocationForm.controls['expire_in']"
                  [dataList]="expirationStep"
                  [multiple]="false"
                  [predefinedList]="true"
                  [titleField]="'name'"
                  formControlName="expire_in"
                  id="expire_in"
                >
                </afaqy-custom-select>
              </div>
            </div>
          </div>

          <div class="col-md-6 margin-bottom">
            <div
              [class.has-danger]="
                (shareLocationForm.controls['start_at'].dirty ||
                  shareLocationForm.controls['start_at'].touched) &&
                shareLocationForm.controls['start_at'].errors
              "
              class="row form-group"
            >
              <div class="col-12">
                <label class="form-control-label">{{
                  'share-location.start_at' | translate
                }}</label>
              </div>
              <div class="col-12 input-group form-group-hint">
                <wj-input-date-time
                  [format]="'yyyy-MM-dd HH:mm'"
                  class="form-control form-control-sm"
                  formControlName="start_at"
                  [min]="today"
                  [timeStep]="30"
                >
                </wj-input-date-time>
                <control-errors-hint
                  [ctl]="shareLocationForm.controls['start_at']"
                >
                </control-errors-hint>
              </div>
            </div>
          </div>

          <div class="col-md-6 margin-bottom">
            <div
              class="row form-group"
              [class.has-danger]="
                (shareLocationForm.controls['emails'].dirty ||
                  shareLocationForm.controls['emails'].touched) &&
                shareLocationForm.controls['emails'].errors
              "
            >
              <div class="col-12">
                <label class="form-control-label">{{
                  'share-location.emails' | translate
                }}</label>
              </div>
              <div class="col-12">
                <tag-input
                  [validators]="emailValidators"
                  [errorMessages]="errorMessages"
                  blinkIfDupe="true"
                  class="customInputHeight form-control form-control-sm"
                  formControlName="emails"
                  placeholder=""
                  secondaryPlaceholder=""
                  theme="bootstrap"
                  [maxItems]="10"
                >
                </tag-input>
                <control-errors [ctl]="shareLocationForm.controls['emails']">
                </control-errors>
                <div class="field_hint w-100">
                  {{ 'share-location.emails-hint' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 margin-bottom">
            <div
              class="row form-group"
              [class.has-danger]="
                (shareLocationForm.controls['mobiles'].dirty ||
                  shareLocationForm.controls['mobiles'].touched) &&
                shareLocationForm.controls['mobiles'].errors
              "
            >
              <div class="col-12">
                <label class="form-control-label">{{
                  'share-location.mobiles' | translate
                }}</label>
              </div>
              <div class="col-12">
                <tag-input
                  [validators]="mobileValidators"
                  [errorMessages]="errorMessages"
                  blinkIfDupe="true"
                  class="customInputHeight form-control form-control-sm"
                  formControlName="mobiles"
                  placeholder=""
                  secondaryPlaceholder=""
                  theme="bootstrap"
                  [maxItems]="10"
                >
                </tag-input>
                <control-errors [ctl]="shareLocationForm.controls['mobiles']">
                </control-errors>
                <div class="field_hint w-100">
                  {{ 'share-location.mobiles-hint' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 margin-bottom">
            <div
              [class.has-danger]="
                (shareLocationForm.controls['reason'].dirty ||
                  shareLocationForm.controls['reason'].touched) &&
                shareLocationForm.controls['reason'].errors
              "
              class="row form-group"
            >
              <div class="col-12">
                <label class="form-control-label">{{
                  'share-location.reason' | translate
                }}</label>
              </div>
              <div class="col-12 input-group form-group-hint">
                <textarea
                  [attr.testId]="'reason'"
                  class="form-control form-control-sm"
                  formControlName="reason"
                >
                </textarea>
                <control-errors-hint
                  [ctl]="shareLocationForm.controls['reason']"
                >
                </control-errors-hint>
              </div>
            </div>
          </div>

          <div class="col-md-12 margin-bottom">
            <div
              [class.has-danger]="
                (shareLocationForm.controls['link'].dirty ||
                  shareLocationForm.controls['link'].touched) &&
                shareLocationForm.controls['link'].errors
              "
              class="row form-group"
            >
              <div class="col-12">
                <label class="form-control-label">{{
                  'share-location.link' | translate
                }}</label>
              </div>
              <div class="col-12 input-group form-group-hint">
                <input
                  [attr.testId]="'link'"
                  class="form-control form-control-sm link-input"
                  formControlName="link"
                />
                <button
                  (click)="copyLinkToClipboard()"
                  class="copy-link-btn"
                  [disabled]="copyLinkDisabled"
                >
                  <i class="mdi mdi-link mdi-18px"></i>
                  {{ 'share-location.copy-link' | translate }}
                </button>
                <control-errors-hint [ctl]="shareLocationForm.controls['link']">
                </control-errors-hint>
              </div>
            </div>
          </div>

          <div class="buttons-wrapper col-sm-12">
            <button (click)="modalClose()" class="close-button" mat-button>
              {{ 'share-location.close' | translate }}
            </button>
            <div class="float-right-dev">
              <mat-spinner [diameter]="25" *ngIf="loading"></mat-spinner>
              <button
                (click)="formSubmit()"
                [disabled]="disableGenerateLinkButton"
                class="generate-link"
                mat-button
              >
                {{ 'share-location.generate-link' | translate }}
              </button>
              <button
                (click)="sendNotification()"
                [disabled]="disableSendButton"
                class="send"
                mat-button
                secured="shareLocation-send"
              >
                {{ 'share-location.send' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </afaqy-modal>
</div>
