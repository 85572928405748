<flash-message
  class="color-range-error-message"
  [message]="errorMessage"
></flash-message>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12 interval-container">
      <div
        class="form-group"
        [class.has-danger]="
          (fromCtl.dirty || fromCtl.touched) && fromCtl.errors
        "
      >
        <div class="input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            placeholder="{{ 'from' | translate }}"
            formControlName="from"
          />
          <control-errors-hint [ctl]="fromCtl"></control-errors-hint>
        </div>
      </div>
      <span class="interval-dash">-</span>
      <div
        class="form-group"
        [class.has-danger]="(toCtl.dirty || toCtl.touched) && toCtl.errors"
      >
        <div class="input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            placeholder="{{ 'to' | translate }}"
            formControlName="to"
          />
          <control-errors-hint [ctl]="toCtl"></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        class="form-group"
        [class.has-danger]="
          (colorCtl.dirty || colorCtl.touched) && colorCtl.errors
        "
      >
        <div class="input-group form-group-hint">
          <input
            class="form-control form-control-sm color-input"
            [style.backgroundColor]="colorCtl.value"
            formControlName="color"
          />
          <control-errors-hint [ctl]="colorCtl"></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12">
      <color-picker
        [initialColor]="colorCtl.value"
        (colorChange)="onChangeColor($event)"
      ></color-picker>
    </div>
  </div>
  <div class="row">
    <div class="col-12 contorls-container">
      <button
        type="button"
        class="btn btn-outline-warning btn-sm"
        (click)="onCancel()"
      >
        {{ 'cancel' | translate }}
      </button>
      <button type="submit" class="btn btn-outline-primary btn-sm">
        {{ 'ok' | translate }}
      </button>
    </div>
  </div>
</form>
