import { AppModel, AfaqyHelper } from '../../../common/classes';
import { UnitLastUpdate } from './unit-last-update';
import { UnitCounters } from './unit-counters';
import { UnitProfile } from './unit-profile';
import { UnitFuelConsumption } from './unit-fuel-consumption';
import { UnitTripDetector } from './unit-trip-detector';
import { UnitShipment } from './unit-shipment';
import { UnitServiceItem } from './unit-service-item';
import { UnitDriverBehavior } from './unit-driver-behavior';
import { UnitSensor, UnitSensorColor } from './unit-sensor';
import { Tailer } from '../../tailers/models';
import { Driver } from '../../drivers/models';
import { Status } from '../../status/models';
import { UnitCommand } from './unit-command';
import { UnitCCTV } from './unit-cctv';
import { CustomField } from 'app/modules/custom-fields/models';

export class Unit extends AppModel {
  private _imei: string;
  private _name: string;
  private _sim_number: string;
  private _sim_serial: string;
  private _device_serial: string;
  private _device: string;
  private _device_password: string;
  private _device_gateway: string;
  private _gateway: string;
  private _device_type: string = 'gps';
  private _active: boolean;
  private _icon: string;
  private _iconUrl: string;
  private _user_id: string;
  private _created_by: string;
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;
  private _driver_id: string;
  private _tailer_id: string;
  private _business_types: any;
  private _auto_tailer: boolean;
  private _auto_driver: boolean;
  private _groupsList: string[];
  private _last_update: UnitLastUpdate = new UnitLastUpdate();
  private _counters: UnitCounters = new UnitCounters();
  private _profile: UnitProfile = new UnitProfile();
  private _custom_fields: CustomField[] = [];
  private _fc: UnitFuelConsumption = new UnitFuelConsumption();
  private _td: UnitTripDetector = new UnitTripDetector();
  private _shipment_info: UnitShipment = new UnitShipment();
  private _sensors: UnitSensor[] = [];
  private _services: UnitServiceItem[] = [];
  private _commands: UnitCommand[] = [];
  private _driver_behavior: UnitDriverBehavior[] = [];
  private _driver: Driver = new Driver();
  private _tailer: Tailer = new Tailer();
  private _status: Status = new Status();
  private _operation_code: string;
  private _company: string;
  private _cctv: UnitCCTV = new UnitCCTV();
  private _hasCCTV: boolean = false;
  private _creator: string;
  private _owner: string;
  private _crat: string;
  private _skip_sim_information: boolean;
  private _is_wasl_connected: string;
  private _safe_intervals: boolean;
  private _job_order: any;

  protected copyFields = [
    'id',
    'name',
    'imei',
    'sim_number',
    'sim_serial',
    'device_serial',
    'device',
    'device_gateway',
    'gateway',
    'device_type',
    'driver_id',
    'business_types',
    'driver',
    'tailer_id',
    'tailer',
    'auto_tailer',
    'auto_driver',
    'active',
    'icon',
    'iconUrl',
    'created_at',
    'sensors',
    'services',
    'commands',
    'driver_behavior',
    'updated_at',
    'created_by',
    'user_id',
    'last_update',
    'counters',
    'job_order',
    'profile',
    'td',
    'shipment_info',
    'groupsList',
    'device_password',
    'deleted_at',
    'users_list',
    'custom_fields',
    'status',
    'operation_code',
    'company',
    'cctv',
    'hasCCTV',
    'creator',
    'owner',
    'crat',
    'skip_sim_information',
    'current_owner',
    'is_wasl_connected',
    // 'last_known_location',
  ];
  protected createFields = [
    'name',
    'imei',
    'sim_number',
    'sim_serial',
    'device_serial',
    'device',
    'device_gateway',
    'gateway',
    'device_type',
    'active',
    'icon',
    'business_types',
    'driver_id',
    'tailer_id',
    'auto_tailer',
    'auto_driver',
    'counters',
    'job_order',
    'profile',
    'fc',
    'td',
    'shipment_info',
    'custom_fields',
    'groupsList',
    'sensors',
    'services',
    'commands',
    'device_password',
    'users_list',
    'operation_code',
    'company',
    'cctv',
    'creator',
    'owner',
    'crat',
    'skip_sim_information',
    'current_owner',
    'is_wasl_connected',
    // 'last_known_location',
  ];
  protected updateFields = [
    'name',
    'imei',
    'sim_number',
    'sim_serial',
    'device_serial',
    'device',
    'device_gateway',
    'gateway',
    'device_type',
    'active',
    'icon',
    'business_types',
    'driver_id',
    'tailer_id',
    'auto_tailer',
    'auto_driver',
    'counters',
    'job_order',
    'profile',
    'fc',
    'td',
    'shipment_info',
    'custom_fields',
    'groupsList',
    'sensors',
    'services',
    'commands',
    'device_password',
    'users_list',
    'operation_code',
    'company',
    'cctv',
    'creator',
    'owner',
    'crat',
    'skip_sim_information',
    'current_owner',
    'is_wasl_connected',
    // 'last_known_location',
  ];

  public copyInto(obj: any) {
    super.copyInto(obj);
    this.updateCustomFields(obj);
    if (obj.fc) {
      this.fc.copyInto(obj.fc);
    }
    // if (obj.fc && obj.fc.math) {
    //   this.fc.math.copyInto(obj.fc.math);
    // }
    if (obj.sensors && obj.sensors.length) {
      const sensorsList: UnitSensor[] = [];
      for (const sensor of obj.sensors) {
        const sensorObj: UnitSensor = new UnitSensor();
        sensorObj.copyInto(sensor);
        this.copyIntoSensorColors(sensorObj);
        sensorsList.push(sensorObj);
      }
      this.sensors = sensorsList;
    }
    if (obj.services && obj.services.length) {
      let servicesList = [];
      for (const service of obj.services) {
        let serviceObj = new UnitServiceItem();
        serviceObj.copyInto(service);
        servicesList.push(serviceObj);
      }
      this.services = servicesList;
    }
    if (obj.commands && obj.commands.length) {
      let commandsList = [];
      for (const command of obj.commands) {
        let commandObj = new UnitCommand();
        commandObj.copyInto(command);
        commandsList.push(commandObj);
      }
      this.commands = commandsList;
    }
    if (obj.driver_behavior && obj.driver_behavior.length) {
      let driverBehaviorList = [];
      for (const driver_behavior of obj.driver_behavior) {
        let driver_behaviorObj = new UnitDriverBehavior();
        driver_behaviorObj.copyInto(driver_behavior);
        driverBehaviorList.push(driver_behaviorObj);
      }
      this.driver_behavior = driverBehaviorList;
    }
  }

  private copyIntoSensorColors(obj: UnitSensor): void {
    if (obj.colors && obj.colors.length && obj.colors[0]) {
      const sensorColorsList: UnitSensorColor[] = [];
      let sensorColorObj: UnitSensorColor;
      for (const sensorColor of obj.colors) {
        sensorColorObj = new UnitSensorColor();
        sensorColorObj.copyInto(sensorColor);
        sensorColorsList.push(sensorColorObj);
      }
      obj.colors = sensorColorsList;
    }
  }

  updateCustomFields(obj: any) {
    this.custom_fields = [];
    const cfField = AfaqyHelper.getMappedKey('custom_fields');
    if (obj[cfField]) {
      for (let x = 0; x < obj[cfField].length; x++) {
        let ncf = new CustomField();
        ncf.copyInto(obj[cfField][x]);
        this.custom_fields.push(ncf);
      }
    }
  }

  get business_types(): any {
    return this._business_types;
  }
  set business_types(value: any) {
    this._business_types = value;
  }

  get imei(): string {
    return this._imei;
  }

  set imei(value: string) {
    this._imei = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get sim_number(): string {
    return this._sim_number;
  }

  set sim_number(value: string) {
    this._sim_number = value;
  }

  get sim_serial(): string {
    return this._sim_serial;
  }

  set sim_serial(value: string) {
    this._sim_serial = value;
  }

  get device_serial(): string {
    return this._device_serial;
  }

  set device_serial(value: string) {
    this._device_serial = value;
  }

  get device(): string {
    return this._device;
  }

  set device(value: string) {
    this._device = value;
  }

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get created_by(): string {
    return this._created_by;
  }

  set created_by(value: string) {
    this._created_by = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get driver_id(): string {
    return this._driver_id;
  }

  set driver_id(value: string) {
    this._driver_id = value;
  }

  get tailer_id(): string {
    return this._tailer_id;
  }

  set tailer_id(value: string) {
    this._tailer_id = value;
  }

  get last_update(): UnitLastUpdate {
    return this._last_update;
  }

  set last_update(value: UnitLastUpdate) {
    this._last_update = value;
  }

  get counters(): UnitCounters {
    return this._counters;
  }

  set counters(value: UnitCounters) {
    this._counters = value;
  }

  get profile(): UnitProfile {
    return this._profile;
  }

  set profile(value: UnitProfile) {
    this._profile = value;
  }

  get custom_fields(): CustomField[] {
    return this._custom_fields;
  }

  set custom_fields(value: CustomField[]) {
    this._custom_fields = value;
  }

  get fc(): UnitFuelConsumption {
    return this._fc;
  }

  set fc(value: UnitFuelConsumption) {
    this._fc = value;
  }

  get td(): UnitTripDetector {
    return this._td;
  }

  set td(value: UnitTripDetector) {
    this._td = value;
  }
  get shipment_info(): UnitShipment {
    return this._shipment_info;
  }

  set shipment_info(value: UnitShipment) {
    this._shipment_info = value;
  }

  get auto_tailer(): boolean {
    return this._auto_tailer;
  }

  set auto_tailer(value: boolean) {
    this._auto_tailer = value;
  }

  get auto_driver(): boolean {
    return this._auto_driver;
  }

  set auto_driver(value: boolean) {
    this._auto_driver = value;
  }

  get groupsList(): string[] {
    return this._groupsList;
  }

  set groupsList(value: string[]) {
    this._groupsList = value;
  }

  get sensors(): UnitSensor[] {
    return this._sensors;
  }

  set sensors(value: UnitSensor[]) {
    this._sensors = value;
    this.updateUnitSensor();
  }

  get services(): UnitServiceItem[] {
    return this._services;
  }

  set services(value: UnitServiceItem[]) {
    this._services = value;
  }

  get commands(): UnitCommand[] {
    return this._commands;
  }

  set commands(value: UnitCommand[]) {
    this._commands = value;
  }

  get driver_behavior(): UnitDriverBehavior[] {
    return this._driver_behavior;
  }

  set driver_behavior(value: UnitDriverBehavior[]) {
    this._driver_behavior = value;
  }

  set is_wasl_connected(value: string) {
    this._is_wasl_connected = value;
  }
  get is_wasl_connected(): string {
    return this._is_wasl_connected;
  }

  get iconUrl(): string {
    return this._iconUrl;
  }

  set iconUrl(value: string) {
    this._iconUrl = value;
  }

  get driver(): Driver {
    return this._driver;
  }

  set driver(value: Driver) {
    this._driver = value;
  }

  get tailer(): Tailer {
    return this._tailer;
  }

  set tailer(value: Tailer) {
    this._tailer = value;
  }

  get device_password(): string {
    return this._device_password;
  }

  set device_password(value: string) {
    this._device_password = value;
  }

  get tailerName(): string {
    if (this.tailer) {
      return this.tailer.name;
    }
    return '';
  }

  get driverName(): string {
    if (this.driver) {
      return this.driver.name;
    }
    return '';
  }
  get status_id(): string {
    return this.job_order.status_id;
  }

  set status_id(value: string) {
    this.job_order.status_id = value;
  }

  get job_order(): any {
    return this._job_order;
  }

  set job_order(value: any) {
    this._job_order = value;
  }

  get status(): Status {
    return this._status;
  }

  set status(value: Status) {
    this._status = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get operation_code(): string {
    return this._operation_code;
  }

  set operation_code(value: string) {
    this._operation_code = value;
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
  }

  get cctv(): UnitCCTV {
    return this._cctv;
  }

  set cctv(value: UnitCCTV) {
    this._cctv = value;
  }

  get hasCCTV(): boolean {
    return this._hasCCTV;
  }

  set hasCCTV(value: boolean) {
    this._hasCCTV = value;
  }

  get accSensor() {
    if (this.accSensorObj === undefined) {
      this.updateUnitSensor();
    }
    if (this.accSensorObj) {
      return this.accSensorObj;
    }
    return null;
  }

  get acc() {
    const accSensor = this.accSensor;
    if (!accSensor) {
      return 0;
    }
    return this.last_update.prms[accSensor['param']];
  }

  get isIdle() {
    return this.acc && this.last_update.spd < this.td.min_speed ? true : false;
  }

  get creator(): string {
    return this._creator;
  }

  set creator(value: string) {
    this._creator = value;
  }

  get owner(): string {
    return this._owner;
  }

  set owner(value: string) {
    this._owner = value;
  }

  get crat(): string {
    return this._crat;
  }

  set crat(value: string) {
    this._crat = value;
  }
  get skip_sim_information(): boolean {
    return this._skip_sim_information;
  }

  set skip_sim_information(value: boolean) {
    this._skip_sim_information = value;
  }

  public get device_gateway(): string {
    return this._device_gateway;
  }
  public set device_gateway(value: string) {
    this._device_gateway = value;
  }
  public get gateway(): string {
    return this._gateway;
  }
  public set gateway(value: string) {
    this._gateway = value;
  }
  public get device_type(): string {
    return this._device_type;
  }
  public set device_type(value: string) {
    this._device_type = value;
  }

  public get safe_intervals(): boolean {
    return this._safe_intervals;
  }
  public set safe_intervals(value: boolean) {
    this._safe_intervals = value;
  }
  private accSensorObj: any;

  updateUnitSensor() {
    let sensor: any;
    if (this.sensors.length) {
      this.sensors.forEach((sen) => {
        if (sen.type == 'acc') {
          sensor = sen;
        }
      });
    }
    this.accSensorObj = sensor;
  }

  sensorParams: string[] = [];

  forList() {
    return {
      id: this.id,
      name: this.name,
      creator: this.creator,
      owner: this.owner,
      imei: this.imei,
      device_protocol: this.device,
      sim_card_number: this.sim_number,
      sim_card_serial: this.sim_serial,
      device_serial: this.device_serial,
      model: this.profile.model,
      vin: this.profile.vin,
      plate_number: this.profile.plate_number,
      driver: this.driver.name,
      trailer: this.tailer.name,
      title: this.titleForList(),
      groupList: this.groupsList,
    };
  }
}
