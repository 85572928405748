/**
 * Created by negm on 11/29/17.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MapService {
  splitterMode = true;
  onlineEvent = fromEvent(window, 'online');
  offlineEvent = fromEvent(window, 'offline');
  mapOptions = new BehaviorSubject<any>({});

  private _listeners: any = {};
  private layersControlListener = new Subject<any>();

  constructor() {
    this.addNewListener('map_main');
  }

  public addNewListener(key) {
    if (!this._listeners[key]) {
      this._listeners[key] = new Subject<any>();
    }
  }

  public removeListener(key) {
    delete this._listeners[key];
  }

  public listen(key): Subject<any> {
    return this._listeners[key];
  }

  public action(callback, key = 'map_main') {
    if (this._listeners[key]) {
      this._listeners[key].next(callback);
    } else {
      setTimeout(() => {
        this.action(callback, key);
      }, 1000);
    }
  }

  public layerControlListen(): Observable<any> {
    return this.layersControlListener.asObservable();
  }

  public changeLayerVisibility(layerId: string, isVisible: boolean) {
    let data = {};
    data[layerId] = isVisible;
    this.layersControlListener.next(data);
  }
}
