import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { AfaqyHelper } from '../../common/classes/afaqy-helper';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Directive({
  selector: '[timeago]',
})
export class TimeAgoDirective {
  private _time: any;
  private _maxInterval: any = true;
  intervalID: any;
  @Input('routerLink') routeParams: any[];

  @Input('timeago') set time(value: any) {
    if (typeof value == 'string' && !value.includes('-')) {
      this._time = parseInt(value);
    } else {
      this._time = value;
    }
  }

  @Input() set maxInterval(value: any) {
    this._maxInterval = value ? true : false;
  }

  @Input() limitDays: number;

  get time() {
    return this._time;
  }

  constructor(
    private authService: AuthService,
    protected translateService: TranslateService,
    private eltRef: ElementRef
  ) {}

  ngOnInit() {
    this.updateTime();
    let directive = this;
    this.intervalID = setInterval(function () {
      directive.updateTime();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  updateTime(): void {
    const el: HTMLElement = this.eltRef.nativeElement;
    if (!this.eltRef.nativeElement.id) {
      this.eltRef.nativeElement.id =
        'time_ago_' + Math.ceil(Math.random() * 100000);
    }
    if (
      !document.getElementById(this.eltRef.nativeElement.id) &&
      this.intervalID
    ) {
      clearInterval(this.intervalID);
      return;
    }
    if (this.time === 0) {
      el.innerHTML = 'N/A';
      return;
    }
    const seconds = moment().diff(this.time, 'seconds');
    const transKey = 'date_intervals.';
    const keys = [
      transKey + 'days',
      transKey + 'hours',
      transKey + 'minutes',
      transKey + 'seconds',
      transKey + 'ago',
      transKey + 'just_now',
      transKey + 'more_than',
    ];
    let trans = this.translateService.instant(keys);

    if (seconds <= 0) {
      el.innerHTML = trans[transKey + 'just_now'];
      return;
    }

    const time = AfaqyHelper.convertNumSecondsToPeriodString(seconds);

    let timeAgo = '';
    let timeDone = false;
    if (time['days']) {
      timeAgo += time['days'] + ' ' + trans[transKey + 'days'] + ' ';
      if (this.limitDays && time['days'] > this.limitDays)
        timeAgo =
          trans[transKey + 'more_than'] +
          ' ' +
          this.limitDays +
          ' ' +
          trans[transKey + 'days'] +
          ' ';
      timeDone = true;
    }
    if (
      (!this._maxInterval || (this._maxInterval && !timeDone)) &&
      time['hours']
    ) {
      timeAgo += time['hours'] + ' ' + trans[transKey + 'hours'] + ' ';
      timeDone = true;
    }
    if (
      (!this._maxInterval || (this._maxInterval && !timeDone)) &&
      time['minutes']
    ) {
      timeAgo += time['minutes'] + ' ' + trans[transKey + 'minutes'] + ' ';
      timeDone = true;
    }
    if (
      (!this._maxInterval || (this._maxInterval && !timeDone)) &&
      time['seconds']
    ) {
      timeAgo += time['seconds'] + ' ' + trans[transKey + 'seconds'] + ' ';
    }

    if (this.translateService.currentLang == 'ar-ltr') {
      timeAgo = trans[transKey + 'ago'] + ' ' + timeAgo;
    } else {
      timeAgo += trans[transKey + 'ago'];
    }
    el.innerHTML = timeAgo;
  }
}
