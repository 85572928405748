import { AfaqyObject } from '../../../common/classes';

export class AppsFleets extends AfaqyObject {
  private _enable: boolean = false;

  protected copyFields = ['enable'];

  get enable(): boolean {
    return this._enable;
  }

  set enable(value: boolean) {
    this._enable = value;
  }
}
