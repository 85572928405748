import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad,
  Route,
} from '@angular/router';
import { AuthService } from './auth.service';
import { Logger, AppConfig } from '../../common/classes';
import { Observable, of } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable()
export class WASLGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let url: string = state.url;
    //Logger.log("canActivate CHECK URL", url, route.data);
    let permissionKey = route.data['permissions'];
    // TODO check on token instead
    // if (!route.queryParams['sid']) {
    //     this.router.navigate([url], { skipLocationChange: AppConfig.skipLocationChange });
    //     return false;
    // }
    return this.checkAccess(permissionKey, url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    let url = `/${route.path}`;
    //Logger.log("canLoad CHECK Module Loading ....", url, route.data);
    let permissionKey = route.data['permissions'];
    // this.checkAccess(permissionKey, url).subscribe(result => {
    //     if (result == false) {
    //         url = `/`;
    //         this.router.navigate([url], { skipLocationChange: AppConfig.skipLocationChange});
    //     }
    // });
    return this.checkAccess(permissionKey, url);
  }

  checkAccess(permissionKey: string[], url: string): Observable<boolean> {
    if (this.authService.isLoggedIn) {
      return of(this.checkUserAccess(permissionKey, url));
    }
    return this.authService.loadedSession.pipe(
      take(1),
      map((e) => {
        if (e) {
          return this.checkUserAccess(permissionKey, url);
        }
        this.authService.redirectTOLogin('checkAccess');
        return false;
      })
    );
  }

  checkUserAccess(permissionKey: string[], url: string): boolean {
    if (this.authService.isLoggedIn) {
      if (!permissionKey || permissionKey.length == 0) {
        return true;
      }
      // if logged in and has WASL previllage
      if (this.authService.isWASL) return true;
    }
    // Logger.log('Permissions Denied', permissionKey);
    this.authService.redirectTo('/');
    //this.authService.redirectUrl = url;
    //this.authService.redirectTOLogin("checkUserAccess");
    return false;
  }
}
