import * as moment from 'moment';
import {
  Period,
  Section,
  SectionData,
  Item,
} from 'app/shared/modules/ngx-timeline/ngx-time-scheduler.model';
export const PERIODS: Period[] = [
  {
    name: '1 day',
    timeFramePeriod: 60,
    timeFrameOverall: 60 * 24,
    timeFrameHeaders: [
      // 'Do MMM',
      'HH',
    ],
    classes: 'period-1day',
  },
  {
    name: '3 days',
    timeFramePeriod: 60 * 3,
    timeFrameOverall: 60 * 24 * 3,
    timeFrameHeaders: ['Do MMM', 'HH'],
    classes: 'period-3day',
  },
  {
    name: '1 week',
    timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
    classes: '',
    timeFrameOverall: 1440 * 7,
    timeFramePeriod: 1440,
  },
  {
    name: '2 week',
    timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
    classes: '',
    timeFrameOverall: 1440 * 14,
    timeFramePeriod: 1440,
  },
];

export const SECTIONS: Section[] = [
  {
    id: '5e8da7a8dc4fae7a545c4297',
    routeName: 'Route A',
    unit_name: 'Bus A1',
    driver: 'Driver A1',
    status: 'Test',
    color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
  },
  {
    id: '5e8da7a8dc4fae7a545c4297',
    routeName: 'Route B',
    unit_name: 'Bus B1',
    driver: 'Driver B1',
    status: 'Test',
    color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
  },
  {
    id: '5e8da7a8dc4fae7a545c4297',
    routeName: 'Route C',
    unit_name: 'Bus C1',
    driver: 'Driver C1',
    status: 'Test',
    color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
  },
  {
    id: '5e8da7a8dc4fae7a545c4297',
    routeName: 'Route D',
    unit_name: 'Bus D1',
    driver: 'Driver D1',
    status: 'Test',
    color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
  },
  {
    id: '5e8da7a8dc4fae7a545c4297',
    routeName: 'Route E',
    unit_name: 'Bus E1',
    driver: 'Driver E1',
    status: 'Test',
    color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
  },
];
export const DETAILS_HEADER: SectionData[] = [
  { key: 'id', title: 'ID', width: 50, show: false, toggleable: false },
  {
    key: 'color',
    title: '',
    width: 40,
    type: 'color',
    show: true,
    toggleable: false,
  },
  {
    key: 'routeName',
    title: 'Route Name',
    width: 150,
    show: true,
    toggleable: true,
  },
  {
    key: 'unit_name',
    title: 'Unit Name',
    width: 150,
    additional: 'driver',
    show: true,
    toggleable: true,
  },
  {
    key: 'status',
    title: 'Status',
    width: 100,
    type: 'tag',
    show: false,
    toggleable: true,
  },
];
export const SOURCE_ITEMS = [
  {
    id: '5e8da7a8dc4fae7a545c4297',
    sectionID: '5e8da7a8dc4fae7a545c4297',
    name: 'Item 4',
    checkpoints: [
      { arrive: moment().startOf('day').add('1', 'hour'), wait: 10 },
      { arrive: moment().startOf('day').add('2', 'hour'), wait: 10 },
    ],
    nextPoints: [
      { arrive: moment().startOf('day').add('3', 'hour'), wait: 10 },
      // {arrive:moment().startOf('day').add('14', 'hour'),wait:0},
      { arrive: moment().startOf('day').add('4', 'hour'), wait: 10 },
    ],
    classes: 'in-progress',
    status: 'In Progress',
  },
  {
    id: 1,
    sectionID: 1,
    name: 'Item 1',
    checkpoints: [
      { arrive: moment().startOf('day').add('7', 'hour'), wait: 100 },
      { arrive: moment().startOf('day').add('10', 'hour'), wait: 90 },
      { arrive: moment().startOf('day').add('12', 'hour'), wait: 160 },
      { arrive: moment().startOf('day').add('19', 'hour'), wait: 240 },
    ],
    classes: 'planned',
    status: 'Planned',
  },
  {
    id: 2,
    sectionID: 3,
    name: 'Item 3',
    status: 'Planned',
    classes: 'planned',
    checkpoints: [
      {
        arrive: moment().startOf('day').add('2', 'hour'),
        wait: 1,
      },
      {
        arrive: moment().startOf('day').add('5', 'hour'),
        wait: 2,
      },
      {
        arrive: moment().startOf('day').add('9', 'hour'),
        wait: 3,
      },
    ],
    nextPoints: [
      {
        arrive: moment().startOf('day').add('12', 'hour'),
        wait: 90,
      },
      {
        arrive: moment().startOf('day').add('14', 'hour'),
        wait: 90,
      },
      {
        arrive: moment().startOf('day').add('32', 'hour'),
        wait: 90,
      },
    ],
  },
  {
    id: 3,
    sectionID: 2,
    name: 'Item 2',
    checkpoints: [
      { arrive: moment().startOf('day').add('12', 'hour'), wait: 0 },
      { arrive: moment().startOf('day').add('14', 'hour'), wait: 0 },
      { arrive: moment().startOf('day').add('22', 'hour'), wait: 0 },
    ],
    classes: 'in-progress',
    status: 'In Progress',
  },
];
