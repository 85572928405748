import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { shipmentLists } from 'app/common/classes/shipment';

@Component({
  selector: 'unit-form-shipment',
  templateUrl: './unit-form-shipment.component.html',
  styleUrls: ['./unit-form-shipment.component.scss'],
})
export class UnitFormShipmentComponent implements OnInit {
  cid = 'units-shipment-';
  @Input() group: FormGroup;

  product_category = shipmentLists.productCategories.map(
    (data) =>
      (data = {
        id: data?.id,
        name: this.translate.instant('units.' + data?.name),
      })
  );
  truck_type = shipmentLists.truckTypes.map(
    (data) =>
      (data = {
        id: data?.id,
        name: this.translate.instant('units.' + data?.name),
      })
  );
  shipment_type = shipmentLists.shipmentTypes.map(
    (data) =>
      (data = {
        id: data?.id,
        name: this.translate.instant('units.' + data?.name),
      })
  );
  shipment_payment_type = shipmentLists.shipmentPaymentTypes.map(
    (data) =>
      (data = {
        id: data?.id,
        name: this.translate.instant('units.' + data?.name),
      })
  );
  constructor(private translate: TranslateService) {}

  ngOnInit() {}
}
